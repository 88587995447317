import React, { useEffect, useState, useCallback } from "react";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/media/images/logo.jpeg";
import {
  defaultState,
  StepBoardingStateAtom,
} from "../../../../recoil/onBoarding";
import NavigationButtons from "../../../../Pages/Onboarding/components/steps/components/common/NavigationButtons";
import ProgressBar from "../../../../Pages/Onboarding/components/steps/components/common/ProgressBar";
import {
  getAiCurrentSlug,
  getCurrentAiAnswer,
  isAiAnswerModified,
  isCurrentAnswerValids,
} from "../../../../Pages/Onboarding/components/steps/context";
import { error, success } from "../../../../Utilities/toast";
import {
  generateAiQuestions,
  generateLiveSkillsGapAnalysis,
  generateSkillsGapAnalysis,
  getMyAiBoardingQuestions,
  saveAiQuestions,
  updateAiQuestionOnboarding,
} from "../../../../Redux/authenticate/authenticate.action";
import Header from "../../../../Pages/Onboarding/components/steps/components/common/Header";
import AIBoardingContent from "../../../../Pages/Onboarding/components/steps/components/AiComponents/AIBoardingContent";
import { updateIsUserStatus } from "../../../../Redux/authenticate/authenticate.slicer";
import OnBoardingShimmer from "../../../../Components/Shimmers/OnBoardingShimmer";
import { StepLoadingAtom } from "../../../../recoil/Loadings";

const AIQuestionBoard = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onboardingAiData, user } = useSelector((state) => state.authenticate);
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [originalAnswers, setOriginalAnswers] = useState([]);

  // Derived state
  const step = stepState.AIQuestionBoard.activeStep;
  const currentAnswer = getCurrentAiAnswer(stepState);
  const isAiQuestions = stepState.AIQuestionBoard.isAiQuestions;
  const isLastQuestion = step >= isAiQuestions.length;

  // Memoized functions
  const prepareQuestionData = useCallback(() => {
    const currentQuestion = getAiCurrentSlug(isAiQuestions, step);
    const existingQuestion = onboardingAiData?.find(
      (item) => item.question === currentQuestion
    );
    const modified = isAiAnswerModified(step, originalAnswers, currentAnswer);

    const questionData = {
      question_slug: currentQuestion,
      answer: currentAnswer,
      modified,
    };

    if (!existingQuestion) {
      return { operation: "create", ...questionData };
    }

    return {
      operation: "update",
      id: existingQuestion.id,
      ...questionData,
    };
  }, [isAiQuestions, step, onboardingAiData, originalAnswers, currentAnswer]);

  const updateLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.onBoarding.aiQuestionBoard = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const updateCarierLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.careerGoals.reTakeSkillsGapAssessment = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const updateLiveSkillGapLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.careerGoals.reLiveSkillsGapAssessment = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const saveCurrentQuestion = useCallback(() => {
    if (step > 0) {
      dispatch(
        saveAiQuestions({
          payload: isAiQuestions[step - 1],
          onSuccess: () => {
            dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
          },
          onError: () => {},
        })
      );
    }
  }, [dispatch, isAiQuestions, step]);

  // Event handlers
  const handleNext = useCallback(
    async (isNextBoard) => {
      if (!isCurrentAnswerValids(currentAnswer)) {
        return error("Please answer the question");
      }
      try {
        const questionData = prepareQuestionData();
        if (!questionData) return;

        updateLoadingState(true);

        if (questionData.operation === "update" && questionData.modified) {
          await dispatch(
            updateAiQuestionOnboarding({
              payload: {
                new_answer: questionData.answer,
              },
              aiQuestionId: questionData.id,
              onSuccess: () => {
                success("AI Question Updated Successfully");
                dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
              },
              onError: () => {},
            })
          );
        }

        if (isNextBoard) {
          saveCurrentQuestion();
          setStepState(defaultState);
          dispatch(updateIsUserStatus());
          handleRetake();
          generateAiFeedback();
          return navigate("/career-goals");
        }

        if (isAiQuestions.length < 5 && isLastQuestion) {
          await dispatch(
            generateAiQuestions({
              AiBoardingPayload: {
                target_career: user?.target_career?.split("#")[0],
                previous_questions: isAiQuestions,
              },
              onSuccess: (res) => {
                success("AI Questions Generated Successfully");
                updateLoadingState(false);

                saveCurrentQuestion();

                setStepState(
                  produce((draft) => {
                    draft.AIQuestionBoard.isAiQuestions.push({
                      question: res,
                      answer: null,
                    });
                    draft.AIQuestionBoard.activeStep += 1;
                  })
                );
              },
              onError: () => {
                updateLoadingState(false);
              },
            })
          );
        } else {
          setStepState(
            produce((draft) => {
              draft.isActiveMode = "AI";
              draft.AIQuestionBoard.activeStep += 1;
              draft.isBoardingLoading = false;
            })
          );
          updateLoadingState(false);
        }
      } catch (err) {
        updateLoadingState(false);
        console.error("Error handling next step:", err);
      }
    },
    [
      currentAnswer,
      prepareQuestionData,
      updateLoadingState,
      dispatch,
      isAiQuestions,
      isLastQuestion,
      user,
      saveCurrentQuestion,
      setStepState,
      navigate,
    ]
  );

  const handleBack = useCallback(() => {
    setStepState(
      produce((draft) => {
        if (draft.AIQuestionBoard.activeStep === 1) {
          draft.isActiveMode = "manual";
          draft.AIQuestionBoard.activeStep = 0;
          return;
        }
        draft.AIQuestionBoard.activeStep -= 1;
      })
    );
  }, [setStepState]);

  const handleSaveLater = useCallback(() => {
    setStepState(defaultState);
    dispatch(updateIsUserStatus());
    handleRetake();
    generateAiFeedback();
    navigate("/career-goals");
  }, [setStepState, navigate]);

  const handleRetake = () => {
    updateCarierLoadingState(true);
    dispatch(
      generateSkillsGapAnalysis({
        onSuccess: () => {
          updateCarierLoadingState(false);
        },
        onError: () => {
          updateCarierLoadingState(false);
          error("Something went wrong, please try again later");
        },
      })
    );
  };

  const generateAiFeedback = () => {
    updateLiveSkillGapLoadingState(true);
    dispatch(
      generateLiveSkillsGapAnalysis({
        onSuccess: () => {
          updateLiveSkillGapLoadingState(false);
        },
        onError: () => {
          updateLiveSkillGapLoadingState(false);
          error("Something went wrong, please try again later");
        },
      })
    );
  };

  // Effects
  useEffect(() => {
    if (onboardingAiData?.length > 0) {
      const initialAnswers = onboardingAiData.map((item) => ({
        question: item.question,
        answer: item.answer,
      }));

      if (onboardingAiData.length > isAiQuestions.length) {
        setStepState(
          produce((draft) => {
            draft.AIQuestionBoard.isAiQuestions = initialAnswers;
          })
        );
      }
      setOriginalAnswers(initialAnswers);
    }
  }, [onboardingAiData, isAiQuestions.length, setStepState]);

  return (
    <div className="layout-root">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 px-20">
        <div className="col-span-2">
          <div className="min-h-screen pt-20 flex flex-col justify-center">
            <div className="max-w-3xl w-full">
              {loadingState.onBoarding.aiQuestionBoard ? (
                <OnBoardingShimmer steps={8} activeStep={step} />
              ) : (
                <>
                  <ProgressBar steps={5} activeStep={step} />
                  <AIBoardingContent />
                  <NavigationButtons
                    activeStep={step}
                    totalSteps={5}
                    onBack={handleBack}
                    onNext={handleNext}
                    onSaveLater={handleSaveLater}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center">
          <img
            src={logo}
            className="img-fluid"
            style={{ width: "744px" }}
            alt="."
          />
        </div>
      </div>
    </div>
  );
};

export default AIQuestionBoard;
