import { produce } from "immer";
import CreatableSelect from "react-select/creatable";

export const MANUAL_QUESTIONS_SLUG_CONFIGRATION = {
  1: {
    text: "What specific skills are you confident in that are directly related to your target role?",
    slug: "confident-skills-target-role",
  },
  2: {
    text: "Which skills or knowledge areas do you feel least confident in for this role?",
    slug: "areas-of-low-confidence",
  },
  3: {
    text: "How do you prefer to learn new skills? (e.g., online courses, workshops, hands-on projects, mentoring)",
    slug: "preferred-learning-methods",
  },
  4: {
    text: "Are there any certifications, industry tools, or technologies in your field that you're unfamiliar with but want to learn?",
    slug: "desired-certifications-tools",
  },
  5: {
    text: "What is your ultimate career goal, and how does this role fit into that path?",
    slug: "ultimate-career-goal",
  },
  6: {
    text: "What challenges or obstacles have prevented you from learning new skills in the past?",
    slug: "past-learning-challenges",
  },
  7: {
    text: "Is there anything else you feel we should know to best help you reach your career goal?",
    slug: "additional-information-for-career-goal",
  },
};

export const getManualCurrentSlug = (step) => {
  return step === 8
    ? "your-resume"
    : MANUAL_QUESTIONS_SLUG_CONFIGRATION[step]?.slug;
};

export const getAiCurrentSlug = (questions, step) => {
  return questions[step - 1]?.question;
};

export const getCurrentManualAnswer = (step, onboardingData, answers) => {
  const currentSlug = getManualCurrentSlug(step);
  const currentQuestionData = onboardingData?.find(
    (item) => item?.question_slug === currentSlug
  );
  return answers.hasOwnProperty(currentSlug)
    ? answers[currentSlug]
    : currentQuestionData?.answer || "";
};

export const getCurrentAiAnswer = (stepState) => {
  const currentQuestion =
    stepState.AIQuestionBoard.isAiQuestions[
      stepState.AIQuestionBoard.activeStep - 1
    ];
  return currentQuestion?.answer || "";
};

export const isAnswerModified = (
  originalAnswers,
  currentSlug,
  currentAnswer,
  fileInput
) => {
  if (!originalAnswers[currentSlug]) return false;

  if (currentSlug === "your-resume" && fileInput) {
    return fileInput.current?.files?.length > 0;
  }
  return currentAnswer !== originalAnswers[currentSlug];
};

export const isAiAnswerModified = (step, originalAnswers, currentAnswer) => {
  if (!originalAnswers[step - 1]?.answer) return false;

  return currentAnswer !== originalAnswers[step - 1]?.answer;
};

export const isCurrentAnswerValids = (currentAnswer) => {
  if (
    currentAnswer &&
    [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ].includes(currentAnswer.type)
  ) {
    return true;
  }

  return currentAnswer && currentAnswer?.trim()?.length > 0;
};

export const IsNotSureInitialForm = [
  {
    question: "What technical or soft skills do you currently possess?",
    answer: {
      technicalField: "",
      softSkills: [],
    },
  },
  {
    question:
      "Have you ever used any specific tools, software, or platforms in your past roles or education?",
    answer: "",
  },
  {
    question: "What type of work experience do you have?",
    answer: "",
  },
  {
    question: "What is your highest level of education or training?",
    answer: "",
  },
  {
    question:
      "What type of activities do you enjoy or excel at in your personal or professional life?",
    answer: [],
  },
  {
    question: "Do you have any specific preferences for your future career?",
    answer: "",
  },
  {
    question:
      "Are you open to learning new skills or transitioning to a new field?",
    answer: "",
  },
];

export const NotSureStepHeader = ({ title, subtitle }) => (
  <>
    <h3 className="h3 onBoard_heading mb-2">{title}</h3>
    <h4 className="h4 onBoard_heading mb-3">{subtitle}</h4>
  </>
);

export const handleCreatableSelectChange = (
  stepIndex,
  selectedOption,
  setStepState
) => {
  setStepState(
    produce((draft) => {
      draft.notSureQuestionBoard.formData[stepIndex].answer = selectedOption
        ? selectedOption
        : "";
    })
  );
};

export const handleSelectChange = (stepIndex, value, setStepState) => {
  setStepState(
    produce((draft) => {
      draft.notSureQuestionBoard.formData[stepIndex].answer = value;
    })
  );
};

export const CreatableSelectWithPortal = ({
  options,
  value,
  onChange,
  defaultLabel,
  name,
}) => (
  <CreatableSelect
    className="basic-single w-full mt-3"
    classNamePrefix="select"
    value={value}
    onChange={onChange}
    defaultValue={{ label: defaultLabel, value: "" }}
    options={options}
    isClearable
    isSearchable
    name={name}
    menuPortalTarget={document.body}
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
  />
);

export const isNotSureCurrentStepValid = (currentStep, formData) => {
  const currentQuestion = formData[currentStep - 1];
  if (!currentQuestion) return false;
  switch (currentStep) {
    case 1:
      return (
        currentQuestion.answer.technicalField &&
        currentQuestion.answer.softSkills.length > 0
      );
    case 2:
      return currentQuestion.answer.trim() !== "";
    case 3:
    case 4:
    case 6:
    case 7:
      return currentQuestion.answer !== "";
    case 5:
      return currentQuestion.answer.length > 0;
    default:
      return true;
  }
};
