import { useState } from "react";
import { useDispatch } from "react-redux";
import { getJobListings, scrapJobs } from "../../Redux/NexaIqSlice";
import { Select, Spin } from "antd";
import {
  addPreferenceforJobs,
  setPreference,
  updatePreferenceforJobs,
} from "../../Redux/UserProfileSlice";

const ProfilePreference = ({
  setStep,
  onClose,
  jobsPreference,
  profilePreferences,
  // setCanScrapJobs,
  // resetFilters,
}) => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [dropdownPreference, setDropdownPreference] = useState(null);

  const handleSetPreference = (value) => {
    const { job_role, job_type } = profilePreferences?.find(
      (item) => item?.id === value
    );
    let payload = {
      title: job_role,
      work_schedule: job_type,
      industry_preference: "",
      location: "",
      workplace_type: "",
    };
    setDropdownPreference(payload);
  };

  const handleSavePreference = () => {
    if (dropdownPreference) {
      setLoader(true);
      // setCanScrapJobs(true);
      // resetFilters();
      dispatch(
        !jobsPreference?.title
          ? addPreferenceforJobs({
              payload: dropdownPreference,
              onSuccess: (data) => {
                setLoader(false);
                if (data) {
                  onClose();
                  dispatch(setPreference(dropdownPreference));
                  setDropdownPreference(null);
                } else {
                  // setCanScrapJobs(false);
                }
              },
            })
          : updatePreferenceforJobs({
              id: jobsPreference?.id,
              payload: dropdownPreference,
              onSuccess: (data) => {
                setLoader(false);
                if (data) {
                  onClose();
                  dispatch(setPreference(dropdownPreference));
                  setDropdownPreference(null);
                } else {
                  // setCanScrapJobs(false);
                }
              },
              onError: () => {
                setLoader(false);
              }
            })
      );
    }
  };

  return (
    <>
      <div className=" pt-3 pb-4 px-4 overflow-auto">
        <Select
          placeholder="Select from Preferences"
          onChange={(value) => {
            handleSetPreference(value);
          }}
          className="w-full mt-2 h-[48px] capitalize"
        >
          {profilePreferences?.map((preference) => (
            <Select.Option
              className="capitalize"
              key={preference?.id}
              value={preference.id}
            >
              {preference.job_role}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="flex gap-3 border-t border-gray-300 py-3 px-4 w-full justify-end">
        <button
          type="button"
          onClick={() => setStep(1)}
          className="modal-button cancel-button"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSavePreference}
          disabled={loader || !dropdownPreference}
          className="modal-button save-button"
        >
          {jobsPreference?.title && Object.keys(jobsPreference).length !== 0
            ? "Update"
            : "Apply"}{" "}
          {loader && <Spin size="small" className="pl-2" />}
        </button>
      </div>
    </>
  );
};

export default ProfilePreference;
