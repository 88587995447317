import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import RichTextEditor from "../../../features/RichTextEditor";
import { createMarkup } from "../../../Utilities/CreateMarkup";
import {
  getSingleResume,
  updateResumeSectionPosition,
  updateWorkExperienceFun,
} from "../../../Redux/ResumeBuilderSlice";
import { error, success } from "../../../Utilities/toast";

import { FiChevronUp, FiChevronDown } from "react-icons/fi";

export default function WorkExperience({ workExperience, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [hoveredSection, setHoveredSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [experiencesState, setExperiencesState] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const [textEditorOpen, setTextEditorOpen] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  const [acheivementContent, setAcheivementContent] = useState("");
  const [defaultAcheivementContent, setAcheivementDefaultContent] =
    useState("");
  const [acheivementEditorOpen, setAcheivementEditorOpen] = useState(null);
  const editableRefs = useRef({});

  const ICON_STYLE = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };
  const updateExperiencesState = (index, field, value) => {
    setExperiencesState((prev) =>
      prev.map((proj, idx) =>
        idx === index ? { ...proj, [field]: value } : proj
      )
    );
  };

  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();

    setActiveField({ index, field });

    if (field === "started_at" || field === "ended_at") {
      setDatePickerOpen(`${index}-${field}`);
    } else if (field === "description") {
      const content = experiencesState[index]?.description || "";
      setEditorContent(content);
      setDefaultContent(content);
      setTextEditorOpen(`${index}-${field}`);
    } else if (field === "key_acheivement") {
      const acheivementContent = experiencesState[index]?.key_acheivement || "";
      setAcheivementContent(acheivementContent);
      setAcheivementDefaultContent(acheivementContent);
      setAcheivementEditorOpen(`${index}-${field}`);
    }
    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      updateExperiencesState(index, field, value);
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Parse the selected date
    date.setHours(0, 0, 0, 0); // Set the time to local midnight
    // Format the date to "YYYY-MM-DDT00:00:00"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    setExperiencesState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };
  const getExperienceInfo = () => {
    dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalExperience = workExperience[activeField.index];
    const updatedExperience = experiencesState[activeField.index];
    const hasChanges =
      originalExperience[activeField.field]?.trim() !==
      updatedExperience[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
      setAcheivementEditorOpen(null);
      const payload = {
        company_data: {
          name: updatedExperience?.name,
          description: updatedExperience?.description,
          key_acheivement: updatedExperience?.key_acheivement,
        },
        role_data: {
          role_name: updatedExperience?.role_name,
          started_at: updatedExperience?.started_at,
          ended_at:
            updatedExperience?.checked == true
              ? null
              : updatedExperience?.ended_at,
          location: updatedExperience?.location,
          type: updatedExperience?.type,
        },
      };
      const nowData = new Date();
      const endedAtDate = new Date(payload?.role_data?.ended_at);
      if (payload?.role_data?.started_at >= payload?.role_data?.ended_at) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }

      dispatch(
        updateWorkExperienceFun({
          payload,
          workExperienceId: updatedExperience?.id,
          onSuccess: (res) => {
            success(res);
            getExperienceInfo();
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      setTextEditorOpen(null);
      setAcheivementEditorOpen(null);
    }
  };
  const updateExperienceState = (index, field, value) => {
    setExperiencesState((prev) =>
      prev.map((proj, idx) =>
        idx === index ? { ...proj, [field]: value } : proj
      )
    );
  };

  const renderEditableField = (experience, index, field) => {
    const isActive = activeField.index === index && activeField.field === field;
    const isHovered =
      hoveredField.index === index && hoveredField.field === field;
    const originalExperience = workExperience[index];
    const updatedExperience = experiencesState[index];

    const hasChanges =
      field &&
      originalExperience &&
      updatedExperience &&
      originalExperience[field]?.trim() !== updatedExperience[field]?.trim();
    return (
      <span
        className={`editable-field ${isActive ? "active px-3" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        onDoubleClick={() => handleFieldClick(index, field)}
        style={{
          position: "relative",
          border: isActive ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={
            field === "description" || field === "key_acheivement"
              ? false
              : isActive
          }
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={
            activeField.field != "started_at" && activeField.field != "ended_at"
              ? submitChanges
              : ""
          }
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {(field === "started_at" && datePickerOpen === `${index}-${field}`) ||
          (field === "ended_at" && datePickerOpen === `${index}-${field}`) ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                experience && !isNaN(new Date(experience).getTime())
                  ? new Date(experience).toLocaleDateString("en-CA") // Converts to 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : field === "description" &&
            textEditorOpen === `${index}-${field}` ? (
            <div className=" customized-editor mt-1.5  ">
              <RichTextEditor
                defaultContent={defaultContent}
                setEditorContent={setEditorContent}
              />
            </div>
          ) : field === "description" ? (
            <p
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
              className={` ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              dangerouslySetInnerHTML={createMarkup(experience)}
            />
          ) : field === "key_acheivement" &&
            acheivementEditorOpen === `${index}-${field}` ? (
            <div className=" customized-editor mt-1.5  ">
              <RichTextEditor
                defaultContent={defaultAcheivementContent}
                setEditorContent={setAcheivementContent}
              />
            </div>
          ) : field === "key_acheivement" ? (
            <p
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
              className={` ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              dangerouslySetInnerHTML={createMarkup(experience)}
            />
          ) : (
            experience
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...ICON_STYLE, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={submitChanges}
            style={{ ...ICON_STYLE, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...ICON_STYLE, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(experiencesState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...experiencesState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setExperiencesState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          workexperience: updatedSections,
        },
        onSuccess: () => {
          dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setExperiencesState(previousSections);
        },
      })
    );
  };
  const initExperiencesState = () =>
    workExperience
      ?.slice()
      .sort((a, b) => Number(a?.position) - Number(b?.position));
  useEffect(() => {
    if (editorContent) {
      updateExperienceState(
        activeField.index,
        activeField.field,
        editorContent
      );
    }
  }, [editorContent]);
  useEffect(() => {
    if (acheivementContent) {
      updateExperienceState(
        activeField.index,
        activeField.field,
        acheivementContent
      );
    }
  }, [acheivementContent]);
  useEffect(() => {
    setExperiencesState(initExperiencesState());
  }, [workExperience]);
  return (
    workExperience &&
    workExperience?.length > 0 && (
      <section className={`cv-professional_summary  `}>
        {experiencesState?.map((experience, index) => {
          const isActive = activeSection === index;
          const isHovered = hoveredSection === index;
          return (
            <div
              className={`editable-field my-1 relative mb-${
                getSingleResumeDoc?.inner_section_spacing
              } ${
                isActive || isHovered ? "editable-field active bg-white" : ""
              }`}
              onMouseEnter={() => setHoveredSection(index)}
              onMouseLeave={() => setHoveredSection(null)}
              onClick={() => {
                if (index !== activeSection && experiencesState !== 1) {
                  setActiveSection(index);
                } else {
                  setActiveSection(null);
                }
              }}
              style={{
                border:
                  isActive || isHovered
                    ? "1px solid #ffff"
                    : "1px solid transparent",
                backgroundColor: isActive ? "#ffff" : "transparent",
                cursor: "text",
              }}
            >
              {isActive && (
                <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold py-2 px-4 rounded-full shadow-md flex items-center gap-2">
                  {/* Upper Arrow */}
                  <FiChevronUp
                    className={`text-white text-lg cursor-pointer ${
                      activeSection == 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: activeSection - 1,
                        source: activeSection,
                      });
                    }}
                  />

                  {/* Lower Arrow */}
                  <FiChevronDown
                    className={`text-white text-lg cursor-pointer  ${
                      activeSection == experiencesState.length - 1
                        ? "hidden"
                        : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: activeSection + 1,
                        source: activeSection,
                      });
                    }}
                  />
                </div>
              )}

              <div
                key={index}
                className="cv-professional_summary-item d-flex justify-content-between mt-3 align-items-center "
              >
                <div className="text-capitalize">
                  <h5
                    className="cv-item-title text-md mb-0 apply-font"
                    style={{
                      color: getSingleResumeDoc?.subheading_color || "#000",
                    }}
                  >
                    {renderEditableField(experience?.name, index, "name")}
                  </h5>

                  <>
                    <p className="cv-item-date mb-0 apply-font mt-1">
                      {renderEditableField(
                        experience?.role_name,
                        index,
                        "role_name"
                      )}
                    </p>
                  </>
                </div>
                <div className="text-capitalize">
                  <>
                    <p className="cv-item-date apply-font">
                      {renderEditableField(
                        formatDate(experience?.started_at),
                        index,
                        "started_at"
                      )}{" "}
                      -{" "}
                      {renderEditableField(
                        formatDate(experience?.ended_at),
                        index,
                        "ended_at"
                      )}
                    </p>
                  </>
                </div>
              </div>
              <div>
                <p className="cv-item-date apply-font">
                  {renderEditableField(
                    experience?.key_acheivement,
                    index,
                    "key_acheivement"
                  )}
                </p>
              </div>
              <div>
                <p className="cv-item-date apply-font">
                  {renderEditableField(
                    experience?.description,
                    index,
                    "description"
                  )}
                </p>
              </div>
              {experiencesState?.length > 0 &&
                index !== experiencesState?.length - 1 && (
                  <hr className="mt-3" />
                )}
            </div>
          );
        })}
      </section>
    )
  );
}
