import React, { useEffect, useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Edit2,
  Save,
  X,
  Upload,
  Trash2,
} from "lucide-react";
import { useSelector } from "react-redux";
import {
  getOnBoardingAction,
  onBoardingAction,
  updateOnboarding,
} from "../../../Redux/authenticate/authenticate.action";
import { success } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { StepLoadingAtom } from "../../../recoil/Loadings";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { Spin } from "antd";
import { useRef } from "react";

const OriginalSkillGapQuestions = () => {
  const localSlugs = [
    {
      id: 1,
      slug: "confident-skills-target-role",
      question:
        "What specific skills are you confident in that are directly related to your target role?",
    },
    {
      id: 2,
      slug: "areas-of-low-confidence",
      question:
        "Which skills or knowledge areas do you feel least confident in for this role?",
    },
    {
      id: 3,
      slug: "preferred-learning-methods",
      question:
        "How do you prefer to learn new skills? (e.g., online courses, workshops, hands-on projects, mentoring)",
    },
    {
      id: 4,
      slug: "desired-certifications-tools",
      question:
        "Are there any certifications, industry tools, or technologies in your field that you’re unfamiliar with but want to learn?",
    },
    {
      id: 5,
      slug: "ultimate-career-goal",
      question:
        "What is your ultimate career goal, and how does this role fit into that path?",
    },
    {
      id: 6,
      slug: "past-learning-challenges",
      question:
        "What challenges or obstacles have prevented you from learning new skills in the past?",
    },
    {
      id: 7,
      slug: "additional-information-for-career-goal",
      question:
        "Is there anything else you feel we should know to best help you reach your career goal?",
    },
    {
      id: 8,
      slug: "your-resume",
      question: "Your Resume",
    },
  ];
  const dispatch = useDispatch();
  const { onboardingData } = useSelector((state) => state.authenticate);
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [accordionData, setAccordionData] = useState(localSlugs);
  const [openItem, setOpenItem] = useState(null);
  const [editingSlug, setEditingSlug] = useState(null);
  const [tempAnswer, setTempAnswer] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const isQuestionActionLoader = (val) => {
    setLoadingState(
      produce((draft) => {
        draft.careerGoals.skillGapAssessmentQuestions = val;
      })
    );
  };

  const updateAnswer = (payload) => {
    isQuestionActionLoader(true);
    dispatch(
      updateOnboarding({
        payload,
        onSuccess: () => {
          dispatch(getOnBoardingAction({ onComplete: () => {} }));
          isQuestionActionLoader(false);
          setEditingSlug(null);
          setTempAnswer("");
          success("Updated Successfully");
        },
        onError: () => {
          isQuestionActionLoader(false);
        },
      })
    );
  };
  const createAnswer = (payload) => {
    isQuestionActionLoader(true);
    dispatch(
      onBoardingAction({
        payload,
        onSuccess: () => {
          dispatch(getOnBoardingAction({ onComplete: () => {} }));
          isQuestionActionLoader(false);
          setEditingSlug(null);
          setTempAnswer("");
          success("Created Successfully");
        },
        onError: () => {
          isQuestionActionLoader(false);
        },
      })
    );
  };

  const toggleAccordion = (id) => {
    setOpenItem(openItem === id ? null : id);
  };

  const handleEdit = (slug, answer) => {
    setEditingSlug(slug);
    setTempAnswer(answer);
  };

  const handleSave = (slug) => {
    const previousData = onboardingData?.find(
      (onboarding) => onboarding?.question_slug === slug
    );
    const prevAnswer =
      previousData?.answer || previousData?.fileUrl ? true : false;

    const payload = {
      question_slug: slug,
      answer: tempAnswer,
    };

    if (prevAnswer) {
      updateAnswer(payload);
    } else {
      createAnswer(payload);
    }
  };

  const handleCancel = () => {
    setEditingSlug(null);
    setTempAnswer("");
  };

  const handleFileChange = (event, id, slug) => {
    const file = event.target.files[0];
    if (file) {
      const previousData = onboardingData?.find(
        (onboarding) => onboarding?.question_slug === slug
      );
      const prevAnswer =
        previousData?.answer || previousData?.fileUrl ? true : false;

      const payload = {
        question_slug: slug,
        file: file,
      };
      if (prevAnswer) {
        updateAnswer(payload);
      } else {
        createAnswer(payload);
      }
      setSelectedFile(file);
      setAccordionData(
        accordionData.map((item) =>
          item.id === id ? { ...item, answer: file.name } : item
        )
      );
    }
  };

  const handleFileRemove = (id) => {
    setSelectedFile(null);
    setAccordionData(
      accordionData.map((item) =>
        item.id === id ? { ...item, answer: null } : item
      )
    );
  };

  useEffect(() => {
    const file = onboardingData?.find(
      (onboarding) => onboarding?.question_slug === "your-resume"
    )?.file_url;
    setSelectedFile(file);
  }, [setSelectedFile]);
  const recognition = useRef(null);
  const [answerMode, setAnswerMode] = useState(null);

  useEffect(() => {
    if (recognition.current) {
      recognition.current.stop();
    }
    setAnswerMode("");
  }, [openItem]);

  const speechToText = () => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      setAnswerMode("voice");
      recognition.current.lang = "en-US";
      recognition.current.interimResults = true;
      recognition.current.continuous = true;
      recognition.current.start();

      recognition.current.onresult = (event) => {
        const answer = Array.from(event.results)
          .filter((result) => result[0].confidence > 0.5) // Check for 100% confidence
          .map((alts) => alts[0].transcript)
          .join("");

        setTempAnswer(answer);
        // setTextAnswer(answer);
      };
      recognition.current.onerror = (event) => {
        // setIsCollapse(false);
        // setAnswerMode(null);
        // setVoiceAnswer(null);
        // onChange("");
      };
    } else {
    }
  };

  return (
    <div className="w-full mx-auto p-1 space-y-4">
      {accordionData.map((item) => {
        const answer = onboardingData?.find(
          (onboarding) => onboarding?.question_slug === item?.slug
        )?.answer;
        const file = onboardingData?.find(
          (onboarding) => onboarding?.question_slug === item?.slug
        )?.file_url;
        return (
          <div
            key={item.id}
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            {/* Question Header */}
            <button
              onClick={() => toggleAccordion(item.id)}
              className="w-full flex justify-between items-center p-4 bg-white hover:bg-gray-50 transition-colors duration-200"
            >
              <h3 className="text-base line-clamp-1 text-left font-medium text-gray-900">
                {item.question}
              </h3>
              {openItem === item.id ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </button>

            {openItem === item.id && (
              <div className="p-4 bg-gray-50">
                {item.slug === "your-resume" ? (
                  <div className="space-y-4">
                    {selectedFile ? (
                      <div className="flex items-center justify-between p-3 gap-5 bg-white rounded-lg">
                        <span className="text-sm text-gray-600 line-clamp-2">
                          {selectedFile?.name || selectedFile}
                        </span>
                        <button
                          onClick={() => handleFileRemove(item.id)}
                          className="text-red-500 hover:text-red-600"
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    ) : (
                      <label className="flex flex-col items-center p-6 bg-white rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400 cursor-pointer">
                        <Upload className="h-8 w-8 text-gray-400" />
                        <span className="mt-2 text-sm text-gray-500">
                          Click to upload or drag and drop
                        </span>
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e) =>
                            handleFileChange(e, item?.id, item?.slug)
                          }
                          accept=".pdf,.doc,.docx"
                        />
                      </label>
                    )}
                  </div>
                ) : editingSlug === item.slug ? (
                  <div className="space-y-4">
                    <textarea
                      value={tempAnswer}
                      onChange={(e) => setTempAnswer(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      rows={3}
                    />
                    <div className="">
                      <button
                        onClick={() => {
                          if (answerMode == "voice") {
                            setAnswerMode("");
                            if (recognition.current) {
                              recognition.current.stop();
                            }
                          } else {
                            speechToText();
                          }
                        }}
                        className={`w-[40px] h-[40px] flex items-center justify-center rounded-full transition-all ${
                          answerMode == "voice"
                            ? "bg-red-500 animate-pulse relative"
                            : "bg-blue-500"
                        } text-white`}
                      >
                        <i className="ri-mic-fill " />

                        {/* Recording Waves Effect (Only when recording starts) */}
                        {answerMode == "voice" && (
                          <span className="absolute w-[50px] h-[50px] rounded-full border-4 border-blue-300 animate-ping"></span>
                        )}
                      </button>
                    </div>

                    <div className="flex justify-end gap-2">
                      <button
                        onClick={handleCancel}
                        disabled={
                          loadingState.careerGoals.skillGapAssessmentQuestions
                        }
                        className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 bg-white rounded-lg border border-gray-300"
                      >
                        <X size={16} />
                        <span>Cancel</span>
                      </button>
                      <button
                        onClick={() => handleSave(item.slug)}
                        disabled={
                          loadingState.careerGoals.skillGapAssessmentQuestions
                        }
                        className="flex items-center gap-2 px-3 py-1.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
                      >
                        <Save size={16} />
                        <span>Save</span>
                        {loadingState.careerGoals
                          .skillGapAssessmentQuestions && <Spin size={20} />}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="relative group">
                    <p className="text-gray-600 pr-10">
                      {answer || "This question is not answerd."}
                    </p>
                    <button
                      onClick={() => handleEdit(item.slug, answer)}
                      className="absolute top-0 right-0 transition-opacity duration-200 p-1 text-gray-400 hover:text-gray-600"
                    >
                      <Edit2 size={16} />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OriginalSkillGapQuestions;
