import { useEffect, useRef, useState } from "react";
import { MdDelete, MdFileUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/main-loader";
import { error, success } from "../../../Utilities/toast";
import DebounceSelect from "../../../Components/dropdowns/DebounceSelect";
import { updateOnboardingUser } from "../../../Redux/authenticate/authenticate.action";
import { getProfessions } from "../../../Redux/UserProfileSlice";
import { getResume } from "../../../Redux/ResumeBuilderSlice";
import ResumeSection from "./ResumeSection";
import { Select } from "antd";
import UserAddress from "./UserAddress";

const UserInfo = ({
  userProfileInfo,
  setUserProfileInfo,
  activeStep,
  setActiveStep,
}) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.authenticate);
  const { jobProfessions } = useSelector((state) => state.UserProfile);
  const { allResume } = useSelector((state) => state.ResumeBuilder);

  const [initialUserProfileInfo, setInitialUserProfileInfo] = useState({});
  const [resumeFile, setResumeFile] = useState(null);
  const [addressError, setAddressError] = useState(false);

  const targetCareersData = jobProfessions?.professions?.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const handleProfileChange = (e) => {
    const { name, value, type, files } = e.target;

    if (name === "contact" && !/^\d*$/.test(value)) {
      return;
    }

    if (type === "file") {
      const file = files[0];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 2; // 2MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file && !validImageTypes.includes(file.type)) {
        error("Please upload a valid image file (jpg, jpeg, or png).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        error(`File size must be less than ${maxSizeInMB}MB.`);
        return;
      }

      setUserProfileInfo((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      setUserProfileInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChangeAddress = (address) => {
    setUserProfileInfo((prev) => ({
      ...prev,
      address,
    }));
  };

  const handleDeleteImage = () => {
    setUserProfileInfo((prev) => ({ ...prev, image: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(userProfileInfo).some(
      (key) => userProfileInfo[key] !== initialUserProfileInfo[key]
    );
    if (!userProfileInfo?.address) {
      setAddressError(true);
      return;
    }
    if (hasChanged) {
      let payload = new FormData();
      Object.entries(userProfileInfo).forEach(([key, val]) => {
        if (key === "image") {
          if (typeof val === "object") {
            payload.append(key, val);
          }
          return;
        } else {
          if (val) {
            payload.append(key, val);
          }
        }
      });
      dispatch(
        updateOnboardingUser({
          payload,
          onSuccess: (res) => {
            success(res);
            setActiveStep((prev) => prev + 1);
          },
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleDropdown = (name, value) => {
    setUserProfileInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchTargetCareeers = async (value, page) => {
    await dispatch(
      getProfessions({
        page: page,
        search: value,
        onSuccess: () => {},
        onError: () => {},
      })
    );
  };

  useEffect(() => {
    if (user) {
      const loadedUserData = {
        name: user?.fullName || "",
        email: user?.email || "",
        image: user?.image || "",
        address: user?.address || "",
        linkedIn_profile: user?.linkedIn_profile || "",
        contact: user?.contact || "",
        education: user?.education || "",
        employment_status: user?.employment_status || "",
        target_career: user?.target_career || "",
        resume_feedback_id: user?.customer_stats?.resume_feedback_id || "",
      };
      setUserProfileInfo(loadedUserData);
      setInitialUserProfileInfo(loadedUserData);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className="profile-header">
          <div className="image-upload-container">
            {!userProfileInfo.image && (
              <>
                <input
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  id="image-upload-input"
                  type="file"
                  name="image"
                  onChange={handleProfileChange}
                />
                <label htmlFor="image-upload-input">
                  <div className="image-upload-box">
                    <span className="upload-icon text-center">
                      <MdFileUpload className="m-auto" />
                      <p>Upload you Photo</p>
                    </span>
                  </div>
                </label>
              </>
            )}

            {userProfileInfo.image && (
              <div className="image-preview-container">
                <img
                  src={
                    typeof userProfileInfo.image === "object"
                      ? URL.createObjectURL(userProfileInfo.image)
                      : userProfileInfo.image
                  }
                  alt="Selected"
                  className="image-preview"
                />
                <div className="image-overlay" onClick={handleDeleteImage}>
                  <button className="delete-icon">
                    <MdDelete />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="profile-form_cover">
          <div className="profile-form">
            <input
              type="text"
              id="username"
              name="name"
              className="form-control mt-3"
              placeholder="Full Name"
              required
              value={userProfileInfo.name}
              onChange={handleProfileChange}
            />
            <input
              type="email"
              id="email"
              name="email"
              className="form-control mt-3"
              placeholder="Email"
              required
              disabled
              value={userProfileInfo.email}
              onChange={handleProfileChange}
            />
            <input
              type="text"
              id="contact"
              name="contact"
              className="form-control mt-3"
              placeholder="Contact Number"
              value={userProfileInfo.contact}
              onChange={handleProfileChange}
            />
            <input
              type="text"
              id="education"
              className="form-control mt-3"
              placeholder="Education"
              name="education"
              value={userProfileInfo.education}
              onChange={handleProfileChange}
            />
            <UserAddress
              initialValue={user?.address || ""}
              setAddress={handleChangeAddress}
              addressError={addressError}
              setAddressError={setAddressError}
            />

            <input
              type="url"
              id="linkedIn_profile"
              className="form-control mt-3"
              placeholder="LinkedIn Profile URL"
              name="linkedIn_profile"
              value={userProfileInfo.linkedIn_profile}
              onChange={handleProfileChange}
            />

            <DebounceSelect
              className="w-full mt-3 h-[48px] capitalize"
              value={userProfileInfo.target_career}
              data={targetCareersData ? targetCareersData : []}
              placeholder="My Current Target Career"
              fetchOptions={fetchTargetCareeers}
              onChange={(value) => handleDropdown("target_career", value)}
              currentPage={jobProfessions?.page}
              totalPages={jobProfessions?.total_pages}
            />

            <Select
              className="w-full mt-3 h-[48px] capitalize"
              value={userProfileInfo.employment_status}
              onChange={(value) => handleDropdown("employment_status", value)}
              placeholder="Select Employment Status"
              showSearch
            >
              <Select.Option value="">Select Employment Status</Select.Option>
              <Select.Option value="FullTime">Full-Time</Select.Option>
              <Select.Option value="PartTime">Part-Time</Select.Option>
              <Select.Option value="Contractor">Contractor</Select.Option>
              <Select.Option value="Intern">Intern</Select.Option>
              <Select.Option value="Freelancer">Freelancer</Select.Option>
              <Select.Option value="Temporary">Temporary</Select.Option>
              <Select.Option value="Unemployed">Unemployed</Select.Option>
              <Select.Option value="Student">Student</Select.Option>
              <Select.Option value="Retired">Retired</Select.Option>
              <Select.Option value="OnLeave">OnLeave</Select.Option>
              <Select.Option value="Sabbatical">Sabbatical</Select.Option>
              <Select.Option value="Probation">Probation</Select.Option>
            </Select>

            <div className="mt-3 w-full">
              <ResumeSection
                allResume={allResume}
                value={userProfileInfo?.resume_feedback_id}
                handleDropdown={handleDropdown}
                resumeFile={resumeFile}
                setResumeFile={setResumeFile}
              />
            </div>

            <div
              className={`w-full mt-2 flex ${
                activeStep === 1 ? "justify-end" : "justify-between"
              }`}
            >
              <button className="ic-btn" type="submit">
                Save & Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserInfo;
