import { useNavigate } from "react-router-dom";
import "./Section4.css";

const Section4 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open("https://cal.com/prithvi-nexa/30min", "_blank");
  };

  const features = [
    {
      icon: "/images/5.png",
      title: "AI Smart-Screening",
      description:
        "Automated talent screening ensures only the top 1% reach your desk. Our AI video interview technology provides transparent analytics, reports, and recordings—so recruiters can spot BS.",
    },
    {
      icon: "/images/4.png",
      title: "Integrated Talent Hub",
      description:
        "Access a pre-vetted pool of candidates with deep insights into their skills, experience, and cultural fit.",
    },
    {
      icon: "/images/3.png",
      title: "Realtime Feedback",
      description:
        "Instantly notify candidates of their status and provide actionable feedback.",
    },
    {
      icon: "/images/2.png",
      title: "Seamless Integration",
      description:
        "Connect Nexa RU with your ATS, LinkedIn, and more for a smoother workflow.",
    },
    {
      icon: "/images/1.png",
      title: "Behavioral & Technical Insights",
      description:
        "Gain in-depth reports on candidates’ soft skills, technical expertise, and growth potential.",
    },
    {
      icon: "/images/1.png", // Assuming a new icon; adjust the path as needed
      title: "AI-Powered Recruiting Agents",
      description:
        "Let AI do the heavy lifting by sourcing top talent, screening resumes, and scheduling interviews. These intelligent agents identify the best candidates based on your requirements, automatically coordinating interview schedules to save your team valuable time.",
    },
  ];

  return (
    <section className="why-nexa-section">
      <div className="intro-text">
        <h2 className="section-title">Why Nexa RU?</h2>
        <p className="section-subtitle">
          Sorting through resumes, evaluating unqualified candidates, and
          finding the right fit can take forever. Nexa RU fixes this.
        </p>
      </div>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <img
              src={feature.icon}
              alt={`${feature.title} Icon`}
              className="feature-icon"
            />
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
      <div
        className="cta-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          className="demo-button"
          onClick={handleClick}
          style={{
            marginBottom: "40px", // Adds space below the button; adjust value as needed
          }}
        >
          See It in Action: Book a Demo
        </button>
      </div>
    </section>
  );
};

export default Section4;
