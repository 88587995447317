import React, { useState } from "react";
import "./Section7.css";

const Section7 = () => {
  const [active, setActive] = useState(null);

  const questions = [
    {
      title: "What is Nexa SmartScreen?",
      answer:
        "Nexa SmartScreen is an AI-powered candidate screener designed to efficiently process numerous applications. It screens candidates against criteria provided by the company, ensuring only the most suitable candidates are shortlisted, thereby saving time and effort in recruitment.",
    },
    {
      title: "Who is Nexa RU designed for?",
      answer:
        "RU is designed for recruters, recruitment agencies, staffing firms, and enterprises of all kind- if you're hiring, we have a solution for you! ",
    },
    {
      title: "Why is Nexa's Talent Pool more important than ever?",
      answer:
        "Efficiency is everything- The top 5% pre-vetted and tested talent in a free-to-use candidate database from NEXA job seekers that integrates with their ATS by pressing a button",
    },
    {
      title: "What makes Nexa RU different from other career services tools?",
      answer:
        "Unlike other platforms, Nexa offers an all-in-one recruiting solution with AI-driven screening, centralized management, ATS integration, and automation all tailored specifically for your firm.",
    },
  ];

  return (
    <div
      className="outermost-container-section7"
      style={{ marginBottom: "10%" }}
    >
      <div className="default-container-section7">
        <div className="text-wrapper-section7">
          <div style={{ marginTop: "90px" }} className="narrow-text-section7">
            <p className="text-default-blue-bold-section7 text-center mb-8 FiraFontFamily bold-family">
              Frequently Asked Questions
            </p>
          </div>
          <div className="wide-text-section7">
            <p className="text-black text-center FiraFontFamily mb-4">
              Got questions about Nexa's Recruiting Platform?
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-xxl-6 col-xl-7 col-lg-8 m-auto">
          <div className="ic-according">
            {questions.map((question, index) => (
              <div
                className={`ic-according-item ${
                  active === index ? "active" : ""
                }`}
                key={question.title}
              >
                <button
                  className={`${active === index ? "active" : ""}`}
                  onClick={() => setActive(active === index ? null : index)}
                >
                  <span className="flex mr-5 md:!mr-0">{question.title}</span>
                </button>
                <div className={`body ${active === index ? "active" : ""}`}>
                  {active === index ? <p>{question.answer}</p> : ""}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section7;
