import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import logo from "../../../../assets/media/images/logo.jpeg";
import { useNavigate } from "react-router-dom";
import Header from "../../../../Pages/Onboarding/components/steps/components/common/Header";
import ProgressBar from "../../../../Pages/Onboarding/components/steps/components/common/ProgressBar";
import NavigationButtons from "../../../../Pages/Onboarding/components/steps/components/common/NavigationButtons";
import NotSureBoardingComponents from "../../../../Pages/Onboarding/components/steps/components/NotSureComponents/NotSureBoardingComponents";
import { isNotSureCurrentStepValid } from "./context";
import { error } from "../../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { saveNotSureQuestions } from "../../../../Redux/authenticate/authenticate.action";
import { updateIsUserStatus } from "../../../../Redux/authenticate/authenticate.slicer";
import { StepLoadingAtom } from "../../../../recoil/Loadings";

const NotSureQuestionBoard = () => {
  //EXTRACT
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [_, setLoadingState] = useRecoilState(StepLoadingAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;
  const TOTAL_STEPS = 7;

  //FUNCTIONS
  const handleNext = () => {
    if (!isNotSureCurrentStepValid(currentStep, formData))
      return error("Please answer the questions");
    localStorage.setItem("onboardingFormData", JSON.stringify(formData));
    if (currentStep === TOTAL_STEPS) {
      setLoadingState(
        produce((draft) => {
          draft.onBoarding.notSureQuestionBoard = true;
        })
      );
      dispatch(
        saveNotSureQuestions({
          formData,
          onComplete: () => {
            setLoadingState(
              produce((draft) => {
                draft.onBoarding.notSureQuestionBoard = false;
              })
            );
            dispatch(updateIsUserStatus());
            navigate("/career-goals");
          },
        })
      );
      return;
    }
    setStepState(
      produce((draft) => {
        draft.notSureQuestionBoard.activeStep += 1;
      })
    );
  };

  const handleBack = () => {
    setStepState(
      produce((draft) => {
        if (draft.notSureQuestionBoard.activeStep === 1) {
          draft.isActiveMode = "welcome";
          draft.notSureQuestionBoard.activeStep = 0;
          return;
        }
        draft.notSureQuestionBoard.activeStep -= 1;
      })
    );
  };

  const handleSaveLater = () => {
    setLoadingState(
      produce((draft) => {
        draft.onBoarding.notSureQuestionBoard = true;
      })
    );
    dispatch(
      saveNotSureQuestions({
        formData,
        onComplete: () => {
          setLoadingState(
            produce((draft) => {
              draft.onBoarding.notSureQuestionBoard = false;
            })
          );
          dispatch(updateIsUserStatus());
          navigate("/career-goals");
        },
      })
    );
  };

  //USE EFFECTS
  useEffect(() => {
    const savedFormData = localStorage.getItem("onboardingFormData");
    if (savedFormData) {
      setStepState(
        produce((draft) => {
          draft.notSureQuestionBoard.formData = JSON.parse(savedFormData);
        })
      );
    }
  }, []);

  return (
    <div className="layout-root">
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 px-20">
        <div className="col-span-2">
          <div className="min-h-screen pt-20 flex flex-col justify-center">
            <div className="max-w-3xl">
              <ProgressBar steps={TOTAL_STEPS} activeStep={currentStep} />
              <NotSureBoardingComponents />
              <NavigationButtons
                activeStep={currentStep}
                totalSteps={TOTAL_STEPS}
                onBack={handleBack}
                onNext={handleNext}
                onSaveLater={handleSaveLater}
              />
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center">
          <img
            src={logo}
            className="img-fluid"
            style={{ width: "744px" }}
            alt="Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default NotSureQuestionBoard;
