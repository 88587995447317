import downArrow from "../assets/icons/downArrow.svg";
import {
  Book_open_light,
  File_dock_light,
  Stat,
  User_scan_light,
  faqs,
  help_center,
  table_settings_light,
} from "../assets/icons/sub-menu-icons";

export const navData = [
  {
    title: "Use Cases",
    icon: downArrow,
    isOpen: false,
    subMenu: [
      {
        title: "For Bootcamps & Vocational Centers",
        icon: File_dock_light,
        route: "/comingsoon",
      },
      {
        title: "Colleges & Universities",
        icon: User_scan_light,
        route: "/comingsoon",
        // route: "/mock-interview",
      },
      {
        title: "For Career Creators",
        icon: Stat,
        route: "/comingsoon",
      },
      {
        title: "Veteran Organizations",
        icon: Book_open_light,
        route: "/comingsoon",
      },
      {
        title: "Workforce & Employment Development Centers",
        icon: table_settings_light,
        route: "/comingsoon",
      },
      {
        title: "Enterprise Recruitment",
        icon: Stat,
        route: "/comingsoon",
      },
    ],
    // subMenu: [
    //   {
    //     title: "Resume optimization",
    //     icon: File_dock_light,
    //   },
    //   {
    //     title: "Interview simulation",
    //     icon: User_scan_light,
    //     route: "/mock-interview",
    //   },
    //   {
    //     title: "Skills Gap Analysis",
    //     icon: Stat,
    //   },
    //   {
    //     title: "Real time labor market analytics",
    //     icon: Chart_alt_light,
    //   },
    //   {
    //     title: "Automated Job applying",
    //     icon: Star_light,
    //   },
    //   {
    //     title: "Virtual career coach",
    //     icon: User_alt_light,
    //   },
    //   {
    //     title: "Enhancing student employability",
    //     icon: candidates,
    //   },
    //   {
    //     title: "Curriculum integration",
    //     icon: Transger_light,
    //   },
    //   {
    //     title: "Performance trucking",
    //     icon: pie_chart_light,
    //   },
    //   {
    //     title: "Automated Candidate Screening",
    //     icon: candidates,
    //   },
    // ],
  },
  {
    title: "Products",
    icon: downArrow,
    isOpen: false,
    subMenu: [
      {
        title: "Nexa Elevate",
        icon: table_settings_light,
        route: "/landing",
      },
      {
        title: "Nexa RU (Beta)",
        icon: Book_open_light,
        route: "/rucoming",
      },
    ],
  },
  {
    title: "Resources",
    icon: downArrow,
    isOpen: false,
    subMenu: [
      {
        title: "Help center",
        icon: help_center,
        route: "/faq",
      },
      {
        title: "FAQ",
        icon: faqs,
        route: "/faq",
      },
    ],
  },
  {
    title: "Company",
    route: "/about-us",
    isOpen: false,
    // subMenu: [
    //   {
    //     title: "FAQ",
    //     link: "/faq",
    //   },
    //   {
    //     title: "Feedback",
    //     link: "https://docs.google.com/forms/d/1clHBZQhEsblWH8f5x3X_hcCHsJycYJLdr5YdmRA8y4k",
    //   },
    //   {
    //     title: "How it works",
    //     link: "/how-it-work",
    //   },
    //   {
    //     title: "Knowledgebase",
    //     link: "/coming-soon",
    //   },
    // ],
  },
  {
    title: "Pricing",
    route: "/pricing",
    isOpen: false,
    // subMenu: [
    //   {
    //     title: "Pricing",
    //     link: "/pricing",
    //   },
    // ],
  },
  {
    title: "Nexa IQ",
    route: "/nexa-iq",
    isOpen: false,
  },
];
