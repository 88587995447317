import React, { useRef, useState } from "react";

const TextQuestionStep = ({ question, value, onChange }) => {
  const recognition = useRef(null);
  const [answerMode, setAnswerMode] = useState(null);
  const handleChange = (e) => {
    if (e.target.value.length <= 1000) {
      onChange(e.target.value);
    }
  };
  const speechToText = () => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      setAnswerMode("voice");
      recognition.current.lang = "en-US";
      recognition.current.interimResults = true;
      recognition.current.continuous = true;
      recognition.current.start();

      recognition.current.onresult = (event) => {
        const answer = Array.from(event.results)
          .filter((result) => result[0].confidence > 0.5) // Check for 100% confidence
          .map((alts) => alts[0].transcript)
          .join("");

        onChange(answer);
        // setTextAnswer(answer);
      };
      recognition.current.onerror = (event) => {
        // setIsCollapse(false);
        // setAnswerMode(null);
        // setVoiceAnswer(null);
        // onChange("");
      };
    } else {
    }
  };
  return (
    <div className="ic-heading py-3">
      <h2 className="h4 onBoard_heading mb-4 leading-9">{question}</h2>
      <div className="text-area">
        <textarea
          placeholder="Type your answer here..."
          rows={4}
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          value={value}
          onChange={handleChange}
        />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500 mt-1">
            {value.length}/1000 characters
          </p>
          <div className="">
            <button
              onClick={() => {
                if (answerMode == "voice") {
                  setAnswerMode("");
                  if (recognition.current) {
                    recognition.current.stop();
                  }
                } else {
                  speechToText();
                }
              }}
              className={`w-12 h-12 flex items-center justify-center rounded-full transition-all ${
                answerMode == "voice"
                  ? "bg-red-500 animate-pulse relative"
                  : "bg-blue-500"
              } text-white`}
            >
              <i className="ri-mic-fill text-2xl" />

              {/* Recording Waves Effect (Only when recording starts) */}
              {answerMode == "voice" && (
                <span className="absolute w-16 h-16 rounded-full border-4 border-blue-300 animate-ping"></span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextQuestionStep;
