import { ArrowRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import getInTouchLeft from "../../assets/media/aboutUs/about-us-get-left.png";
import getInTouchRight from "../../assets/media/aboutUs/about-us-get-right.png";

const GetInTouchSection = () => {
  return (
    <section className="relative bg-gradient-to-b from-blue-50 via-white to-indigo-50 overflow-hidden py-20">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-96 h-96 bg-blue-200/30 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-200/30 rounded-full blur-3xl animate-pulse delay-700" />
      </div>

      {/* Grid Pattern */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNjB2NjBIMHoiLz48cGF0aCBkPSJNMzAgMzBoMzB2MzBIMzB6IiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L2c+PC9zdmc+')] opacity-40" />

      {/* Content */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="backdrop-blur-lg bg-gradient-to-r from-blue-600/10 via-indigo-600/10 to-purple-600/10 rounded-2xl p-8 md:p-12 shadow-xl border border-white/20">
          <div className="relative flex flex-col items-center text-center">
            {/* Left Image */}
            <div className="absolute left-0 top-1/2 -translate-y-1/2 hidden md:block">
              <img
                src={getInTouchLeft}
                alt=""
                className="w-32 h-auto object-contain opacity-80"
              />
            </div>

            {/* Content */}
            <div className="max-w-2xl mx-auto">
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-transparent bg-clip-text">
                  Get in Touch
                </span>
              </h2>

              <p className="text-xl text-gray-600 mb-8">
                Join leading institutions, enterprises, workforce development
                centers, and career coaches that trust Nexa to supercharge the
                economy.
              </p>
              <Link to="/contact-us">
                <button className="inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-full font-medium hover:from-blue-600 hover:to-indigo-600 transition-all duration-300 group">
                  Contact Us
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </Link>
            </div>

            {/* Right Image */}
            <div className="absolute right-0 top-1/2 -translate-y-1/2 hidden md:block">
              <img
                src={getInTouchRight}
                alt=""
                className="w-32 h-auto object-contain opacity-80"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouchSection;
