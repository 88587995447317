import React from "react";

const OnBoardingShimmer = ({
  steps = 7,
  activeStep = 1,
  showTextarea = true,
  buttonsCount = 3,
}) => {
  return (
    <div className="p-6 mx-auto w-full">
      <div className="flex gap-2 mb-4">
        {Array.from({ length: steps }).map((_, index) => (
          <div
            key={index}
            className={`w-10 h-1 ${
              index < activeStep ? "bg-blue-500" : "bg-gray-300"
            } animate-pulse`}
          ></div>
        ))}
      </div>

      <div className="h-6 w-4/5 bg-gray-300 rounded animate-pulse mb-2"></div>
      <div className="h-6 w-3/5 bg-gray-300 rounded animate-pulse mb-4"></div>

      {showTextarea && (
        <>
          <div className="h-60 w-full bg-gray-300 rounded animate-pulse mb-2"></div>
          <div className="h-4 w-1/5 bg-gray-300 rounded animate-pulse mb-4"></div>
        </>
      )}

      <div className="flex gap-4">
        {Array.from({ length: buttonsCount }).map((_, index) => (
          <div
            key={index}
            className={`${
              index === buttonsCount - 1 ? "w-36 opacity-50" : "w-20"
            } h-10 bg-gray-300 rounded-lg animate-pulse`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default OnBoardingShimmer;
