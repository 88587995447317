import React, { useState, useRef, useEffect } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { success } from "../../../Utilities/toast";
import {
  getResumeInterests,
  updateResumeInterests,
  updateResumeSectionPosition,
} from "../../../Redux/ResumeBuilderSlice";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Interests({ interests, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeInterestIndex, setActiveInterestIndex] = useState(null);
  const [hoveredInterestIndex, setHoveredInterestIndex] = useState(null);
  const [interestState, setInterestState] = useState([]);
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const handleFieldClick = (index) => {
    if (activeInterestIndex !== null) submitChanges();
    setActiveInterestIndex(index);
    setTimeout(() => {
      editableRefs.current[index]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setInterestState((prev) =>
        prev?.map((interest, idx) => (idx === index ? value : interest))
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setInterestState((prev) =>
        prev?.map((interest, idx) => (idx === index ? value : interest))
      );
    }
  };
  const fetchInterests = () => {
    dispatch(getResumeInterests({ resumeId: id, onSuccess: () => {} }));
  };

  const submitChanges = () => {
    const originalInterest = interests?.interests?.find(
      (i, idx) => idx === activeInterestIndex
    );
    const updatedInterest = interestState.find(
      (c) => c.id === activeInterestIndex
    );
    const hasChanges =
      originalInterest && originalInterest !== updatedInterest?.trim();
    if (hasChanges) {
      setActiveInterestIndex(null);
      setHoveredInterestIndex(null);
      dispatch(
        updateResumeInterests({
          interestId: interests?.id,
          payload: { interests: interestState },
          onSuccess: (res) => {
            success(res);
            fetchInterests();
          },
          onError: () => {
            setInterestState(interests?.interests);
          },
        })
      );
    } else {
      setActiveInterestIndex(null);
      setHoveredInterestIndex(null);
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(interestState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...interestState];

    setInterestState(reorderedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          interests: [{ id: interests?.id, data: reorderedSections }],
        },
        onSuccess: () => {
          setActiveInterestIndex(null);
          setHoveredInterestIndex(null);
          dispatch(getResumeInterests({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setInterestState(previousSections);
        },
      })
    );
  };
  const renderInterest = (interest, index) => {
    const isActive = activeInterestIndex === index;
    const isHovered = hoveredInterestIndex === index;
    const originalInterest = interests?.interests?.find(
      (i, idx) => idx === index
    );

    const hasChanges =
      originalInterest && originalInterest !== interest?.trim();

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active px-3" : ""}`}
        onMouseEnter={() => setHoveredInterestIndex(index)}
        onMouseLeave={() => setHoveredInterestIndex(null)}
        onDoubleClick={() => handleFieldClick(index, interest.id)}
        style={{
          position: "relative",
          border:
            isActive || isHovered ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[index] = el)}
          onInput={(e) => handleFieldInput(index, e)}
          onBlur={submitChanges}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field `}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {interest}
        </span>
        {isActive && (
          <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold  px-1 rounded-full shadow-md flex items-center gap-2">
            {/* Upper Arrow */}
            <FiChevronUp
              className={`text-white text-lg cursor-pointer ${
                activeInterestIndex == 0 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeInterestIndex - 1,
                  source: activeInterestIndex,
                });
              }}
            />

            {/* Lower Arrow */}
            <FiChevronDown
              className={`text-white text-lg cursor-pointer  ${
                activeInterestIndex == interestState.length - 1 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeInterestIndex + 1,
                  source: activeInterestIndex,
                });
              }}
            />
          </div>
        )}
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={() => setActiveInterestIndex(null)}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, interest.id)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };

  useEffect(() => {
    if (interests) setInterestState(interests?.interests);
  }, [interests]);

  return (
    <section className="cv-interests">
      {interests && interests?.interests?.length > 0 && (
        <ul className="cv-skills-list d-flex justify-content-start">
          {interestState?.map((interest, index) => {
            return (
              <li
                className="cv-skill_top apply-font flex gap-1 align-middle mt-1 mb-0"
                key={index}
              >
                <span className="font-bold text-lg">•</span>{" "}
                {renderInterest(interest, index)}
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}
