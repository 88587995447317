import React from "react";

const ChatShimmer = () => {
  return (
    <div className="grid h-screen grid-cols-12">
      <div className="col-span-12 md:col-span-6 lg:col-span-4 h-full border-r rounded-tl-3xl rounded-bl-3xl bg-black border-black border-opacity-10 p-4 animate-pulse">
        <div className="flex items-center">
          <div className="h-6 w-32 bg-gray-700 rounded"></div>
          <div className="ml-auto h-6 w-6 bg-gray-700 rounded-full"></div>
        </div>
        <div className="relative mt-4">
          <div className="h-10 w-full bg-gray-700 rounded-lg"></div>
        </div>
        <div className="mt-6 space-y-4">
          {[1, 2, 3, 4].map((_, index) => (
            <div key={index} className="flex items-center gap-3 p-2">
              <div className="h-12 w-12 bg-gray-700 rounded-full"></div>
              <div className="flex-1">
                <div className="h-4 w-3/4 bg-gray-700 rounded mb-1"></div>
                <div className="h-3 w-1/2 bg-gray-700 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="col-span-12 md:col-span-6 lg:col-span-8 relative">
        <div className="w-full flex flex-col h-full">
          <div className="flex-grow flex flex-col justify-center items-center gap-3">
            <div className="h-12 w-12 bg-gray-700 rounded-full"></div>
            <div className="h-6 w-40 bg-gray-700 rounded"></div>
            <div className="h-4 w-28 bg-gray-700 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatShimmer;
