import Repository from "./Repository";

const GET_ALL_SECTORS = "/skill-practice/customer/sector";
const GET_QUESTIONS = "/skill-practice/session/questions";
const SAVE_QUESTION_RESPONSE = "/skill-practice/session/response";
const GET_FEEDBACK = "/skill-practice/session/feedback";

const SkillsPracticeRepository = {
  getAllSectors() {
    return Repository.get(GET_ALL_SECTORS);
  },
  getQuestions(payload) {
    return Repository.post(GET_QUESTIONS, payload);
  },
  saveQuestionResponse(payload) {
    return Repository.post(SAVE_QUESTION_RESPONSE, payload);
  },
  getFeedback(sessionId) {
    return Repository.get(`${GET_FEEDBACK}?session_id=${sessionId}`);
  },
};

export default SkillsPracticeRepository;
