import { Button, Form, Input, InputNumber, Select } from "antd";
import { useState } from "react";
import Sec4 from "../../assets/Images/sec4.png";

export default function Section4() {
  const { TextArea } = Input;

  const [formSubmitted, setFormSubmitted] = useState(false);

  // Updated onFinish function
  const onFinish = async (values) => {
    console.log("Success:", values);

    // Prepare the data in the expected format
    const fields = [
      { name: "firstName", value: values.firstName },
      { name: "lastName", value: values.lastName },
      { name: "email", value: values.email },
      { name: "phone", value: values.mobileNumber },
      { name: "company", value: values.companyName },
      { name: "organizationType", value: values.organizationType },
      { name: "useCase", value: values.comments },
    ];

    try {
      const response = await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/46093892/9667d9dc-dcbb-4cb3-ac7a-35e0dd1c74ff",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer pat-na1-e324b4b2-06b9-4588-8cdd-7e4b0ca63271`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fields }), // Pass the fields array
        }
      );

      const responseData = await response.json();
      if (responseData.inlineMessage == "Thanks for submitting the form.") {
        setFormSubmitted(true);
      } else {
        setFormSubmitted(false);
      }
      console.log(responseData);

      // Optionally display a success message
    } catch (error) {
      console.error("Error sending data to HubSpot:", error);
      // Optionally display an error message
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="sec4-bg my-20 py-10">
      <div className="flex justify-center items-center">
        <h1 className="Poppins text-heading text-center h-12">Talk To</h1>
        <div className="h-12">
          <h1
            className="Poppins text-heading text-center"
            style={{ color: "#2B6BE2" }}
          >
           Us Today :)
          </h1>
          <img className="mx-auto img-line -my-3" src={Sec4} alt="" />
        </div>
      </div>

      <p className="OpenSans text-lg text-center leading-5 font-normal width-para mx-auto mt-7 mb-10">
        Transform your hiring and training with end-to-end solutions. Contact
        our sales team today to explore how we can help.
      </p>

      <div className="form flex OpenSans justify-center">
        {formSubmitted ? (
          <p
            className="Poppins text-heading text-center h-12"
            // style={{ color: "#2B6BE2" }}
          >
            Thank you! We will get in touch shortly
          </p>
        ) : (
          <Form
            name="validateOnly"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex justify-center flex-wrap gap-x-4">
              <div>
                <p className="text-base mb-2 capitalize">First Name</p>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Enter your first name"
                    className="input-width py-4 rounded-xl border-none"
                  />
                </Form.Item>
              </div>
              <div>
                <p className="text-base mb-2 capitalize">Last Name</p>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input
                    type="text"
                    placeholder="Enter your last name"
                    className="input-width py-4 rounded-xl border-none"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex justify-center flex-wrap gap-x-4">
              <div>
                <p className="text-base mb-2 capitalize">Mobile Number</p>
                <Form.Item
                  name="mobileNumber"
                  rules={[
                    { required: true, message: "Mobile number is required" },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter your mobile number"
                    className="input-width py-4 rounded-xl border-none"
                  />
                </Form.Item>
              </div>
              <div>
                <p className="text-base mb-2 capitalize">Email</p>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    className="input-width py-4 rounded-xl border-none"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex justify-center flex-wrap gap-x-4">
              <div>
                <p className="text-base mb-2 capitalize">Company Name</p>
                <Form.Item
                  name="companyName"
                  rules={[
                    { required: true, message: "Company name is required" },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Enter your company name"
                    className="input-width py-4 rounded-xl border-none"
                  />
                </Form.Item>
              </div>
              <div>
                <p className="text-base mb-2 capitalize">Organization Type</p>
                <Form.Item
                  name="organizationType"
                  rules={[
                    {
                      required: true,
                      message: "Organization type is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select your organization type"
                    dropdownClassName="custom-dropdown"
                    className="custom-select1"
                  >
                    <Select.Option value="option1">
                      Career Coach
                    </Select.Option>
                    <Select.Option value="option2">
                      Bootcamp
                    </Select.Option>
                    <Select.Option value="option3">College/University</Select.Option>
                    <Select.Option value="option4">
                       Recruiting/Staffing Agency
                    </Select.Option>
                    <Select.Option value="option5">Startup</Select.Option>
                    <Select.Option value="option6">
                      SMB
                    </Select.Option>
                    <Select.Option value="option7">
                      Enterprise
                    </Select.Option>
                    <Select.Option value="option8">
                     Government Organization
                    </Select.Option>
                    <Select.Option value="option9">
                     Non-profit Organization
                    </Select.Option>
                    <Select.Option value="option10">
                     Veteran Organization
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div>
              <p className="text-base mb-2 capitalize">Nexa Use Case</p>
              <Form.Item
                name="comments"
                rules={[
                  { required: true, message: "Please enter your comments" },
                ]}
              >
                <TextArea
                  style={{ resize: "none" }}
                  rows={4}
                  placeholder="Enter your use case"
                  className="w-full py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>

            {/* Submit Button */}
            <div className="mt-4 flex justify-center">
              <Form.Item>
                <Button
                  style={{
                    background:
                      "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                  }}
                  htmlType="submit"
                  className="px-6 text-white rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}
