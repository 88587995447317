import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section8.css";

const Section8 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open("https://cal.com/prithvi-nexa/30min", "_blank");
  };

  return (
    <>
      <div
        style={{ marginTop: "20%", width: "75%", color: "#000000" }}
        className="section8-box"
      >
        {/* Left Side: Text */}
        <div className="section8-left">
          <div className="text-wrapper-section8">
            <div className="narrow-text-section8">
              <p className="text-default-blue-bold-section8 mt-2">
                Ready to Transform Hiring?
              </p>
            </div>
            <div className="wide-text-section8">
              <p className="text-default-black-section8 FiraFontFamily">
                Join the thousands of recruiters using Nexa to transform their
                hiring process and onboarding superstars.
              </p>
            </div>

            <button onClick={handleClick} className="try-nexa-button-section8">
              Book a Free Demo Today
            </button>
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="section8-right">
          <img
            src="images/Bootcamp/BootCampSection8.png"
            alt="Boot Camp Section 8"
            className="section8-image"
          />
        </div>
      </div>
    </>
  );
};

export default Section8;
