import React from "react";
import PaginationShimmer from "./PaginationShimmer";

const TaskShimmer = () => {
  return (
    <div
      className="ic-interview-full-body overflow-y-auto max-h-screen"
      style={{ paddingTop: "6em" }}
    >
      <div className="ic-question-start max-w-[700px] w-full border mx-md-auto mx-4 shadow-lg py-30 my-4 animate-pulse">
        <div className="d-flex justify-between items-center -mt-3 mb-4 gap-x-1">
          <div className="h-6 w-32 bg-gray-300 rounded"></div>
        </div>

        {[1, 2].map((_, index) => (
          <div key={index} className="w-100">
            <div className="question-header2 mb-3 flex justify-between">
              <div className="h-4 w-20 bg-gray-300 rounded"></div>
              <div className="h-4 w-16 bg-gray-300 rounded"></div>
            </div>
            <div className="d-flex justify-between items-center">
              <div className="w-full">
                <div className="h-6 w-3/4 bg-gray-300 rounded mb-2"></div>
                <div className="h-4 w-1/2 bg-gray-300 rounded"></div>
              </div>
              <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
            </div>
            <hr className="resume_hr mt-3" />
          </div>
        ))}

        {/* Pagination Loader */}
        <PaginationShimmer />
      </div>
    </div>
  );
};

export default TaskShimmer;
