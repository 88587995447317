import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section1.css";

const Section1 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open("https://cal.com/prithvi-nexa/30min", "_blank");
  };

  return (
    <div className="default-container background-container">
      <div className="text-container">
        <p className="text-default-blue-bold FiraFontFamily">
          Transform Hiring: AI Smart-Screening Built For Faster, Smarter
          Recrutment.
        </p>
        <p className="text-default-black FiraFontFamily">
          Source, screen, and connect with the top 1% of talent effortlessly.
          Nexa RU saves you time, cuts costs, and delivers pre-vetted candidates
          with unmatched transparent insights.
        </p>

        <button
          onClick={handleClick}
          className="start-trial-button FiraFontFamily"
        >
          Book a Demo
        </button>
      </div>
      <div className="zoom-container image-container image-container-media zoom-container">
        <img
          src="/images/rulogo.png"
          alt="Transform Your staffing firm"
          className="section-image"
        />
      </div>
    </div>
  );
};

export default Section1;
