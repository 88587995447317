import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withRouter } from "../../Utilities";
import Header from "../../Components/header";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import Cards from "./components/Cards";
import {
  getAllSectors,
  getSkillPracticeQuestions,
} from "../../Redux/SkillsPracticeSlice";
import Loader from "../../Components/main-loader";
import { setinterviewMode, setPreviousMode } from "../../Redux/GlobalStates";
import CircularLoader from "../../Components/loader";
import QuestionInfo from "./components/QuestionInfo";
import Interview from "./components/Interview";
import QuotaExceeded from "../../Components/quotaExceeded";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import { error } from "../../Utilities/toast";
import CardShimmer from "../../Components/Shimmers/CardShimmer";

const SkillPractice = ({ params }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { sectors } = useSelector((state) => state.skillsPractice);
  const { user } = useSelector((state) => state.authenticate);
  const { interViewMode } = useSelector((state) => state.globalState);

  const { sector, domain, subdomain, skill, tool } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubDomain, setSelectedSubDomain] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null);

  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
  const [interviewTimer, setInterviewTimer] = useState(false);
  const [interviewTrainingSessionId, setInterviewTrainingSessionId] =
    useState(null);
  const [questions, setQuestions] = useState([]);

  const [audio, setAudio] = useState(true);
  const [hasInterviewStarted, setHasInterviewStarted] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);

  const [history, setHistory] = useState([]);

  const handleMode = (currentMode) => {
    dispatch(setPreviousMode(interViewMode));
    dispatch(setinterviewMode(currentMode));
  };

  const handleSelection = (level, value) => {
    setHistory((prev) => [...prev, level]);
    if (level === "sector") {
      setSelectedSector(value);
    } else if (level === "domain") {
      setSelectedDomain(value);
    } else if (level === "subdomain") {
      setSelectedSubDomain(value);
    } else if (level === "skill") {
      setSelectedSkill(value);
    } else if (level === "tool") {
      setSelectedTool(value);
    }
  };

  const handleBack = () => {
    if (history.length > 0) {
      const lastLevel = history.pop();
      setHistory([...history]);

      let newPath = "/skills-practice";

      if (lastLevel === "tool") {
        setSelectedTool(null);
        newPath += `/${sector}/${domain}/${subdomain}/${skill}`;
      } else if (lastLevel === "skill") {
        setSelectedSkill(null);
        newPath += `/${sector}/${domain}/${subdomain}`;
      } else if (lastLevel === "subdomain") {
        setSelectedSubDomain(null);
        newPath += `/${sector}/${domain}`;
      } else if (lastLevel === "domain") {
        setSelectedDomain(null);
        newPath += `/${sector}`;
      } else if (lastLevel === "sector") {
        setSelectedSector(null);
      }

      navigate(newPath);
    }
  };

  const findItemByName = (items, name) =>
    items?.find(
      (item) =>
        item.name.replace(/ /g, "-").replace(/\//g, "_").toLowerCase() === name
    );

  const handleGenerateQuestions = () => {
    const payload = {
      sector_id: selectedSector?.id,
      domain_id: selectedDomain?.id,
      subdomain_id: selectedSubDomain?.id,
      skill_id: selectedSkill?.id,
      tool_id: selectedTool?.id,
      question_count: 5,
      random: true,
    };
    setIsGeneratingQuestions(true);
    dispatch(getSkillPracticeQuestions(payload))
      .unwrap()
      .then((res) => {
        if (!res.interview_session_id || !res.questions?.length) {
          error("No questions found");
          navigate("/skills-practice");
          return;
        }
        setInterviewTrainingSessionId(res.interview_session_id);
        setQuestions(res.questions);
      })
      .finally(() => setIsGeneratingQuestions(false));
  };

  useEffect(() => {
    if (sectors?.length) {
      setHasInterviewStarted(false);
      let newHistory = [];
      setSelectedSector(null);
      setSelectedDomain(null);
      setSelectedSubDomain(null);
      setSelectedSkill(null);
      setSelectedTool(null);
      if (sector) {
        newHistory.push("sector");
        const foundSector = findItemByName(sectors, sector);
        if (foundSector) {
          setSelectedSector(foundSector);
          setSelectedDomain(null);
          setSelectedSubDomain(null);
          setSelectedSkill(null);
          setSelectedTool(null);
          if (domain) {
            newHistory.push("domain");
            const foundDomain = findItemByName(foundSector.domains, domain);
            if (foundDomain) {
              setSelectedDomain(foundDomain);
              setSelectedSubDomain(null);
              setSelectedSkill(null);
              setSelectedTool(null);
              if (subdomain) {
                newHistory.push("subdomain");
                const foundSubDomain = findItemByName(
                  foundDomain.subdomains,
                  subdomain
                );
                if (foundSubDomain) {
                  setSelectedSubDomain(foundSubDomain);
                  setSelectedSkill(null);
                  setSelectedTool(null);
                  if (skill) {
                    newHistory.push("skill");
                    const foundSkill = findItemByName(
                      foundSubDomain.skills,
                      skill
                    );
                    if (foundSkill) {
                      setSelectedSkill(foundSkill);
                      setSelectedTool(null);
                      if (foundSkill?.tools?.length > 0) {
                        if (tool) {
                          newHistory.push("tool");
                          const foundTool = findItemByName(
                            foundSkill.tools,
                            tool
                          );
                          if (foundTool) {
                            setSelectedTool(foundTool);
                            handleGenerateQuestions(foundTool?.id);
                          }
                        }
                      } else {
                        handleGenerateQuestions(foundSkill?.id);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        setHistory(newHistory);
      }
    }
  }, [sectors, params]);

  useEffect(() => {
    dispatch(setinterviewMode(""));
    setIsLoading(true);
    dispatch(getAllSectors())
      .unwrap()
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (
      user?.subscriptionQuotas?.pround?.quota_allowed ===
      user?.subscriptionQuotas?.pround?.quota_consumed
    ) {
      setIsQuoteModalOpen(true);
    }
  }, [user?.subscriptionQuotas?.pround]);

  useEffect(() => {
    dispatch(getUserSubscription());
  }, []);

  return (
    <div className="relative object-cover h-screen overflow-y-scroll">
      {location.pathname !== "/skills-practice" && (
        <div className="resume-back absolute">
          <button
            onClick={handleBack}
            className="z-50 mt-28 ml-8 bg-white rounded-full size-6 p-1 transition-all duration-500 rotate-180"
          >
            <img src={chevronDoubleRightIcon} alt="" />
          </button>
        </div>
      )}
      <Header sound={hasInterviewStarted} audio={audio} setAudio={setAudio} />
      {isLoading ? (
        <div className="mt-[186px] p-[15px]">
          <CardShimmer />
        </div>
      ) : !selectedSector ? (
        <Cards
          name="sector"
          items={sectors}
          selectedItem={selectedSector}
          setSelectedItem={(value) => handleSelection("sector", value)}
        />
      ) : !selectedDomain ? (
        <Cards
          name="domain"
          items={selectedSector?.domains}
          selectedItem={selectedDomain}
          setSelectedItem={(value) => handleSelection("domain", value)}
        />
      ) : !selectedSubDomain ? (
        <Cards
          name="subdomain"
          items={selectedDomain?.subdomains}
          selectedItem={selectedSubDomain}
          setSelectedItem={(value) => handleSelection("subdomain", value)}
        />
      ) : !selectedSkill ? (
        <Cards
          name="skill"
          items={selectedSubDomain?.skills}
          selectedItem={selectedSkill}
          setSelectedItem={(value) => handleSelection("skill", value)}
        />
      ) : !selectedTool && selectedSkill?.tools?.length > 0 ? (
        <Cards
          name="vendor"
          items={selectedSkill?.tools}
          selectedItem={selectedTool}
          setSelectedItem={(value) => handleSelection("tool", value)}
        />
      ) : isGeneratingQuestions ? (
        <CircularLoader title="Our Nexa engine has started to find you the best questions for your session, please wait while we make you the best candidate! " />
      ) : !hasInterviewStarted &&
        interviewTrainingSessionId &&
        questions?.length ? (
        <QuestionInfo
          developer={false}
          handleMode={handleMode}
          role={selectedTool?.name}
          setInterviewTimer={setInterviewTimer}
          setHasInterviewStarted={setHasInterviewStarted}
        />
      ) : hasInterviewStarted &&
        interviewTrainingSessionId &&
        questions?.length ? (
        <Interview
          allQuestions={questions}
          audio={audio}
          skill={params?.skill}
          type={params?.tool}
          interviewTrainingSessionId={interviewTrainingSessionId}
          setInterviewTimer={setInterviewTimer}
          interviewTimer={interviewTimer}
          setSelectedQuestionType={setSelectedQuestionType}
        />
      ) : (
        <div className="h-screen flex flex-col gap-4 items-center justify-center">
          <p>Unable to generate questions. Please try again.</p>
          <Link to="/skills-practice">
            <button className="bg-primary rounded shadow text-white px-4 py-2 font-semi-bold">
              Try Again
            </button>
          </Link>
        </div>
      )}
      {isQuoteModalOpen && (
        <QuotaExceeded
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
        />
      )}
    </div>
  );
};

export default withRouter(SkillPractice);
