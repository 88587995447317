import React from "react";
import {
  handleSelectChange,
  NotSureStepHeader,
} from "../../../../../../../Pages/Onboarding/components/steps/context";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";
import { LEARNING_READINESS } from "../../../../../../../Pages/Onboarding/components/boardingConstants";
import { Select } from "antd";

const Step7 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;
  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Career Goals and Learning Readiness"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <Select
          placeholder="Select an option"
          className="w-full mt-2 h-[48px] capitalize"
          value={formData[currentStep - 1].answer || undefined}
          onChange={(value) =>
            handleSelectChange(currentStep - 1, value, setStepState)
          }
        >
          {LEARNING_READINESS.map((option, index) => (
            <Select.Option key={index} value={option} className="capitalize">
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default Step7;
