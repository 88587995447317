import { useState, useEffect } from "react";
import { RotateCw } from "lucide-react";
import { useSelector } from "react-redux";
import { getRecommendCareerSuggestion } from "../../../Redux/authenticate/authenticate.action";
import { useDispatch } from "react-redux";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../../recoil/Loadings";
import { produce } from "immer";
import TextShimmer from "../../../Components/Shimmers/TextShimmer";
import { StepBoardingStateAtom } from "../../../recoil/onBoarding";
import { error } from "../../../Utilities/toast";
import { Link } from "react-router-dom";
import nexaLogo from "../../../assets/Images/logo.png";
import icon from "../../../assets/Images/image (1).png";

const CarrierSuggestion = () => {
  //REDUX
  const dispatch = useDispatch();
  const { user, recommendCareersSuggestions } = useSelector(
    (state) => state.authenticate
  );
  console.log("user: ", user);

  //RECOIL
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [stepState] = useRecoilState(StepBoardingStateAtom);

  //STATES
  const [description, setDescription] = useState(recommendCareersSuggestions);
  const [displayText, setDisplayText] = useState(recommendCareersSuggestions);
  const [isTyping, setIsTyping] = useState(false);
  const [typeIndex, setTypeIndex] = useState(0);

  //FUNCTIONS
  const handleRetake = () => {
    if ([stepState.notSureKey, null, undefined].includes(user?.target_career)) {
      error("Please select a target career");
      return;
    }

    setLoadingState(
      produce((draft) => {
        draft.careerGoals.recommendCareersSuggestions = true;
      })
    );
    dispatch(
      getRecommendCareerSuggestion({
        isRetake: true,
        carier_goal: user?.target_career?.split("#")[0],
        onComplete: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.recommendCareersSuggestions = false;
            })
          );
          setDisplayText("");
          setIsTyping(true);
          setTypeIndex(0);
        },
      })
    );
  };

  useEffect(() => {
    setDescription(recommendCareersSuggestions);
    setDisplayText(recommendCareersSuggestions);
  }, [recommendCareersSuggestions]);

  useEffect(() => {
    if (isTyping && typeIndex < description?.length) {
      const timer = setTimeout(() => {
        setDisplayText(description?.slice(0, typeIndex + 1));
        setTypeIndex(typeIndex + 1);
      }, 30);

      return () => clearTimeout(timer);
    } else if (isTyping && typeIndex >= description?.length) {
      setIsTyping(false);
      setTypeIndex(0);
    }
  }, [isTyping, typeIndex, description]);

  return (
    <div className="w-full  bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-3 mx-auto max-w-5xl transition-all duration-300">
      {/* Header Section */}
      <div className="border-b border-gray-100">
        <div className="flex justify-between items-center p-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Recommend Careers suggestions
          </h2>
          <div className="flex gap-3">
            <button
              onClick={handleRetake}
              disabled={loadingState.careerGoals.recommendCareersSuggestions}
              className={`flex items-center gap-2 px-4 py-2 bg-emerald-50 text-emerald-600 rounded-lg hover:bg-emerald-100 transition-colors duration-200 ${
                user?.target_career === stepState.notSureKey
                  ? "cursor-not-allowed"
                  : ""
              }`}
            >
              <RotateCw size={18} />
              <span>Retake</span>
            </button>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6">
        <div className="prose max-w-none min-h-[100px]">
          <p className="text-gray-600 leading-relaxed">
            {loadingState.careerGoals.recommendCareersSuggestions ? (
              <TextShimmer />
            ) : displayText ? (
              user?.target_career !== "not_sure" ? (
                <div className="flex flex-wrap">
                  {displayText.split(",").map((item, index) => (
                    <div key={index} className="relative group p-1 ">
                      <span className="bg-blue-100 text-blue-700 overflow-hidden cursor-pointer px-3 py-1 rounded-full text-sm shadow-sm border-[1px] border-blue-200 hover:border-blue-400 transition-colors duration-200">
                        {item.trim()}
                      </span>
                      <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-1 hidden group-hover:flex flex-col w-64 bg-white shadow-lg rounded-lg border border-gray-300 p-3">
                        <Link
                          to={`/IQ/jobboard?location=${
                            user?.city || ""
                          }&title=${item}`}
                          className="flex items-start"
                        >
                          <img src={icon} alt="" className="w-[16px] mr-2" />
                          <span className="mb-2  font-medium text-blue-700 text-xs">
                            {item.trim()}
                          </span>
                        </Link>
                        <div className="w-full h-32 bg-gray-200 rounded-lg flex items-center justify-center overflow-hidden px-4">
                          <img
                            src={nexaLogo}
                            alt="Preview"
                            className="w-full h-full object-contain"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: displayText,
                  }}
                />
              )
            ) : (
              <h2 className="text-gray-600 text-lg text-center min-h-[200px] capitalize flex justify-center items-center">
                No Recommend Careers suggestions found for this customer. Please
                generate analysis first.
              </h2>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarrierSuggestion;
