import {
  AppWindow,
  BookOpen,
  ChartNoAxesColumn,
  ChevronDown,
  FileChartColumn,
  Headset,
  Menu,
  MessageSquareMore,
  ScanFace,
  X,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlineVerified } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import organizationIcon from "../../assets/icons/organization.png";
import userIcon from "../../assets/icons/userIcon.svg";
import logo from "../../assets/Images/logo.png";
import { pageRefresh } from "../../config/socketConfig";
import { MobileSidebarContext } from "../../context/mobilesidemenuContext";
import { stopMachineSpeaks } from "../../Pages/SimulatorPro/Components/MachineSpeaks";
import { logoutUser } from "../../Redux/authenticate/authenticate.action";
import { removeToken } from "../../Redux/authenticate/authenticate.slicer";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";

function Header({ outside, sound = false, audio, setAudio }) {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState({
    useCases: false,
    products: false,
    resources: false,
  });
  const [totalUnreadChats, setTotalUnreadChats] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useSelector((state) => state.authenticate);
  const { connectedChats, userId } = useSelector((state) => state.chat);
  const { file_url, interViewMode, interviewCompleted } = useSelector(
    (state) => state.globalState
  );

  const { isMobileSidebarActive, setIsMobileSidebarActive } =
    useContext(MobileSidebarContext);

  const handleLogout = () => {
    dispatch(logoutUser())
      .unwrap()
      .then(() => {
        dispatch(removeToken());
        stopMachineSpeaksGlobal();
        stopMachineSpeaks();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsMobileSidebarActive(!isMobileSidebarActive);
  };

  const handleMouseEnter = (menu) => {
    setSubmenuOpen({
      useCases: false,
      products: false,
      resources: false,
      [menu]: true,
    });
  };

  const handleMouseLeave = (menu) => {
    setSubmenuOpen({ ...submenuOpen, [menu]: false });
  };

  const toggleSubmenu = (submenu) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [submenu]: !prev[submenu],
    }));
  };

  const formatName = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length > 2) {
      return `${nameParts[0][0]}.${nameParts.slice(1).join(" ")}`;
    }
    return name;
  };

  const AudioController = () => {
    setAudio(!audio);
  };

  useEffect(() => {
    if (user && userId) {
      pageRefresh({
        token: localStorage.getItem("auth"),
        encrypted_user_id: userId?.user_id,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (connectedChats && userId) {
      let totalUnreadCount = 0;
      connectedChats.forEach((chat) => {
        chat.unread_messages?.forEach((unread) => {
          if (unread.user_id === userId.user_id) {
            totalUnreadCount += unread.unread_messages;
          }
        });
      });
      setTotalUnreadChats(totalUnreadCount);
    }
  }, [connectedChats, userId]);

  if (interViewMode === "interview_start_developer" && !interviewCompleted)
    return null;

  return (
    <header className="sticky bg-transparent h-20 top-0 w-full z-50 header-res Satoshi max-w-[1600px]">
      <div className="bg-white shadow-2xl border-res h-16 flex items-center justify-between px-6 py-4">
        <div className="text-xl font-bold">
          <NavLink to="/" className="flex items-center">
            <img
              src={file_url || logo}
              alt="Logo"
              className="h-6 mr-2 object-contain"
            />
          </NavLink>
        </div>

        {!user ? (
          <nav className="navbar-desktop items-center">
            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("useCases")}
              onMouseLeave={() => handleMouseLeave("useCases")}
            >
              <div className=" cursor-pointer hover:opacity-80">
                Use Cases
                <ChevronDown
                  className={`inline-block cursor-pointer transition-transform duration-700 ${
                    submenuOpen.useCases ? "rotate-180" : ""
                  }`}
                  size={16}
                />
              </div>
              {submenuOpen.useCases && (
                <div className="absolute space-y-4 left-0 bg-white shadow-xl p-6 rounded-xl transition-all duration-700 ease-in-out whitespace-nowrap">
                  <NavLink
                    to="/bootcamps"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <FileChartColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    For Bootcamps & Vocational Centers
                  </NavLink>
                  <NavLink
                    to="/comingsoon"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ScanFace size={18} color="rgb(59 130 246 / 1)" /> Colleges
                    & Universities
                  </NavLink>
                  <NavLink
                    to="/creatorpage"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <AppWindow size={18} color="rgb(59 130 246 / 1)" /> For
                    Career Creators & Coaches
                  </NavLink>
                  <NavLink
                    to="/comingsoon"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ChartNoAxesColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    For Government Employment Programs
                  </NavLink>
                  <NavLink
                    to="/comingsoon"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <BookOpen size={18} color="rgb(59 130 246 / 1)" /> Veteran
                    Organizations
                  </NavLink>
                  <NavLink
                    to="/comingsoon"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <AppWindow size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Workforce & Employment Development Centers
                  </NavLink>
                  <NavLink
                    to="/comingsoon"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ChartNoAxesColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Enterprise Recruitment
                  </NavLink>
                </div>
              )}
            </div>

            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("products")}
              onMouseLeave={() => handleMouseLeave("products")}
            >
              <div className=" cursor-pointer hover:opacity-80">
                Products
                <ChevronDown
                  className={`inline-block transition-transform duration-700 ${
                    submenuOpen.products ? "rotate-180" : ""
                  }`}
                  size={16}
                />
              </div>
              {submenuOpen.products && (
                <div className="absolute space-y-4 left-0 bg-white shadow-xl p-6 rounded-lg transition-all duration-700 ease-in-out whitespace-nowrap">
                  <NavLink
                    to="/landing"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <AppWindow size={18} color="rgb(59 130 246 / 1)" /> Nexa
                    Elevate
                  </NavLink>
                  <NavLink
                    to="/rucoming"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <BookOpen size={18} color="rgb(59 130 246 / 1)" /> Nexa RU
                    (Beta)
                  </NavLink>
                </div>
              )}
            </div>

            <div
              className="relative"
              onMouseEnter={() => handleMouseEnter("resources")}
              onMouseLeave={() => handleMouseLeave("resources")}
            >
              <div className=" cursor-pointer hover:opacity-80">
                Resources
                <ChevronDown
                  className={`inline-block transition-transform duration-700 ${
                    submenuOpen.resources ? "rotate-180" : ""
                  }`}
                  size={16}
                />
              </div>
              {submenuOpen.resources && (
                <div className="absolute space-y-4 left-0 bg-white shadow-xl p-6 rounded-xl transition-all duration-700 ease-in-out whitespace-nowrap">
                  <a
                    href="https://tally.so/r/3yDKdg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <FileChartColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Career Center Efficiency Assessment
                  </a>
                  <a
                    href="https://drive.google.com/file/d/1kegVSbQKUghYDiz-7xt0YyCRUiaRXjzf/view?pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <AppWindow size={18} color="rgb(59 130 246 / 1)" /> Case
                    Studies
                  </a>
                  <NavLink
                    to="/contact-us"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <MessageSquareMore size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Contact us
                  </NavLink>
                  <NavLink
                    to="/faq"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ChartNoAxesColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    FAQ
                  </NavLink>
                  <a
                    href="https://share.hsforms.com/1OqLkWvR5SHCqlZOUvRD9-Arfy9w"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <Headset size={18} color="rgb(59 130 246 / 1)" /> Help
                    center
                  </a>
                  <a
                    href="https://trynexa.com/IQ/jobboard"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ChartNoAxesColumn size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Job Board
                  </a>
                  <a
                    href="https://trynexa.com/IQ/jobboard"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <MessageSquareMore size={18} color="rgb(59 130 246 / 1)" />{" "}
                    Post a Job for FREE
                  </a>
                  <NavLink
                    to="/"
                    className="hover:text-blue-600 Satoshi flex gap-2 items-center"
                  >
                    <ScanFace size={18} color="rgb(59 130 246 / 1)" />
                    ROI Calculator
                  </NavLink>
                </div>
              )}
            </div>

            <div>
              <NavLink to="/about-us" className="font-[400] hover:opacity-80">
                Company
              </NavLink>
            </div>
            <div>
              <NavLink to="/pricing" className="font-[400] hover:opacity-80">
                Pricing
              </NavLink>
            </div>
            <div>
              <NavLink to="/nexa-iq" className="font-[400] hover:opacity-80">
                Nexa IQ
              </NavLink>
            </div>
          </nav>
        ) : (
          <div className="ic-profile-dropdown">
            <div className="ic-avatar-profile lg:flex hidden">
              <img
                src={user?.picture || userIcon}
                alt=""
                className="w-8 h-8 rounded-full"
              />
            </div>
            <div className="ic-dropdown-menu">
              <div className="ic-profiles-dropdown">
                <div>
                  <img
                    src={user?.picture || userIcon}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                </div>
                <div>
                  <p className="flex gap-1 justify-center items-center">
                    {formatName(user.name || user?.fullName)}{" "}
                    {user?.is_badge && (
                      <MdOutlineVerified size={17} color="#2c85ed" />
                    )}
                  </p>
                  {user?.organizationId && (
                    <div className="flex items-center mt-2">
                      <img
                        src={organizationIcon}
                        alt="Organization"
                        className="h-4 w-4 mr-2"
                      />
                      <span>{user?.organizationName}</span>
                    </div>
                  )}
                  {!user?.organizationId && (
                    <span className="text-gray-600">Admin</span>
                  )}
                </div>
              </div>
              <ul className="ic-drop-menu curved-edges">
                <li>
                  <NavLink
                    to="/home"
                    className="flex items-center py-2 px-4 hover:bg-gray-100"
                  >
                    <i className="ri-dashboard-line mr-4"></i>
                    Dashboard
                  </NavLink>
                  <NavLink
                    to="/user/analytics"
                    className="flex items-center py-2 px-4 hover:bg-gray-100"
                  >
                    <i className="ri-user-line mr-4"></i>
                    My Profile
                  </NavLink>
                  <NavLink
                    to="/user2/settings"
                    className="flex items-center py-2 px-4 hover:bg-gray-100"
                  >
                    <i className="ri-dashboard-line mr-4"></i>
                    Settings
                  </NavLink>
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center py-2 px-4 hover:bg-gray-100 text-left"
                  >
                    <i className="ri-logout-circle-r-line mr-4"></i>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}

        {!user && (
          <div className="hidden md:flex">
            <NavLink
              to="/login"
              className="hover:opacity-80 mx-4 font-bold text-blue-600 self-center"
            >
              Sign In
            </NavLink>
            <NavLink
              to="/contact-us"
              className="bg-blue-600 text-white font-bold px-4 py-2 rounded-full hover:bg-blue-700"
            >
              Contact Us
            </NavLink>
          </div>
        )}

        <button
          className="respon-none1 block lg:hidden text-gray-700 focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {sound && (
          <button
            className="sound-click ml-4 p-2 hover:bg-gray-100 rounded-full"
            onClick={AudioController}
          >
            {audio ? (
              <i className="ri-volume-up-line text-xl"></i>
            ) : (
              <i className="ri-volume-mute-line text-xl"></i>
            )}
          </button>
        )}
      </div>

      {isOpen && (
        <div className="respon-none1 bg-white shadow-md">
          <nav className="flex flex-col py-4 px-6">
            {!user ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer py-3"
                    onClick={() => toggleSubmenu("useCases")}
                  >
                    <div className="font-extrabold">Use Cases</div>
                    <ChevronDown
                      className={`transition-transform duration-300 ${
                        submenuOpen.useCases ? "rotate-180" : ""
                      }`}
                      size={16}
                    />
                  </div>
                  {submenuOpen.useCases && (
                    <div className="flex flex-col space-y-3 pl-4 mt-2">
                      <NavLink
                        to="/bootcamps"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <FileChartColumn
                          size={14}
                          color="rgb(59 130 246 / 1)"
                        />{" "}
                        For Bootcamps & Vocational Centers
                      </NavLink>
                      <NavLink
                        to="/creatorpage"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <ChartNoAxesColumn
                          size={14}
                          color="rgb(59 130 246 / 1)"
                        />{" "}
                        For Career Creators & Coaches
                      </NavLink>
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <ScanFace size={14} color="rgb(59 130 246 / 1)" />{" "}
                        Colleges & Universities
                      </NavLink>
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <ChartNoAxesColumn
                          size={14}
                          color="rgb(59 130 246 / 1)"
                        />{" "}
                        For Government Employment Programs
                      </NavLink>
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <BookOpen size={14} color="rgb(59 130 246 / 1)" />{" "}
                        Veteran Organizations
                      </NavLink>
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <AppWindow size={14} color="rgb(59 130 246 / 1)" />{" "}
                        Workforce & Employment Development Centers
                      </NavLink>
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <ChartNoAxesColumn
                          size={14}
                          color="rgb(59 130 246 / 1)"
                        />{" "}
                        Enterprise Recruitment
                      </NavLink>
                    </div>
                  )}
                </div>

                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer py-3"
                    onClick={() => toggleSubmenu("products")}
                  >
                    <div className="font-extrabold">Products</div>
                    <ChevronDown
                      className={`transition-transform duration-300 ${
                        submenuOpen.products ? "rotate-180" : ""
                      }`}
                      size={16}
                    />
                  </div>
                  {submenuOpen.products && (
                    <div className="flex flex-col space-y-3 pl-4 mt-2">
                      <NavLink
                        to="/landing"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <AppWindow size={14} color="rgb(59 130 246 / 1)" /> Nexa
                        Elevate
                      </NavLink>
                      <NavLink
                        to="/rucoming"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <BookOpen size={14} color="rgb(59 130 246 / 1)" />
                        Nexa RU (Recruiting Platform)
                      </NavLink>
                    </div>
                  )}
                </div>

                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer py-3"
                    onClick={() => toggleSubmenu("resources")}
                  >
                    <div className="font-extrabold">Resources</div>
                    <ChevronDown
                      className={`transition-transform duration-300 ${
                        submenuOpen.resources ? "rotate-180" : ""
                      }`}
                      size={16}
                    />
                  </div>
                  {submenuOpen.resources && (
                    <div className="flex flex-col space-y-3 pl-4 mt-2">
                      <NavLink
                        to="/comingsoon"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <Headset size={14} color="rgb(59 130 246 / 1)" /> Help
                        Center
                      </NavLink>
                      <NavLink
                        to="/faq"
                        className="hover:text-blue-600 text-sm Satoshi flex gap-2 items-center"
                      >
                        <MessageSquareMore
                          size={14}
                          color="rgb(59 130 246 / 1)"
                        />{" "}
                        FAQ
                      </NavLink>
                    </div>
                  )}
                </div>

                <NavLink to="/about-us" className="font-[400] py-3">
                  Company
                </NavLink>
                <NavLink to="/pricing" className="font-[400] py-3">
                  Pricing
                </NavLink>
                <NavLink to="/nexa-iq" className="font-[400] py-3">
                  Nexa IQ
                </NavLink>

                <div className="flex flex-col gap-3 mt-4">
                  <NavLink
                    to="/login"
                    className="text-blue-600 font-bold text-center py-2 border border-blue-600 rounded-full hover:bg-blue-50"
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    to="/start-interview"
                    className="bg-blue-600 text-white font-bold text-center py-2 rounded-full hover:bg-blue-700"
                  >
                    Contact Sales
                  </NavLink>
                </div>
              </>
            ) : (
              // Mobile menu for logged-in users
              <div className="flex flex-col space-y-3">
                <div className="flex items-center space-x-3 py-3 border-b">
                  <img
                    src={user?.picture || userIcon}
                    alt=""
                    className="w-[2.5rem] h-10 rounded-full"
                  />
                  <div>
                    <p className="font-semibold">
                      {formatName(user.name || user?.fullName)}
                    </p>
                    {user?.organizationId && (
                      <div className="flex items-center mt-1">
                        <img
                          src={organizationIcon}
                          alt="Organization"
                          className="h-4 w-4 mr-2"
                        />
                        <span className="text-sm text-gray-600">
                          {user?.organizationName}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <NavLink
                  to="/home"
                  className={({ isActive }) =>
                    `flex items-center py-3 ${isActive ? "text-blue-600" : ""}`
                  }
                >
                  <i className="ri-dashboard-line mr-4"></i>
                  Dashboard
                  {totalUnreadChats > 0 && (
                    <span className="ml-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full">
                      {totalUnreadChats > 99 ? "99+" : totalUnreadChats}
                    </span>
                  )}
                </NavLink>

                <NavLink
                  to="/user/analytics"
                  className={({ isActive }) =>
                    `flex items-center py-3 ${isActive ? "text-blue-600" : ""}`
                  }
                >
                  <i className="ri-user-line mr-4"></i>
                  My Profile
                </NavLink>

                <NavLink
                  to="/user2/settings"
                  className={({ isActive }) =>
                    `flex items-center py-3 ${isActive ? "text-blue-600" : ""}`
                  }
                >
                  <i className="ri-settings-line mr-4"></i>
                  Settings
                </NavLink>

                <button
                  onClick={handleLogout}
                  className="flex items-center py-3 text-red-600"
                >
                  <i className="ri-logout-circle-r-line mr-4"></i>
                  Logout
                </button>
              </div>
            )}
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
