import { Tab } from "@headlessui/react";
import { Mail, MailOpen } from "lucide-react";

const tabs = [
  { name: "All", icon: MailOpen },
  { name: "Unread", icon: Mail },
];

const NotificationTab = ({ activeTab, setActiveTab }) => {
  return (
    <div className="w-full">
      <div className="p-2 ">
        <Tab.Group
          selectedIndex={tabs.findIndex((tab) => tab.name === activeTab)}
          onChange={(index) => setActiveTab(tabs[index].name)}
        >
          <Tab.List className="flex space-x-1 bg-transparent p-1 rounded-lg">
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  `flex flex-row items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
                   ${
                     selected
                       ? "bg-blue-500 text-white"
                       : "text-gray-400 hover:bg-blue-200 hover:text-white"
                   }`
                }
              >
                <tab.icon className="h-5 w-5 mb-1 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
    </div>
  );
};

export default NotificationTab;
