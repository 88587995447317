import React from "react";
import TextQuestionStep from "../../../../../../Pages/Onboarding/components/steps/components/common/TextQuestionStep";
import {
  getManualCurrentSlug,
  MANUAL_QUESTIONS_SLUG_CONFIGRATION,
} from "../../../../../../Pages/Onboarding/components/steps/context";

const ManualBoardingContent = ({
  stepState,
  fileInput,
  currentAnswer,
  setAnswers,
}) => {
  const onAnswerChange = (value) => {
    const currentSlug = getManualCurrentSlug(
      stepState.manualQuestionBoard.activeStep
    );
    setAnswers((prev) => ({
      ...prev,
      [currentSlug]: value,
    }));
  };

  if (stepState.manualQuestionBoard.activeStep === 8) {
    return (
      <div className="ic-mockInterview flex justify-start w-full">
        <div className="ic-mock-interview mt-5">
          <h4 className="h4 onBoard_heading">
            Please Upload your Resume to Tailor your Experience
          </h4>
          <form action="#">
            <input
              type="file"
              name="file"
              ref={fileInput}
              accept=".pdf,.docx"
              hidden
              onChange={(e) => {
                onAnswerChange(e.target.files[0]);
              }}
            />
            <div
              className="ic-file-upload"
              onClick={() => {
                fileInput.current.click();
              }}
            >
              <div className="ic-icon">
                <img
                  src="/images/document-upload.png"
                  alt=""
                  style={{ width: "auto" }}
                />
              </div>
              <p className="support-text">
                <span className="primary">Click to Upload </span>
              </p>
            </div>
          </form>
          {currentAnswer && (
            <h4>File selected: {currentAnswer.name || currentAnswer}</h4>
          )}
        </div>
      </div>
    );
  }
  return (
    <TextQuestionStep
      question={
        MANUAL_QUESTIONS_SLUG_CONFIGRATION[
          stepState.manualQuestionBoard.activeStep
        ]?.text
      }
      value={currentAnswer || ""}
      onChange={onAnswerChange}
    />
  );
};

export default ManualBoardingContent;
