import { Globe, Lightbulb, Target, Users } from "lucide-react";
import React from "react";
import card1Image from "../../assets/media/aboutUs/01.png";
import card2Image from "../../assets/media/aboutUs/02.png";
import card3Image from "../../assets/media/aboutUs/03.png";
import card4Image from "../../assets/media/aboutUs/04.png";

const WorkforceSection = () => {
  const cards = [
    {
      image: card1Image,
      icon: <Target className="w-6 h-6 text-blue-500" />,
      title: "MERITOCRACY AT NEXA",
      description:
        "At Nexa, we operate as a meritocracy, where talent, effort, and results drive opportunities and success.",
    },
    {
      image: card2Image,
      icon: <Users className="w-6 h-6 text-purple-500" />,
      title: "FOCUSED WORKPLACE CULTURE",
      description:
        "Hiring Practices: We actively seek high potential talent and ensure our hiring processes are free from bias. We use structured interviews & promote excellence.",
    },
    {
      image: card3Image,
      icon: <Lightbulb className="w-6 h-6 text-indigo-500" />,
      title: "IMPACTFUL LEADERSHIP",
      description:
        "We are looking to make change in the real world- whether thats for veterans, job seekers, employers, or workforce development firms- we are here to serve",
    },
    {
      image: card4Image,
      icon: <Globe className="w-6 h-6 text-blue-500" />,
      title: "PLATFORM ACCESSIBILITY",
      description:
        "User-Friendly Design: Nexa is designed to be accessible to users of all backgrounds and abilities. We continuously work to ensure our platform is easy to use and meets the highest accessibility standards. Language Support: We offer multilingual support to cater to our diverse user base, ensuring that language is not a barrier to accessing our career services. Tailored Content: Our AI-driven tools provide personalized recommendations and feedback that consider the unique needs and backgrounds of each user.",
    },
  ];

  return (
    <section className="relative bg-gradient-to-b from-blue-50 via-white to-indigo-50 overflow-hidden py-24">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-96 h-96 bg-blue-200/30 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-200/30 rounded-full blur-3xl animate-pulse delay-700" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-purple-200/20 rounded-full blur-3xl animate-pulse delay-500" />
      </div>

      {/* Grid Pattern */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNjB2NjBIMHoiLz48cGF0aCBkPSJNMzAgMzBoMzB2MzBIMzB6IiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L2c+PC9zdmc+')] opacity-40" />

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold">
            <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-transparent bg-clip-text">
              WORKFORCE DEVELOPMENT EXCELLENCE
            </span>
          </h2>
        </div>

        {/* Cards Grid */}
        <div className="grid md:grid-cols-2 gap-8">
          {cards.map((card, index) => (
            <div
              key={index}
              className="group backdrop-blur-lg bg-white/80 rounded-2xl shadow-xl border border-white hover:shadow-2xl transition-all duration-300 flex flex-col"
            >
              <div className="relative h-32 md:h-40 rounded-t-2xl overflow-hidden bg-white">
                <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-purple-500/10 group-hover:from-blue-500/20 group-hover:to-purple-500/20 transition-all duration-300 z-10" />
                <img
                  src={card.image}
                  alt={card.title}
                  className="absolute inset-0 w-full h-full object-contain object-center transform group-hover:scale-105 transition-transform duration-500 p-2"
                />
              </div>

              <div className="p-6 flex-1 flex flex-col">
                <div className="flex items-start gap-3 mb-3">
                  <div className="mt-1">{card.icon}</div>
                  <h3 className="text-lg font-bold text-gray-800">
                    {card.title}
                  </h3>
                </div>
                <p className="text-gray-600 leading-relaxed text-sm">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkforceSection;
