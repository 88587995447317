import { produce } from "immer";
import { X } from "lucide-react";
import React, { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import OriginalSkillGapQuestions from "../OriginalSkillGapQuestions";
import { useSelector } from "react-redux";
import { generateAiQuestions } from "../../../../Redux/authenticate/authenticate.action";
import { StepLoadingAtom } from "../../../../recoil/Loadings";
import { success } from "../../../../Utilities/toast";
import { useDispatch } from "react-redux";

const CareerOnBoarding = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  //REDUX
  const { onboardingAiData, user } = useSelector((state) => state.authenticate);

  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [_, setLoadingState] = useRecoilState(StepLoadingAtom);

  //FUNCTIONS

  const updateLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.onBoarding.aiQuestionBoard = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const handleSaveAndDoLater = () => {
    setStepState(
      produce((draft) => {
        draft.isCareerGoalBoarding.isOpenBoarding = false;
      })
    );
    if (
      onboardingAiData.length > 0 ||
      stepState.AIQuestionBoard.isAiQuestions.length > 0
    ) {
      setStepState(
        produce((draft) => {
          draft.isCareerGoalBoarding.isOpenAiModal = true;
        })
      );
      const initialAnswers = onboardingAiData.map((item) => ({
        question: item.question,
        answer: item.answer,
      }));
      setStepState(
        produce((draft) => {
          draft.AIQuestionBoard.isAiQuestions = initialAnswers;
          draft.AIQuestionBoard.activeStep = 1;
        })
      );
      updateLoadingState(false);
      return;
    }
    updateLoadingState(true);
    setStepState(
      produce((draft) => {
        draft.isCareerGoalBoarding.isOpenAiModal = true;
      })
    );
    dispatch(
      generateAiQuestions({
        AiBoardingPayload: {
          target_career: user?.target_career?.split("#")[0],
          previous_questions: [],
        },
        onSuccess: (res) => {
          success("AI Questions Generated Successfully");
          updateLoadingState(false);
          setStepState(
            produce((draft) => {
              draft.AIQuestionBoard.isAiQuestions.push({
                question: res,
                answer: null,
              });
              draft.AIQuestionBoard.activeStep = 1;
            })
          );
        },
        onError: () => {
          updateLoadingState(false);
        },
      })
    );
  };

  //USE EFFECT
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setStepState(
          produce((draft) => {
            draft.isCareerGoalBoarding.isOpenBoarding = false;
          })
        );
      }
    };

    if (stepState.isCareerGoalBoarding.isOpenBoarding) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [stepState.isCareerGoalBoarding.isOpenBoarding]);

  if (!stepState.isCareerGoalBoarding.isOpenBoarding) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm transition-opacity">
      <div
        ref={modalRef}
        className="w-4/5 max-w-4xl max-h-[90vh] bg-white rounded-2xl shadow-2xl flex flex-col transform transition-all animate-fadeIn"
      >
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100">
          <h3 className="text-xl font-semibold text-gray-800">
            Edit Skills Gap Assessment Report
          </h3>
          <button
            onClick={() =>
              setStepState(
                produce((draft) => {
                  draft.isCareerGoalBoarding.isOpenBoarding = false;
                })
              )
            }
            className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
          >
            <X size={20} className="text-gray-500" />
          </button>
        </div>

        <div className="p-6 overflow-auto">
          <div className="mb-4">
            <div className="px-2">
              <div className="flex flex-col justify-center">
                <div className="max-w-4xl mx-auto w-full">
                  <OriginalSkillGapQuestions />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 py-4 border-t border-gray-100 flex justify-end space-x-4">
          <button
            className="ic-btn chip-btn"
            onClick={() => handleSaveAndDoLater()}
          >
            Save and do later
          </button>
        </div>
      </div>
    </div>
  );
};

export default CareerOnBoarding;
