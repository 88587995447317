import React, { useEffect } from "react";
import Header from "../../Components/header";
import { useParams, useNavigate } from "react-router-dom"; // For routing and params
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "../../Components/main-loader";
import moment from "moment";
import {
  getSingleNotification,
  updateNotifications,
} from "../../Redux/NotificationsSlice";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
const NotificationDetailPage = () => {
  const dispatch = useDispatch();
  const { notificationDetail, loading } = useSelector(
    (state) => state.notifications
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/notifications");
  };
  useEffect(() => {
    dispatch(getSingleNotification(id));
  }, [id]);

  useEffect(() => {
    if (notificationDetail && !notificationDetail.is_read) {
      dispatch(
        updateNotifications({
          id,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    }
  }, [id, notificationDetail]);

  const sanitizedDescription = DOMPurify.sanitize(
    notificationDetail?.description
  );
  const createdTime = moment(notificationDetail?.created_on);
  const now = moment();
  const diffInHours = now.diff(createdTime, "hours");
  const displayTime =
    diffInHours < 24
      ? `${createdTime.format("D MMM")} (${createdTime.fromNow()})`
      : createdTime.format("D MMM h:mm A");
  if (!notificationDetail) {
    return (
      <>
        <Header />
        <main role="main" className="ml-sm-auto col-lg-12 px-4 bg-cover">
          <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
            {/* Back arrow button */}
            <button
              onClick={handleBack}
              className="text-blue-500 hover:text-blue-700 mb-4 flex items-center space-x-2"
            >
              <FaArrowLeft className="text-xl" />
              <span>Back to Notifications</span>
            </button>
            <div className="text-semibold text-center">
              Notification not found
            </div>
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        className="ic-interview-full-body m"
        style={{
          marginTop: "7em",
        }}
      >
        <main role="main" className="ml-sm-auto col-lg-12 px-4 bg-cover">
          <div className="max-w-5xl mx-auto p-4 sm:px-6 lg:px-8">
            {/* Back arrow button */}
            <button
              onClick={handleBack}
              className="text-blue-500 hover:text-blue-700 mb-4 flex items-center space-x-2"
            >
              <FaArrowLeft className="text-xl" />
              <span>Back to Notifications</span>
            </button>

            <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
              <div className="space-y-4">
                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                  <div className="flex flex-col items-start gap-x-2 flex-wrap">
                    <span className="font-bold text-lg sm:text-xl text-gray-700 break-words">
                      {notificationDetail?.subject || "N/A"}
                    </span>
                    <span className="text-gray-900 text-sm sm:text-right">
                      {/* {moment(notificationDetail?.created_on).format(
                        "D MMM h:mm A"
                      )}{" "}
                      ({moment(notificationDetail?.created_on).fromNow()}) */}
                      {displayTime}
                    </span>
                  </div>
                  <span className="text-sm font-medium px-3 py-1 rounded-full bg-blue-500 text-white">
                    {notificationDetail?.notification_type === "not_verified"
                      ? "Incomplete Profile"
                      : notificationDetail?.notification_type ===
                        "not_in_skill_gap_analysis"
                      ? "Missing Skill"
                      : notificationDetail?.notification_type ===
                        "no_interview_training"
                      ? "Interview Training"
                      : notificationDetail?.notification_type ===
                        "not_in_onboarding"
                      ? "Onboarding"
                      : notificationDetail?.notification_type ===
                        "no_job_search"
                      ? "Job Search"
                      : notificationDetail?.notification_type ===
                        "no_target_career"
                      ? "Target Career"
                      : notificationDetail?.notification_type || "N/A"}
                  </span>
                </div>

                <div className="text-gray-900 text-md break-words">
                  {parse(sanitizedDescription) || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default NotificationDetailPage;
