import { GoogleOAuthProvider } from "@react-oauth/google";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/CSS/index.css";
import AppRoutes from "./AuthGuardLayout/AppRoutes";
import HooksWrapper from "./hookWrapper";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/store";
import { LoadScript } from "@react-google-maps/api";
import { RecoilRoot } from "recoil";
import "./assets/CSS/autoApply.css";
//pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 'pdfjs-dist/build/pdf.worker.min.mjs',
//  import.meta.url,
//).toString();
//post hog: API Key: phx_etrgPmlFkyEzlQnO2k1aF9NhZF2ICXZqeapIOh420tgxLOi
//Initialize posthog

// try {
//   posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
//     api_host: "https://us.i.posthog.com",
//   });
//   console.log("PostHog initialized successfully");
// } catch (error) {
//   console.error("Error initializing PostHog:", error);
// }

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`,
  import.meta.url
).toString();
//
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
    <Provider store={store}>
      <RecoilRoot>
        <PersistGate loading={null} persistor={persistor}>
          <HooksWrapper>
            <BrowserRouter>
              <LoadScript
                loading="async"
                libraries={["places"]}
                googleMapsApiKey="AIzaSyCbo461gdIi_xKfREjdycrnXYWR1v-0JYY"
              >
                <AppRoutes />
              </LoadScript>
            </BrowserRouter>
          </HooksWrapper>
        </PersistGate>
      </RecoilRoot>
    </Provider>
  </GoogleOAuthProvider>
);
reportWebVitals();
