import React from "react";
import "./Section5.css";

const Section5 = () => {
  return (
    <div className="default-container-section5">
      <div className="text-wrapper-section5">
        <div className="narrow-text-section5">
          <p className="text-default-blue-bold-section5 mb-8 FiraFontFamily bold-family">
            See the{" "}
            <span className="text-default-blue-bold-section5 highlighted-word FiraFontFamily bold-family">
              Results
            </span>{" "}
            for Yourself
          </p>
        </div>
      </div>
      <div
        style={{ alignItems: "center",justifyContent:"center" }}
        className="content-container-section2 zoom-container"
      >
        <div className="checkmark-section" style={
          {
            maxWidth:"1000px"
          }
        }>
          <img
            style={{ width: "75%", marginLeft: "10%" }}
            src="/images/Group132131501.png"
            alt="BootCamp Section 2"
            className="section-image"
          />
        </div>
        <div className="image-container">
          <div className="wide-text">
            <p
              className="text-default-blue-bold-section2 text-left mb-4 FiraFontFamily bold-family"
              style={{ fontSize: "35px" }}
            >
              Staffing Agency
            </p>

            <div className="mb-6">
              <p
                className="text-default-blue-bold-section2 text-left FiraFontFamily bold-family"
                style={{ fontSize: "20px" }}
              >
                Challenge
              </p>
              <p className="text-default-black-section2 text-left FiraFontFamily">
                High turnover and long time-to-hire
              </p>
            </div>

            <div className="mb-6">
              <p
                className="text-default-blue-bold-section2 text-left FiraFontFamily bold-family"
                style={{ fontSize: "20px" }}
              >
                Solution
              </p>
              <p className="text-default-black-section2 text-left FiraFontFamily">
                Nexa RU streamlined hiring for software engineers, reducing
                time-to-hire by 60%
              </p>
            </div>

            <div>
              <p
                className="text-default-blue-bold-section2 text-left FiraFontFamily bold-family"
                style={{ fontSize: "20px" }}
              >
                Result
              </p>
              <p className="text-default-black-section2 text-left FiraFontFamily">
                $50,000 saved annually and improved employee retention by 30%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
