import React from "react";

const ResumeShimmer = () => {
  return (
    <div className="flex flex-wrap gap-6 p-6 mt-[200px]">
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className="relative w-[300px] h-[350px] p-4 bg-white rounded-lg shadow-lg border animate-pulse flex flex-col"
        >
          <div className="flex flex-col items-center mb-4">
            <div className="h-5 w-2/3 bg-gray-300 rounded mb-2"></div>
            <div className="h-4 w-1/2 bg-gray-300 rounded mb-3"></div>
          </div>
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className="mb-3">
              <div className="h-3 w-1/3 bg-gray-300 rounded mb-1"></div>
              <div className="h-3 w-full bg-gray-200 rounded"></div>
              <div className="h-3 w-3/4 bg-gray-200 rounded mt-1"></div>
            </div>
          ))}

          {/* Footer */}
          <div className="mt-auto flex flex-col items-center">
            <div className="h-4 w-1/2 bg-gray-300 rounded mb-2"></div>
            <div className="flex space-x-3">
              <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
              <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
              <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResumeShimmer;
