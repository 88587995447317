import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md"; // Import MdDone
import React, { useEffect, useState, useRef } from "react";
import {
  getResumeProfile,
  udpateResumeProfile,
} from "../../../Redux/ResumeBuilderSlice";
import { error, success } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Bold, Italic, Underline } from "lucide-react";

export default function PersonalInformation({ profile, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [profileState, setProfileState] = useState({
    first_name: "",
    first_name_style: "",
    last_name: "",
    last_name_style: "",
    email: "",
    email_style: "",
    address: "",
    address_style: "",
    city: "",
    city_style: "",
    phone: "",
    phone_style: "",
    linked_in: "",
    linked_in_style: "",
    introductory_title: "",
    introductory_title_style: "",
    key_attribute: "",
    key_attribute_style: "",
  });
  console.log(profileState, "profileState");
  const [activeField, setActiveField] = useState(null);
  const [hoveredField, setHoveredField] = useState(null);
  const editableRefs = useRef({});

  const handleFieldClick = (key) => {
    if (activeField && activeField !== key) handleFieldBlur(activeField);
    setActiveField(key);
    setTimeout(() => editableRefs.current[key]?.focus(), 0);
  };

  const handleFieldInput = (key, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[key];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setProfileState((prev) => ({ ...prev, [key]: value }));
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setProfileState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleFieldBlur = (key) => {
    const trimmedValue = profileState[key].trim();
    setProfileState((prev) => ({
      ...prev,
      [key]: trimmedValue || "",
    }));
    setActiveField(null);
    submitChanges();
  };

  const handleSubmit = () => {
    handleFieldBlur(activeField);
    setActiveField(null);
  };

  const getUserInfo = () => {
    const resumeId = id;
    dispatch(getResumeProfile({ resumeId, onSuccess: () => {} }));
  };

  const submitChanges = () => {
    const hasChanges = Object.keys(profileState).some(
      (key) => profileState[key] !== profile[key]
    );
    if (hasChanges) {
      setActiveField(null);
      setHoveredField(null);
      const userInfo = { contactId: profileState.id, data: profileState };
      dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      ).catch((err) => error(err.response?.data?.detail || "Update failed"));
    }
  };

  const renderField = (key, styleKey, fontSize) => {
    const hasChanges =
      profileState[key] !== profile[key] ||
      profileState[styleKey] !== profile[styleKey];
    const isActive = activeField === key;
    const isHovered = hoveredField === key;

    const iconStyle = {
      position: "absolute",
      top: "-10px",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "1.4rem",
      color: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      cursor: "pointer",
    };

    return (
      <span
        className={`editable-field  ${isActive ? "active px-3" : ""}`}
        onMouseEnter={() => setHoveredField(key)}
        onMouseLeave={() => setHoveredField(null)}
        style={{
          position: "relative",
          border: isActive ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive ? "#ffff" : "transparent",
          fontSize: fontSize || "inherit",
          cursor: "text",
          fontFamily: `${getSingleResumeDoc?.font_style}`,
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[key] = el)}
          onInput={(e) => handleFieldInput(key, e)}
          onBlur={() => handleFieldBlur(key)}
          onDoubleClick={() => handleFieldClick(key)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          className={`editable-field  ${profileState[styleKey]}`}
          suppressContentEditableWarning={true}
          style={{
            fontSize: fontSize || "inherit",
            cursor: "text",
            fontFamily: `${getSingleResumeDoc?.font_style}`,
          }}
        >
          {profileState[key]}
        </span>
        {isActive && (
          <div className="absolute  !rounded-2xl  editable-field active top-[-40px] left-0 flex items-center gap-2 bg-white p-2 border border-gray-200  shadow-lg shadow-blue-100 z-10">
            <button
              onClick={() => {
                setProfileState((prev) => {
                  const currentStyle = profileState[styleKey];
                  const isBold = currentStyle?.includes("font-bold");
                  const updatedStyle = isBold
                    ? currentStyle.replace(/\bfont-bold\b/, "").trim()
                    : `${currentStyle} font-bold`.trim();
                  return {
                    ...prev,
                    [styleKey]: updatedStyle,
                  };
                });
              }}
              className={`w-5 h-5   flex items-center justify-center  text-black text-sm font-semibold rounded-md ${
                profileState[styleKey]?.includes("font-bold")
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              title="Bold"
            >
              <Bold size={18} />
            </button>

            {/* <button
              onClick={() => {
                setProfileState((prev) => {
                  const currentStyle = profileState[styleKey];
                  const isBold = currentStyle?.includes("italic");
                  const updatedStyle = isBold
                    ? currentStyle.replace(/\bitalic\b/, "").trim()
                    : `${currentStyle} italic`.trim();
                  return {
                    ...prev,
                    [styleKey]: updatedStyle,
                  };
                });
              }}
              className={`w-5 h-5 flex items-center justify-center  text-black text-sm italic rounded-md ${
                profileState[styleKey]?.includes("italic")
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              title="Italic"
            >
              <Italic size={18} />
            </button> */}
            <button
              onClick={() => {
                setProfileState((prev) => {
                  const currentStyle = profileState[styleKey];
                  const isBold = currentStyle?.includes("underline");
                  const updatedStyle = isBold
                    ? currentStyle.replace(/\bunderline\b/, "").trim()
                    : `${currentStyle} underline`.trim();
                  return {
                    ...prev,
                    [styleKey]: updatedStyle,
                  };
                });
              }}
              className={`w-5 h-5 flex items-center justify-center  text-black text-sm underline rounded-md ${
                profileState[styleKey]?.includes("underline")
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              title="Underline"
            >
              <Underline size={18} />
            </button>
          </div>
        )}
        {isActive && hasChanges && (
          <MdDone
            onClick={handleSubmit}
            style={{ ...iconStyle, left: "-15px", background: "#4caf50" }}
          />
        )}

        {isActive && !hasChanges && (
          <MdClose
            onClick={() => {
              handleFieldBlur(key);
              setActiveField(null);
            }}
            style={{ ...iconStyle, right: "-16px", background: "#ff4d4d" }}
          />
        )}

        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(key)}
            style={{ ...iconStyle, right: "-16px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };

  useEffect(() => {
    if (profile) setProfileState(profile);
  }, [profile]);

  return (
    profile && (
      <header
        className={`cv-header ${
          activeField !== null || hoveredField !== null
            ? "editable-field active bg-white p-"
            : ""
        }`}
      >
        <p
          className="cv-name text-uppercase text-field"
          style={{
            color: getSingleResumeDoc?.heading_color || "#000",
            fontSize: getSingleResumeDoc?.font_size || "",
            fontFamily: `${getSingleResumeDoc?.font_style}`,
          }}
        >
          {renderField("first_name", "first_name_style")}
          &nbsp;
          {renderField("last_name", "last_name_style")}
        </p>
        <p className="cv-email text-center text-field">
          {renderField("introductory_title", "introductory_title_style")}
          {profileState.introductory_title &&
            profileState.email &&
            (profileState.address || profileState.city) &&
            " | "}
          {renderField("key_attribute", "key_attribute_style")}
          {profileState.key_attribute && " | "}
          {renderField("email", "email_style")}
          {profileState.email && " | "}
          {renderField("address", "address_style")}
          {profileState.address && profileState.city ? ", " : ""}
          {renderField("city", "city_style")}
          {(profileState.address || profileState.city) &&
            profileState.phone &&
            " | "}
          {renderField("phone", "phone_style")}
          {profileState.phone && profileState.linked_in && " | "}
          {renderField("linked_in", "linked_in_style")}
        </p>
      </header>
    )
  );
}
