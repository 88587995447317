import { createAsyncThunk } from "@reduxjs/toolkit";
import { error, success } from "../../Utilities/toast";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { AuthenticateApi } from "./api";
import { addUserSocket } from "../../config/socketConfig";
import { toast } from "react-toastify";
const authRepo = RepositoryFactory.get("auth");

export const authenticateAction = createAsyncThunk(
  "authenticate",
  async (payload) => {
    const { data } = await AuthenticateApi(payload);
    if (data) {
      let ssoPayload = {
        displayName: data?.name,
        email: data.email,
        picture: data.picture,
        uid: payload,
      };
      return ssoPayload;
    }
  }
);

export const ssoLoginAction = createAsyncThunk("ssoLogin", async (token) => {
  const { data } = await authRepo.ssoLogin(token);
  if (data?.status_code === 200) {
    success("Logged in successfully");
    return data;
  }
});
export const ssoInvitationLoginAction = createAsyncThunk(
  "ssoInvitationLogin",
  async (payload) => {
    const { data } = await authRepo.ssoInvitationLogin(payload);
    if (data?.status_code === 200) {
      return data;
    }
  }
);

export const checkSessionAction = createAsyncThunk("checkSession", async () => {
  const payload = localStorage.getItem("auth");
  const { data } = await AuthenticateApi(payload);
  return data;
});

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.authenticateUser(payload);
      if (data?.status_code === 200) {
        addUserSocket(data.accessToken);
        if (!data?.data && data?.detail == "User is revoked by organization") {
          return { revoked: true, ...data };
        } else {
          success("Logged in successfully");
          return data;
        }
      }
      throw new Error("Failed to login");
    } catch (err) {
      console.log("err: ", err);
      error(err?.data?.detail || "Someting went wrong, please try again later");
      return rejectWithValue(err.message);
    }
  }
);
export const delegateAccess = createAsyncThunk(
  "auth/delegateAccess",
  async (token, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.authenticateWithContext(token);

      if (data?.status_code === 200) {
        addUserSocket(data.accessToken);
        if (!data?.data && data?.detail == "User is revoked by organization") {
          return { revoked: true, ...data };
        } else {
          success("Logged in successfully");
          return data;
        }
      }
      throw new Error("Failed to login");
    } catch (err) {
      error("Access denied! please try again later");
      return rejectWithValue(err.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "auth/createUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.createNewUser(payload);
      if (data.status_code === 200) {
        success("User Created successfully");
        return data;
      }
      // throw new Error("Failed to create user");
    } catch (err) {
      console.log(err, "errerrerrerr");
      const errorMessage =
        err?.data?.detail ||
        (err.response?.data?.messages?.email &&
          "Please enter a valid email that matches the pattern example@example.com") ||
        err.response?.data?.key;
      error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const createInvitedUser = createAsyncThunk(
  "auth/invitedUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.registerInvitedUser(payload);
      if (data.status_code === 200) {
        success("User Created successfully");
        return data;
      } else {
        error(data.detail);
      }
      throw new Error("Failed to create user");
    } catch (err) {
      error(
        err?.data?.detail ||
          err.response?.data?.message ||
          "Someting went wrong, please try again later"
      );
      return rejectWithValue(err.message);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.forgetPass(payload);
      // if (data.details === "Reset email sent successfully") {
      return data;
      // }
      // throw new Error("Failed to reset password");
    } catch (err) {
      error(err.response?.data?.message);
      return rejectWithValue(err.message);
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authRepo.verfiyEmail(payload);
      if (response?.status === 200) {
        return response.data.details;
      }
      throw new Error("Verification failed");
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getUserSubscription = createAsyncThunk(
  "auth/userSubscripition",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.getUserBundle();
      if (data.status_code === 200) {
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const onBoardingAction = createAsyncThunk(
  "auth/onBoardingAction",
  async ({ payload, onSuccess, onError }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.onBoarding(payload);
      onSuccess();
      return data;
    } catch (err) {
      onError();
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);
export const updateOnboarding = createAsyncThunk(
  "auth/updateOnBoard",
  async ({ payload, onSuccess, onError }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.udpateOnBoard(payload);
      onSuccess();
      return data;
    } catch (err) {
      onError();
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);
export const updateOnboardingUser = createAsyncThunk(
  "auth/updateOnboardingUser",
  async (
    { payload, onSuccess, onError: onError = () => {} },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await authRepo.updateOnboardingUser(payload);
      await dispatch(getCurrentUser());
      onSuccess(data?.detail);
      return data;
    } catch (err) {
      onError();
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);
export const getOnBoardingAction = createAsyncThunk(
  "auth/getOnBoard",
  async ({ onComplete }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.getOnBoard();
      onComplete();
      if (data.entries.length > 0) {
        return data.entries;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail || "Something Went Wrong");
      onComplete();
      return rejectWithValue(err.message);
    }
  }
);
export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.currentUserInfo();
      return data.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getSkillsAnalysis = createAsyncThunk(
  "auth/skillsAnalysis",
  async ({ onComplete }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.skillsGapAnalysis();
      onComplete();
      return data.data;
    } catch (err) {
      onComplete();
      return rejectWithValue(err.message);
    }
  }
);
export const generateSkillsGapAnalysis = createAsyncThunk(
  "auth/generateSkillsGapAnalysis",
  async ({ onSuccess, onError }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.generateSkillsAnalysis();
      onSuccess();
      return data.data;
    } catch (err) {
      onError();
      return rejectWithValue(err.message);
    }
  }
);

export const generateLiveSkillsGapAnalysis = createAsyncThunk(
  "auth/generateLiveSkillsGapAnalysis",
  async ({ onSuccess, onError }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.generateLiveSkillsGapAnalysis();
      onSuccess();
      return data.data.analysis;
    } catch (err) {
      onError();
      return rejectWithValue(err.message);
    }
  }
);

export const refreshAuthToken = createAsyncThunk(
  "auth/refreshAuthToken",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.refreshToken();
      if (data.status_code === 200) {
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.logoutCurrentUser();
      if (data.status_code === 200) {
        dispatch({ type: "RESET" });
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateprofile = createAsyncThunk(
  "auth/updateprofile",
  async ({ payload, onSuccess }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.udpateProfile(payload);
      if (data) {
        onSuccess();
        return data;
      } else {
        throw new Error("Failed to update simulations");
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const continueIndividualAction = createAsyncThunk(
  "auth/individualContinue",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.individualUser();
      if (data.status_code === 200) {
        success(data?.detail);
        return data;
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const cancelUserSubscription = createAsyncThunk(
  "auth/cancelSubscrtiption",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.cancelSubscription();
      if (data.status_code === 200) {
        success(data?.detail);
        return data;
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);

/// get quote funcion
export const getQuote = createAsyncThunk(
  "theme/getQuote",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.getQuote();
      if (data) {
        onSuccess(data?.data);
      }
      return data;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);

//ON BOARDING
export const generateAiQuestions = createAsyncThunk(
  "auth/generateAiQuestions",
  async ({ AiBoardingPayload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.generateAiQuestions(AiBoardingPayload);
      if (data) {
        onSuccess(data?.new_question);
      }
      return data;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      onError();
      return rejectWithValue(err.message);
    }
  }
);

export const updateAiQuestionOnboarding = createAsyncThunk(
  "auth/updateAiQuestionOnboarding",
  async (
    { payload, aiQuestionId, onSuccess, onError },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await authRepo.updateAiQuestionOnboarding(
        payload,
        aiQuestionId
      );
      onSuccess();
      return data;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      onError();
      return rejectWithValue(err.message);
    }
  }
);

export const saveAiQuestions = createAsyncThunk(
  "auth/saveAiQuestions",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.saveAiQuestions(payload);
      onSuccess();
      return data;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getMyAiBoardingQuestions = createAsyncThunk(
  "auth/getMyAiBoardingQuestions",
  async ({ onComplete }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.getMyAiBoardingQuestions();
      onComplete();
      return data.questions;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      onComplete();
      return rejectWithValue(err.message);
    }
  }
);

const handleNotSurePayload = (payload) => {
  return {
    questions_answers: payload
      .filter(
        (item) =>
          item.answer !== null &&
          item.answer !== undefined &&
          item.answer !== "" &&
          !(
            typeof item.answer === "object" &&
            Object.keys(item.answer).length === 0
          ) &&
          !(Array.isArray(item.answer) && item.answer.length === 0)
      )
      .map((item) => ({
        question: item.question,
        answer:
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer,
      })),
  };
};

export const saveNotSureQuestions = createAsyncThunk(
  "auth/saveNotSureQuestions",
  async ({ formData, onComplete }, { rejectWithValue, dispatch }) => {
    try {
      const payload = handleNotSurePayload(formData);
      const { data } = await authRepo.saveNotSureQuestions(payload);
      onComplete();
      return data.questions;
    } catch (err) {
      onComplete();
      error(err?.response?.data?.detail || "Something Went Wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getRecommendCareerSuggestion = createAsyncThunk(
  "auth/getRecommendCareerSuggestion",
  async ({ isRetake, carier_goal, onComplete }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.getRecommendCareerSuggestion(
        isRetake,
        carier_goal
      );
      onComplete();
      return data?.recommended_career || data?.data?.analysis;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      onComplete();
      return rejectWithValue(err.message);
    }
  }
);

export const getAiSkillGapAssessmentReport = createAsyncThunk(
  "auth/getAiSkillGapAssessmentReport",
  async ({ onComplete }, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.getAiSkillGapAssessmentReport();
      onComplete();
      return data?.recommended_career || data?.data?.analysis;
    } catch (err) {
      error(err?.response?.data?.detail || "Something Went Wrong");
      onComplete();
      return rejectWithValue(err.message);
    }
  }
);
