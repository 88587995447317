import React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space, Spin } from "antd";
import debounce from "lodash/debounce";
import { error } from "../../../Utilities/toast";

let index = 0;

const TargetCareerGoalDropdown = ({
  data,
  fetchOptions,
  disabled,
  currentPage,
  totalPages,
  placeholder,
  placeholderAdd,
  debounceTimeout = 1500,
  ...props
}) => {
  const inputRef = useRef(null);
  const [items, setItems] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [searched, setSearched] = useState(false);
  const [name, setName] = useState({});
  const [loader, setLoader] = useState({
    data: false,
    btn: false,
  });

  const fetchData = (value, page) => {
    setLoader((prev) => ({ ...prev, data: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, data: false }));
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const isValuePresent = data?.some((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      );
      if (isValuePresent) {
        return;
      }
      setSearchValue(value);
      fetchData(value, 1);
      setSearched(true);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleLoadmore = () => {
    const page = currentPage + 1;
    const value = searchValue ? searchValue : "";
    setLoader((prev) => ({ ...prev, btn: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, btn: false }));
    });
  };

  const onNameChange = (event) => {
    const value = event.target.value;
    setName({ value: value, label: value });
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!name?.value?.trim()) return;
    const isDuplicate = items?.find(
      (item) =>
        item?.label?.trim().toLowerCase() === name?.value?.trim().toLowerCase()
    );
    if (isDuplicate) {
      error("Already Exists");
      return;
    }
    setItems([name || `New item ${index++}`, ...items]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleBlur = () => {
    if (searched) {
      fetchData("", 1);
      setSearched(false);
    }
  };

  useEffect(() => {
    fetchData("", 1);
  }, []);

  return (
    <Select
      showSearch
      filterOption={true}
      disabled={disabled}
      onSearch={debounceFetcher}
      onBlur={handleBlur}
      notFoundContent={
        loader.data ? (
          <p className="flex text-xs justify-center w-full">Loading...</p>
        ) : (
          <p className="flex text-xs justify-center w-full">No Results Found</p>
        )
      }
      {...props}
      dropdownRender={(menu) => (
        <>
          <Space className="flex items-center w-full py-1 px-2">
            <Input
              placeholder={placeholderAdd}
              className="w-full"
              ref={inputRef}
              value={name.value}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button
              type="text"
              disabled={!name?.value?.trim()}
              icon={<PlusOutlined />}
              onClick={addItem}
            >
              Add
            </Button>
          </Space>
          <Divider className="my-2" />
          {menu}
        </>
      )}
    >
      {items?.length > 0 && (
        <>
          <Select.Option value={null}>{placeholder}</Select.Option>
          <Select.Option value="not_sure">I am Not Sure</Select.Option>
        </>
      )}
      {items?.length > 0 &&
        items?.map((option, ind) => (
          <Select.Option className="capitalize" key={ind} value={option.value}>
            {option.label}
          </Select.Option>
        ))}

      {currentPage < totalPages && (
        <Select.Option
          value="load_more"
          disabled
          className="p-0 m-0 bg-red-500"
        >
          <div
            className={`flex items-center justify-center h-full w-full ${
              !loader.btn
                ? "bg-primary cursor-pointer"
                : "bg-[#1e508d] cursor-default"
            } hover:bg-[#1e508d] text-white text-center font-bold`}
            onClick={!loader.btn ? handleLoadmore : null}
          >
            Load More {loader.btn && <Spin size="small" className="ml-2" />}
          </div>
        </Select.Option>
      )}
    </Select>
  );
};

export default TargetCareerGoalDropdown;
