import React, { useState, useRef, useEffect } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getResumeCategory,
  updateResumeSectionPosition,
  updateResumeSkill,
} from "../../../Redux/ResumeBuilderSlice";
import { success } from "../../../Utilities/toast";
import { Categories } from "emoji-picker-react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function TopSkills({ categories, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeSkillIndex, setActiveSkillIndex] = useState(null);
  const [hoveredSkillIndex, setHoveredSkillIndex] = useState(null);
  const [skillState, setSkillState] = useState(categories || []);
  const [selectedSkillId, setSelectedSkillId] = useState("");
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const handleFieldClick = (index, id) => {
    if (activeSkillIndex !== null) submitChanges();
    setSelectedSkillId(id);
    setActiveSkillIndex(index);
    setTimeout(() => {
      editableRefs.current[index]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];

    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;

      setSkillState((prev) =>
        prev.map((skill) =>
          skill.id === skillState[index].id ? { ...skill, name: value } : skill
        )
      );

      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setSkillState((prev) =>
        prev.map((skill) =>
          skill.id === skillState[index].id ? { ...skill, name: value } : skill
        )
      );
    }
  };
  const fetchCategories = () => {
    dispatch(getResumeCategory({ ID: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    if (!selectedSkillId) return;
    const originalSkill = categories[0]?.skills?.find(
      (s) => s.id === selectedSkillId
    );
    const updatedSkill = skillState.find((s) => s.id === selectedSkillId);
    if (
      originalSkill &&
      updatedSkill &&
      originalSkill.name.trim() !== updatedSkill.name.trim()
    ) {
      setActiveSkillIndex(null);
      setHoveredSkillIndex(null);
      const payload = {
        resume_id: id,
        name: updatedSkill.name.trim(),
        skill_id: selectedSkillId,
      };
      dispatch(
        updateResumeSkill({
          ID: selectedSkillId,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchCategories();
          },
          onError: () => {
            setSkillState(categories[0].skills);
          },
        })
      );
    } else {
      setSelectedSkillId("");
      setActiveSkillIndex(null);
      setHoveredSkillIndex(null);
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(skillState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...skillState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setSkillState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          skills: updatedSections,
        },
        onSuccess: () => {
          setActiveSkillIndex(null);
          setHoveredSkillIndex(null);
          dispatch(getResumeCategory({ ID: id, onSuccess: () => {} }));
        },
        onError: () => {
          setSkillState(previousSections);
        },
      })
    );
  };
  const renderSkill = (skill, index) => {
    const isActive = activeSkillIndex === index;
    const isHovered = hoveredSkillIndex === index;
    const originalSkill = categories[0]?.skills?.find(
      (s) => s.id === skill?.id
    );
    const hasChanges =
      originalSkill && originalSkill.name !== skill?.name.trim();
    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active px-3" : ""}`}
        onMouseEnter={() => setHoveredSkillIndex(index)}
        onMouseLeave={() => setHoveredSkillIndex(null)}
        onDoubleClick={() => handleFieldClick(index, skill?.id)}
        style={{
          position: "relative",
          border:
            isActive || isHovered ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[index] = el)}
          onInput={(e) => handleFieldInput(index, e)}
          onBlur={submitChanges}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field `}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {skill?.name}
        </span>
        {isActive && (
          <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold  px-1 rounded-full shadow-md flex items-center gap-2">
            {/* Upper Arrow */}
            <FiChevronUp
              className={`text-white text-lg cursor-pointer ${
                activeSkillIndex == 0 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeSkillIndex - 1,
                  source: activeSkillIndex,
                });
              }}
            />

            {/* Lower Arrow */}
            <FiChevronDown
              className={`text-white text-lg cursor-pointer  ${
                activeSkillIndex == skillState.length - 1 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeSkillIndex + 1,
                  source: activeSkillIndex,
                });
              }}
            />
          </div>
        )}
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && !hasChanges && (
          <MdClose
            onClick={() => setActiveSkillIndex(null)}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, skill?.id)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };

  useEffect(() => {
    if (categories) {
      const sortedSkills = categories[0]?.skills
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setSkillState(sortedSkills);
    }
  }, [categories]);
  return (
    <section className="cv-skills">
      {categories?.find((item) => item.name === "Top Skills") && (
        <ul className="cv-skills-list d-flex justify-content-start">
          {skillState?.map((skill, index) => (
            <li
              className="cv-skill_top mt-1 mb-0 apply-font flex gap-1 align-middle"
              key={index}
            >
              <span className="font-bold text-lg">•</span>{" "}
              {renderSkill(skill, index)}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
