import React from "react";
import { useRecoilValue } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../recoil/onBoarding";
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
} from "./NotSureSteps";

const steps = [
  <Step1 />,
  <Step2 />,
  <Step3 />,
  <Step4 />,
  <Step5 />,
  <Step6 />,
  <Step7 />,
];

const NotSureBoardingComponents = () => {
  // RECOIL
  const stepState = useRecoilValue(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState?.notSureQuestionBoard?.activeStep || 1;

  return <div>{steps[currentStep - 1] || <Step1 />}</div>;
};

export default NotSureBoardingComponents;
