export const TECHNICAL_SKILLS = [
  "Programming",
  "Data Analysis",
  "Design",
  "Writing",
  "Customer Service",
  "Marketing",
];
export const SOFT_SKILLS = [
  { label: "Communication", value: "communication" },
  { label: "Problem-Solving", value: "problem-solving" },
  { label: "Teamwork", value: "teamwork" },
  { label: "Leadership", value: "leadership" },
  { label: "Time Management", value: "time-management" },
];

export const WORK_EXPERIENCE_OPTIONS = [
  {
    label: "Service Industry (e.g., hospitality, retail)",
    value: "service_industry",
  },
  { label: "Administrative/Office Work", value: "administrative_office" },
  { label: "Technical/IT Roles", value: "technical_it" },
  { label: "Creative/Design Roles", value: "creative_design" },
  { label: "Sales/Customer Support", value: "sales_support" },
  { label: "Management/Leadership", value: "management_leadership" },
];
export const EDUCATION_OPTIONS = [
  { label: "High School Diploma", value: "high_school_diploma" },
  { label: "Vocational/Technical Training", value: "vocational_training" },
  { label: "Associate Degree", value: "associate_degree" },
  { label: "Bachelor's Degree", value: "bachelor_degree" },
  { label: "Master's/Professional Degree", value: "masters_degree" },
  {
    label: "Certifications (e.g., Google Analytics, AWS, PMP)",
    value: "certifications",
  },
];
export const INTERESTS = [
  {
    label: "Solving complex problems or puzzles.",
    value: "solving-complex-problems",
  },
  {
    label: "Designing or creating things (e.g., graphics, code, writing).",
    value: "designing-creating-things",
  },
  {
    label: "Interacting and working with people.",
    value: "interacting-working-with-people",
  },
  {
    label: "Organizing and managing tasks or projects.",
    value: "organizing-managing-tasks",
  },
  {
    label: "Researching and analyzing information.",
    value: "researching-analyzing-information",
  },
  {
    label: "Teaching or mentoring others.",
    value: "teaching-mentoring-others",
  },
];

export const CAREER_PREFERENCES = [
  "Job Stability/Security",
  "Remote Work Flexibility",
  "High Earning Potential",
  "Creative Freedom",
  "Leadership Opportunities",
  "Impact or Purpose (e.g., working in sustainability, healthcare, etc.)",
];
export const LEARNING_READINESS = [
  "Yes, I'm ready to upskill/reskill.",
  "Yes, but I need to know where to start.",
  "No, I prefer to focus on my existing skillset.",
];
