import React from "react";
import { NotSureStepHeader } from "../../../../../../../Pages/Onboarding/components/steps/context";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";
import { Select } from "antd";
import {
  SOFT_SKILLS,
  TECHNICAL_SKILLS,
} from "../../../../../../../Pages/Onboarding/components/boardingConstants";
import { produce } from "immer";
import CreatableSelect from "react-select/creatable";

const Step1 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;

  //FUNCTIONS
  const handleTechnicalFieldChange = (value) => {
    setStepState(
      produce((draft) => {
        draft.notSureQuestionBoard.formData[
          currentStep - 1
        ].answer.technicalField = value;
      })
    );
  };

  const handleSoftSkillsChange = (values) => {
    setStepState(
      produce((draft) => {
        draft.notSureQuestionBoard.formData[currentStep - 1].answer.softSkills =
          values;
      })
    );
  };

  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Self-Assessment of Skills"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <Select
          placeholder="Select Technical Field"
          className="w-full mt-2 h-[48px] capitalize"
          value={formData[currentStep - 1]?.answer?.technicalField || undefined}
          onChange={handleTechnicalFieldChange}
        >
          {TECHNICAL_SKILLS.map((skill, index) => (
            <Select.Option key={index} value={skill} className="capitalize">
              {skill}
            </Select.Option>
          ))}
        </Select>

        <CreatableSelect
          className="basic-single w-full mt-3"
          classNamePrefix="select"
          defaultValue={[]}
          isMulti
          isClearable
          isSearchable
          name="color"
          options={SOFT_SKILLS}
          isLoading={false}
          menuPortalTarget={document.body}
          value={formData[currentStep - 1].answer.softSkills}
          onChange={(selectedOptions) =>
            handleSoftSkillsChange(selectedOptions)
          }
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
    </div>
  );
};

export default Step1;
