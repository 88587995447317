import "./Section5.css";

const Section5 = () => {
  return (
    <section className="testimonials-section">
      <div className="intro-text">
        <h2 className="section-title">
          What Our <span className="highlight">Partners</span> Are Saying
        </h2>
      </div>
      <div className="testimonial-container">
        <img
          src="/images/icons/left-arrow.svg"
          alt="Previous"
          className="nav-arrow left-arrow"
        />
        <div className="testimonial-card">
          <p className="testimonial-quote">
            "Nexa RU saved us thousands of dollars and delivered top-tier
            candidates. It’s like having a supercharged recruiter on our team!"
          </p>
          <div className="author-info">
            <img
              src="/images/Bootcamp/BootcampSection5.png"
              alt="John L"
              className="author-image"
            />
            <div className="author-details">
              <p className="author-name">John L</p>
              <p className="author-title">
                Recruiting Director at ACH Staffing
              </p>
            </div>
          </div>
        </div>
        <img
          src="/images/icons/right-arrow.svg"
          alt="Next"
          className="nav-arrow right-arrow"
        />
      </div>
    </section>
  );
};

export default Section5;
