import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import FeedbackAPI from "../repository/MyInterviewRepository";
// import { transform } from "html2canvas/dist/types/css/property-descriptors/transform";

const profileRepo = RepositoryFactory.get("profile");

export const jobsAppliedStats = createAsyncThunk(
  "profile/appliedJobs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.jobsAppliedStats();
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch jobs applied analytics");
      }
    } catch (err) {
      console.error("Error fetching jobs applied analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const interviewTrainingAnalytics = createAsyncThunk(
  "profile/training",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.trainingSessions();
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch training analytics");
      }
    } catch (err) {
      console.error("Error fetching training analytics", err);
      return rejectWithValue(err.message);
    }
  }
);
export const interviewSimulationAnalytics = createAsyncThunk(
  "profile/simulation",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await profileRepo.simulationSessions();
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch Simulation analytics");
      }
    } catch (err) {
      console.error("Error fetching Simulation analytics", err);
      return rejectWithValue(err.message);
    }
  }
);

export const GetInterviewSimulationsData = createAsyncThunk(
  "profile/simulationList",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await FeedbackAPI.InterviewSimulationsData(payload);
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch feedback list");
      }
    } catch (err) {
      console.error("Error fetching feedback list", err);
      return rejectWithValue(err.message);
    }
  }
);

export const FeedbackData = createAsyncThunk(
  "profile/feedbackData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FeedbackAPI.GET_feebacks(payload);
      if (response?.data?.status_code === 200) {
        return response?.data;
      } else {
        return rejectWithValue({ message: "Failed to fetch feedback data" });
      }
    } catch (err) {
      console.error("Error fetching feedback data", err);
      return rejectWithValue(err.message);
    }
  }
);

export const FeedbackSimulationData = createAsyncThunk(
  "profile/FeedbackSimulationData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FeedbackAPI.GET_SIMULATION_FEEDBACK(payload);
      if (response?.data) {
        return response?.data;
      } else {
        return rejectWithValue({ message: "Failed to fetch feedback data" });
      }
    } catch (err) {
      console.error("Error fetching feedback data", err);
      return rejectWithValue(err.message);
    }
  }
);

export const TrainingSessionData = createAsyncThunk(
  "profile/TrainingSessionData",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await FeedbackAPI.GET_training_sessions(payload);
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch training sessions data");
      }
    } catch (err) {
      console.error("Error fetching training sessions data", err);
      return rejectWithValue(err.message);
    }
  }
);

export const skillsPracticeData = createAsyncThunk(
  "profile/skillspracticeData",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await FeedbackAPI.SkillPracticeData(payload);
      if (data?.status_code === 200) {
        return data.data;
      } else {
        throw new Error("Failed to fetch feedback list");
      }
    } catch (err) {
      console.error("Error fetching feedback list", err);
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  jobsStats: null,
  interviewStats: null,
  simulationList: [],
  feedbacks: {},
  trainingSessionData: [],
  skillPracticeData: [],
  isLoading: false,
  error: null,
  interviewSimulationStats: null,
};

const profileAnalyticsSlice = createSlice({
  name: "profileAnalytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(interviewTrainingAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(interviewTrainingAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interviewStats = action.payload;
      })
      .addCase(interviewTrainingAnalytics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(interviewSimulationAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(interviewSimulationAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interviewSimulationStats = action.payload;
      })
      .addCase(interviewSimulationAnalytics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(GetInterviewSimulationsData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(GetInterviewSimulationsData.fulfilled, (state, action) => {
        // state.isLoading = false;
        let pagination = {
          totalPages: action.payload.total_pages,
          totalSessions: action.payload.total_sessions,
        };
        state.simulationList = action.payload.sessions;
        state.simulationPagination = pagination;
      })
      .addCase(GetInterviewSimulationsData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(FeedbackData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FeedbackData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.feedbacks = action.payload;
      })
      .addCase(FeedbackData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(FeedbackSimulationData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FeedbackSimulationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.feedbacks = action.payload;
      })
      .addCase(FeedbackSimulationData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(TrainingSessionData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(TrainingSessionData.fulfilled, (state, action) => {
        // state.isLoading = false;
        let pagination = {
          totalPages: action.payload.total_pages,
          totalSessions: action.payload.total_sessions,
        };
        state.trainingSessionData = action.payload.sessions;
        state.trainingPagination = pagination;
      })
      .addCase(TrainingSessionData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(skillsPracticeData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(skillsPracticeData.fulfilled, (state, action) => {
        // state.isLoading = false;
        let pagination = {
          totalPages: action.payload.total_pages,
          totalSessions: action.payload.total_sessions,
        };
        state.skillPracticeData = action.payload.sessions;
        state.skilllPagination = pagination;
      })
      .addCase(skillsPracticeData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(jobsAppliedStats.fulfilled, (state, action) => {
        state.jobsStats = action.payload;
      });
  },
});

export default profileAnalyticsSlice.reducer;
