import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import {
  applyOnJob,
  getJobListings,
  resumeGrading,
} from "../../../../Redux/NexaIqSlice";
import { error } from "../../../../Utilities/toast";
import { useDispatch } from "react-redux";
import ResumeScoreModal from "../../../../Components/NexaIqSideBar/ResumeScoreModal";
import { buildPayload, createSearchPayload } from "../IQContext";
import { useSearchParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const ApplyJobModal = ({
  isOpenModal,
  toggleModal,
  setIsActiveApplyStep,
  isactiveApplyStep,
  closeSidebar,
  setIsModal,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const { getSingleJobData, applyRecommendation } = useSelector(
    (state) => state.nexaIq
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const { jobsPreference } = useSelector((state) => state.UserProfile);
  const [answers, setAnswers] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileTypes = ["PDF", "DOC", "DOCX"];
  const [gradingLoading, setGradingLoading] = useState(false);
  const [gradingModal, setGradingModal] = useState(false);
  const [gradingData, setGradingData] = useState("");
  const [disableResume, setDisableResume] = useState(false);
  const prePayload = createSearchPayload(jobsPreference, paramsObject);
  const getJobPayload = buildPayload({
    activeTab: "myJobs",
    filters: prePayload,
  });
  const handleAnswerChange = (value, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };
  const handleChange = (file) => {
    if (file) {
      const payload = new FormData();
      payload.append("job_id", getSingleJobData?.id);
      payload.append("file", file);

      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        error("File size must be less than 1 MB");
        file = null;
      } else {
        setFile(file);
        setGradingLoading(true);
        setGradingModal(true);
        dispatch(
          resumeGrading({
            payload,
            onSuccess: (res) => {
              setGradingLoading(false);
              if (res) {
                setGradingData(res);
                setDisableResume(true);
              } else {
                setGradingData(null);
              }
            },
          })
        );
      }
    }
  };

  const handleSubmit = () => {
    if (isactiveApplyStep === 1) {
      setIsActiveApplyStep(2);
    } else {
      //   setLoadings(true);
      if (file == null) {
        error("Please upload your resume!");
        // setLoadings(false);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("job_id", getSingleJobData?.id);
      if (getSingleJobData?.preset_questions != null) {
        const answersString = answers.join(","); // Join the answers with a comma
        formData.append("answers", answersString); // Append the answers string to formData
      }
      setIsLoading((prev) => ({ ...prev, isDefaultLoading: true }));
      dispatch(
        applyOnJob({
          formData,
          onSuccess: (res) => {
            setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
            toggleModal();
            closeSidebar();
            setIsModal((prev) => ({
              ...prev,
              ThankYouModal: true,
            }));
            setIsLoading((prev) => ({ ...prev, isMainShimmer: true }));
            dispatch(
              getJobListings({
                payload: getJobPayload,
                page: 1,
                jobApplied: false,
                nexa_jobs: false,
                onSuccess: () => {
                  setIsLoading((prev) => ({ ...prev, isMainShimmer: false }));
                },
                onError: () => {
                  setIsLoading((prev) => ({ ...prev, isMainShimmer: false }));
                },
              })
            );
          },
          onError: () => {
            setIsLoading((prev) => ({ ...prev, isDefaultLoading: false }));
          },
        })
      );
    }
  };

  const handleFileOpen = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  };
  return (
    <ReactModal
      isOpen={isOpenModal}
      onRequestClose={toggleModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "20px 40px",
          maxWidth: "750px",
          width: "95%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 9999,
          maxHeight: "90vh",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
    >
      <>
        <button
          onClick={toggleModal}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
        >
          <IoClose size={24} />
        </button>
        {/* {loading && <Loader />} */}
        <h2 className="text-xl font-bold mt-2">
          {isactiveApplyStep == 1
            ? "Answer the Questions"
            : "Upload Your Awesome Resume"}
        </h2>
        <div className="modal-body-iq flex flex-col gap-4 mt-2">
          {isactiveApplyStep == 1 ? (
            getSingleJobData?.preset_questions?.map((question, index) => (
              <label key={index} className="modal-label">
                <span className="text-xl">{question}</span>
                <textarea
                  rows="4"
                  className="modal-input-Answers w-full h-20"
                  placeholder="Answer here..."
                  value={answers[index]}
                  onChange={(e) => handleAnswerChange(e.target.value, index)}
                />
              </label>
            ))
          ) : (
            <label className="modal-label modalNexaIQ">
              <div className="pt-4 group-open:animate-fadeIn">
                <form action="#">
                  <FileUploader
                    name="file"
                    handleChange={handleChange}
                    types={fileTypes}
                    disabled={gradingLoading || disableResume}
                  />
                  {file && (
                    <div
                      onClick={handleFileOpen}
                      className="mt-4 p-2 text-blue-500 cursor-pointer"
                    >
                      {file.name} (Click to preview)
                    </div>
                  )}
                </form>
              </div>
            </label>
          )}
        </div>
        <div className="modal-footer">
          <button
            onClick={() => {
              if (isactiveApplyStep == 1) {
                toggleModal();
              } else {
                setIsActiveApplyStep(1);
              }
            }}
            className={`modal-button cancel-button ${
              getSingleJobData?.preset_questions == null ? "hidden" : ""
            }`}
          >
            {isactiveApplyStep == 1 ? "Cancel" : "Back"}
          </button>
          <button
            className="modal-button save-button"
            disabled={loading}
            onClick={handleSubmit}
          >
            {isactiveApplyStep == 1
              ? "Next"
              : isactiveApplyStep == 2 && !loading
              ? "Submit"
              : "Submiting..."}
          </button>
        </div>
      </>
      {gradingModal && (
        <ResumeScoreModal
          data={gradingData}
          isOpen={gradingModal}
          onClose={toggleModal}
          onConfirm={() => setGradingModal(false)}
          loading={gradingLoading}
        />
      )}
    </ReactModal>
  );
};

export default ApplyJobModal;
