import React from "react";

const PaginationShimmer = () => {
  return (
    <div className="flex items-center justify-center mt-4 space-x-3">
      <div className="h-8 w-20 bg-gray-300 rounded"></div>
      <div className="h-8 w-8 bg-gray-300 rounded"></div>
      <div className="h-8 w-20 bg-gray-300 rounded"></div>
    </div>
  );
};

export default PaginationShimmer;
