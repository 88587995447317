import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PreviewAdditionalSection from "./PreviewAdditionalSection";
import { createMarkup } from "../../Utilities/CreateMarkup";

const PreviewResume = ({ resumeData }) => {
  const { getSingleResumeDoc } = useSelector((state) => state.ResumeBuilder);
  const [sectionsConfig, setSectionConfigs] = useState([]);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };

  const addClassToPTags = (htmlString, className) => {
    if (!htmlString) return "";
    return htmlString.replace(
      /<p\b([^>]*)>/gi,
      `<p$1 class="${className} style={{fontFamily:${getSingleResumeDoc?.font_style}}">`
    );
  };

  const sectionComponents = useMemo(
    () => [
      {
        key: "details",
        component: (
          <header className="cv-header">
            <p
              className="cv-name text-uppercase "
              style={{
                color: `${
                  getSingleResumeDoc?.heading_color
                    ? getSingleResumeDoc?.heading_color
                    : "#000"
                }`,
                fontSize: `${
                  getSingleResumeDoc?.font_size
                    ? getSingleResumeDoc?.font_size
                    : ""
                }`,
                fontFamily: `${getSingleResumeDoc?.font_style}`,
              }}
            >
              <span
                className={`cv-name text-uppercase ${resumeData?.profile?.first_name_style} `}
              >
                {resumeData?.profile?.first_name}
              </span>{" "}
              <span
                className={`cv-name text-uppercase ${resumeData?.profile?.last_name_style} `}
              >
                {resumeData?.profile?.last_name}
              </span>
            </p>
            <p
              className="cv-email !text-lg  text-center"
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
            >
              {resumeData?.profile?.introductory_title && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.introductory_title_style} `}
                >
                  {" "}
                  {resumeData?.profile?.introductory_title} |{" "}
                </span>
              )}
              {resumeData?.profile?.key_attribute && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.key_attribute_style} `}
                >
                  {" "}
                  {resumeData?.profile?.key_attribute} |{" "}
                </span>
              )}
              {resumeData?.profile?.email && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.email_style} `}
                >
                  {" "}
                  {resumeData?.profile?.email} |{" "}
                </span>
              )}
              {resumeData?.profile?.address && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.address_style} `}
                >
                  {" "}
                  {resumeData?.profile?.address} |{" "}
                </span>
              )}
              {resumeData?.profile?.phone && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.phone_style} `}
                >
                  {" "}
                  {resumeData?.profile?.phone} |{" "}
                </span>
              )}
              {resumeData?.profile?.linked_in && (
                <span
                  className={`cv-email !text-lg  text-center ${resumeData?.profile?.linked_in_style} `}
                >
                  {" "}
                  {resumeData?.profile?.linked_in}
                </span>
              )}
            </p>
          </header>
        ),
      },
      {
        key: "professionalsummary",
        component: resumeData?.professionalSummaries?.map((item, index) => (
          <div
            key={index}
            className="cv-professional_summary-item mb-0 mt-1 pl-1 "
          >
            <p
              className={`cv-item-date apply-font ${
                getSingleResumeDoc?.font_style &&
                getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
              }`}
              style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
              dangerouslySetInnerHTML={{
                __html: addClassToPTags(item?.text, ""),
              }}
            />
          </div>
        )),
      },
      {
        key: "certification",
        component: (
          <ul className="cv-skills-list d-flex justify-content-start">
            {resumeData?.certificates?.map((certificate, index) => (
              <li className="cv-skill_top" key={index}>
                <span className="font-bold text-sm">•</span>{" "}
                <span
                  style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                >
                  {certificate?.name}
                </span>
              </li>
            ))}
          </ul>
        ),
      },
      {
        key: "skills",
        component:
          resumeData?.skills &&
          resumeData?.skills?.map((category, index) => (
            <ul className="cv-skills-list flex flex-wrap" key={index}>
              {category?.skills?.map((skill, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-xs">•</span>{" "}
                  <span
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {skill?.name}
                  </span>
                </li>
              ))}
            </ul>
          )),
      },
      {
        key: "workexperience",
        component: resumeData?.workExperience?.map((experience, index) => (
          <>
            <div
              key={index}
              className={`cv-professional_summary-item mt-3 d-flex justify-content-between align-items-center mb-${getSingleResumeDoc?.inner_section_spacing}`}
            >
              <div className="text-capitalize">
                <h3
                  className="cv-item-title mb-0 "
                  style={{
                    color: `${
                      getSingleResumeDoc?.subheading_color
                        ? getSingleResumeDoc?.subheading_color
                        : "#000"
                    }`,
                    fontFamily: `${getSingleResumeDoc?.font_style}`,
                  }}
                >
                  {experience?.name}
                </h3>
                <p
                  className="cv-item-date mb-0"
                  style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                >
                  {experience?.role_name}
                </p>
              </div>
              <div className="text-capitalize">
                <p
                  className="cv-item-date "
                  style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                >
                  {formatDate(experience?.started_at)}
                  {experience?.ended_at == null
                    ? " - present"
                    : `${""} - ${formatDate(experience?.ended_at)}`}
                </p>
              </div>
            </div>
            <div>
              <p
                className={`cv-item-date apply-font ${
                  getSingleResumeDoc?.font_style &&
                  getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
                }`}
                style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                dangerouslySetInnerHTML={createMarkup(
                  experience.key_acheivement
                )}
              />
              {/* {experience?.description}...
              </p> */}
            </div>
            <div>
              <p
                className={`cv-item-date apply-font ${
                  getSingleResumeDoc?.font_style &&
                  getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
                }`}
                style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                dangerouslySetInnerHTML={createMarkup(experience.description)}
              />
              {/* {experience?.description}...
              </p> */}
            </div>
            {resumeData?.workExperience?.length > 0 &&
              index !== resumeData?.workExperience?.length - 1 && (
                <hr className="mt-3" />
              )}
          </>
        )),
      },
      {
        key: "education",
        component: resumeData?.educations?.map((education, index) => (
          <>
            <div
              key={index}
              className={`cv-professional_summary-item pl-1 mt-3 mb-${getSingleResumeDoc?.inner_section_spacing}`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3
                    className="cv-item-title m-0 "
                    style={{
                      color: `${
                        getSingleResumeDoc?.subheading_color
                          ? getSingleResumeDoc?.subheading_color
                          : "#000"
                      }`,
                      fontFamily: `${getSingleResumeDoc?.font_style}`,
                    }}
                  >
                    {education.school_name}
                  </h3>
                  <p
                    className="cv-item-date m-0"
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {education.degree && education.degree + " | "}
                    {education?.field && education?.field}
                  </p>
                </div>
                <div>
                  <p
                    className="cv-item-date text-capitalize "
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {education?.ended_at == null
                      ? " present"
                      : `${formatDate(education?.ended_at)}`}
                  </p>
                </div>
              </div>
              <div>
                <p
                  className={`cv-item-date apply-font ${
                    getSingleResumeDoc?.font_style &&
                    getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
                  }`}
                  dangerouslySetInnerHTML={createMarkup(
                    education.additional_information
                  )}
                />
              </div>
            </div>
            {resumeData?.educations?.length > 0 &&
              index !== resumeData?.educations?.length - 1 && (
                <hr className="mt-3" />
              )}
          </>
        )),
      },
      {
        key: "project",
        component: (
          <section className="cv-projects">
            {resumeData?.projects?.map((pro, index) => (
              <>
                <div
                  className={`cv-project-item pl-1 mt-3 mb-${getSingleResumeDoc?.inner_section_spacing}`}
                  key={index}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3
                        className="cv-item-title mb-0 "
                        style={{
                          color: `${
                            getSingleResumeDoc?.subheading_color
                              ? getSingleResumeDoc?.subheading_color
                              : "#000"
                          }`,
                          fontFamily: `${getSingleResumeDoc?.font_style}`,
                        }}
                      >
                        {pro?.project_name}
                      </h3>
                      <p
                        className="cv-item-title "
                        style={{
                          fontFamily: `${getSingleResumeDoc?.font_style}`,
                        }}
                      >
                        {pro?.organization}
                      </p>
                    </div>
                    <p
                      className="cv-item-date"
                      style={{
                        fontFamily: `${getSingleResumeDoc?.font_style}`,
                      }}
                    >
                      {formatDate(pro?.start_date)} -{" "}
                      {formatDate(pro?.end_date)}
                    </p>
                  </div>
                  <div className="cv-professional_summary-item ">
                    <p
                      className={`cv-item-date apply-font ${
                        getSingleResumeDoc?.font_style &&
                        getSingleResumeDoc?.font_style.replace(/\s+/g, "-")
                      }`}
                      style={{
                        fontFamily: `${getSingleResumeDoc?.font_style}`,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: addClassToPTags(
                          pro?.additional_information,
                          ""
                        ),
                      }}
                    />
                  </div>
                </div>
                {resumeData?.projects?.length > 0 &&
                  index !== resumeData?.projects?.length - 1 && (
                    <hr className="mt-3" />
                  )}
              </>
            ))}
          </section>
        ),
      },
      {
        key: "publications",
        component: resumeData?.publications?.map((publication, index) => {
          return (
            <>
              <div
                className={`cv-publication-item d-flex justify-content-between align-items-center pl-1 mt-2 mb-${getSingleResumeDoc?.inner_section_spacing}`}
                key={index}
              >
                <div>
                  <h3
                    className="cv-item-title mb-0 "
                    style={{
                      color: `${
                        getSingleResumeDoc?.subheading_color
                          ? getSingleResumeDoc?.subheading_color
                          : "#000"
                      }`,
                      fontFamily: `${getSingleResumeDoc?.font_style}`,
                    }}
                  >
                    {publication.title}
                  </h3>
                  <p
                    className="cv-item-date "
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {publication.publisher}
                  </p>
                </div>
                <p
                  className="cv-item-description"
                  style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                >
                  {formatDate(publication?.date)}
                </p>
              </div>

              {resumeData?.publications?.length > 0 &&
                index !== resumeData?.publications?.length - 1 && (
                  <hr className="mt-2" />
                )}
            </>
          );
        }),
      },
      {
        key: "interests",
        component: (
          <ul className="cv-skills-list flex flex-wrap">
            {resumeData?.interests?.interests &&
              resumeData?.interests?.interests?.map((interest, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-xs">•</span>
                  <span
                    className=" ml-1"
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {interest}
                  </span>
                </li>
              ))}
          </ul>
        ),
      },
      {
        key: "skill",
        component: (
          <ul className="cv-skills-list flex flex-wrap">
            {resumeData?.softskills?.skills &&
              resumeData?.softskills?.skills?.map((skill, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-xs">•</span>
                  <span
                    className=" ml-1"
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {skill}
                  </span>
                </li>
              ))}
          </ul>
        ),
      },
      {
        key: "language",
        component: (
          <ul className="cv-skills-list flex flex-wrap">
            {resumeData?.languages?.languages &&
              resumeData?.languages?.languages?.map((language, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-xs">•</span>
                  <span
                    className=""
                    style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
                  >
                    {language}
                  </span>
                </li>
              ))}
          </ul>
        ),
      },
    ],
    [resumeData]
  );

  const updatedSections = useMemo(() => {
    if (!getSingleResumeDoc?.positions) return [];

    return getSingleResumeDoc.positions
      .filter((position) => !position.isDeleted)
      .map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (comp) => comp.key === position.key
          );
          return matchingComponent
            ? { ...position, component: matchingComponent.component }
            : position;
        }

        const matchingAdditional = getSingleResumeDoc.additional_sections?.find(
          (item) => item.identity_key === position.key
        );

        return matchingAdditional
          ? {
              ...position,
              component: (
                <PreviewAdditionalSection sections={matchingAdditional} />
              ),
            }
          : position;
      })
      .sort((a, b) => a.position - b.position);
  }, [getSingleResumeDoc, sectionComponents]);
  useEffect(() => {
    if (getSingleResumeDoc?.font_style) {
      // Generate the Google Fonts link URL
      const fontName = getSingleResumeDoc?.font_style.replace(" ", "+");
      const fontLinkHref = `https://fonts.googleapis.com/css2?family=${fontName}&display=swap`;

      // Check if the font link already exists to avoid duplicates
      let fontLink = document.querySelector(`link[href="${fontLinkHref}"]`);
      if (!fontLink) {
        fontLink = document.createElement("link");
        fontLink.href = fontLinkHref;
        fontLink.rel = "stylesheet";
        document.head.appendChild(fontLink);
      }

      // Apply the font style as a CSS variable
      document.documentElement.style.setProperty(
        "--dynamic-font-family",
        getSingleResumeDoc.font_style
      );
    }
  }, [getSingleResumeDoc?.font_style]);
  useEffect(() => {
    setSectionConfigs(updatedSections);
  }, [updatedSections, setSectionConfigs]);

  return (
    <div
      className={`cv-child-container-previewTemp hover:bg-gray-200 w-full h-full 
      `}
      style={{
        fontFamily: `var(--dynamic-font-family, ${getSingleResumeDoc?.font_style})`,
      }}
    >
      {sectionsConfig?.map((section, index) => {
        return (
          <div
            className={`py-1 px-2 w-full mb-${getSingleResumeDoc?.section_spacing}`}
          >
            <div className="flex item-center">
              {section?.title !== "Personal Information" && (
                <span
                  className={`cv-section-title  ${section?.style}`}
                  style={{
                    color: `${
                      getSingleResumeDoc?.heading_color
                        ? getSingleResumeDoc?.heading_color
                        : "#000"
                    }`,
                    fontSize: `${
                      getSingleResumeDoc?.font_size
                        ? getSingleResumeDoc?.font_size
                        : ""
                    }`,
                    fontFamily: `${getSingleResumeDoc?.font_style}`,
                  }}
                >
                  {section.title}
                </span>
              )}
            </div>
            {section?.title !== "Personal Information" && (
              <hr
                style={{
                  border: `1px solid ${
                    getSingleResumeDoc?.heading_color || "#000"
                  }`,
                  marginTop: "-6px",
                }}
              />
            )}
            {section.component}
          </div>
        );
      })}
    </div>
  );
};

export default PreviewResume;
