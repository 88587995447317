import React from "react";
import WelcomeScreen from "../../../../Pages/Onboarding/components/WelcomeScreen";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import ProfileInfo from "../../../../Pages/Onboarding/components/ProfileInfo";
import SelectCareerGoal from "../../../../Pages/Onboarding/components/modals/SelectCareerGoal";
import Header from "../../../../Pages/Onboarding/components/steps/components/common/Header";

const IsWelcomeBoard = () => {
  const [stepState, _] = useRecoilState(StepBoardingStateAtom);
  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <Header />
        <main className="container mx-auto px-4 pt-32 pb-16 min-h-screen flex flex-col items-center justify-center ">
          {stepState.isWelcomeBoard.activeStep ? (
            <ProfileInfo />
          ) : (
            <WelcomeScreen />
          )}
        </main>
      </div>
      <SelectCareerGoal />
    </>
  );
};

export default IsWelcomeBoard;
