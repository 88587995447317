import React from "react";
import { NotSureStepHeader } from "../../../../../../../Pages/Onboarding/components/steps/context";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";
import { produce } from "immer";

const Step2 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;

  //FUNCTIONS
  const handleTextAreaChange = (e) => {
    setStepState(
      produce((draft) => {
        draft.notSureQuestionBoard.formData[currentStep - 1].answer =
          e.target.value;
      })
    );
  };
  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Self-Assessment of Skills"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <textarea
          placeholder="Type your answer here..."
          rows={4}
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          value={formData[currentStep - 1].answer}
          onChange={handleTextAreaChange}
        />
      </div>
    </div>
  );
};

export default Step2;
