import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { profileSchema } from "../../../../shared/schema";
import ProfileImageUpload from "./ProfileImageUpload";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { success } from "../../../../Utilities/toast";
import { updateOnboardingUser } from "../../../../Redux/authenticate/authenticate.action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import UserAddress from "../../../../Pages/UserProfile/components/UserAddress";

//CONTEXT
const EMPLOYMENT_OPTIONS = [
  "FullTime",
  "PartTime",
  "Contractor",
  "Intern",
  "Freelancer",
  "Temporary",
  "Unemployed",
  "Student",
  "Retired",
  "OnLeave",
  "Sabbatical",
  "Probation",
];

const ProfileForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);

  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [initialData, setInitialData] = useState(null);
  const [addressError, setAddressError] = useState(false);

  const form = useForm({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      address: "",
      education: "",
      contact: "",
      linkedIn_profile: "",
      employment_status: undefined,
    },
  });

  const handleChangeAddress = (address) => {
    form.setValue("address", address);
  };

  const isFormUnchanged = (data) => {
    return JSON.stringify(data) === JSON.stringify(initialData);
  };
  const onSubmit = (data) => {
    const isNotSure = user?.target_career === stepState?.notSureKey;

    if (isFormUnchanged(data)) {
      setStepState(
        produce((draft) => {
          draft.isActiveMode = isNotSure ? stepState?.notSureKey : "manual";
          draft.manualQuestionBoard.activeStep = isNotSure ? 0 : 1;
          draft.AIQuestionBoard.activeStep = 0;
          draft.notSureQuestionBoard.activeStep = isNotSure ? 1 : 0;
        })
      );
      return;
    }

    const address = form.watch("address");
    if (!address) {
      setAddressError(true);
      return;
    }

    if (!user?.target_career) {
      setStepState(
        produce((draft) => {
          draft.isProfilePayload = data;
          draft.isCarierGoalModal.value = true;
        })
      );
      return;
    }

    const payload = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      if (key === "image") {
        if (val instanceof File || val instanceof Blob) {
          payload.append(key, val);
        }
      } else if (val) {
        payload.append(key, val);
      }
    });

    dispatch(
      updateOnboardingUser({
        payload,
        onSuccess: (res) => {
          success(res);
          setStepState(
            produce((draft) => {
              draft.isActiveMode = isNotSure ? stepState?.notSureKey : "manual";
              draft.manualQuestionBoard.activeStep = isNotSure ? 0 : 1;
              draft.AIQuestionBoard.activeStep = 0;
              draft.notSureQuestionBoard.activeStep = isNotSure ? 1 : 0;
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      const userData = {
        image: user?.image || undefined,
        address: user?.address || "",
        education: user?.education || "",
        contact: user?.contact || "",
        linkedIn_profile: user?.linkedIn_profile || "",
        employment_status: user?.employment_status || "",
      };
      form.reset(userData);
      setInitialData(userData);
    }
  }, [user, form]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <ProfileImageUpload
        control={form.control}
        name="image"
        error={form.formState.errors.image?.message}
      />
      <div className="form-group">
        <label htmlFor="education">Address:</label>
        <div className="-mt-3">
          <UserAddress
            initialValue={user?.address || ""}
            setAddress={handleChangeAddress}
            addressError={addressError}
            setAddressError={setAddressError}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="education">Education:</label>
        <input
          type="text"
          id="education"
          name="education"
          {...form.register("education")}
          className="form-control"
        />
        {form.formState.errors.education && (
          <p className="text-red-500 text-sm mt-1">
            {form.formState.errors.education.message}
          </p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="contact">Phone Number:</label>
        <input
          type="text"
          id="contact"
          name="contact"
          {...form.register("contact")}
          className="form-control"
        />
        {form.formState.errors.contact && (
          <p className="text-red-500 text-sm mt-1">
            {form.formState.errors.contact.message}
          </p>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="linkedIn_profile">LinkedIn Profile:</label>
        <input
          type="text"
          id="linkedIn_profile"
          name="linkedIn_profile"
          {...form.register("linkedIn_profile")}
          className="form-control"
        />
        {form.formState.errors.linkedIn_profile && (
          <p className="text-red-500 text-sm mt-1">
            {form.formState.errors.linkedIn_profile.message}
          </p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="employment_status">Employment Status:</label>
        <select
          id="employment_status"
          name="employment_status"
          {...form.register("employment_status")}
          className="form-control"
        >
          <option value="">Select Employment Status</option>
          {EMPLOYMENT_OPTIONS.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
        {form.formState.errors.employment_status && (
          <p className="text-red-500 text-sm mt-1">
            {form.formState.errors.employment_status.message}
          </p>
        )}
      </div>
      <button
        type="submit"
        className="w-full py-2 px-4 mt-3 border border-transparent outline-none rounded-md shadow-sm text-lg font-semibold text-white bg-primary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
      >
        Update Profile
      </button>
    </form>
  );
};

export default ProfileForm;
