import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/icons/logo.jpeg";

const Cards = ({ name, items, selectedItem, setSelectedItem }) => {
  const location = useLocation();

  const getUrl = (name) => {
    let url = location.pathname + "/";
    if (selectedItem && selectedItem.name) {
      url += selectedItem.name
        .replace(/ /g, "-")
        .replace(/\//g, "_")
        .toLowerCase();
    }
    if (!name || typeof name !== "string") return "";
    return name.replace(/ /g, "-").replace(/\//g, "_").toLowerCase();
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h1 className="text-center text-capitalize text-bold">
            Which {name} are you interested in practicing?
          </h1>
        </div>
        <div className="ic-interview-card-container">
          {Array.isArray(items) && items.length > 0 ? (
            items
              ?.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item, index) => (
                <div className="ic-interview-items relative" key={index}>
                  <Link
                    to={getUrl(item?.name)}
                    className="w-full h-full flex flex-col justify-center items-center gap-2"
                    onClick={() => setSelectedItem(item)}
                  >
                    <div className="ic-icons overflow-hidden">
                      <img src={Logo} className="img-fluid" alt="" />
                    </div>
                    <p>{item?.name}</p>
                    <i
                      className="ri-arrow-right-line ic-right-arrow"
                      style={{ fontSize: "22px" }}
                      size="30"
                    />
                  </Link>
                </div>
              ))
          ) : (
            <h2 className="text-xl font-semibold text-gray-600">
              There are no {name}s available
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cards;
