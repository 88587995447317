import React from "react";

const TableBodyShimmer = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, i) => (
        <tr key={i} className="border-b animate-pulse">
          <td className="p-3">
            <div className="h-4 w-32 bg-gray-300 rounded"></div>
          </td>
          <td className="p-3">
            <div className="h-4 w-24 bg-gray-300 rounded"></div>
          </td>
          <td className="p-3">
            <div className="h-8 w-24 bg-gray-300 rounded-full"></div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableBodyShimmer;
