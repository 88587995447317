import { useState, useEffect } from "react";
import { IoCameraOutline } from "react-icons/io5";
import {
  ACCEPTED_IMAGE_TYPES,
  MAX_FILE_SIZE,
} from "../../../../Utilities/validation";
import { useController } from "react-hook-form";

const ProfileImageUpload = ({ name, control, error }) => {
  // React Hook Form Controller
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  //STATES
  const [previewUrl, setPreviewUrl] = useState();

  //FUNCTIONS
  const handleChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      alert("Image must be less than 5MB");
      return;
    }

    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      alert("Only .jpg, .jpeg, and .png formats are supported");
      return;
    }
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    const newPreviewUrl = URL.createObjectURL(file);
    setPreviewUrl(newPreviewUrl);

    onChange(file);
  };

  //USE EFFECTS
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div className="flex items-center flex-col mb-3">
      <label
        className="relative min-w-16 min-h-16 w-16 h-16 flex rounded-full overflow-hidden bg-gray-100 hover:opacity-80 transition-opacity cursor-pointer justify-center items-center"
        htmlFor="profileImage"
      >
        {value || previewUrl ? (
          <img
            src={typeof value === "string" ? value : previewUrl}
            alt="profile"
            className="w-full h-full object-cover"
          />
        ) : (
          <IoCameraOutline size={24} className="text-gray-400" />
        )}
      </label>
      <label
        htmlFor="profileImage"
        className="mt-2 text-sm text-gray-600 cursor-pointer hover:text-gray-800"
      >
        Upload Profile Image
      </label>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      <input
        id="profileImage"
        name="image"
        onChange={handleChange}
        type="file"
        className="hidden"
        accept={ACCEPTED_IMAGE_TYPES.join(",")}
      />
    </div>
  );
};

export default ProfileImageUpload;
