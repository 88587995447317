import {
  Bookmark,
  Building2,
  Calendar,
  ChevronDown,
  Search,
  X
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../Components/header";
import Loader from "../../Components/main-loader";
import Logo from "../../assets/icons/logo.jpeg";

// Mock data generator for infinite scroll
const generateMockEvents = (startIndex, count) => {
  return Array(count)
    .fill(null)
    .map((_, idx) => ({
      id: startIndex + idx,
      organizer: `Organization ${startIndex + idx}`,
      organizerLogo: "/api/placeholder/40/40",
      title: `Event ${startIndex + idx}`,
      format: idx % 2 === 0 ? "Virtual" : "In-person",
      location:
        idx % 4 === 0
          ? "NYC"
          : idx % 4 === 1
          ? "SF"
          : idx % 4 === 2
          ? "BOS"
          : "CHI",
      datetime: "Upcoming",
      tags:
        idx % 3 === 0
          ? ["CAREER FAIR"]
          : idx % 3 === 1
          ? ["HIRING"]
          : ["CONFERENCE"],
      status: "Open for registration",
      registered: idx % 4 === 0,
    }));
};

const categoryOptions = [
  {
    label: "Career fair",
    value: "CAREER FAIR",
    description: "Hiring events with numerous employers at your school.",
  },
  {
    label: "Networking",
    value: "NETWORKING",
    description:
      "Social events with people who can help grow your professional network.",
  },
  {
    label: "Hiring",
    value: "HIRING",
    description: "Find opportunities with employers that want to hire you now.",
  },
  {
    label: "Employer info",
    value: "EMPLOYER INFO",
    description:
      "Info sessions and talks about day-to-day life, culture, and teams.",
  },
  {
    label: "Guidance",
    value: "GUIDANCE",
    description:
      "Resources for your career, job skills, resume, interviewing, and more.",
  },
  {
    label: "Academic",
    value: "ACADEMIC",
    description: "Resources and info related to your school.",
  },
  {
    label: "Conference",
    value: "CONFERENCE",
    description:
      "All-day and multi-day events like expos, summits, and tradeshows.",
  },
  {
    label: "General",
    value: "GENERAL",
    description: "General topics of interest.",
  },
];

const dateOptions = {
  timeRange: [
    { label: "All upcoming", value: "all_upcoming" },
    { label: "Today", value: "today" },
    { label: "Next 10 days", value: "next_10" },
    { label: "Next 30 days", value: "next_30" },
    { label: "Past year", value: "past_year" },
  ],
  timeOfDay: [
    { label: "Morning", value: "morning" },
    { label: "Afternoon", value: "afternoon" },
  ],
  daysOfWeek: [
    { label: "Mon", value: "monday" },
    { label: "Tue", value: "tuesday" },
    { label: "Wed", value: "wednesday" },
    { label: "Thu", value: "thursday" },
    { label: "Fri", value: "friday" },
    { label: "Sat", value: "saturday" },
    { label: "Sun", value: "sunday" },
  ],
};

const mediumOptions = [
  { label: "Virtual", value: "Virtual" },
  { label: "In-person", value: "In-person" },
];

const locationOptions = [
  {
    label: "New York City",
    value: "NYC",
    description: "Events in the New York metropolitan area",
  },
  {
    label: "San Francisco",
    value: "SF",
    description: "Events in the San Francisco Bay Area",
  },
  {
    label: "Boston",
    value: "BOS",
    description: "Events in the Greater Boston Area",
  },
  {
    label: "Chicago",
    value: "CHI",
    description: "Events in the Chicago metropolitan area",
  },
];

const loadGoogleMapsScript = () => {
  // If already loaded, resolve immediately.
  if (window.google && window.google.maps && window.google.maps.places) {
    return Promise.resolve(window.google);
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYMFA__LmwukC3dzDTeXZRmyz6rdS84hg&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(window.google);
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// FilterPopup Component (Modified for custom location input)
const FilterPopup = ({
  title,
  options,
  isOpen,
  onClose,
  onApply,
  selectedOptions,
  onReset,
}) => {
  const [localSelected, setLocalSelected] = useState(selectedOptions);
  const [customLocation, setCustomLocation] = useState(""); // New state for custom location input
  const inputRef = useRef(null);

  const handleOptionChange = (option) => {
    setLocalSelected((prev) => {
      const newSelected = prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option];
      return newSelected;
    });
  };

  const handleCustomLocationAdd = () => {
    if (
      customLocation.trim() &&
      !localSelected.includes(customLocation.trim())
    ) {
      setLocalSelected((prev) => [...prev, customLocation.trim()]);
      setCustomLocation(""); // Clear input after adding
    }
  };

  useEffect(() => {
    if (title === "Location" && isOpen && inputRef.current) {
      loadGoogleMapsScript()
        .then((google) => {
          const autocomplete = new google.maps.places.Autocomplete(
            inputRef.current,
            {
              types: ["(cities)"], // or use ["geocode"] for addresses
            }
          );
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place && (place.formatted_address || place.name)) {
              setCustomLocation(place.formatted_address || place.name);
            }
          });
        })
        .catch((error) => {
          console.error("Error loading Google Maps script:", error);
        });
    }
  }, [isOpen, title]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center
        ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
    >
      <div className="bg-white w-full max-w-md rounded-2xl overflow-hidden transform transition-all duration-300">
        <div className="p-6">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-4xl font-semibold">{title}</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 p-2"
            >
              <X size={24} />
            </button>
          </div>

          {/* Custom Location Input */}
          {title === "Location" && (
            <div className="mb-6">
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={customLocation}
                  ref={inputRef}
                  onChange={(e) => setCustomLocation(e.target.value)}
                  placeholder="Enter a custom location"
                  className="flex-1 px-4 py-2 border border-gray-200 rounded-full text-lg focus:ring-2 focus:ring-blue-100 focus:border-blue-300 outline-none"
                />
                <button
                  onClick={handleCustomLocationAdd}
                  disabled={!customLocation.trim()}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-full disabled:bg-gray-300"
                >
                  Add
                </button>
              </div>
            </div>
          )}

          <div className="space-y-6 max-h-[60vh] overflow-y-auto">
            {options.map((option) => (
              <div
                key={option.value}
                className="flex items-start justify-between py-4 cursor-pointer border-b border-gray-100"
              >
                <div className="flex-1 pr-4">
                  <p className="text-xl font-semibold mb-2">{option.label}</p>
                  {option.description && (
                    <p className="text-gray-500 text-lg">
                      {option.description}
                    </p>
                  )}
                </div>
                <div
                  className={`w-8 h-8 border-2 rounded transition-all duration-200 mt-1 ml-4 flex-shrink-0 cursor-pointer
                    ${
                      localSelected.includes(option.value)
                        ? "border-indigo-600 bg-indigo-600"
                        : "border-gray-300"
                    }`}
                  onClick={() => handleOptionChange(option.value)}
                />
              </div>
            ))}
            {/* Display custom locations as options */}
            {title === "Location" &&
              localSelected
                .filter((loc) => !options.some((opt) => opt.value === loc))
                .map((customLoc) => (
                  <div
                    key={customLoc}
                    className="flex items-start justify-between py-4 cursor-pointer border-b border-gray-100"
                  >
                    <div className="flex-1 pr-4">
                      <p className="text-xl font-semibold mb-2">{customLoc}</p>
                    </div>
                    <div
                      className="w-8 h-8 border-2 rounded transition-all duration-200 mt-1 ml-4 flex-shrink-0 cursor-pointer border-indigo-600 bg-indigo-600"
                      onClick={() => handleOptionChange(customLoc)}
                    />
                  </div>
                ))}
          </div>

          <div className="flex justify-between items-center mt-8 pt-6">
            <button
              onClick={() => {
                setLocalSelected([]);
                setCustomLocation("");
                onReset();
              }}
              className="text-indigo-600 text-lg font-medium"
            >
              Reset
            </button>
            <button
              onClick={() => {
                onApply(localSelected);
                onClose();
              }}
              className="px-8 py-4 bg-indigo-600 text-white text-lg font-medium rounded-full"
            >
              View {localSelected.length > 0 ? `${localSelected.length} ` : ""}
              results
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// DateFilterPopup Component (Unchanged)
const DateFilterPopup = ({
  isOpen,
  onClose,
  onApply,
  selectedOptions,
  onReset,
}) => {
  const [localSelected, setLocalSelected] = useState(selectedOptions);

  const handleOptionChange = (section, value) => {
    setLocalSelected((prev) => {
      const newSelected = { ...prev };

      if (section === "timeRange") {
        newSelected.timeRange = [value];
      } else if (section === "timeOfDay" || section === "daysOfWeek") {
        if (!newSelected[section]) newSelected[section] = [];
        const index = newSelected[section].indexOf(value);
        if (index === -1) {
          newSelected[section].push(value);
        } else {
          newSelected[section].splice(index, 1);
        }
      }

      return newSelected;
    });
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center
        ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
    >
      <div className="bg-white w-full max-w-2xl rounded-3xl overflow-hidden transform transition-all duration-300">
        <div className="p-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold">Date</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 p-1"
            >
              <X size={20} />
            </button>
          </div>

          <div className="space-y-6">
            <div className="space-y-2">
              <h4 className="text-base font-semibold">When are you looking?</h4>
              <div className="space-y-2">
                {dateOptions.timeRange.map((option) => (
                  <label
                    key={option.value}
                    className="flex items-center space-x-2 cursor-pointer py-1"
                  >
                    <div
                      className={`w-4 h-4 rounded-full border-2 flex items-center justify-center
                        ${
                          localSelected.timeRange &&
                          localSelected.timeRange.includes(option.value)
                            ? "border-indigo-600"
                            : "border-gray-300"
                        }`}
                      onClick={() =>
                        handleOptionChange("timeRange", option.value)
                      }
                    >
                      {localSelected.timeRange &&
                        localSelected.timeRange.includes(option.value) && (
                          <div className="w-2 h-2 rounded-full bg-indigo-600" />
                        )}
                    </div>
                    <span className="text-sm">{option.label}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <h4 className="text-base font-semibold">Time of day</h4>
              <p className="text-gray-500 text-xs">
                Based on Central Standard Time
              </p>
              <div className="space-y-2">
                {dateOptions.timeOfDay.map((option) => (
                  <label
                    key={option.value}
                    className="flex items-center space-x-2 cursor-pointer py-1"
                  >
                    <div
                      className={`w-4 h-4 rounded border-2
                        ${
                          localSelected.timeOfDay &&
                          localSelected.timeOfDay.includes(option.value)
                            ? "border-indigo-600 bg-indigo-600"
                            : "border-gray-300"
                        }`}
                      onClick={() =>
                        handleOptionChange("timeOfDay", option.value)
                      }
                    />
                    <span className="text-sm">{option.label}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <h4 className="text-base font-semibold">Days of the week</h4>
              <div className="flex flex-wrap gap-2">
                {dateOptions.daysOfWeek.map((option) => (
                  <button
                    key={option.value}
                    onClick={() =>
                      handleOptionChange("daysOfWeek", option.value)
                    }
                    className={`px-3 py-1 rounded-full border text-xs font-medium transition-colors
                      ${
                        localSelected.daysOfWeek &&
                        localSelected.daysOfWeek.includes(option.value)
                          ? "border-indigo-600 bg-indigo-600 text-white"
                          : "border-gray-300 text-gray-700 hover:border-indigo-300"
                      }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-6 pt-4">
            <button
              onClick={() => {
                setLocalSelected({});
                onReset();
              }}
              className="text-indigo-600 text-lg font-medium"
            >
              Reset
            </button>
            <button
              onClick={() => {
                onApply(localSelected);
                onClose();
              }}
              className="px-8 py-4 bg-indigo-600 text-white text-lg font-medium rounded-full"
            >
              View results
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// FilterButton Component (Unchanged)
const FilterButton = ({
  label,
  icon,
  active,
  onClick,
  isOpen,
  className = "",
}) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 border rounded-full flex items-center gap-3 hover:bg-gray-50 transition-all duration-200 whitespace-nowrap
      ${
        active
          ? "border-blue-500 text-blue-600 bg-blue-50 shadow-sm"
          : "border-gray-200"
      }
      ${isOpen ? "border-blue-500 shadow-sm" : ""} ${className}`}
  >
    {icon}
    <span className="font-medium">{label}</span>
    <ChevronDown
      size={18}
      className={`transform transition-transform duration-200 ${
        isOpen ? "rotate-180" : ""
      }`}
    />
  </button>
);

// QuickLink Component (Unchanged)
const QuickLink = ({ icon, title, subtitle }) => (
  <div className="p-8 bg-white rounded-2xl hover:shadow-xl transition-all duration-300 cursor-pointer hover:-translate-y-2 border border-gray-100 group">
    <div className="text-green-500 mb-4 transform group-hover:scale-110 transition-transform duration-300">
      {icon}
    </div>
    <h3 className="font-semibold text-gray-900 text-lg mb-2">{title}</h3>
    <p className="text-sm text-gray-600 group-hover:text-blue-600 transition-colors duration-300">
      {subtitle} →
    </p>
  </div>
);

// EventCard Component (Modified to use Logo)
const EventCard = ({ event }) => {
  const [isSaved, setIsSaved] = useState(false);
  const isVirtual = event.location === "Online";

  return (
    <div className="bg-white p-8 rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 hover:-translate-y-2 border border-gray-100">
      <div className="flex justify-between items-start mb-6">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 rounded-xl bg-gray-200 overflow-hidden">
            <img
              src={Logo} // Changed to use static Logo import
              alt="Organizer"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="font-medium text-lg">{event.organizer}</span>
        </div>
        <button
          onClick={() => setIsSaved(!isSaved)}
          className={`${
            isSaved ? "text-blue-600" : "text-gray-400"
          } hover:text-blue-600`}
        >
          <Bookmark size={24} />
        </button>
      </div>

      <a
        href={event.link}
        target="_blank"
        rel="noopener noreferrer"
        className="block mb-3"
      >
        <h2 className="text-2xl font-bold text-gray-800 hover:text-blue-600">
          {event.title}
        </h2>
      </a>

      <div className="flex items-center gap-3 text-gray-600 mb-5">
        <span className="font-medium">
          {isVirtual ? "Virtual" : "In-person"}
        </span>
        <span>·</span>
        <span>{event.date}</span>
        {!isVirtual && (
          <>
            <span>·</span>
            <span>{event.location}</span>
          </>
        )}
      </div>

      <div className="text-sm font-medium text-gray-600">{event.price}</div>
    </div>
  );
};

// Main Events Component (Unchanged except for integration)
const Events = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const loader = useRef(null);
  const [isMediumOpen, setIsMediumOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [selectedMediums, setSelectedMediums] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const mediumButtonRef = useRef(null);
  const categoryButtonRef = useRef(null);
  const locationButtonRef = useRef(null);
  const dateButtonRef = useRef(null);
  const [eventApiLoader, setEventApiLoader] = useState(false);

  const getEvents = async () => {
    try {
      setEventApiLoader(true);
      const token = localStorage.getItem("auth");
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 120000);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/events/all`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          signal: controller.signal,
        }
      );

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error("Failed to fetch events");
      }

      const data = await response.json();
      const processedEvents = data.events.map((event) => ({
        id: event.id,
        organizer: event.organizer,
        title: event.title,
        link: event.link,
        date: event.date,
        location: event.location,
        price: event.price,
        organizerLogo: event.organizerLogo || "/placeholder-logo.png",
        format: event.format,
        tags: event.tags || [],
      }));
      setEvents(processedEvents);
      setEventApiLoader(false);
      console.log("dataaaaaaaaaaaaaa------------", data);
    } catch (error) {
      setEventApiLoader(false);
      console.error("Error:", error);
    }
  };

  const init = async () => {
    await getEvents();
  };

  useEffect(() => {
    init();
    const handleClickOutside = (event) => {
      if (
        mediumButtonRef.current &&
        !mediumButtonRef.current.contains(event.target)
      ) {
        setIsMediumOpen(false);
      }
      if (
        categoryButtonRef.current &&
        !categoryButtonRef.current.contains(event.target)
      ) {
        setIsCategoryOpen(false);
      }
      if (
        locationButtonRef.current &&
        !locationButtonRef.current.contains(event.target)
      ) {
        setIsLocationOpen(false);
      }
      if (
        dateButtonRef.current &&
        !dateButtonRef.current.contains(event.target)
      ) {
        setIsDateOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const filtered = events.filter((event) => {
      const matchesSearch =
        searchQuery === "" ||
        event?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event?.organizer?.toLowerCase().includes(searchQuery.toLowerCase());

      // const matchesMedium =
      //   selectedMediums.length === 0 || selectedMediums.includes(event.format);
      const eventMedium = event.location === "Online" ? "Virtual" : "In-person";
      const matchesMedium =
        selectedMediums.length === 0 || selectedMediums.includes(eventMedium);

      const matchesCategory =
        selectedCategories.length === 0 ||
        event?.tags?.some((tag) => selectedCategories.includes(tag));

      const matchesLocation =
        selectedLocations.length === 0 ||
        selectedLocations.includes(event.location);

      const matchesDate = filterByDate(event, selectedDates);

      return (
        matchesSearch &&
        matchesMedium &&
        matchesCategory &&
        matchesLocation &&
        matchesDate
      );
    });

    setFilteredEvents(filtered);
  }, [
    searchQuery,
    events,
    selectedMediums,
    selectedCategories,
    selectedLocations,
    selectedDates,
  ]);

  const parseEventDate = (dateStr) => {
    if (!dateStr) return null;
    const normalizedStr = dateStr.trim();
    const lowerStr = normalizedStr.toLowerCase();
    if (
      lowerStr.includes("sales end soon") ||
      lowerStr.includes("going fast") ||
      lowerStr.includes("no date")
    ) {
      return null;
    }
    const cleanedStr = normalizedStr.replace(/\[.*\]$/, "");

    const parsed = new Date(cleanedStr);
    return isNaN(parsed) ? null : parsed;
  };

  const filterByDate = (event, selectedDates) => {
    if (!selectedDates || Object.keys(selectedDates).length === 0) return true;

    const eventDate = parseEventDate(event.date);
    if (!eventDate) return false;

    const now = new Date();
    let valid = true;

    // 1. Filter by timeRange (assumed to be a single selection)
    if (selectedDates.timeRange && selectedDates.timeRange.length > 0) {
      const range = selectedDates.timeRange[0];
      switch (range) {
        case "all_upcoming":
          valid = eventDate >= now;
          break;
        case "today":
          valid = eventDate.toDateString() === now.toDateString();
          break;
        case "next_10": {
          const next10 = new Date();
          next10.setDate(now.getDate() + 10);
          valid = eventDate >= now && eventDate <= next10;
          break;
        }
        case "next_30": {
          const next30 = new Date();
          next30.setDate(now.getDate() + 30);
          valid = eventDate >= now && eventDate <= next30;
          break;
        }
        case "past_year": {
          const pastYear = new Date();
          pastYear.setFullYear(now.getFullYear() - 1);
          valid = eventDate >= pastYear && eventDate < now;
          break;
        }
        default:
          break;
      }
    }

    // 2. Filter by timeOfDay (e.g., morning, afternoon)
    if (
      valid &&
      selectedDates.timeOfDay &&
      selectedDates.timeOfDay.length > 0
    ) {
      const hours = eventDate.getHours();
      // Define time ranges (adjust as needed)
      // For example: morning = 5am to 12pm, afternoon = 12pm to 5pm
      if (
        selectedDates.timeOfDay.includes("morning") &&
        !(hours >= 5 && hours < 12)
      ) {
        valid = false;
      }
      if (
        selectedDates.timeOfDay.includes("afternoon") &&
        !(hours >= 12 && hours < 17)
      ) {
        valid = false;
      }
    }

    // 3. Filter by daysOfWeek
    if (
      valid &&
      selectedDates.daysOfWeek &&
      selectedDates.daysOfWeek.length > 0
    ) {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const day = days[eventDate.getDay()];
      if (!selectedDates.daysOfWeek.includes(day)) {
        valid = false;
      }
    }

    return valid;
  };

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page]);

  const quickLinks = [
    {
      icon: <span className="text-4xl">✨</span>,
      title: "Career fairs at your school",
    },
    {
      icon: <span className="text-4xl">💭</span>,
      title: "Career center employer events",
    },
    {
      icon: <span className="text-4xl">⭐</span>,
      title: "Career center guidance events",
    },
    {
      icon: <span className="text-4xl">🍀</span>,
      title: "Events hosted by employers",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      {eventApiLoader && <Loader />}
      <div className="max-w-7xl mx-auto px-6 pt-28 pb-12 space-y-16">
        <div className="space-y-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search For Local Career Events"
              className="w-full px-6 py-4 pl-14 border border-gray-200 rounded-full text-lg shadow-sm focus:ring-2 focus:ring-blue-100 focus:border-blue-300 transition-all outline-none bg-white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="absolute left-5 top-5 text-gray-400" size={24} />
          </div>

          <div className="flex items-center gap-4 overflow-x-auto scrollbar-hide">
            {/* <div ref={categoryButtonRef} className="relative shrink-0">
              <FilterButton
                label="Category"
                icon={<Menu size={18} />}
                active={selectedCategories.length > 0}
                isOpen={isCategoryOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCategoryOpen(!isCategoryOpen);
                  setIsMediumOpen(false);
                  setIsLocationOpen(false);
                  setIsDateOpen(false);
                }}
              />
              <FilterPopup
                title="Category"
                options={categoryOptions}
                isOpen={isCategoryOpen}
                onClose={() => setIsCategoryOpen(false)}
                selectedOptions={selectedCategories}
                onApply={setSelectedCategories}
                onReset={() => setSelectedCategories([])}
              />
            </div> */}
            <div ref={mediumButtonRef} className="relative shrink-0">
              <FilterButton
                label="Medium"
                icon={<Building2 size={18} />}
                active={selectedMediums.length > 0}
                isOpen={isMediumOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMediumOpen(!isMediumOpen);
                  setIsCategoryOpen(false);
                  setIsLocationOpen(false);
                  setIsDateOpen(false);
                }}
              />
              <FilterPopup
                title="Medium"
                options={mediumOptions}
                isOpen={isMediumOpen}
                onClose={() => setIsMediumOpen(false)}
                selectedOptions={selectedMediums}
                onApply={setSelectedMediums}
                onReset={() => setSelectedMediums([])}
              />
            </div>
            <div ref={locationButtonRef} className="relative shrink-0">
              <FilterButton
                label="Location"
                icon={<Building2 size={18} />}
                active={selectedLocations.length > 0}
                isOpen={isLocationOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsLocationOpen(!isLocationOpen);
                  setIsMediumOpen(false);
                  setIsCategoryOpen(false);
                  setIsDateOpen(false);
                }}
              />
              <FilterPopup
                title="Location"
                options={locationOptions}
                isOpen={isLocationOpen}
                onClose={() => setIsLocationOpen(false)}
                selectedOptions={selectedLocations}
                onApply={setSelectedLocations}
                onReset={() => setSelectedLocations([])}
              />
            </div>
            <div ref={dateButtonRef} className="relative shrink-0">
              <FilterButton
                label="Date"
                icon={<Calendar size={18} />}
                active={Object.keys(selectedDates).length > 0}
                isOpen={isDateOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDateOpen(!isDateOpen);
                  setIsMediumOpen(false);
                  setIsCategoryOpen(false);
                  setIsLocationOpen(false);
                }}
              />
              <DateFilterPopup
                isOpen={isDateOpen}
                onClose={() => setIsDateOpen(false)}
                selectedOptions={selectedDates}
                onApply={setSelectedDates}
                onReset={() => setSelectedDates({})}
              />
            </div>
            {/* <FilterButton
              label="Employer"
              icon={<Building2 size={18} />}
              active={false}
              className="shrink-0"
            /> */}
            {/* <FilterButton
              label="Saved"
              icon={<SlidersHorizontal size={18} />}
              active={false}
              className="shrink-0"
            /> */}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {quickLinks.map((link, index) => (
            <QuickLink key={index} {...link} />
          ))}
        </div>

        <div>
          <h2 className="text-3xl font-bold mb-8 text-gray-800">
            Networking & Workforce Events
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredEvents.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>

          <div ref={loader} className="flex justify-center py-8">
            {loading && (
              <div className="animate-pulse text-gray-500">
                Loading more events...
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx global>{`
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default Events;
