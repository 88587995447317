import { Spin } from "antd";
import React from "react";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../../../../../recoil/Loadings";

const NavigationButtons = ({
  activeStep,
  totalSteps,
  onBack,
  onNext,
  onSaveLater,
}) => {
  const [loadingState] = useRecoilState(StepLoadingAtom);
  return (
    <div className="flex flex-wrap items-center gap-3 mt-1">
      <button
        className="ic-btn next-btn"
        onClick={onBack}
        disabled={loadingState.onBoarding.notSureQuestionBoard}
      >
        Back
      </button>
      {activeStep <= totalSteps && (
        <button
          className="ic-btn next-btn"
          onClick={() => onNext(activeStep === totalSteps)}
          disabled={loadingState.onBoarding.notSureQuestionBoard}
        >
          {activeStep < totalSteps ? "Next" : "Submit"}{" "}
          {loadingState.onBoarding.notSureQuestionBoard &&
            !(activeStep < totalSteps) && (
              <Spin size="small" className="pl-3" />
            )}
        </button>
      )}
      {activeStep < totalSteps && (
        <button
          className="ic-btn chip-btn"
          onClick={onSaveLater}
          disabled={loadingState.onBoarding.notSureQuestionBoard}
        >
          Save and do later{" "}
          {loadingState.onBoarding.notSureQuestionBoard && (
            <Spin size="small" className="pl-3" />
          )}
        </button>
      )}
    </div>
  );
};

export default NavigationButtons;
