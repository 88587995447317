import "./Section2.css";

const Section2 = () => {
  return (
    <section className="problem-solution-section">
      <div className="intro-text">
        <h2 className="section-title">
          The Hiring{" "}
          <span className="highlight" style={{ fontSize: "2.5rem" }}>
            Problem
          </span>{" "}
          Is Clear
        </h2>
        <p className="section-subtitle">
          Hiring today is costly, time-consuming, and often inefficient. Sorting
          through piles of resumes, assessing unqualified candidates, and
          finding the right fit can take weeks—or even months.
        </p>
      </div>

      <div className="content-block">
        <div className="text-content">
          <h3 className="content-title">The Hiring Challenge</h3>
          <p className="content-description">
            Traditional recruitment struggles with inefficiencies—endless resume
            reviews, inconsistent candidate vetting, and prolonged timelines
            drain resources and delay results.
          </p>
        </div>
        <div className="image-content">
          <img
            src="/images/cccc.png"
            alt="Hiring Challenges"
            className="content-image"
          />
        </div>
      </div>

      <div className="content-block reverse">
        <div className="text-content">
          <h3 className="content-title">Meet Nexa RU</h3>
          <p className="content-description">
            Nexa RU revolutionizes hiring with AI-powered sourcing, pre-vetted
            talent pools, and deep behavioral insights—cutting hiring time by
            75% and ensuring perfect-fit candidates.
          </p>
        </div>
        <div className="image-content">
          <img
            src="/images/Group1321315101.png"
            alt="Nexa RU Solution"
            className="content-image"
          />
        </div>
      </div>
    </section>
  );
};

export default Section2;
