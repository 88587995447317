import { useEffect } from "react";
import ReusableSection from "./ReusableSection";
import "./Section6.css";

const sectionData = [
  {
    imageUrl: "images/test1.png",
    text1: "More Value, Less Effort.",
    text2:
      "Nexa RU reduces hiring costs by 80% and time-to-hire by 75%. Every candidate is pre-vetted, saving recruiters up to 20 hours per job posting",
    imagePosition: "right",
  },
  {
    imageUrl: "images/test2.png",
    text1: "Take Control of Your Recruitment Workflow",
    text2:
      "From candidate analytics to job posting performance, Nexa RU's admin dashboard gives you everything you need in one place.",
    imagePosition: "left",
  },
  {
    imageUrl: "images/test3.png",
    text1: "Works with Your Existing Tools",
    text2:
      "Nexa RU seamlessly integrates with popular ATS platforms and job boards, complementing your existing workflow without disruption.",
    imagePosition: "right",
  },
];

const Section6 = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    // Observe all section containers
    document.querySelectorAll(".section-container").forEach((section) => {
      observer.observe(section);
    });

    // Cleanup
    return () => {
      document.querySelectorAll(".section-container").forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="outermost-container-section6">
      <div className="default-container-section6">
        <div className="text-wrapper-section6">
          <div className="narrow-text-section6">
            <p className="text-default-blue-bold-section6 text-center mb-8 FiraFontFamily bold-family">
              Key{" "}
              <span className="text-default-blue-bold-section6 highlighted-word FiraFontFamily bold-family">
                Features
              </span>{" "}
              for your hiring workflow
            </p>
          </div>
          <div className="wide-text-section6">
            <p className="text-default-black-section6 text-center FiraFontFamily">
              Nexa is purpose-built to <b>simplify and enhance</b> your
              recruiting workflow
            </p>
          </div>
        </div>
      </div>
      <div className="reusable-container-section6">
        {sectionData.map((section, index) => (
          <ReusableSection
            key={index}
            text1={section.text1}
            text2={section.text2}
            imageUrl={section.imageUrl}
            imagePosition={section.imagePosition}
            extraClass={index % 2 === 0 ? "section-container-even" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default Section6;
