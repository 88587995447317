import React, { useEffect, useState } from "react";
import {
  RotateCw,
  ChevronDown,
  ChevronUp,
  HelpCircle,
  FileText,
} from "lucide-react";
import { useSelector } from "react-redux";
import { SkillsGapConvertion } from "../../../Utilities/SkillsGapConvertion";
import { useDispatch } from "react-redux";
import { generateSkillsGapAnalysis } from "../../../Redux/authenticate/authenticate.action";
import { error } from "../../../Utilities/toast";
import OriginalSkillGapQuestions from "./OriginalSkillGapQuestions";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../../recoil/Loadings";
import TextShimmer from "../../../Components/Shimmers/TextShimmer";
import { produce } from "immer";
import { StepBoardingStateAtom } from "../../../recoil/onBoarding";
import ReactSpeedometer from "react-d3-speedometer";

const OriginalSkillGap = ({ speed = 100 }) => {
  //REDUX
  const dispatch = useDispatch();
  const { user, skillsAnalysis } = useSelector((state) => state.authenticate);

  //RECOIL
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [stepState] = useRecoilState(StepBoardingStateAtom);

  //STATES
  const [isTyping, setIsTyping] = useState(false);
  const [displayText, setDisplayText] = useState([]);
  const [typeIndex, setTypeIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeSection, setActiveSection] = useState("description");
  const [contentHeight, setContentHeight] = useState("300px");
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleRetake = () => {
    if ([stepState.notSureKey, null, undefined].includes(user?.target_career)) {
      error("Please select a target career");
      return;
    }
    setLoadingState(
      produce((draft) => {
        draft.careerGoals.reTakeSkillsGapAssessment = true;
      })
    );
    dispatch(
      generateSkillsGapAnalysis({
        onSuccess: () => {
          const convertedText = SkillsGapConvertion(
            skillsAnalysis?.analysis ?? ""
          );
          setDisplayText(convertedText);
          setTypeIndex(0);
          setIsTyping(true);
          setIsExpanded(true);
          setContentHeight("none");
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.reTakeSkillsGapAssessment = false;
            })
          );
        },
        onError: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.reTakeSkillsGapAssessment = false;
            })
          );
          error("Something went wrong, please try again later");
        },
      })
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setContentHeight(isExpanded ? "300px" : "none");
  };

  const toggleSection = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveSection((prev) =>
        prev === "description" ? "questions" : "description"
      );
      setTimeout(() => {
        setIsTransitioning(false);
      }, 50);
    }, 300);
  };

  const renderContent = () => {
    if (isTyping) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: displayText.slice(0, typeIndex + 1).join(""),
          }}
        />
      );
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: skillsAnalysis?.analysis || "",
        }}
      />
    );
  };

  useEffect(() => {
    if (isTyping && typeIndex < displayText.length) {
      const timeout = setTimeout(() => {
        setTypeIndex((prev) => prev + 1);
      }, speed);

      return () => clearTimeout(timeout);
    } else if (isTyping && typeIndex >= displayText.length) {
      setIsTyping(false);
    }
  }, [isTyping, typeIndex, displayText.length, speed]);

  return (
    <div className="w-full bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-3 mx-auto my-6 max-w-5xl transition-all duration-300">
      <div className="border-b border-gray-100">
        <div className="flex justify-between items-center p-6 flex-col sm:flex-row gap-4">
          <h2 className="text-xl font-semibold text-gray-800">
            Skills Gap Assessment
          </h2>
          <div className="flex gap-3">
            <button
              onClick={handleRetake}
              disabled={activeSection !== "description"}
              className={`flex items-center gap-2 px-2 sm:px-4 py-2 bg-emerald-50 text-emerald-600 rounded-lg hover:bg-emerald-100 transition-colors duration-200 ${
                activeSection !== "description" && "cursor-not-allowed"
              }`}
            >
              <RotateCw size={18} />
              <span className="whitespace-nowrap">Retake</span>
            </button>
            <button
              onClick={toggleSection}
              disabled={user?.target_career === stepState.notSureKey}
              className={`flex items-center gap-2 px-2 sm:px-4 py-2 rounded-lg transition-colors duration-200 
      ${
        activeSection === "description"
          ? "bg-purple-50 text-purple-600 hover:bg-purple-100"
          : "bg-indigo-50 text-indigo-600 hover:bg-indigo-100"
      } ${
                user?.target_career === stepState.notSureKey
                  ? "cursor-not-allowed"
                  : ""
              }`}
            >
              {activeSection === "description" ? (
                <>
                  <HelpCircle size={18} />
                  <span className="whitespace-nowrap">View/Edit Questions</span>
                </>
              ) : (
                <>
                  <FileText size={18} />
                  <span className="whitespace-nowrap">View Description</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div
          className={`group bg-[#f7f7f7] p-6 rounded-xl shadow-xl transition-all duration-300 transform
            ${isTransitioning ? "opacity-0 scale-95" : "opacity-100 scale-100"}
            `}
        >
          {activeSection === "description" ? (
            loadingState.careerGoals.reTakeSkillsGapAssessment ||
            loadingState.careerGoals.skillsGapAssessment ? (
              <TextShimmer />
            ) : skillsAnalysis?.analysis ? (
              <div className="relative">
                {console.log("skillsAnalysis", skillsAnalysis.likelihood)}
                <div
                  className="skillsgap max-w-none overflow-hidden transition-all duration-300"
                  style={{
                    maxHeight: contentHeight,
                    position: "relative",
                  }}
                >
                  {renderContent()}
                  {!isExpanded && (
                    <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-50 to-transparent" />
                  )}
                  <div className="flex justify-center items-center mt-4">
                    <ReactSpeedometer
                      maxValue={100}
                      value={
                        skillsAnalysis.likelihood
                          ? Number(skillsAnalysis.likelihood)
                          : 0
                      }
                      needleColor="red"
                      startColor="green"
                      segments={10}
                      endColor="blue"
                    />
                  </div>
                </div>

                {/* {isExpanded&&} */}

                <div className="flex justify-center mt-4">
                  <button
                    onClick={toggleExpand}
                    className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200"
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUp size={20} />
                        <span>Show Less</span>
                      </>
                    ) : (
                      <>
                        <ChevronDown size={20} />
                        <span>Show More</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <h2 className="text-gray-600 text-lg text-center min-h-[200px] capitalize flex justify-center items-center">
                No skill gap found for this customer. Please generate analysis
                first.
              </h2>
            )
          ) : (
            <OriginalSkillGapQuestions />
          )}
        </div>
      </div>
    </div>
  );
};

export default OriginalSkillGap;
