import React, { useEffect, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri"; // Import an icon for the exit button
import { useDispatch } from "react-redux";
import Header from "../../Components/header/index";
import CircularLoader from "../../Components/loader";
import octoStack from "../../DummyData/octoStack.json";
import useChatLogs from "../../hook/chatStream";
import { setinterviewMode } from "../../Redux/GlobalStates";
import Interview from "./octaInterview";

function CustomInput() {
  const dispatch = useDispatch();
  const [grade, setGrade] = useState("");
  const [topic, setTopic] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [timeRequired, setTimeRequired] = useState("");
  const [standards, setStandards] = useState("");
  const [skilled, setSkilled] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getFeedback } = useChatLogs({
    storeChats: false,
    url: "/dashboard/evaluate",
  });

  useEffect(() => {
    let filledFields = 0;
    if (grade) filledFields++;
    if (topic) filledFields++;
    if (difficulty) filledFields++;
    if (timeRequired) filledFields++;
    if (standards) filledFields++;
    if (skilled) filledFields++;
    setProgress((filledFields / 6) * 100);
  }, [grade, topic, difficulty, timeRequired, standards, skilled]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  const handleGenerate = async () => {
    try {
      setLoading(true);
      let summary = `Design a coding project for me, where technical focus will be ${grade} & the coding language should be ${topic}. The difficult level of the project should be ${difficulty}. ${
        standards ? `The project should be compatible with ${standards}` : ""
      }  Please follow the below structure\n ${JSON.stringify(
        octoStack.technical_frontend
      )} ${
        skilled ? `& I am having a ${skilled} skill}` : ""
      }  .Please return result in json format with array named "results"`;
      const resposne = await getFeedback(summary);

      if (resposne) {
        let results = JSON.parse(resposne);

        if (results?.results) {
          setQuestion(results?.results);
        }
        if (results?.summary) {
          setQuestion(results?.summary);
        }
        if (results?.responses) {
          setQuestion(results?.responses);
        }
      }

      dispatch(setinterviewMode("interview_start_developer"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Update body styles when the component mounts
    document.body.style.overflow = "hidden"; // Example: Disable scrolling

    // Clean up styles when the component unmounts
    return () => {
      document.body.style.overflow = ""; // Reset scrolling
    };
  }, []);

  if (loading) {
    return (
      <CircularLoader
        title="Nexa is hard at work creating your coding project, give us a minute"
        icon={true}
      />
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        position: "relative",
        height: "100vh",
        overflow: "hidden",
      }}
      className={question ? "" : "ic-interviews"}
    >
      {!question && <Header />}
      {!question && (
        <div style={styles.container}>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
            }}
          >
            {/* Exit Button */}
            <button
              style={styles.exitButton}
              onClick={() => window.history.back()}
            >
              <RiArrowLeftLine style={styles.exitIcon} />
            </button>

            {/* Progress Bar Attached to the Top of the Card */}
            <div style={styles.progressBarContainer}>
              <div
                style={{ ...styles.progressBar, width: `${progress}%` }}
              ></div>
            </div>

            {/* Title */}
            <h2 style={styles.title}>Design your Coding Project</h2>

            {/* Form */}
            <div style={styles.form}>
              <div style={styles.row}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    Technical Focus <span style={styles.important}>*</span>
                  </label>
                  <select
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="1">Frontend</option>
                    <option value="2">Backend</option>
                    <option value="3">Fullstack</option>
                    <option value="4">Mobile</option>
                  </select>
                </div>

                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    Coding Language Focus{" "}
                    <span style={styles.important}>*</span>
                  </label>
                  <input
                    type="text"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    placeholder="Enter coding language"
                    style={styles.input}
                  />
                </div>
              </div>

              <div style={styles.row}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    Difficulty Level <span style={styles.important}>*</span>
                  </label>
                  <select
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="1">Easy</option>
                    <option value="2">Medium</option>
                    <option value="3">Hard</option>
                  </select>
                </div>

                <div style={styles.inputGroup}>
                  <label style={styles.label}>Time Required</label>
                  <select
                    value={timeRequired}
                    onChange={(e) => setTimeRequired(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="1">Under 24 hours</option>
                    <option value="2">1-3 Days</option>
                    <option value="3">4+ Days</option>
                  </select>
                </div>
              </div>

              <div style={styles.row}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>Skill Level</label>
                  <select
                    value={skilled}
                    onChange={(e) => setSkilled(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="Entry Level">Entry Level</option>
                    <option value="Medium experience (2-4 years)">
                      Medium experience (2-4 years)
                    </option>
                    <option value="Advanced Developer (5+ years)">
                      Advanced Developer (5+ years)
                    </option>
                  </select>
                </div>

                <div style={styles.inputGroup}>
                  <label style={styles.label}>Details</label>
                  <input
                    type="text"
                    value={standards}
                    onChange={(e) => setStandards(e.target.value)}
                    placeholder="Add details"
                    style={styles.input}
                  />
                </div>
              </div>

              <button
                disabled={!grade || !topic || !difficulty}
                style={styles.button}
                onClick={handleGenerate}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      )}

      {question && <Interview techStack={question} />}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    padding: "50px",
    boxSizing: "border-box",
    paddingTop: "10px", // Adjusted to lower the card by 20px more
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "30px",
    maxWidth: "600px",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginTop: "0", // Reset marginTop since paddingTop is used
    position: "relative", // Add relative positioning for the exit button
  },
  exitButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "8px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
  },
  exitIcon: {
    fontSize: "24px",
    color: "#007bff",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e9ecef",
    borderRadius: "4px 4px 0 0", // Rounded corners only at the top
    width: "100%",
    position: "absolute", // Position the progress bar absolutely within the card
    top: 0, // Attach to the top of the card
    left: 0,
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#007bff",
    borderRadius: "4px 4px 0 0", // Rounded corners only at the top
    transition: "width 0.3s ease",
  },
  title: {
    textAlign: "center",
    color: "#343a40",
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "30px",
    marginTop: "20px", // Add margin to separate from the progress bar
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px", // Increased gap between form elements
  },
  row: {
    display: "flex",
    gap: "20px", // Gap between columns in a row
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flex: 1, // Ensure equal width for columns
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#495057",
  },
  select: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  input: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#ffffff",
    padding: "14px 20px",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginTop: "20px", // Added margin to separate from the last input
    opacity: 1,
  },
  important: {
    color: "red",
  },
};

export default CustomInput;
