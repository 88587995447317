import React from "react";
import {
  handleSelectChange,
  NotSureStepHeader,
} from "../../../../../../../Pages/Onboarding/components/steps/context";
import { Select } from "antd";
import { CAREER_PREFERENCES } from "../../../../../../../Pages/Onboarding/components/boardingConstants";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";

const Step6 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;
  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Interests and Preferences"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <Select
          placeholder="Select preferences"
          className="w-full mt-2 h-[48px] capitalize"
          value={formData[currentStep - 1].answer || undefined}
          onChange={(value) =>
            handleSelectChange(currentStep - 1, value, setStepState)
          }
        >
          {CAREER_PREFERENCES.map((preference, index) => (
            <Select.Option
              key={index}
              value={preference}
              className="capitalize"
            >
              {preference}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default Step6;
