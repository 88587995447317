import { createSlice } from "@reduxjs/toolkit";
import {
  authenticateAction,
  checkSessionAction,
  createUser,
  forgetPassword,
  generateSkillsGapAnalysis,
  getOnBoardingAction,
  getSkillsAnalysis,
  getUserSubscription,
  loginUser,
  refreshAuthToken,
  ssoLoginAction,
  updateOnboarding,
  updateprofile,
  createInvitedUser,
  cancelUserSubscription,
  continueIndividualAction,
  delegateAccess,
  logoutUser,
  updateOnboardingUser,
  getCurrentUser,
  saveAiQuestions,
  getMyAiBoardingQuestions,
  getRecommendCareerSuggestion,
  generateLiveSkillsGapAnalysis,
  getAiSkillGapAssessmentReport,
} from "./authenticate.action";
import { googleLogout } from "@react-oauth/google";

const initialState = {
  status: "",
  auth: false,
  user: null,
  onboardingData: [],
  onboardingAiData: [],
  authToken: null,
  loading: false,
  isloading: false,
  onBoardStep: 0,
  recommendCareersSuggestions: null,
  liveSkillsAnalysis: null,
};

const authenticateSlicer = createSlice({
  name: "authenticateSlicer",
  initialState,
  reducers: {
    setToken(state, action) {
      localStorage.setItem("token", action.payload);
    },
    updateAuth: (state, action) => {
      let { accessToken, refreshToken } = action.payload;
      state.authToken = accessToken;
      state.refreshToken = refreshToken;
    },
    removeToken(state) {
      state.auth = false;
      state.user = null;
      state.authToken = null;
      googleLogout();
      localStorage.removeItem("auth");
      localStorage.removeItem("refreshAuth");
      localStorage.removeItem("onboardingFormData");
    },
    setOnboardStep(state, action) {
      state.onBoardStep = action.payload;
    },
    updateIsUserStatus(state) {
      state.user.isNewUser = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ssoLoginAction.fulfilled, (state, { payload }) => {
        const { accessToken, refreshToken, status_code, ...rest } = payload;
        state.user = rest;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        state.loading = false;
        localStorage.setItem("auth", accessToken);
        localStorage.setItem("refreshAuth", refreshToken);
      })
      .addCase(ssoLoginAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(ssoLoginAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const { revoked, accessToken, refreshToken, status_code, ...rest } =
          payload;
        state.user = rest;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        localStorage.setItem("refreshAuth", refreshToken);
        state.loading = false;
        localStorage.setItem("auth", accessToken);
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delegateAccess.fulfilled, (state, { payload }) => {
        const { revoked, accessToken, refreshToken, status_code, ...rest } =
          payload;
        state.user = rest;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        localStorage.setItem("refreshAuth", refreshToken);
        state.loading = false;
        localStorage.setItem("auth", accessToken);
      })
      .addCase(delegateAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(delegateAccess.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        const { accessToken, refreshToken, status_code, ...rest } = payload;
        state.user = rest;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        state.loading = false;
        localStorage.setItem("auth", accessToken);
        localStorage.setItem("refreshAuth", refreshToken);
      })
      .addCase(createUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createInvitedUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvitedUser.fulfilled, (state, { payload }) => {
        const { accessToken, refreshToken, status_code, ...rest } = payload;
        state.user = rest;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        state.loading = false;
        localStorage.setItem("auth", accessToken);
        localStorage.setItem("refreshAuth", refreshToken);
      })
      .addCase(createInvitedUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(forgetPassword.rejected, (state) => {
        state.loading = false;
      })

      .addCase(authenticateAction.pending, (state) => {
        state.status = "pending";
        state.auth = false;
      })
      .addCase(authenticateAction.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.auth = true;
        // state.user = action.payload;
      })
      .addCase(authenticateAction.rejected, (state) => {
        state.status = "rejected";
        state.auth = false;
      })
      .addCase(checkSessionAction.pending, (state) => {
        state.status = "pending";
        state.auth = false;
      })
      .addCase(checkSessionAction.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.auth = true;
        state.user = action.payload;
      })
      .addCase(checkSessionAction.rejected, (state) => {
        state.status = "rejected";
        state.auth = false;
      })

      .addCase(getUserSubscription.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getUserSubscription.fulfilled, (state, action) => {
        // state.isLoading = false;
        const { data, activeBundle, is_trial_consumed } = action.payload;
        state.user.activeBundle = activeBundle;
        state.user.subscriptionCancelled = data?.cancel_at_period_end;
        state.user.cancelTime = data?.current_period_end;
        state.user.subscriptionStatus = data?.status;
        state.user.is_trial_consumed = is_trial_consumed;
        state.user.subscribedPlan = data?.plan_name;
        state.user.subscriptionQuotas = data?.quotas;
        state.user.accessToIQ = data?.is_nexa_iq;
        state.user.accessToOctagon = data?.is_octagon;
      })
      .addCase(getUserSubscription.rejected, (state, action) => {
        // state.isLoading = false;
      })
      .addCase(refreshAuthToken.fulfilled, (state, action) => {
        const { accessToken, refreshToken } = action.payload;
        state.authToken = accessToken;
        state.refreshToken = refreshToken;
        localStorage.setItem("auth", accessToken);
        localStorage.setItem("refreshAuth", refreshToken);
      })
      .addCase(getOnBoardingAction.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getOnBoardingAction.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.onboardingData = action.payload;
      })
      .addCase(getOnBoardingAction.rejected, (state, action) => {
        // state.isLoading = false;
      })

      .addCase(getMyAiBoardingQuestions.fulfilled, (state, action) => {
        state.onboardingAiData = action.payload;
      })

      .addCase(getCurrentUser.fulfilled, (state, action) => {
        let newUser = {
          ...state.user,
          ...action.payload,
          fullName: action.payload.name,
          picture: action.payload.image,
        };
        state.user = newUser;
      })
      .addCase(updateOnboardingUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOnboardingUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateOnboardingUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateprofile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateprofile.fulfilled, (state, action) => {
        state.loading = false;
        state.user.fullName = action.payload.name;
        state.user.picture = action.payload.image;
      })
      .addCase(updateprofile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSkillsAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSkillsAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.skillsAnalysis = action.payload;
      })
      .addCase(getSkillsAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateSkillsGapAnalysis.pending, (state) => {
        state.isloading = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(generateSkillsGapAnalysis.fulfilled, (state, action) => {
        state.isloading = false;
        state.loading = false;
        state.skillsAnalysis = action.payload;
      })
      .addCase(generateSkillsGapAnalysis.rejected, (state, action) => {
        state.isloading = false;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateLiveSkillsGapAnalysis.fulfilled, (state, action) => {
        state.liveSkillsAnalysis = action.payload;
      })
      .addCase(cancelUserSubscription.pending, (state) => {
        state.isloading = true;
      })
      .addCase(cancelUserSubscription.fulfilled, (state, action) => {
        state.isloading = false;
      })
      .addCase(cancelUserSubscription.rejected, (state, action) => {
        state.isloading = false;
      })
      .addCase(continueIndividualAction.pending, (state) => {
        state.isloading = true;
      })
      .addCase(continueIndividualAction.fulfilled, (state, action) => {
        state.isloading = false;
        state.user.organizationId = null;
      })
      .addCase(continueIndividualAction.rejected, (state, action) => {
        state.isloading = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isloading = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isloading = false;
        state.user = null;
        removeToken();
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isloading = false;
      })
      .addCase(getRecommendCareerSuggestion.fulfilled, (state, action) => {
        state.recommendCareersSuggestions = action.payload;
      })
      .addCase(getAiSkillGapAssessmentReport.fulfilled, (state, action) => {
        state.liveSkillsAnalysis = action.payload;
      });
  },
});

export const {
  setToken,
  removeToken,
  setOnboardStep,
  updateAuth,
  updateIsUserStatus,
} = authenticateSlicer.actions;
export default authenticateSlicer.reducer;
