import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section4.css";

const Section4 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open("https://cal.com/prithvi-nexa/30min", "_blank");
  };

  return (
    <div className="default-container-section4">
      <div className="text-wrapper-4">
        <div className="narrow-text">
          <p className="text-default-blue-bold-4 text-center mb-8 FiraFontFamily bold-family">
            Why Nexa RU?
          </p>
        </div>
        <div className="wide-text">
          <p className="text-default-black-4 text-center FiraFontFamily">
            Hiring today is costly, time-consuming, and often inefficient.
            Sorting through piles of resumes, assessing unqualified candidates,
            and finding the right fit can take weeks—sometimes months.
          </p>
        </div>
      </div>
      <div className="outer-container-4">
        {/* New Boxes Section with Divider */}
        <div className="main-content-4">
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box" style={{ width: "400px" }}>
              <img
                src="/images/5.png"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3
                style={{ color: "#000" }}
                className="new-box-title FiraFontFamily"
              >
                AI Smart-Screening
              </h3>
              <p
                style={{ color: "#000" }}
                className="new-box-description FiraFontFamily"
              >
                Automated talent screening ensures only the top 1% reach your
                desk. Our State-of-the-art{" "}
                <span
                  style={{ color: "#000" }}
                  className="bolder-text FiraFontFamily"
                >
                  AI video interview technology provides transparent analytics,
                  reports, and recordings - so recruiters can spot BS
                </span>
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div className="new-box" style={{ width: "400px" }}>
              <img
                src="/images/4.png"
                alt="Globe Icon"
                className="new-box-icon"
              />
              <h3
                style={{ color: "#000" }}
                className="new-box-title FiraFontFamily"
              >
                Integrated Talent Hub
              </h3>
              <p
                style={{ color: "#000" }}
                className="new-box-description FiraFontFamily"
              >
                <span className="bolder-text FiraFontFamily">
                  Access a pre-vetted pool of candidates
                </span>{" "}
                with deep insights into their{" "}
                <span className="bolder-text FiraFontFamily">
                  skills, experience, and cultural fit
                </span>
              </p>
            </div>
          </div>
          <img src="/images/icons/horizental-divider.svg" />
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box" style={{ width: "400px" }}>
              <img
                src="/images/3.png"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3
                style={{ color: "#000" }}
                className="new-box-title FiraFontFamily"
              >
                Realtime Feedback
              </h3>
              <p
                style={{ color: "#000" }}
                className="new-box-description FiraFontFamily"
              >
                Instantly notify candidates of their status and provide{" "}
                <span className="bolder-text FiraFontFamily">
                  actionable feedback
                </span>
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div className="new-box" style={{ width: "400px" }}>
              <img
                src="/images/2.png"
                alt="Globe Icon"
                className="new-box-icon"
              />
              <h3
                style={{ color: "#000" }}
                className="new-box-title FiraFontFamily"
              >
                Seamless Integration
              </h3>
              <p
                style={{ color: "#000" }}
                className="new-box-description FiraFontFamily"
              >
                Connect Nexa RU with Your{" "}
                <span className="bolder-text FiraFontFamily">
                  ATS, Linkedin, and more
                </span>{" "}
                for a smoother workflow
              </p>
            </div>
          </div>
          <img src="/images/icons/horizental-divider.svg" />
          <div className="new-boxes-container">
            {/* First Box */}
            <div className="new-box" style={{ width: "400px" }}>
              <img
                src="/images/1.png"
                alt="Double Arrow Icon"
                className="new-box-icon"
              />
              <h3
                style={{ color: "#000" }}
                className="new-box-title FiraFontFamily"
              >
                Behavioral and Technical Insights
              </h3>
              <p
                style={{ color: "#000" }}
                className="new-box-description FiraFontFamily"
              >
                Gain in-depth reports on candidates'{" "}
                <span className="bolder-text FiraFontFamily">
                  soft skills, technical expertise, and growth potential{" "}
                </span>
              </p>
            </div>

            {/* Divider */}
            <img
              className="responsive-mob"
              src="/images/icons/vertical-divider.svg"
            />

            {/* Second Box */}
            <div style={{ width: "400px" }} className="new-box-button">
              <button
                onClick={handleClick}
                className="try-nexa-button FiraFontFamily"
              >
                See it in Action: Book a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
