import { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MiniResumePreview from "../../Components/Resume/MiniResumePreview";
import { PlusSignIcon } from "../../Components/Resume/svgimages";
import { iconsImgs } from "../../Components/dashboardComponents/utils/images";
import Header from "../../Components/header";
import CircularLoader from "../../Components/loader";
import Modal from "../../Components/modal";
import QuotaExceeded from "../../Components/quotaExceeded";
import {
  addNewResume,
  clearSingleResume,
  deleteResume,
  getResume,
  getResumeFeedback,
  udpateResumeProfile,
  updateResume,
  uploadExistingResume,
  uploadResumeTimer,
} from "../../Redux/ResumeBuilderSlice";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import { error, success } from "../../Utilities/toast";
import { addStep } from "../../features/companyinformation/StepsSlice";
// import CardShimmer from "../../Components/Shimmers/CardShimmer";
import ResumeShimmer from "../../Components/Shimmers/ResumeShimmer";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const { allResume, isLoading, isAILoading, uploadingTime } = useSelector(
    (state) => state.ResumeBuilder
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [resumeState, setResumeState] = useState("");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [isModal, setIsModal] = useState(false);
  const initialState = {
    name: "",
    person_name: "",
    company_name: "",
    job_role: "",
  };
  const [createResume, setCreateResume] = useState(initialState);
  const [selectedResume, setSelectedResume] = useState(null);
  const [currentResume, setCurrentResume] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [storeResumeFeedback, setStoreResumeFeedback] = useState(null);
  const [resumeType, setResumeType] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedDeleteResumeId, setSelectedDeleteResumeId] = useState("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const location = useLocation();

  // Step 1: Add state for managing the modal
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);

  // Step 2: Add a function to toggle the modal
  const toggleResumeModal = () => {
    setIsResumeModalOpen(!isResumeModalOpen);
  };

  const handleEdit = (resume) => {
    setCurrentResume(resume);
    setFirstName(resume.contact.first_name);
    setLastName(resume.contact.last_name);
    setModalIsOpen(true);
  };

  const handleSave = async () => {
    const { id, ...existingContactData } = currentResume.contact;

    const userInfo = {
      contactId: id,
      data: {
        ...existingContactData,
        first_name: firstName,
        last_name: lastName,
      },
    };

    try {
      const resultAction = await dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (data) => {
            success("Contact information updated successfully");
            dispatch(getResume({ onSuccess: () => {} }));
            setModalIsOpen(false);
          },
        })
      );

      if (udpateResumeProfile.rejected.match(resultAction)) {
        error(resultAction.payload || "Failed to update contact information");
      }
    } catch (err) {
      error("An unexpected error occurred: " + err.message);
    }
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row max-h-[calc(100vh-300px)] overflow-y-scroll">
        <div className="col-12 order-md-1 order-2">
          <div className="cardBody">
            <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
            <hr />

            {storeResumeFeedback?.feedback &&
              Object?.entries(
                eval("(" + storeResumeFeedback?.feedback + ")")
              )?.map(([key, value]) => (
                <div key={key} className="my-3 ">
                  {key === "summary" ? (
                    <div className="pb-2">
                      <h3 className="text-dark font-bold px-3">
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())} of
                        feedback
                      </h3>
                      <p className="text-muted mx-3">{value}</p>
                    </div>
                  ) : (
                    <div className="pb-2">
                      {/* Display other sections with score */}
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <h3 className="text-dark font-bold">
                          {key
                            ?.replace(/_/g, " ")
                            ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
                        </h3>
                        <div className="bg-slate-400 rounded-xl px-3">
                          <span className="text-white">
                            Score: {value?.score}
                          </span>
                        </div>
                      </div>
                      {/* Display the comments */}
                      <p className="text-muted mx-3">{value?.feedback}</p>
                    </div>
                  )}
                  <hr />
                </div>
              ))}
          </div>
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Overall Feedback Score:</h5>
        <span className="score">{storeResumeFeedback?.score}.0</span>
      </div>
    );

    return (
      <Modal show={isModal} header={header} content={content} footer={footer} />
    );
  };

  const handleNewResume = () => {
    if (resumeType === "new") {
      dispatch(
        addNewResume({
          createResume,
          onSuccess: (toast, id) => {
            navigate(`/resume-builder/create/${id?.id}`);
          },
          onError: (err) => {
            // console.log("...", err);
            error("Quota Limit exhausted, please upgrade the plan");
          },
        })
      );
    } else {
      setUploadLoading(true);
      setCreateModal(false);
      let payload = new FormData();
      if (selectedResume) {
        payload.append("file", selectedResume);
      }
      Object?.entries(createResume)?.forEach(([key, val]) => {
        payload.append(key, val);
      });
      // setUploadProgress(0);

      // Simulate gradual progress
      // let simulatedProgress = 0;
      // const interval = setInterval(() => {
      //   if (simulatedProgress < 85) {
      //     simulatedProgress += 1;
      //     setUploadProgress(simulatedProgress);
      //   }
      // }, 150);
      dispatch(
        uploadExistingResume({
          payload,
          onSuccess: () => {
            // setCreateModal(false);
            // dispatch(getResume({ onSuccess: () => {} }));
            dispatch(uploadResumeTimer());
            setResumeType("");
            setUploadLoading(false);
            // clearInterval(interval); // Stop the first interval
            // Quickly move from 80 to 100
            // const fastInterval = setInterval(() => {
            //   if (simulatedProgress < 100) {
            //     simulatedProgress += 5; // Increase by 5 for faster progress
            //     setUploadProgress(simulatedProgress);
            //   } else {
            //     clearInterval(fastInterval); // Clear the fast interval once it reaches 100
            //     setCreateModal(false);
            //     dispatch(getResume({ onSuccess: () => {} }));
            //     setResumeType("");
            //   }
            // }, 100); // Faster update interval for smoother transition
          },
          onError: () => {
            setUploadLoading(false);
          },
        })
      );
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteResume({
        resumeId: selectedDeleteResumeId,
        onSuccess: (res) => {
          success(res);
          dispatch(getResume({ onSuccess: () => {} }));
        },
      })
    );
  };

  const handleGetFeedback = (id) => {
    dispatch(
      getResumeFeedback({
        id,
        onSuccess: (res) => {
          setIsModal(true);
          setStoreResumeFeedback(res?.data);
        },
      })
    );
  };
  const handleUpdateResume = () => {
    dispatch(
      updateResume({
        createResume,
        resumeId: selectedResumeId,
        onSuccess: () => {
          success("Resume updated successfully");
          setCreateModal(false);
          setResumeType("");
          dispatch(getResume({ onSuccess: () => {} }));
        },
        onError: (err) => {
          error(err);
        },
      })
    );
  };
  const handleResumeClick = (id) => {
    setSelectedResumeId(id);
    const selectedResume = allResume.find((resume) => resume.id === id);
    if (selectedResume) {
      setCreateResume({
        name: selectedResume.resume_name,
        person_name: selectedResume?.person_name,
        company_name: selectedResume?.company_name,
        job_role: selectedResume?.job_role,
      });
      setResumeState("edit");
      setResumeType("existing");
      setCreateModal(true);
    }
  };
  const groupedResumes = allResume?.reduce((acc, resume) => {
    const { parent_resume_id, contact } = resume;
    // if (contact !== null) {
    if (!acc[parent_resume_id]) {
      acc[parent_resume_id] = acc[parent_resume_id] || [];
    }
    acc[parent_resume_id].push(resume);
    // }
    return acc;
  }, {});
  // Identify the latest `created_at` across all resumes
  const latestResumeDate =
    allResume &&
    Math.max(
      ...allResume.map((resume) => new Date(resume.created_at).getTime())
    );
  // Sort the groupedResumes by the latest created_at in each group
  const sortedGroupedResumes =
    groupedResumes &&
    Object?.fromEntries(
      Object?.entries(groupedResumes)
        ?.sort(([, resumesA], [, resumesB]) => {
          const latestA = Math.max(
            ...resumesA.map((resume) => new Date(resume.created_at).getTime())
          );
          const latestB = Math.max(
            ...resumesB.map((resume) => new Date(resume.created_at).getTime())
          );
          return latestB - latestA;
        })
        .map(([parentId, resumes]) => [
          parentId,
          resumes.map((resume) => ({
            ...resume,
            isNew: new Date(resume.created_at).getTime() === latestResumeDate, // Mark the latest resume
          })),
        ])
    );
  console.log(sortedGroupedResumes, "GroupedResumes");
  useEffect(() => {
    if (uploadingTime == false) {
      dispatch(getResume({ onSuccess: () => {} }));
    }
  }, [uploadingTime]);
  useEffect(() => {
    dispatch(addStep(1));
  }, [dispatch]);
  useEffect(() => {
    if (
      user?.subscriptionQuotas?.resume?.quota_allowed ===
      user?.subscriptionQuotas?.resume?.quota_consumed
    ) {
      setIsQuoteModalOpen(true);
    }
  }, [user?.subscriptionQuotas?.resume]);
  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
    dispatch(clearSingleResume());
  }, []);
  useEffect(() => {
    dispatch(getUserSubscription());
  }, []);
  if (isAILoading) {
    return (
      <CircularLoader
        title="Hang tight, we are scanning your resume and creating an detailed report based on your target job’s description!"
        icon={true}
      />
    );
  }
  // if (uploadLoading) {
  //   return (
  //     <CircularLoader
  //       title=" Hang on tight, we are scanning your resume and making sure we got all of your amazing life's work in our platform so you can land that next role! Check back in 60-90 seconds"
  //       icon={true}
  //     />
  //   );
  // }
  return (
    <>
      <Header />
      {isLoading ? (
        <ResumeShimmer />
      ) : uploadLoading ? (
        <CircularLoader
          title="Hang on tight, we are scanning your resume and making sure we got all of your amazing life's work in our platform so you can land that next role! Check back in 60-90 seconds"
          icon={true}
        />
      ) : (
        <div
          className={`ic-interview-full-body overflow-y-auto w-full`}
          style={{
            paddingTop: location.pathname !== "/user/profile" ? "6em" : "1em",
          }}
        >
          {/* {resumeType === "existing" && resumeState === "add" && uploadLoading ? (
          <>
            <div className="h-[calc(100vh - 14rem)] my-auto px-5 w-full rounded-lg flex flex-col items-center justify-center">
              <div className="size-40 w-full flex flex-col items-center justify-center">
                <div className=" transform loaderStyler"></div>
                <div className=" transform ">
                  <p className="mt-2 pr-2 w-full center text-xl text-[#007bff] font-">
                    Hang on tight, we are scanning your resume and making sure
                    we got all of your amazing life's work in our platform so
                    you can land that next role!
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : ( */}
          <main
            role="main"
            className={`ml-sm-auto col-lg-12 px-4 bg-cover`}
            // style={{
            //   backgroundImage:
            //     location.pathname !== "/user/profile" &&
            //     `url(${"/images/background.svg"})`,
            // }}
          >
            <div
              className={`content pb-5 ${
                location.pathname !== "/user/profile" ? "pt-14" : "pt-0"
              }`}
            >
              <div
                className={`career_slide-cover ${
                  location.pathname !== "/user/profile" ? " mt-10" : "mt-0"
                } md:!mt-2`}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // marginTop: "50px",
                  }}
                  className={`${
                    location.pathname === "/user/profile" && "ml-4"
                  }`}
                >
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "24px",
                      fontWeight: "700",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    Nexa Resume
                  </p>
                  {location.pathname !== "/user/profile" && (
                    <div className="flex items-center justify-end gap-3">
                      <button
                        onClick={toggleResumeModal}
                        className="bg-[#1e62fe] text-white rounded-lg py-2 px-4 border-none text-sm flex items-center gap-2 hover:bg-[#0a51ed] transition-colors shadow-sm"
                      >
                        Resume Tutorial
                      </button>
                      <button
                        onClick={() => {
                          // const filteredResumes = allResume.filter(
                          //   (resume) => resume.contact === null
                          // );

                          // if (filteredResumes.length === 0) {
                          setCreateResume(initialState);
                          setCreateModal(true);
                          setResumeType("new");
                          setResumeState("add");
                          // } else {
                          //   const resumeFilterId = filteredResumes[0].id;
                          //   navigate(`/resume-builder/view/${resumeFilterId}`);
                          // }
                        }}
                        className="bg-[#2B6BE2] text-white rounded-lg py-2 px-6 border-none text-sm flex items-center gap-2"
                      >
                        <PlusSignIcon />
                        Create New
                      </button>
                      <button
                        disabled={uploadingTime ? true : false}
                        onClick={() => {
                          setCreateResume(initialState);
                          setCreateModal(true);
                          setResumeState("add");
                          setResumeType("existing");
                          setUploadProgress(0);
                        }}
                        className="bg-[#2B6BE2] text-white rounded-lg py-2 px-6 border-none text-sm flex items-center gap-2"
                      >
                        <FaUpload />
                        {uploadingTime
                          ? "Building your Resume"
                          : "Upload Existing"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`w-full pb-14`}>
              {allResume?.length < 1 && (
                <h2 className="no-resume">No Resume Found</h2>
              )}
              {sortedGroupedResumes &&
                Object?.entries(sortedGroupedResumes)?.map(
                  ([parent_resume_id, resumes]) => (
                    <div key={parent_resume_id} className="w-full mb-4">
                      {resumes?.find((item) => item?.contact) && (
                        <h2
                          key={resumes.find((item) => item?.contact)?.id}
                          className="text-base underline text-capitalize text-slate-500"
                        >
                          {resumes.find((item) => item?.contact)?.job_role}
                        </h2>
                      )}
                      <div className="flex flex-wrap gap-4 justify-start mt-2">
                        {resumes.map((item) => (
                          <MiniResumePreview
                            key={item.id}
                            item={item}
                            handleResumeClick={(id) => handleResumeClick(id)}
                            handleGetFeedback={handleGetFeedback}
                            setSelectedDeleteResumeId={
                              setSelectedDeleteResumeId
                            }
                            setIsOpenConfirmationModal={
                              setIsOpenConfirmationModal
                            }
                          />
                        ))}
                      </div>
                      <hr className="my-4 border-2 border-blue-500 " />
                    </div>
                  )
                )}
            </div>
            <ReactModal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  maxWidth: "500px",
                  width: "90%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <h2>Edit Resume</h2>
              <div className="modal-body">
                <div className="modal-row">
                  <label className="modal-label">
                    <span>First Name:</span>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="modal-input"
                    />
                  </label>
                  <label className="modal-label">
                    <span>Last Name:</span>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="modal-input"
                    />
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={handleSave}
                  className="modal-button save-button"
                >
                  Save
                </button>
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="modal-button cancel-button"
                >
                  Cancel
                </button>
              </div>
            </ReactModal>
            <ReactModal
              isOpen={createModal}
              onRequestClose={() => {
                setResumeType("");
                setCreateModal(false);
              }}
              shouldCloseOnOverlayClick={false} // Prevent closing on outside click
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  maxWidth: "500px",
                  width: "90%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <h2>{resumeState === "edit" ? "Edit" : "Start"} Resume</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (resumeState === "add") {
                    handleNewResume();
                  } else {
                    handleUpdateResume();
                  }
                }}
              >
                {resumeType === "existing" &&
                  resumeState === "add" &&
                  uploadLoading && (
                    <div className="absolute inset-0 rounded-sm bg-black bg-opacity-50 z-10 pointer-events-auto"></div>
                  )}
                {resumeType === "existing" &&
                  resumeState === "add" &&
                  uploadLoading && (
                    <>
                      <div className="absolute z-50 top-30 h-auto w-auto rounded-lg  flex flex-col items-center justify-center">
                        <div className="size-40 w-full flex flex-col items-center justify-center">
                          <div className=" transform">
                            <img
                              src={iconsImgs.aiLoading}
                              alt=""
                              className="w-[100px]"
                            />
                          </div>
                          <div className=" transform ">
                            <p className="mt-2 pr-2 w-full text-sm text-white font-bold">
                              Hang on tight, we are scanning your resume and
                              making sure we got all of your amazing life's work
                              in our platform so you can land that next role!
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                <div className="relative modal-body">
                  <div className="modal-row w-full">
                    {" "}
                    <label className="modal-label">
                      <div className="flex">
                        <span>Resume Name</span>
                        <span className="text-red-600">*</span>
                      </div>
                      <input
                        type="text"
                        value={createResume?.name}
                        onChange={(e) =>
                          setCreateResume((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        required
                        className="modal-input"
                      />
                    </label>
                    <label className="modal-label">
                      <span>Your Name:</span>
                      <input
                        type="text"
                        value={createResume?.person_name}
                        onChange={(e) =>
                          setCreateResume((prev) => ({
                            ...prev,
                            person_name: e.target.value,
                          }))
                        }
                        className="modal-input"
                      />
                    </label>
                  </div>
                  <div className="modal-row w-full">
                    <label className="modal-label">
                      <span>Target Company Name:</span>
                      <input
                        type="text"
                        value={createResume?.company_name}
                        onChange={(e) =>
                          setCreateResume((prev) => ({
                            ...prev,
                            company_name: e.target.value,
                          }))
                        }
                        className="modal-input"
                      />
                    </label>
                    <label className="modal-label">
                      <div className="flex">
                        <span>Target Job Role</span>
                        <span className="text-red-600">*</span>
                      </div>
                      <input
                        type="text"
                        value={createResume?.job_role}
                        onChange={(e) =>
                          setCreateResume((prev) => ({
                            ...prev,
                            job_role: e.target.value,
                          }))
                        }
                        required
                        className="modal-input"
                      />
                    </label>
                  </div>
                  {resumeType === "existing" && resumeState === "add" && (
                    <label className="modal-label">
                      <div className="flex">
                        <span>Upload Resume</span>
                        <span className="text-red-600">*</span>
                      </div>
                      <input
                        type="file"
                        accept=".pdf, .docx, .doc"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const fileSizeInMB = file.size / 1024 / 1024;
                            const allowedTypes = [
                              "application/pdf",
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              "application/msword",
                            ];

                            if (fileSizeInMB > 1) {
                              error("File size must be less than 1 MB");
                              e.target.value = null;
                            } else if (!allowedTypes.includes(file.type)) {
                              error(
                                "Only PDF or Word documents (.docx, .doc) are allowed"
                              );
                              e.target.value = null;
                            } else {
                              setSelectedResume(file);
                            }
                          }
                        }}
                        required
                        className="modal-input !border-none"
                      />
                    </label>
                  )}
                  <div className="modal-footer w-full justify-center pb-0">
                    <button type="submit" className="modal-button save-button">
                      {resumeState === "edit"
                        ? "Save"
                        : resumeType === "new"
                        ? "Start"
                        : "Upload"}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setCreateModal(false);
                        setResumeType("");
                      }}
                      className="modal-button cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </ReactModal>
            <div
              className={`ic-modal ${isOpenConfirmationModal ? "show" : ""}`}
            >
              <div className="ic-modal-dialog">
                <div className="modal-main-content w-100">
                  <div className="ic-modal-body">
                    <div className="ic-modal-content">
                      <p className="text-xl">
                        Are you sure you want to Delete this Resume?
                      </p>
                    </div>
                    <div className="ic-footer-modal d-flex justify-content-end">
                      <button
                        onClick={() => {
                          setIsOpenConfirmationModal(false);
                          setSelectedDeleteResumeId("");
                        }}
                      >
                        No
                      </button>
                      <button
                        onClick={() => {
                          handleDelete();
                          setIsOpenConfirmationModal(false);
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {isResumeModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
              <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-3xl transform transition-transform duration-300 ease-in-out scale-100">
                <div className="flex justify-between items-center p-4 border-b">
                  <button
                    onClick={toggleResumeModal}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    ✖
                  </button>
                </div>
                <div className="p-4">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/hm5bbT-kD-c"
                    title="Resume Tutorial"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded-lg"
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {getModal()}
      {isQuoteModalOpen && (
        <QuotaExceeded
          isQuoteModalOpen={isQuoteModalOpen}
          setIsQuoteModalOpen={setIsQuoteModalOpen}
        />
      )}
    </>
  );
}

export default Index;
