import { atom } from "recoil";

const defaultState = {
  careerGoals: {
    recommendCareersSuggestions: false,
    skillsGapAssessment: false,
    reTakeSkillsGapAssessment: false,
    liveSkillsGapAssessment: false,
    reLiveSkillsGapAssessment: false,
    skillGapAssessmentQuestions: false,
  },
  onBoarding: {
    manualQuestionBoard: false,
    aiQuestionBoard: false,
    notSureQuestionBoard: false,
  },
};

export const StepLoadingAtom = atom({
  key: "LoadingStepState",
  default: defaultState,
});

export { defaultState };
