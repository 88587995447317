import "./styles.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getCurrentUser,
  getMyAiBoardingQuestions,
  getOnBoardingAction,
} from "../../Redux/authenticate/authenticate.action";
import { StepBoardingStateAtom } from "../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import ManualQuestionsBoard from "../../Pages/Onboarding/components/steps/ManualQuestionsBoard";
import NotSureQuestionBoard from "../../Pages/Onboarding/components/steps/NotSureQuestionBoard";
import AIQuestionBoard from "../../Pages/Onboarding/components/steps/AIQuestionBoard";
import { useSelector } from "react-redux";
import IsWelcomeBoard from "../../Pages/Onboarding/components/steps/IsWelcomeBoard";
import { useNavigate } from "react-router-dom";

export default function Onboarding() {
  //REDUX
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authenticate);

  //RECOIL
  const [stepState] = useRecoilState(StepBoardingStateAtom);

  useEffect(() => {
    if (!user.isNewUser) return navigate("/career-goals");
    dispatch(getCurrentUser());
    dispatch(getOnBoardingAction({ onComplete: () => {} }));
    dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
  }, []);

  return (
    <div>
      {stepState.isActiveMode === "welcome" && <IsWelcomeBoard />}
      {stepState.isActiveMode === "manual" && <ManualQuestionsBoard />}
      {stepState.isActiveMode === "AI" && <AIQuestionBoard />}
      {stepState.isActiveMode === stepState.notSureKey && (
        <NotSureQuestionBoard />
      )}
    </div>
  );
}
