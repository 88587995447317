import React from "react";
import { useSelector } from "react-redux";
import CircularLoader from "../loader";
import SelectEnhanceResume from "./SelectEnhanceResume";
import EnhanceResume from "./EnhanceResume";

const AiGeneration = ({ setShow, show, selectedResume, setSelectedResume }) => {
  const { isAILoading } = useSelector((state) => state.ResumeBuilder);

  if (isAILoading) {
    return (
      <CircularLoader
        title="Nexa is hard at work to generate the perfect resume for you to absolutely blow the hiring managers away with your skills, please give us a minute!"
        icon={true}
        className={"h-full"}
      />
    );
  }

  return (
    <div className="max-w-[1500px] w-full justify-center flex">
      {!show && (
        <EnhanceResume
          setSelectedResume={setSelectedResume}
          setShow={setShow}
        />
      )}

      {show && (
        <SelectEnhanceResume
          setSelectedResume={setSelectedResume}
          selectedResume={selectedResume}
          setShow={setShow}
        />
      )}
    </div>
  );
};

export default AiGeneration;
