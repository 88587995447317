import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const dashboardRepo = RepositoryFactory.get("Dashboard");

// Async actions
export const getJobRoles = createAsyncThunk(
  "dashboard/getJobRoles",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.getAllRoles();
      if (data?.reason === "successful") {
        dispatch(dashboardLoader(false));
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      dispatch(dashboardLoader(false));
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const handleJobRoleStatus = createAsyncThunk(
  "dashboard/handleJobRoleStatus",
  async ({ id, activeTab }, { rejectWithValue, dispatch }) => {
    dispatch(dashboardLoader(true));
    try {
      let response;
      if (activeTab === "visible") {
        response = await dashboardRepo.hideJobRole(id);
      } else {
        response = await dashboardRepo.showJobRole(id);
      }
      await dispatch(getJobRoles());
      success(response?.data?.detail || "Res");
    } catch (err) {
      dispatch(dashboardLoader(false));
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const generateQuestions = createAsyncThunk(
  "dashboard/generateQuestions",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashboardRepo.generateQuestions(payload);
      return data;
    } catch (err) {
      error(err.data.detail);
      return rejectWithValue(err);
    }
  }
);

export const submitAnswers = createAsyncThunk(
  "dashboard/submitAnswers",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await dashboardRepo.submitAnswers(payload);
      return data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getInterviewResponse = createAsyncThunk(
  "dashboard/getInterviewResponse",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.getInterviewResponse(id);
      dispatch(dashboardLoader(false));
      return data;
    } catch (err) {
      dispatch(dashboardLoader(false));
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getPricingBundles = createAsyncThunk(
  "dashboard/getPricingBundles",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await dashboardRepo.getPricing();
      if (response.status === 200) {
        return response.data.productBundles[0];
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const stripeCheckoutAction = createAsyncThunk(
  "dashboard/stripeCheckout",
  async (id, { dispatch, rejectWithValue }) => {
    dispatch(dashboardLoader(true));
    try {
      const response = await dashboardRepo.stripeCheckout(id);
      if (response.status === 200) {
        dispatch(dashboardLoader(false));
        return response.data;
      }
    } catch (err) {
      dispatch(dashboardLoader(false));
      return rejectWithValue(err.message);
    }
  }
);
export const upgradeSubscription = createAsyncThunk(
  "dashboard/upgradeSubscription",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await dashboardRepo.updateSubscription(id);
      if (response.status === 200) {
        success(response.data.message);
        return response.data;
      }
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const setSubmitTest = createAsyncThunk(
  "dashboard/setSubmitTest",
  async (payload, { rejectWithValue }) => {
    try {
      const { obj } = await dashboardRepo.dashboardEvaluate(payload);
      if (obj.success) {
        return obj.data; // This data will be passed to the fulfilled action
      } else {
        throw new Error("Test submission failed");
      }
    } catch (err) {
      console.error("Error in submitting test:", err);
      return rejectWithValue(err.message); // Use rejectWithValue to pass custom error messages
    }
  }
);

export const getSimulationsAction = createAsyncThunk(
  "dashboard/getSimulations",
  async ({ payload, onSuccess, onError }, { dispatch, rejectWithValue }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.startSimulations(payload);
      if (data?.reason === "successful") {
        dispatch(dashboardLoader(false));
        onSuccess(data);
        return data; // Assuming you might want to store this data in Redux state
      }
      // else {
      //   throw new Error("Simulation start failed due to server reason");
      // }
    } catch (err) {
      dispatch(dashboardLoader(false));
      onError(err);
      // return rejectWithValue(err.message);
    }
  }
);
export const nextSimulationsAction = createAsyncThunk(
  "dashboard/nextSimulations",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.nextSimulations(payload);
      if (data?.reason === "successful") {
        dispatch(dashboardLoader(false));
        return data;
      } else {
        throw new Error("Failed to retrieve next simulations");
      }
    } catch (err) {
      dispatch(dashboardLoader(false));
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const updateSimulationsAction = createAsyncThunk(
  "dashboard/updateSimulations",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.updateSimulation(payload);
      if (data?.reason === "successful") {
        dispatch(dashboardLoader(false));
        return data;
      } else {
        throw new Error("Failed to update simulations");
      }
    } catch (err) {
      dispatch(dashboardLoader(false));
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getSimulatorResponse = createAsyncThunk(
  "dashboard/getSimulatorResponse",
  async (id, { dispatch, rejectWithValue }) => {
    dispatch(dashboardLoader(true));
    try {
      const { data } = await dashboardRepo.getSimultorResponse(id);
      if (data?.reason === "successful") {
        dispatch(dashboardLoader(false));
        return {
          feedbackList: data.feedbackList,
          averageScore: data.averageScore,
        }; // Returning the data you need, could be used directly in your state or components
      } else {
        throw new Error("Failed to fetch simulator response");
      }
    } catch (err) {
      dispatch(dashboardLoader(false));
      console.error("Error fetching simulator response:", err);
      error(err.response.data.detail);
      return rejectWithValue(err.message); // Properly handling and forwarding the error
    }
  }
);

export const evaluateTrainingSession = createAsyncThunk(
  "dashboard/evaluateTrainingSession",
  async (payload, thunkApi) => {
    try {
      await dashboardRepo.interviewTrainingIde(payload);
      const response = await thunkApi.dispatch(
        interviewFeedback(payload.interviewTrainingSessionId)
      );
      return response.payload;
    } catch (err) {
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const interviewFeedback = createAsyncThunk(
  "dashboard/interviewFeedback",
  async (id, thunkApi) => {
    try {
      const { data } = await dashboardRepo.interviewFeedback(id);
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

// Dashboard slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    roles: null,
    pricingBundle: null,
    isLoading: false,
    isRoleLoading: false,
  },
  reducers: {
    dashboardLoader: (state, action) => {
      state.isRoleLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSimulatorResponse.pending, (state) => {
        state.isRoleLoading = true;
        state.error = null;
      })
      .addCase(getSimulatorResponse.fulfilled, (state, action) => {
        state.isRoleLoading = false;
      })
      .addCase(getSimulatorResponse.rejected, (state, action) => {
        state.isRoleLoading = false;
        state.error = action.payload;
      })
      .addCase(nextSimulationsAction.pending, (state) => {
        state.isRoleLoading = true;
      })
      .addCase(nextSimulationsAction.fulfilled, (state, action) => {
        state.isRoleLoading = false;
      })
      .addCase(nextSimulationsAction.rejected, (state, action) => {
        state.isRoleLoading = false;
        state.error = action.payload;
      })
      .addCase(updateSimulationsAction.pending, (state) => {
        state.isRoleLoading = true;
      })
      .addCase(updateSimulationsAction.fulfilled, (state, action) => {
        state.isRoleLoading = false;
      })
      .addCase(updateSimulationsAction.rejected, (state, action) => {
        state.isRoleLoading = false;
        state.error = action.payload;
      })
      .addCase(setSubmitTest.pending, (state) => {
        state.isRoleLoading = true;
        state.error = null; // Clear previous errors when retrying
      })
      .addCase(setSubmitTest.fulfilled, (state, action) => {
        state.isRoleLoading = false;
        state.testData = action.payload; // Store the data received from a successful test submission
      })
      .addCase(setSubmitTest.rejected, (state, action) => {
        state.isRoleLoading = false;
        state.error = action.payload; // Store the error message if the test submission fails
      })
      .addCase(getSimulationsAction.pending, (state) => {
        state.isRoleLoading = true;
        state.error = null;
      })
      .addCase(getSimulationsAction.fulfilled, (state, action) => {
        // state.simulationData = action.payload;
        state.isRoleLoading = false;
      })
      .addCase(getSimulationsAction.rejected, (state, action) => {
        state.isRoleLoading = false;
        state.error = action.payload;
        // Error message is already shown via showError in the thunk
      })
      .addCase(stripeCheckoutAction.pending, (state) => {
        state.isRoleLoading = true;
      })
      .addCase(stripeCheckoutAction.fulfilled, (state, action) => {
        state.isRoleLoading = false;
      })
      .addCase(stripeCheckoutAction.rejected, (state, action) => {
        state.isRoleLoading = false;
      })
      .addCase(getJobRoles.fulfilled, (state, { payload }) => {
        state.roles = payload;
      })
      .addCase(getPricingBundles.fulfilled, (state, { payload }) => {
        state.pricingBundle = payload;
      })
      .addCase(evaluateTrainingSession.pending, (state) => {
        state.isRoleLoading = true;
      })
      .addCase(evaluateTrainingSession.fulfilled, (state) => {
        state.isRoleLoading = false;
      })
      .addCase(evaluateTrainingSession.rejected, (state) => {
        state.isRoleLoading = false;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.isRoleLoading = true;
        }
      )
      .addMatcher(
        (action) =>
          action.type.endsWith("/fulfilled") ||
          action.type.endsWith("/rejected"),
        (state) => {
          state.isRoleLoading = false;
        }
      );
  },
});

export const { dashboardLoader } = dashboardSlice.actions;
export default dashboardSlice.reducer;
