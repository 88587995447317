import React from "react";

const CardShimmer = () => {
  return (
    <div className="grid grid-cols-2 sx:grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-4 ">
      {Array.from({ length: 12 }).map((_, index) => (
        <div
          key={index}
          className="flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-sm"
        >
          <div className="w-16 h-16 rounded-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse"></div>
          <div className="w-3/4 h-4 mt-2 rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse"></div>
          <div className="w-1/2 h-4 mt-1 rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse"></div>
        </div>
      ))}
    </div>
  );
};

export default CardShimmer;
