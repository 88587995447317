import { useState, useEffect, useCallback } from "react";
import { ChevronDown, ChevronUp, Edit2, RotateCw } from "lucide-react";
import { useSelector } from "react-redux";
import { StepBoardingStateAtom } from "../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../../recoil/Loadings";
import TextShimmer from "../../../Components/Shimmers/TextShimmer";
import { error, success } from "../../../Utilities/toast";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import {
  generateAiQuestions,
  generateLiveSkillsGapAnalysis,
  getMyAiBoardingQuestions,
} from "../../../Redux/authenticate/authenticate.action";
import EditLiveSkillsReportModal from "./EditLiveSkillsReportModal";
const LiveSkillReport = () => {
  //REDUX
  const dispatch = useDispatch();
  const { user, liveSkillsAnalysis, onboardingAiData } = useSelector(
    (state) => state.authenticate
  );

  //RECOIL
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  //STATES
  const [liveSkillReport, setLiveSkillReport] = useState(liveSkillsAnalysis);
  const [displayText, setDisplayText] = useState(liveSkillsAnalysis);
  const [isTyping, setIsTyping] = useState(false);
  const [typeIndex, setTypeIndex] = useState(0);
  const [contentHeight, setContentHeight] = useState("300px");
  const [isExpanded, setIsExpanded] = useState(false);
  const AiBoardingPayload = {
    target_career: user?.target_career?.split("#")[0],
    previous_questions: [],
  };

  const updateLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.onBoarding.aiQuestionBoard = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const handleEdit = () => {
    if (
      onboardingAiData.length > 0 ||
      stepState.AIQuestionBoard.isAiQuestions.length > 0
    ) {
      setStepState(
        produce((draft) => {
          draft.isCareerGoalBoarding.isOpenAiModal = true;
        })
      );
      const initialAnswers = onboardingAiData.map((item) => ({
        question: item.question,
        answer: item.answer,
      }));
      setStepState(
        produce((draft) => {
          draft.AIQuestionBoard.isAiQuestions = initialAnswers;
          draft.AIQuestionBoard.activeStep = 1;
        })
      );
      updateLoadingState(false);
      return;
    }
    updateLoadingState(true);
    setStepState(
      produce((draft) => {
        draft.isCareerGoalBoarding.isOpenAiModal = true;
      })
    );
    dispatch(
      generateAiQuestions({
        AiBoardingPayload,
        onSuccess: (res) => {
          success("AI Questions Generated Successfully");
          updateLoadingState(false);
          setStepState(
            produce((draft) => {
              draft.AIQuestionBoard.isAiQuestions.push({
                question: res,
                answer: null,
              });
              draft.AIQuestionBoard.activeStep = 1;
            })
          );
        },
        onError: () => {
          updateLoadingState(false);
        },
      })
    );
  };

  const handleRetake = () => {
    if ([stepState.notSureKey, null, undefined].includes(user?.target_career)) {
      error("Please select a target career");
      return;
    }
    setIsExpanded(true);
    setLoadingState(
      produce((draft) => {
        draft.careerGoals.reLiveSkillsGapAssessment = true;
      })
    );
    dispatch(
      generateLiveSkillsGapAnalysis({
        onSuccess: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.reLiveSkillsGapAssessment = false;
            })
          );
          setDisplayText("");
          setIsTyping(true);
          setTypeIndex(0);
        },
        onError: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.reLiveSkillsGapAssessment = false;
            })
          );
          error("Something went wrong, please try again later");
        },
      })
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setContentHeight(isExpanded ? "300px" : "none");
  };

  useEffect(() => {
    if (isTyping && typeIndex < liveSkillReport.length) {
      const timer = setTimeout(() => {
        setDisplayText(liveSkillReport.slice(0, typeIndex + 1));
        setTypeIndex(typeIndex + 1);
      }, 30);

      return () => clearTimeout(timer);
    } else if (isTyping && typeIndex >= liveSkillReport.length) {
      setIsTyping(false);
      setTypeIndex(0);
    }
  }, [isTyping, typeIndex, liveSkillReport]);

  useEffect(() => {
    setLiveSkillReport(liveSkillsAnalysis);
    setDisplayText(liveSkillsAnalysis);
  }, [liveSkillsAnalysis]);

  useEffect(() => {
    dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
  }, []);

  return (
    <div className="w-full  bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-3 mx-auto max-w-5xl transition-all duration-300">
      {/* Header Section */}
      <div className="border-b border-gray-100">
        <div className="flex justify-between items-center p-6 flex-col sm:flex-row gap-4">
          <h2 className="text-xl font-semibold text-gray-800">
            Practical Skills Gap Report
          </h2>
          <div className="flex gap-3">
            <button
              onClick={handleEdit}
              disabled={
                user?.target_career === stepState.notSureKey ||
                loadingState.careerGoals.liveSkillsGapAssessment ||
                loadingState.careerGoals.reLiveSkillsGapAssessment
              }
              className="flex items-center gap-2 px-2 sm:px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors duration-200"
            >
              <Edit2 size={18} />
              <span className="whitespace-nowrap">Take Assessment</span>
            </button>
            <button
              onClick={handleRetake}
              className="flex items-center gap-2 px-2 sm:px-4 py-2 bg-emerald-50 text-emerald-600 rounded-lg hover:bg-emerald-100 transition-colors duration-200"
            >
              <RotateCw size={18} />
              <span className="whitespace-nowrap">Get Feedback</span>
            </button>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6">
        <div className="prose max-w-none min-h-[150px]">
          <p className="text-gray-600 leading-relaxed">
            {loadingState.careerGoals.liveSkillsGapAssessment ||
            loadingState.careerGoals.reLiveSkillsGapAssessment ? (
              <TextShimmer />
            ) : displayText ? (
              <div className="relative">
                <div
                  className="skillsgap max-w-none overflow-hidden transition-all duration-300"
                  style={{
                    maxHeight: contentHeight,
                    position: "relative",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: displayText,
                    }}
                  />
                  {!isExpanded && (
                    <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-50 to-transparent" />
                  )}
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    onClick={toggleExpand}
                    className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200 rounded-full bg-gray-100 hover:bg-gray-200"
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUp size={20} />
                        <span>Show Less</span>
                      </>
                    ) : (
                      <>
                        <ChevronDown size={20} />
                        <span>Show More</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <h2 className="text-gray-600 text-lg text-center min-h-[200px] capitalize flex justify-center items-center">
                No Recommend AI Careers suggestions found for this customer.
                Please generate analysis first.
              </h2>
            )}
          </p>
        </div>
      </div>
      <EditLiveSkillsReportModal updateLoadingState={updateLoadingState} />
    </div>
  );
};

export default LiveSkillReport;
