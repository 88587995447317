import React from "react";
import "./ReusableSection.css";

const ReusableSection = ({
  text1,
  text2,
  imageUrl,
  imagePosition = "left",
  noPadding = false,
  extraClass = "",
}) => {
  return (
    <div
      style={{ opacity: "1" }}
      className={`section-container ${
        noPadding ? "section-no-padding" : ""
      } ${extraClass}`}
    >
      {/* Render Image First If Position is "left" */}
      {imagePosition === "left" && (
        <div className="image-content-left zoom-container-alternate">
          <img
            src={imageUrl}
            alt="Section Image"
            className="section-image max-width-section6"
          />
        </div>
      )}
      {/* Text Content */}
      <div className="text-content">
        <h2 className="section-title">{text1}</h2>
        <p className="section-description">{text2}</p>
      </div>
      {/* Render Image Last If Position is "right" */}
      {imagePosition === "right" && (
        <div
          className="image-content zoom-container-alternate"
          style={{ overflow: "hidden" }}
        >
          <img
            src={imageUrl}
            alt="Section Image"
            className="section-image max-width-section6"
          />
        </div>
      )}
    </div>
  );
};

export default ReusableSection;
