import React from "react";

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm shadow-sm z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img
              src="/images/logo.png"
              alt="Nexa Logo"
              className="h-10 w-auto"
            />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
