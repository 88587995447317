import React from "react";
import ProfileForm from "../../../Pages/Onboarding/components/forms/ProfileForm";

const ProfileInfo = () => {
  return (
    <div className="py-3 w-full">
      <h2 className="font-semibold text-xl mb-3">
        Update your profile information
      </h2>
      <ProfileForm />
    </div>
  );
};

export default ProfileInfo;
