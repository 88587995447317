import mammoth from "mammoth";
import OpenAI from "openai";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React from "react";

const MILLISECONDS_PER_SECOND = 1000;

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const callOpenAIStream = async (endpoint, payload, onIncomingChunk) => {
  console.log("Final payload sent to OpenAI:", payload);

  const response = await fetch(`https://api.openai.com/v1/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error("Error response from OpenAI:", errorText);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  if (!response.body) {
    throw new Error("No body included in the response object");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let content = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value);
    const lines = chunk.split("\n");

    for (const line of lines) {
      const trimmedLine = line.trim();

      if (!trimmedLine) continue;

      if (trimmedLine.startsWith("data: ")) {
        const jsonString = trimmedLine.slice(6); // Remove "data: "
        if (jsonString === "[DONE]") break;

        try {
          const parsed = JSON.parse(jsonString);
          const messagePart = parsed.choices[0]?.delta?.content || "";
          content += messagePart;
          onIncomingChunk(messagePart);
        } catch (err) {
          console.error("Error parsing streaming chunk:", err);
        }
      }
    }
  }

  return content;
};

// IndexedDB helper functions
export const openDB = (dbName, version, upgradeCallback) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      upgradeCallback(db);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const addData = (db, storeName, data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put(data);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getData = (db, storeName, id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(id);

    request.onsuccess = (event) => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const useChatLogs = ({
  storeChats = true,
  chatDB = "chatDB",
  url = "/nexavoyce/stream-response",
}) => {
  const [messages, _setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [controller, setController] = React.useState(null);

  const submitPrompt = React.useCallback(
    async (prompt) => {
      if (loading) return;

      setLoading(true);

      const newMessage = {
        content: "",
        role: "assistant",
        meta: { loading: true },
      };
      const contextMessages = messages
        .filter(({ content, role }) => typeof content === "string" && role)
        .map(({ content, role }) => ({ content, role }));

      _setMessages((prev) => [
        ...prev,
        { content: prompt, role: "user" },
        newMessage,
      ]);

      try {
        const abortController = new AbortController();
        setController(abortController);

        const payload = {
          model: "gpt-4-turbo",
          messages: [...contextMessages, { role: "user", content: prompt }],
          stream: true,
        };

        console.log("Payload being sent:", payload);

        await callOpenAIStream("chat/completions", payload, (chunk) => {
          _setMessages((prev) =>
            prev.map((msg, i) => {
              if (i === prev.length - 1) {
                return { ...msg, content: msg.content + chunk };
              }
              return msg;
            })
          );
        });
      } catch (err) {
        console.error("Error during API call:", err);
      } finally {
        setLoading(false);
      }
    },
    [loading, messages]
  );

  const getFeedback = async (content) => {
    const params = {
      messages: [{ role: "user", content }],
      model: "gpt-3.5-turbo",
      // model: "gpt-4o",
      response_format: { type: "json_object" },
    };
    const chatCompletion = await openai.chat.completions.create(params);
    return chatCompletion?.choices[0]?.message?.content;
  };

  const resetChat = React.useCallback(() => {
    if (!loading) {
      _setMessages([]);
    }
  }, [loading]);

  const emptyMessages = () => {
    _setMessages([]);
  };

  const extractTextFromPDF = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const typedArray = new Uint8Array(event.target.result);
        const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
        let text = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          text += textContent.items.map((item) => item.str).join(" ") + "\n";
        }
        resolve(text);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const extractTextFromDocx = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        try {
          const result = await mammoth.extractRawText({
            arrayBuffer: event.target.result,
          });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  // 🔹 Upload Image to Cloudinary (Replace with your own service if needed)
  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "YOUR_UPLOAD_PRESET");
    formData.append("cloud_name", "YOUR_CLOUD_NAME");

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/YOUR_CLOUD_NAME/image/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    if (!data.secure_url) throw new Error("Image upload failed.");
    return data.secure_url;
  };

  const uploadFile = async (file, prompt) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    _setMessages((prev) => [
      ...prev,
      { content: `Uploading file: ${file.name}...`, role: "user" },
      {
        content: "Processing file...",
        role: "assistant",
        meta: { loading: true },
      },
    ]);

    try {
      let fileContent = "";
      let fileAnalysisMessage = "";

      if (file.type.startsWith("image/")) {
        // 🔹 Handle Image Upload
        const imageUrl = await uploadImageToCloudinary(file);
        fileAnalysisMessage = `Analyze this image: ${imageUrl}`;
      } else if (file.type === "text/plain") {
        fileContent = await file.text();
        fileAnalysisMessage = `Here is the extracted text:\n\n${fileContent}`;
      } else if (file.type === "application/pdf") {
        fileContent = await extractTextFromPDF(file);
        fileAnalysisMessage = `Here is the extracted text from the PDF:\n\n${fileContent}`;
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        fileContent = await extractTextFromDocx(file);
        fileAnalysisMessage = `Here is the extracted text from the DOCX file:\n\n${fileContent}`;
      } else {
        // 🔹 Upload non-image file to OpenAI
        const formData = new FormData();
        formData.append("file", file);
        formData.append("purpose", "assistants");

        const fileResponse = await fetch("https://api.openai.com/v1/files", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: formData,
        });

        if (!fileResponse.ok) {
          throw new Error(`File upload failed: ${fileResponse.statusText}`);
        }

        const fileData = await fileResponse.json();
        fileAnalysisMessage = `Analyze this file: ${fileData.id}`;
      }

      // 🔹 Ask ChatGPT to analyze the file or image
      const chatResponse = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: [
              { role: "user", content: prompt },
              { role: "user", content: fileAnalysisMessage },
            ],
            stream: false,
          }),
        }
      );

      if (!chatResponse.ok) {
        throw new Error(`Chat request failed: ${chatResponse.statusText}`);
      }

      const chatData = await chatResponse.json();
      const responseText =
        chatData.choices[0]?.message?.content || "No response received.";

      // Update messages to display the response
      _setMessages((prev) =>
        prev.map((msg) =>
          msg.meta?.loading
            ? { ...msg, content: responseText, meta: { loading: false } }
            : msg
        )
      );
    } catch (error) {
      console.error("Error in uploadFile:", error);
      _setMessages((prev) => [
        ...prev,
        { content: `Error: ${error.message}`, role: "assistant" },
      ]);
    }
  };

  return {
    messages,
    loading,
    submitPrompt,
    resetChat,
    emptyMessages,
    uploadFile,
    getFeedback,
  };
};

export default useChatLogs;
