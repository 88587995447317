import Repository from "./Repository";

const GET_ALL_NOTIFICATIONS = "/customer/notifications/all";
const GET_UNREAD_NOTIFICATIONS = "/customer/notifications/unread";
const GET_SINGLE_NOTIFICATION = "/customer/notifications/id/";
const UPDATE_NOTIFICATIONS = "/customer/notifications/read/";
const DELETE_NOTIFICATIONS = "/customer/notifications/delete";

const NotificationsRepository = {
  getAllNotifications() {
    return Repository.get(`${GET_ALL_NOTIFICATIONS}`);
  },
  getUnReadNotifications() {
    return Repository.get(`${GET_UNREAD_NOTIFICATIONS}`);
  },
  updateNotifications(id) {
    return Repository.put(`${UPDATE_NOTIFICATIONS}${id}`);
  },
  getSingleNotification(id) {
    return Repository.get(`${GET_SINGLE_NOTIFICATION}${id}`);
  },
  deleteNotifications(payload) {
    return Repository.delete(`${DELETE_NOTIFICATIONS}`, { data: payload });
  },
};

export default NotificationsRepository;
