// old
import React, { useContext, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userIcon from "../../assets/icons/userIcon.svg";
import { MobileSidebarContext } from "../../context/mobilesidemenuContext";
import { SidebarContext } from "../../context/sidebarContext";
import { navData } from "../../DummyData/NavData";
import { stopMachineSpeaks } from "../../Pages/SimulatorPro/Components/MachineSpeaks";
import { logoutUser } from "../../Redux/authenticate/authenticate.action";
import { removeToken } from "../../Redux/authenticate/authenticate.slicer";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";
import {
  navigationHomeLinks,
  navigationLinks,
} from "../dashboardComponents/data/data";
import "./Popupmenu.css";
import { pageRefresh } from "../../config/socketConfig";
import { updateActiveConversation } from "../../Redux/ChatSlice";
import organinazationIcon from "../../assets/icons/organization.png";
import { MdOutlineVerified } from "react-icons/md";
import { getThemeColors } from "../../Redux/GlobalStates";
import { defaultState, StepBoardingStateAtom } from "../../recoil/onBoarding";
import { useRecoilState } from "recoil";

const Header = ({ outside, sound = false, audio, setAudio, onOpenPopup }) => {
  // const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);
  const { pathname } = useLocation();
  const [totalUnreadChats, setTotalUnreadChats] = useState(0);
  const {
    file_url,
    interViewMode,
    interviewCompleted,
    sidebar_colorBtn,
    sidebar_colorBg,
  } = useSelector((state) => state.globalState);
  const { connectedChats, userId } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.authenticate);
  console.log("user: ", user);
  const [_, setStepState] = useRecoilState(StepBoardingStateAtom);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    activeLinkIdx,
    setActiveLinkIdx,
    isMobileSidebarActive,
    setIsMobileSidebarActive,
  } = useContext(MobileSidebarContext);
  const { isSidebarOpen } = useContext(SidebarContext);

  const sidebarClick = () => {
    setIsMobileSidebarActive(!isMobileSidebarActive);
  };

  const AudioController = () => {
    setAudio(!audio);
  };

  const handleLogout = () => {
    setStepState(defaultState);
    localStorage.removeItem("onboardingFormData");
    dispatch(logoutUser())
      .unwrap()
      .then((res) => {
        // success(res.detail);
        dispatch(removeToken());
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const location = useLocation();
  const serializedUid = localStorage.getItem("auth");

  const isUserHome =
    location.pathname === "/user/profile" ||
    location.pathname === "/user/analytics" ||
    location.pathname === "/career-goals" ||
    location.pathname === "/resume" ||
    location.pathname === "/news" ||
    location.pathname === "/chats" ||
    location.pathname === "/pricing" ||
    location.pathname === "/payments" ||
    location.pathname === "/simulator-pro-interview/:token" ||
    location.pathname === "/user2/settings";
  // console.log("isUserHome: ", isUserHome);

  const [menuItems, setMenuItems] = useState(navData);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const toggleMenu = (index) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index].isOpen = !updatedMenuItems[index].isOpen;
    setMenuItems(updatedMenuItems);
  };
  const updatedNavigationLinks = navigationLinks.map((link) => ({
    ...link,
    title: user?.organizationId
      ? link.title.replace(/Nexa\s*/g, "").trim()
      : link.title,
  }));

  const linksToDisplay = !isUserHome
    ? user?.organizationId != null
      ? updatedNavigationLinks.filter(
          (link) => !(link.id == 10 && !user?.accessToIQ)
        )
      : updatedNavigationLinks
    : navigationHomeLinks.filter(
        (link) => !((link.id === 6 || link.id === 7) && !user?.organizationId)
      );

  const formatName = (name) => {
    const nameParts = name.split(" ");

    if (nameParts.length > 2) {
      const abbreviatedName = `${nameParts[0][0]}.${nameParts
        .slice(1)
        .join(" ")}`;
      return abbreviatedName;
    }

    return name;
  };
  useEffect(() => {
    if (user && userId) {
      pageRefresh({
        token: localStorage.getItem("auth"),
        encrypted_user_id: userId?.user_id,
      });
    }
  }, [userId]);
  useEffect(() => {
    if (file_url) {
      let expiresMatch = file_url.match(/[?&]Expires=(\d+)/);
      // Check if the "Expires" parameter exists and is a valid number
      if (expiresMatch && expiresMatch[1]) {
        let expiresEpoch = parseInt(expiresMatch[1], 10);
        // Get the current Unix timestamp
        let currentEpoch = Math.floor(Date.now() / 1000);
        // Compare the two timestamps
        if (expiresEpoch <= currentEpoch) {
          console.log("logo expired");
          dispatch(getThemeColors());
        } else {
          console.log("Not expired yet");
        }
      }
    }
  }, [file_url]);

  useEffect(() => {
    if (connectedChats && userId) {
      let totalUnreadCount = 0;
      connectedChats.forEach((chat) => {
        chat.unread_messages?.forEach((unread) => {
          if (unread.user_id === userId.user_id) {
            totalUnreadCount += unread.unread_messages; // Sum unread messages
          }
        });
      });
      setTotalUnreadChats(totalUnreadCount);
    }
  }, [connectedChats, userId]);
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (interViewMode === "interview_start_developer" && !interviewCompleted)
    return;

  return (
    <div>
      <div
        className={`ic-overlay ${outside ? "Outside" : ""} ${
          isMobileSidebarActive ? "active" : ""
        }`}
        onClick={sidebarClick}
      ></div>
      <div
        className={`header transition-all ease-in ${outside ? "Outside" : ""} ${
          isSidebarOpen ? "!w-full" : ""
        }`}
      >
        <div className={"container header-content"}>
          <div className="ic-navbar-heads">
            <div className="navbar-logo">
              <Link to="/">
                <img
                  src={`${
                    file_url && file_url != "" ? file_url : "/images/logo.png"
                  }`}
                  alt="logo"
                />
              </Link>
            </div>
            {/* {!isUserHome ? ( */}
            <div
              className={`ic-navbar-nav ${
                isMobileSidebarActive ? "active tranparantBgResolve" : ""
              }`}
              style={{ backgroundColor: sidebar_colorBg }}
            >
              {user && (
                <ul
                  className={`mobile-side-bar mt-3 ${
                    isMobileSidebarActive ? "block" : "none"
                  }`}
                >
                  {linksToDisplay
                    .filter(
                      ({ title }) =>
                        !(
                          [
                            "My Profile",
                            "Career Goals",
                            "Analytics",
                            "Tasks",
                            "Chats",
                            "Skills Gap Assessment",
                          ].indexOf(title) > -1 &&
                          pathname.includes("user2/settings")
                        )
                    )
                    .filter(({ title }) => {
                      const excludedRoutes = [
                        "user/profile",
                        "user/analytics",
                        "career-goals",
                        "chats",
                        "tasks",
                      ];

                      return !(
                        ["Billing", "payments"].includes(title) &&
                        excludedRoutes.some((route) => pathname.includes(route))
                      );
                    })
                    .map(({ Image, ...navigationLink }) => {
                      return (
                        <li
                          key={navigationLink.id}
                          className={`${
                            navigationLink.visible !== "test" &&
                            navigationLink.visible !== undefined
                              ? "hidden"
                              : "block"
                          } ${
                            navigationLink.isIndividual === undefined
                              ? "block"
                              : navigationLink.isIndividual &&
                                !user.organizationId
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          <div
                            onClick={() => {
                              setActiveLinkIdx(navigationLink.id);
                              sidebarClick();
                            }}
                          >
                            <div
                              role="button"
                              onClick={() => {
                                navigate(navigationLink?.link);
                                dispatch(updateActiveConversation(null));
                              }}
                              // to={navigationLink?.link}
                              className={`mobile-nav-link p-2 flex my-2 rounded-md ${
                                navigationLink.id === activeLinkIdx
                                  ? `active text-white`
                                  : sidebar_colorBg == "#000000"
                                  ? "text-white"
                                  : ""
                              }`}
                              style={{
                                backgroundColor:
                                  navigationLink.id === activeLinkIdx
                                    ? sidebar_colorBtn
                                    : "",
                              }}
                            >
                              {!isUserHome ? (
                                typeof Image === "string" ? (
                                  <img
                                    src={Image}
                                    className={`mobile-nav-link-icon ${
                                      navigationLink.id === activeLinkIdx
                                        ? `active bg-transparent
                                        `
                                        : sidebar_colorBg == "#000000"
                                        ? "text-white"
                                        : ""
                                    }  ${
                                      sidebar_colorBg == "#000000"
                                        ? "icon_white"
                                        : ""
                                    }`}
                                    alt={navigationLink.title}
                                  />
                                ) : (
                                  <Image
                                    className={`mobile-nav-link-icon ${
                                      navigationLink.id === activeLinkIdx
                                        ? `active bg-transparent`
                                        : sidebar_colorBg == "#000000"
                                        ? "text-white"
                                        : ""
                                    } ${
                                      sidebar_colorBg == "#000000"
                                        ? "icon_whiteSvg"
                                        : ""
                                    }`}
                                  />
                                )
                              ) : (
                                <i
                                  className={`${Image} mobile-nav-link-icon ${
                                    navigationLink.id === activeLinkIdx
                                      ? `active bg-transparent`
                                      : null
                                  } `}
                                ></i>
                              )}
                              <span className="px-3">
                                {navigationLink.title}
                              </span>
                              {navigationLink.title === "Chats" &&
                                totalUnreadChats > 0 && (
                                  <span className="w-7 h-7  bg-blue-600 flex items-center  justify-center text-sm rounded-full text-white">
                                    {totalUnreadChats > 99
                                      ? "+99"
                                      : totalUnreadChats}
                                  </span>
                                )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              )}
              {user ? (
                <></>
              ) : (
                <ul className="ic-navbar-nav-ul">
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <button
                        type="button"
                        onClick={() => {
                          toggleMenu(index);
                          if (item.route) {
                            navigate(item.route);
                          }
                        }}
                      >
                        {item.title}
                        {item.icon && (
                          <div>
                            <img src={item.icon} alt="icon" />
                          </div>
                        )}
                      </button>
                      {(item.isOpen || innerWidth >= 1199) && item.subMenu && (
                        <ul className="ic-navbar-dropdown">
                          {item.subMenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <button
                                type="button"
                                onClick={() =>
                                  subItem?.route && navigate(subItem.route)
                                }
                              >
                                {subItem.icon && (
                                  <div>
                                    <img src={subItem.icon} alt="icon" />
                                  </div>
                                )}{" "}
                                {subItem.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                  <div className="flex flex-col gap-2 mt-2 sidebarButtons">
                    <div className="header-contact-sales-btn-wrapper w-100">
                      <Link to="/login" className="header-contact-sales-btn">
                        Sign In
                      </Link>
                    </div>
                    <div className="header-contact-sales-btn-wrapper w-100">
                      <Link
                        to="/start-interview"
                        className="header-contact-sales-btn"
                      >
                        Contact Sales
                      </Link>
                    </div>
                  </div>
                </ul>
              )}
            </div>
            <div className="ic-navbar-right">
              {!user ? (
                <div className="flex flex-row space-x-2 ">
                  <div className="header-contact-sales-btn-wrapper hidden md:flex">
                    <Link to="/login" className="header-contact-sales-btn ">
                      Sign In
                    </Link>
                  </div>
                  <div className="header-contact-sales-btn-wrapper">
                    <Link
                      to="/start-interview"
                      className="header-contact-sales-btn"
                    >
                      Contact Sales
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="ic-profile-dropdown">
                  <div className="ic-avatar-profile">
                    <img src={user?.picture || userIcon} alt="" />
                  </div>
                  <div className="ic-dropdown-menu">
                    <div className="ic-profiles-dropdown">
                      <div>
                        <img src={user?.picture || userIcon} alt="" />
                      </div>
                      <div>
                        <p className="flex gap-1 justify-center items-center">
                          {" "}
                          {formatName(user.name || user?.fullName)}{" "}
                          <span className="relative group">
                            {user?.is_badge && (
                              <>
                                <MdOutlineVerified size={17} color="#2c85ed" />
                                <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max mr-6 left-1/2 transform -translate-x-1/2">
                                  Nexa Certified Employer Ready
                                </div>
                              </>
                            )}
                          </span>
                        </p>
                        {user?.organizationId !== null && (
                          <div className="d-flex mt-2 items-center">
                            <span>
                              <img
                                src={organinazationIcon}
                                alt="Organization"
                                className="organizationDropdownImg"
                              />
                            </span>
                            &nbsp;<span>{user?.organizationName}</span>
                          </div>
                        )}
                        {user?.organizationId == null && <span>Admin</span>}
                      </div>
                    </div>
                    <ul className="ic-drop-menu curved-edges">
                      <li>
                        {/* {user.is_admin == 1 ? ( */}
                        <Link to="/home">
                          <i className="ri-dashboard-line mr-4"></i>
                          Dashboard
                        </Link>
                        {/* ) : null} */}
                        <Link to="/user/profile">
                          <i className="ri-user-line mr-4"></i>
                          My Profile
                        </Link>
                        <Link to="/user2/settings">
                          <i className="ri-dashboard-line mr-4"></i>
                          Settings
                        </Link>
                        <Link
                          onClick={() => {
                            handleLogout();
                            stopMachineSpeaksGlobal();
                            stopMachineSpeaks();
                          }}
                          as="button"
                        >
                          <i className="ri-logout-circle-r-line mr-4"></i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {location.pathname !== "/pricing" &&
                location.pathname !== "/simulator-pro-interview/:token" && (
                  <div
                    className={`humbarger-menu ${
                      isMobileSidebarActive ? "" : ""
                    }`}
                    onClick={sidebarClick}
                  >
                    {isMobileSidebarActive ? (
                      <IoClose
                        color="#000"
                        size={25}
                        style={{ margin: "auto" }}
                      />
                    ) : (
                      <>
                        <span className="bar1"></span>
                        <span className="bar2"></span>
                        <span className="bar3"></span>
                      </>
                    )}
                  </div>
                )}

              {sound ? (
                <div
                  className="sound-click me-2 me-lg-3"
                  onClick={AudioController}
                >
                  {audio ? (
                    <i className="ri-volume-up-line"></i>
                  ) : (
                    <i className="ri-volume-mute-line"></i>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
