import React, { act, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../Components/Resume/Select";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addWorkExperienceFun,
  aiGenerateKeyAchievement,
  aiGenerateWorkExperience,
  deleteResumeCompaney,
  getSingleResume,
  updateResumeSectionPosition,
  updateWorkExperienceFun,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import RichTextEditor from "../RichTextEditor";
import { createMarkup } from "../../Utilities/CreateMarkup";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { WiStars } from "react-icons/wi";

const WorkExperienceForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { workExperience } = useSelector((state) => state.ResumeBuilder);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  const [acheivementContent, setAcheivementContent] = useState("");
  const [defaultAcheivementContent, setAcheivementDefaultContent] =
    useState("");
  const [activelyWorking, setActivelyWorking] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generatingKey, setGeneratingKey] = useState(false);
  const [updateDocId, setUpdateDocId] = useState(null);
  const [experiencesState, setExperiencesState] = useState([]);
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAddWork = () => {
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, companeyId, roleId) => {
    setIsFormVisible(true);
    setUpdateDocId(companeyId);
    const foundWorkObj = workExperience.find((obj, idx) => idx === index);
    reset({
      companyPosition: foundWorkObj?.role_name,
      companyDescription: foundWorkObj?.description,
      companyName: foundWorkObj?.name,
      companyLocation: foundWorkObj?.location,
      checked: !foundWorkObj?.ended_at ? true : false,
      startDate: foundWorkObj?.started_at,
      endDate: foundWorkObj?.ended_at,
    });
    setSelectedType(foundWorkObj?.type);
    setSelectedStartDate(
      foundWorkObj?.started_at ? new Date(foundWorkObj?.started_at) : new Date()
    );
    setSelectedEndDate(
      foundWorkObj?.ended_at ? new Date(foundWorkObj?.ended_at) : new Date()
    );
    setDefaultContent(foundWorkObj?.description);
    setAcheivementDefaultContent(foundWorkObj?.key_acheivement);
  };

  const onCancel = () => {
    setUpdateDocId(null);
    setIsFormVisible(false);
    reset({
      companyName: "",
      companyDescription: "",
      companyPosition: "",
      companyLocation: "",
      checked: "",
      startDate: "",
      endDate: "",
    });
    setEditorContent("");
    setDefaultContent("");
    setAcheivementContent("");
    setAcheivementDefaultContent("");
    setSelectedType("");
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
  };

  const handleRegenerate = (title, name, description, type) => {
    const payload = {
      job_role: title,
      company_name: name,
      description: description,
    };
    console.log("payload: ", payload);
    if (type == "workExperience") {
      setGenerating(true);
      dispatch(
        aiGenerateWorkExperience({
          payload,
          onSuccess: (res) => {
            console.log("res: ", res.data);
            setDefaultContent(res?.data);
            setEditorContent(res?.data);
            success(res?.detail);
            // getExperienceInfo();
            setGenerating(false);
          },
          onError: () => {
            setGenerating(false);
          },
        })
      );
    } else {
      setGeneratingKey(true);
      dispatch(
        aiGenerateKeyAchievement({
          payload,
          onSuccess: (res) => {
            console.log("res: ", res.data);
            setAcheivementDefaultContent(res?.data);
            setAcheivementContent(res?.data);
            success(res?.detail);
            // getExperienceInfo();
            setGeneratingKey(false);
          },
          onError: () => {
            setGeneratingKey(false);
          },
        })
      );
    }
  };
  const onSubmit = (data) => {
    data.jobType = selectedType;
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;

    data.startDate = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.endDate = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    const payload = {
      company_data: {
        name: data?.companyName,
        description: editorContent !== "" ? editorContent : defaultContent,
        key_acheivement:
          acheivementContent !== ""
            ? acheivementContent
            : defaultAcheivementContent,
      },
      role_data: {
        name: data?.companyPosition,
        started_at: data?.startDate,
        ended_at: data?.checked == true ? null : data?.endDate,
        location: data?.companyLocation,
        type: data?.jobType,
      },
    };
    const nowData = new Date();
    const endedAtDate = new Date(payload?.role_data?.ended_at);
    if (payload?.role_data?.started_at >= payload?.role_data?.ended_at) {
      error("Start date cannot be greater than or equal to end date");
      return;
    }
    if (endedAtDate > nowData) {
      error("End date cannot be greater than current date");
      return;
    }
    if (updateDocId) {
      dispatch(
        updateWorkExperienceFun({
          payload,
          workExperienceId: updateDocId,
          onSuccess: (res) => {
            success(res);
            getExperienceInfo();
          },
        })
      );
    } else {
      dispatch(
        addWorkExperienceFun({
          payload,
          resumeId: id,
          onSuccess: (res) => {
            success(res);
            getExperienceInfo();
          },
        })
      );
    }
    onCancel();
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(experiencesState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...experiencesState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setExperiencesState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          workexperience: updatedSections,
        },
        onSuccess: () => {
          dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setExperiencesState(previousSections);
        },
      })
    );
  };
  const getExperienceInfo = () => {
    dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
  };
  useEffect(() => {
    if (workExperience?.length > 0) {
      const sortedExperiences = workExperience
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setExperiencesState(sortedExperiences);
    }
  }, [workExperience]);
  useEffect(() => {
    setActivelyWorking(getValues("checked"));
  }, [getValues("checked")]);
  return (
    <div className="mydetails rounded-xl px-0 pb-0">
      {isFormVisible && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-3 mb-3 border-bottom border-primary"
        >
          <div className="d-flex flex-column mb-1">
            <div className="form-group w-100">
              <label htmlFor="companyPosition">Job Title</label>
              <input
                type="text"
                id="companyPosition"
                className="form-control"
                {...register("companyPosition", {
                  required: "Company Position is required",
                })}
              />
              {errors.companyPosition && (
                <p className="text-danger">{errors.companyPosition.message}</p>
              )}
            </div>

            <div className="form-group w-100">
              <label htmlFor="key_acheivement">Key Achievement</label>
              <div className="relative">
                <div className="customized-editor">
                  <RichTextEditor
                    setEditorContent={setAcheivementContent}
                    defaultContent={defaultAcheivementContent}
                  />
                </div>
                <div className="absolute bottom-0 right-0 m-3">
                  <button
                    className="d-flex align-items-center justify-center bg-[#2b6be2] hover:bg-[#2458ba] text-white text-[14px] h-[40px] rounded-full px-2"
                    type="button"
                    // disabled={editorContent === ""}
                    onClick={() =>
                      handleRegenerate(
                        getValues("companyPosition"),
                        getValues("companyName"),
                        acheivementContent !== ""
                          ? acheivementContent
                          : defaultAcheivementContent,
                        "achievement"
                      )
                    }
                  >
                    {!generatingKey ? (
                      <>
                        <WiStars size={25} /> Nexa Enhance
                      </>
                    ) : (
                      <>
                        <WiStars size={25} />
                        Enhancing...
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group w-100">
              <label htmlFor="companyDescription">Job Description</label>
              <div className="relative">
                <div className="customized-editor">
                  <RichTextEditor
                    setEditorContent={setEditorContent}
                    defaultContent={defaultContent}
                  />
                </div>
                <div className="absolute bottom-0 right-0 m-3">
                  <button
                    className="d-flex align-items-center justify-center bg-[#2b6be2] hover:bg-[#2458ba] text-white text-[14px] h-[40px] rounded-full px-2"
                    type="button"
                    // disabled={editorContent === ""}
                    onClick={() =>
                      handleRegenerate(
                        getValues("companyPosition"),
                        getValues("companyName"),
                        getValues("companyDescription"),
                        "workExperience"
                        // editorContent !== "" ? editorContent : defaultContent
                      )
                    }
                  >
                    {!generating ? (
                      <>
                        <WiStars size={25} /> Nexa Enhance
                      </>
                    ) : (
                      <>
                        <WiStars size={25} />
                        Enhancing...
                      </>
                    )}
                  </button>
                </div>
              </div>

              {/* <textarea
                id="companyDescription"
                className="workcontrol"
                style={{ height: "150px" }}
                {...register("companyDescription", {
                  required: "Company Description is required",
                })}
              /> */}
              {errors.companyDescription && (
                <p className="text-danger">
                  {errors.companyDescription.message}
                </p>
              )}
            </div>
          </div>
          <div className="form-group w-100">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              className="form-control"
              placeholder="Facebook"
              {...register("companyName", {
                required: "Company Name is required",
              })}
            />
            {errors.companyName && (
              <p className="text-danger">{errors.companyName.message}</p>
            )}
          </div>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                className="form-control"
                {...register("companyLocation", {
                  required: "Company Location is required",
                })}
              />
              {errors.companyLocation && (
                <p className="text-danger">{errors.companyLocation.message}</p>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="companyType">Type</label>
              <SelectComponent
                onSelect={setSelectedType}
                selectedValue={selectedType}
              />
            </div>
          </div>
          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                selectedDate={selectedStartDate}
                onSelect={setSelectedStartDate}
              />
            </div>
            {activelyWorking === false && (
              <div className="form-group ml-0 md:ml-3 w-full md:w-50">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  selectedDate={selectedEndDate}
                  onSelect={setSelectedEndDate}
                  disabled={getValues("checked")}
                />
              </div>
            )}
          </div>
          <div className="d-flex mb-1">
            <div className="form-check ml-1">
              <input
                className="custom-check-input"
                type="checkbox"
                id="stillworking"
                {...register("checked", {
                  onChange: (e) => {
                    setActivelyWorking(e.target.checked);
                  },
                })}
              />
              <label className="form-check-label" htmlFor="stillworking">
                I am currently in this position
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-end py-2">
            <button
              type="button"
              className="cancel-btn mr-3"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              Save
            </button>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item border-0 pt-0 mx-2 mb-2">
          <button
            className="pl-2 professionalitem-button"
            onClick={handleAddWork}
          >
            <i className="ri-add-circle-line" style={{ fontSize: "20px" }}></i>
            <span className="ml-2" style={{ fontSize: "18px" }}>
              Add Work Experience
            </span>
          </button>
        </div>
      )}
      {workExperience &&
        workExperience?.map((experience, index) => (
          <div className="bg-white rounded-xl shadow-lg mb-3 mx-2">
            <div className="professionalsummary-companey p-3">
              <div className="d-flex flex-col">
                <h4 className="bold">{experience?.name}</h4>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    experience?.description
                  )}
                />
              </div>
              <div className="professionalitem-actions rounded-bl-lg rounded-tr-xl bg-[#eaecf0]">
                <button className="relative group professionalitem-button">
                  <FiChevronUp
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer ${
                      index == 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index - 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Up
                  </div>
                </button>
                <button className="relative group professionalitem-button">
                  <FiChevronDown
                    className={`text-gray-700 hover:text-blue-500 text-lg cursor-pointer  ${
                      index == workExperience.length - 1 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      handleOnDragEnd({
                        destination: index + 1,
                        source: index,
                      });
                    }}
                  />
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Move Down
                  </div>
                </button>
                <button
                  className="relative group professionalitem-button fs-[18px]"
                  onClick={() => handleEditingIndex(index, experience?.id)}
                >
                  <i className="ri-edit-line fs-[18px]"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Edit Work Experiece
                  </div>
                </button>
                <button
                  className="relative group professionalitem-button fs-[18px]"
                  onClick={() => {
                    dispatch(
                      deleteResumeCompaney({
                        payload: experience?.id,
                        onSuccess: (res) => {
                          success(res);
                          getExperienceInfo();
                        },
                      })
                    );
                  }}
                >
                  <i className="ri-delete-bin-line"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Delete Work Experiece
                  </div>
                </button>
              </div>
            </div>
            <div className="professionalsummary-item px-4 py-3">
              <div className="d-flex justify-between items-center w-full">
                <p className="mr-2 font-bold">{experience?.role_name}</p>
                <p>{experience?.location}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default WorkExperienceForm;
