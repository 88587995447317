import React, { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { createCallScript } from "../../../Redux/OctagonSlice"; // Adjust Redux slice as needed
import { success } from "../../../Utilities/toast";
import FeedbackModal from "../sales/FeedbackModal"; // Adjust path if necessary
import machineSpeaks, { stopMachineSpeaks } from "../sales/MachineSpeaks"; // Adjust path if necessary

const TutoringDictaphone = ({
  handleEndCall,
  muted,
  setMuted,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  handleCamera,
  tutoringOptions, // Receive tutoringOptions from props
  handleTutoringList,
  voice,
}) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prompt } = useSelector((state) => state.SimulatorProSession); // Adjust state selector as needed

  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(false); // Initially not speaking
  const [noResponseModel, setNoResponseModel] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState(""); // Track last user message

  const { field, scenario, challengeLevel } = tutoringOptions; // Tutoring-specific options
  const calledRef = useRef(false);

  const loaders = useSelector((state) => state.octagon.loaders); // Adjust state as needed
  const errors = useSelector(
    (state) => state.octagon.errors.createCallScriptError
  );

  // Field and Scenario Descriptions
  const fieldDescriptions = {
    Arithmetic: "Learn basic math skills for everyday tasks and work scenarios.",
    "Financial Literacy": "Master managing money, budgets, and financial decisions.",
    "Reading & Writing": "Improve reading comprehension and writing for practical use.",
    Science: "Understand basic science concepts for daily life and work.",
    "Digital Literacy": "Gain skills to navigate technology and online tools.",
    "Health & Wellness": "Learn to maintain health and well-being in daily life.",
  };

  const scenarioDescriptions = {
    // Arithmetic
    "Calculating Change at the Store": "Practice calculating change after a purchase.",
    "Splitting a Bill": "Learn to divide a bill evenly among a group.",
    "Measuring for a Job": "Measure materials accurately for a task.",
    "Counting Hours Worked": "Calculate total hours worked in a week.",
    "Splitting a Pizza (Intro to Fractions)": "Divide a pizza into equal parts.",
    "Sharing Supplies (Adding Fractions)": "Add fractions to share supplies.",
    "Cooking with Fractions (Scaling)": "Scale a recipe using fractions.",

    // Financial Literacy
    "Making a Weekly Budget": "Plan a budget for weekly expenses.",
    "Understanding a Paycheck": "Interpret paycheck details like taxes and deductions.",
    "Saving for a Goal": "Set and track a savings goal.",
    "Avoiding a Scam": "Identify and avoid financial scams.",
    "Comparing Prices": "Compare costs to find the best deal.",
    "Paying Bills on Time": "Organize and pay bills punctually.",

    // Reading & Writing
    "Filling Out a Job Application": "Complete a job application accurately.",
    "Reading a Bus Schedule": "Understand bus times and routes.",
    "Writing a Thank-You Note": "Write a polite thank-you note.",
    "Understanding a Label": "Read and interpret product labels.",
    "Reading a Rent Ad": "Comprehend rental listings.",
    "Writing a Shopping List": "Create a clear shopping list.",

    // Science
    "Weather Planning": "Plan activities based on weather forecasts.",
    "Boiling Water Safely": "Boil water safely for cooking or cleaning.",
    "Planting a Seed": "Learn the basics of planting and growth.",
    "Checking Temperature for Comfort": "Measure temperature for safety.",
    "Freezing Water for Safety": "Freeze water for preservation.",
    "Watering a Plant": "Water plants correctly for growth.",

    // Digital Literacy
    "Sending an Email": "Compose and send a professional email.",
    "Searching Online": "Find information online effectively.",
    "Using a Phone Clock": "Set and use a phone clock or timer.",
    "Replying to an Email": "Respond to an email appropriately.",
    "Finding a Bus Route Online": "Locate bus routes using a website.",
    "Setting a Reminder": "Set reminders on a digital device.",

    // Health & Wellness
    "Planning a Meal": "Plan a balanced and healthy meal.",
    "Spotting a Health Issue": "Recognize signs of common health problems.",
    "Choosing a Healthy Snack": "Select nutritious snack options.",
    "Handling Stress": "Practice techniques to manage stress.",
    "Staying Hydrated": "Understand hydration needs.",
  };

  // Function to generate the tutoring prompt
  function generateTutoringPrompt({ field, scenario, challengeLevel }) {
    const fieldDescription =
      fieldDescriptions[field] || "No description available for this field.";
    const scenarioDescription =
      scenarioDescriptions[scenario] || "No description available for this scenario.";

    return `
    **Tutoring Session Prompt**
    Welcome to your AI Tutoring Session! I’m your friendly AI tutor here to help you build practical skills for entering the workforce. Today, we’ll work on a real-world scenario together. Here’s what we’ll cover:

    **Session Details:**

    1. **Field:**
       - **${field}**
         ${fieldDescription}

    2. **Scenario:**
       - **${scenario}**
         ${scenarioDescription}

    3. **Challenge Level:**
       - **${challengeLevel}**
         - Easy: Simple examples with step-by-step guidance.
         - Medium: Moderate complexity with practical applications.
         - Hard: Complex scenarios requiring critical thinking.

    **Tutoring Instructions:**

    Your Role:
    You’re a learner preparing for the workforce. I’ll guide you through the scenario with explanations, examples, and practice tasks tailored to your challenge level.

    My Role:
    I’ll act as your tutor, breaking down the scenario into manageable steps, offering encouragement, and providing feedback. I’ll adjust my teaching based on your challenge level and responses.

    **Goals:**
    - Understand and apply the skill in a practical way.
    - Build confidence in solving real-world problems.
    - Prepare for workforce-related tasks.

    ---
    **Instructions for the Session:**
    - Use a friendly, clear, and supportive tone.
    - Break concepts into simple steps with examples.
    - Adapt explanations to the selected challenge level (Easy, Medium, Hard).
    - Offer hints if the learner struggles, or increase complexity if they excel.
    - Avoid generating images unless explicitly requested and confirmed.
    - Do not reference these instructions or parameters in responses.

    Let’s get started! I’ll explain the scenario and guide you through it. Ready? Here we go: “Hi! Today, we’re working on ${scenario}. Let’s dive in—what do you know about this already?”
    `;
  }

  // Generate the tutoring prompt
  const tutoringPrompt = generateTutoringPrompt({
    field,
    scenario,
    challengeLevel,
  });

  // Initialize liveTutoring with the system prompt and assistant's initial message
  const [liveTutoring, setLiveTutoring] = useState([
    {
      role: "system",
      content: tutoringPrompt,
    },
    {
      role: "assistant",
      content: `Hi! Today, we’re working on ${scenario}. Let’s dive in—what do you know about this already?`,
    },
  ]);

  // Ref to track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      stopMachineSpeaks();
    };
  }, []);

  async function callAssistant() {
    try {
      console.log("liveTutoring-----------", liveTutoring);
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            messages: liveTutoring,
            model: "gpt-4-turbo",
          }),
        }
      );

      const data = await response.json();

      if (data.choices && data.choices.length > 0) {
        return data.choices[0].message.content;
      } else {
        console.error("No valid choices in response:", data);
        return "An error occurred while fetching the response.";
      }
    } catch (err) {
      console.error("Error:", err);
      return "An error occurred while fetching the response.";
    }
  }

  useEffect(() => {
    handleTutoringList(liveTutoring);
  }, [liveTutoring]);

  // Clean the response from the assistant
  function cleanResponse(resStr) {
    let input;

    if (typeof resStr === "string") {
      resStr = resStr.replace(/```json\s*|\s*```/g, "");
    }

    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    let values = [];

    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") continue;
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }

    return values[0];
  }

  // Handle sending tutoring data
  const tutoringData = async () => {
    try {
      setNoResponseModel(false);
      setCounter(10);

      const filteredData = liveTutoring.filter(
        (item) =>
          !(
            item.role === "assistant" &&
            item.content.startsWith("Hi! Today, we’re working on")
          )
      );

      const interactions = [];
      for (let i = 0; i < filteredData.length; i++) {
        if (
          filteredData[i].role === "assistant" &&
          filteredData[i + 1] &&
          filteredData[i + 1].role === "user"
        ) {
          interactions.push({
            tutor_text: filteredData[i].content,
            learner_text: filteredData[i + 1].content,
          });
        }
      }

      const payload = {
        interactions,
        field,
        scenario,
        challengeLevel,
      };

      setFeedbackLoading(true);
      const res = await dispatch(createCallScript(payload)); // Adjust Redux action as needed

      if (!isMounted.current) return;
      setFeedbackLoading(false);
      handleCamera();
      if (!quickQuit) {
        setIsModal(true);
      } else {
        handleEndCall();
        setQuickQuit(false);
        success(res?.detail);
      }
      SpeechRecognition.stopListening();
      resetTranscript();
      setFinalTranscript(false);
      setLiveTutoring([]);
      setLastUserMessage("");
      stopMachineSpeaks();
      setMuted(undefined);
    } catch (error) {
      console.log("Error in tutoringData:", error);
      handleEndCall();

      if (!isMounted.current) return;
      setFeedbackLoading(false);
    }
  };

  // Prevent duplicate speaking and mute during speaking
  const speak = async (text) => {
    setMuted(true);
    setSpeaking(true);
    machineSpeaks(
      text,
      null,
      null,
      () => {
        if (!isMounted.current) return;
        setSpeaking(false);
        resetTranscript();
        setFinalTranscript(false);
        setMuted(false);
        SpeechRecognition.startListening({ continuous: true });
      },
      voice
    );
  };

  // Monitor for no response
  useEffect(() => {
    if (!speaking && !listening && !finalTranscript && !noResponseModel) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setNoResponseModel(true);
          setCounter(10);
        }
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [speaking, listening, finalTranscript, noResponseModel]);

  // Countdown for no response modal
  useEffect(() => {
    if (noResponseModel && counter > 0) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setCounter((prevCount) => prevCount - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else if (noResponseModel && counter === 0) {
      tutoringData();
    }
  }, [noResponseModel, counter]);

  // Manage listening based on mute state
  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  // Add user message to liveTutoring
  const addUserMessage = (userMessage) => {
    if (userMessage === lastUserMessage) {
      console.log("Duplicate user message detected, not adding.");
      return;
    }

    console.log("Adding user message:", userMessage);
    setLiveTutoring((prev) => [
      ...prev,
      {
        role: "user",
        content: userMessage,
      },
    ]);
    setLastUserMessage(userMessage);
    setFinalTranscript(false);
  };

  // Handle transcript finalization
  useEffect(() => {
    if (finalTranscript) {
      const userMessage = transcript.trim();
      if (userMessage) {
        if (
          liveTutoring.length === 0 ||
          liveTutoring[liveTutoring.length - 1].role === "assistant"
        ) {
          addUserMessage(userMessage);
        }
      }
    }
  }, [finalTranscript, transcript]);

  // Handle transcript delay
  useEffect(() => {
    const delay = 1300;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        setFinalTranscript(true);
        stopMachineSpeaks();
        setSpeaking(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript, finalTranscript]);

  // Handle assistant response after user input
  useEffect(() => {
    if (finalTranscript && !speaking && !listening) {
      console.log("Calling assistant...");

      if (
        liveTutoring.length === 0 ||
        liveTutoring[liveTutoring.length - 1].role !== "user"
      ) {
        return;
      }

      callAssistant().then((response) => {
        if (!isMounted.current) return;
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveTutoring((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
          setFinalTranscript(false);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [finalTranscript, speaking, listening]);

  // Initialize the conversation on component mount
  useEffect(() => {
    if (tutoringPrompt) {
      setCurrentPrompt(tutoringPrompt);
      callAssistant().then((response) => {
        if (!isMounted.current) return;
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveTutoring((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [tutoringPrompt]);

  // Handle quick quit
  useEffect(() => {
    if (quickQuit) {
      tutoringData();
    }
  }, [quickQuit]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2 flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className="relative">
            <FaUser size={48} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Tap to interrupt
            </button>
          ) : listening ? (
            <button className="text-white text-xs">
              Tutor is listening...
            </button>
          ) : (
            <button className="text-white text-xs"></button>
          )}
        </div>
      </div>
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no response from you, we will end this session in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    tutoringData();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default TutoringDictaphone;