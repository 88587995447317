import React from "react";

const TextShimmer = () => {
  return (
    <div className="flex gap-2 flex-col w-full">
      <div className="w-4/4 h-6 rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-[length:200%_100%] animate-pulse animate-shimmer "></div>
      <div className="w-3/4 h-6 rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-[length:200%_100%] animate-pulse animate-shimmer"></div>
      <div className="w-2/4 h-6 rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-[length:200%_100%] animate-pulse animate-shimmer"></div>
    </div>
  );
};

export default TextShimmer;
