import React from "react";
import "./Section2.css";

const CheckmarkItem = ({ text }) => {
  return (
    <div className="checkmark-item">
      <img
        src="/images/icons/bleue-checkmark.svg"
        alt="Checkmark"
        className="checkmark-icon"
      />
      <p className="checkmark-text">{text}</p>
    </div>
  );
};

const Section2 = () => {
  const items = [
    "Manual career services are labor-intensive and outdated.",
    "Tracking outcomes for graduates is challenging and inconsistent.",
    "Limited resources to provide personalized career guidance.",
    "Lack of visibility into job-matching success.",
    "Inefficient workflows increase operational strain on staff.",
  ];

  return (
    <div className="default-container-section2">
      <div className="text-wrapper-section2">
        <div className="narrow-text">
          <p className="text-default-blue-bold-section2 text-center mb-8 FiraFontFamily bold-family">
            The Hiring{"  "}
            <span className="text-default-blue-bold-section2 highlighted-word FiraFontFamily bold-family">
              PROBLEM
            </span>{" "}
            Is Clear.
          </p>
        </div>
        <div className="wide-text">
          <p
            className="text-default-black-section2 text-center FiraFontFamily"
            style={{ color: "#000000" }}
          >
            Hiring today is costly, time-consuming, and often inefficient.
            Sorting through piles of resumes, assessing unqualified candidates,
            and finding the right fit can take weeks—sometimes months.
          </p>
        </div>
      </div>
      <div
        style={{ alignItems: "center",justifyContent:"center" }}
        className="content-container-section2 zoom-container"
      >
        <div className="checkmark-section" style={{maxWidth:"1000px"}}>
          <img
            src="/images/cccc.png"
            alt="BootCamp Section 2"
            className="section-image"
          />
        </div>
        <div className="image-container">
          <div className="wide-text">
            <p
              style={{ fontSize: "35px" }}
              className="text-default-blue-bold-section2 text-center mb-8 FiraFontFamily bold-family"
            >
              The Hiring Problem Is Clear.
            </p>
            <p className="text-default-black-section2 text-center FiraFontFamily">
              Hiring today is expensive, time-intensive, and often inefficient.
              Sifting through countless resumes, screening unqualified
              candidates, and identifying the perfect fit can take weeks—or even
              months.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ alignItems: "center",justifyContent:"center" }}
        className="content-container-section2 zoom-container"
      >
        <div className="image-container">
          <div className="wide-text">
            <p
              style={{ fontSize: "35px" }}
              className="text-default-blue-bold-section2 text-center mb-8 FiraFontFamily bold-family"
            >
              Meet Nexa RU: Revolutionizing the Recruitment Process.
            </p>
            <p className="text-default-black-section2 text-center FiraFontFamily">
              Nexa RU streamlines hiring by combining AI-powered talent
              sourcing, pre-vetted candidate pools, and deep behavioral
              insights. Reduce hiring time by 75% and ensure every hire fits
              perfectly.
            </p>
          </div>
        </div>
        <div className="checkmark-section" style={{maxWidth:"1000px"}}>
          <img
            src="/images/Group1321315101.png"
            alt="BootCamp Section 2"
            className="section-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2;
