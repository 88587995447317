import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";
import axios from "axios";
const iqRepo = RepositoryFactory.get("nexaIq");

// Async actions
export const getStates = createAsyncThunk(
  "nexaIq/getStates",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: "canada",
        }
      );

      return response.data.data.states;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getCountryStates = createAsyncThunk(
  "nexaIq/getCountryStates",
  async ({ state, onSuccess }, { rejectWithValue }) => {
    try {
      if (state == null) return;
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/state/cities",
        {
          country: "canada",
          state: state,
        }
      );

      return response.data.data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getJobListings = createAsyncThunk(
  "nexaIq/getJobListings",
  async (
    { payload, page, jobApplied, nexa_jobs, onSuccess, onError },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await iqRepo.getJobListings(
        payload,
        page,
        jobApplied,
        nexa_jobs
      );
      if (data?.status_code === 200) {
        onSuccess(data?.data?.iq_jobs);
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      onError();
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const scrapJobs = createAsyncThunk(
  "nexaIq/scrapJobs",
  async ({ payload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.scrapJobs(payload);
      if (data?.data) {
        onSuccess();
        return data;
      } else {
        const { data } = await iqRepo.scrapJobs(payload);

        if (data?.status_code === 400) {
          onError();
          error(data?.detail);
          return data;
        } else {
          onSuccess();
          return data;
        }
      }
      throw new Error("Fetch failed");
    } catch (err) {
      onError();
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getSingleJob = createAsyncThunk(
  "nexaIq/getSingleJob",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.getSingleJob(id);
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const resumeGrading = createAsyncThunk(
  "nexaIq/resumeGrading",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.resumeGrading(payload);
      onSuccess(data);
      return data;
    } catch (err) {
      onSuccess();
      error(err.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

//// Resume Grading
export const applyOnJob = createAsyncThunk(
  "nexaIq/applyOnJob",
  async ({ formData, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.applyJob(formData);
      if (data?.status_code === 201) {
        onSuccess("success");
        success(data?.detail);
        return data;
      }
      throw new Error("apply failed");
    } catch (err) {
      onError();
      error(err.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

//// Applied Job Count
export const appliedJobCount = createAsyncThunk(
  "nexaIq/appliedJobCount",
  async ({ job_id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.appliedJobCount(job_id);
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("apply failed");
    } catch (err) {
      onError();
      error(err.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
//Recommendation on Applying
export const recommendationOnApply = createAsyncThunk(
  "profile/recommendationOnApply",
  async (
    { title, company_name, experience, onComplete },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await iqRepo.recommendationOnApply(
        title,
        company_name,
        experience
      );
      if (data?.status_code === 200) {
        onComplete(data.data);
        return data.data;
      } else {
        throw new Error("Failed to fetch training sessions data");
      }
    } catch (err) {
      console.error("Error while recommending job", err);
      onComplete();
      return rejectWithValue(err);
    }
  }
);

//// Add New Job Title
export const addNewJobTitle = createAsyncThunk(
  "nexaIq/addNewJobTitle",
  async ({ name, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.addNewJobTitle(name);
      if (data?.data) {
        success(data?.detail);
        onSuccess();
        return data;
      } else {
        error(data?.detail);
      }
      throw new Error("apply failed");
    } catch (err) {
      onError();
      error(err.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

//// Delete Job Title
export const deleteMyJobRole = createAsyncThunk(
  "nexaIq/deleteMyJobRole",
  async ({ id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.deleteMyJobRole(id);
      if (data?.data) {
        success(data?.detail);
        onSuccess();
        return data;
      } else {
        onError();
        error(data?.detail);
      }
      throw new Error("apply failed");
    } catch (err) {
      onError();
      error(err.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const IqQuickApplyRole = createAsyncThunk(
  "nexaIq/IqQuickApplyRole",
  async ({ role }, { rejectWithValue }) => {
    return role;
  }
);

const NexaIqSlice = createSlice({
  name: "nexaIq",
  initialState: {
    loading: false,
    countryStates: [],
    stateCities: [],
    jobs: [],
    getSingleJobData: null,
    applyRecommendation: null,
    isQuickApply: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(IqQuickApplyRole.fulfilled, (state, action) => {
        state.isQuickApply = action.payload;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.countryStates = action.payload;
      })
      .addCase(appliedJobCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(appliedJobCount.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(appliedJobCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCountryStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountryStates.fulfilled, (state, action) => {
        state.loading = false;
        state.stateCities = action.payload;
      })
      .addCase(getCountryStates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getJobListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobListings.fulfilled, (state, action) => {
        state.reason = action.payload?.reason;
        state.jobs_found = action.payload?.data?.jobs_found;
        state.currentPage = action?.payload?.data?.page;
        state.totalPages = action?.payload?.data?.total_pages;
        state.jobs = [
          ...(action?.payload?.data?.smart_screen_jobs || []).map((job) => ({
            ...job,
            source: "smart_screen_jobs",
          })),
          ...(action?.payload?.data?.iq_jobs || []).map((job) => ({
            ...job,
            source: "iq_jobs",
          })),
        ];
        state.loading = false;
      })
      .addCase(getJobListings.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSingleJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleJob.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleJobData = action.payload.data;
      })
      .addCase(getSingleJob.rejected, (state) => {
        state.loading = false;
      })
      .addCase(recommendationOnApply.fulfilled, (state, action) => {
        state.applyRecommendation = action.payload;
      });
  },
});

export default NexaIqSlice.reducer;
