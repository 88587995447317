import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const SkillsPractice = RepositoryFactory.get("skillsPractice");

export const getAllSectors = createAsyncThunk(
  "skillsPractice/getAllSectors",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SkillsPractice.getAllSectors();
      return data?.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSkillPracticeQuestions = createAsyncThunk(
  "skillsPractice/getSkillPracticeQuestions",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await SkillsPractice.getQuestions(payload);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const saveSkillPracticeQuestionResponse = createAsyncThunk(
  "skillsPractice/saveSkillPracticeQuestionResponse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SkillsPractice.saveQuestionResponse(payload);
      return response;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getSkillPracticeInterviewFeedback = createAsyncThunk(
  "dashboard/getSkillPracticeInterviewFeedback",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await SkillsPractice.getFeedback(id);
      return data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

const SkillsPracticeSlice = createSlice({
  name: "skillsPractice",
  initialState: {
    sectors: null,
    questions: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSectors.fulfilled, (state, action) => {
      state.sectors = action.payload;
    });
    builder.addCase(getSkillPracticeQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
  },
});

export default SkillsPracticeSlice.reducer;
