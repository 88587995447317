import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri"; // Import an icon for the exit button
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import DialerAudio from "../../assets/sound/dialer.mp3";
import Header from "../../Components/header/index";
import Modal from "../../Components/modal";
import { error } from "../../Utilities/toast";
import Lina from "./images/lina.png";
import Luka from "./images/luka.png";
import Robert from "./images/robert.png";
import Stacy from "./images/stacy.png";
import SalesMeetScreen from "./sales/salesmeet";
import "./spin.style.css";

function SalesOptions() {
  const [showMeet, setShowMeet] = useState(false);
  const [callType, setCallType] = useState("");
  const [product, setProduct] = useState("");
  const [play, { stop }] = useSound(DialerAudio);
  const [difficulty, setDifficulty] = useState("");
  const [industry, setIndustry] = useState("");
  const [situation, setSituation] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [options, setOptions] = useState({});
  const [personna, setPersonna] = useState("");
  const [audioStream, setAudioStream] = useState(null);
  const [videoStream, setVideoStream] = useState(null);
  const [muted, setMuted] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [respt, setRespt] = useState([]);
  const [summary, setSummary] = useState("");
  const [summary1, setSummary1] = useState("");
  const [summary2, setSummary2] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(1);
  const [selectedVoice, setSelectedVoice] = useState("nova");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to handle navigation back to /octagon
  const handleExit = () => {
    navigate("/octagon");
  };

  const handleGenerate = () => {
    const generatedOptions = {
      callType,
      product,
      difficulty,
      industry,
      situation,
      personna,
    };
    setOptions(generatedOptions);

    if (!showMeet) {
      play();
      setTimeout(() => {
        stop();
        setShowMeet(true);
      }, 2500);
    }
    setShowMeet(true);
    console.log(generatedOptions);
  };

  const handleEndCall = () => {
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }

    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }

    setIsModal(true);
    fetchData();
    fetchData1();
    fetchData2();

    setOptions({});
    setShowMeet(false);
    setCallType("");
    setProduct("");
    setDifficulty("");
    setIndustry("");
    setSituation("");
    setProgress(0);
    setJobDescription("");
    setContactDetails("");
    setMuted(false);
  };

  const handleMute = () => {
    if (audioStream) {
      audioStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setMuted(!muted);
    }
  };

  const saveFeedback = async (feedback) => {
    try {
      const conversationsHTML = respt
        .map((message) =>
          message.role !== "system"
            ? `<h5>${message.role}:</h5> <p>${message.content}</p>`
            : ""
        )
        .join("");

      const feedbackData = {
        conversations: `<div class="row mx-3 my-3">${conversationsHTML}</div>`,
        feedback: feedback,
        role_id: 2,
      };
      const token = localStorage.getItem("auth");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/octagon/simulation/feedback`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedbackData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save feedback");
      }

      const data = await response.json();
      console.log("Feedback saved successfully:", data);
      return data;
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleCamera = () => {
    if (videoStream) {
      videoStream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    }
  };

  useEffect(() => {
    let filledFields = 0;
    if (callType) filledFields++;
    if (product) filledFields++;
    if (difficulty) filledFields++;
    if (industry) filledFields++;
    if (situation) filledFields++;
    setProgress((filledFields / 5) * 100);
  }, [callType, product, difficulty, industry, situation]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300);
    }
  }, [progress]);

  useEffect(() => {
    if (showMeet) {
      const initiateStreams = async () => {
        try {
          const constraintsAudio = { audio: true, video: false };
          const constraintsVideo = { video: true, audio: false };

          const audio = await navigator.mediaDevices.getUserMedia(
            constraintsAudio
          );
          setAudioStream(audio);

          const video = await navigator.mediaDevices.getUserMedia(
            constraintsVideo
          );
          setVideoStream(video);
        } catch (err) {
          console.error("Error accessing media devices.", err);
          error("Unable to access camera or microphone.");
          setShowMeet(false);
          if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
            setAudioStream(null);
          }
          if (videoStream) {
            videoStream.getTracks().forEach((track) => track.stop());
            setVideoStream(null);
          }
        }
      };

      initiateStreams();
    }

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [showMeet]);

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const header = (
    <div className="mockInterview-modal-header">
      <button className="modal-close" onClick={() => handleCloseModal()}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );

  const fetchData = async () => {
    console.log(respt);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: respt,
            max_tokens: 150,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setSummary(data.choices[0].message.content);
    } catch (error) {
      console.error("Error fetching summary:", error);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: [
              {
                role: "system",
                content: `
                  You are an evaluator. Analyze the conversation and provide a structured evaluation in JSON format.
                  Ensure all keys are included and the JSON is valid. Use the following structure:
                  {
                    "clarityScore": [number],
                    "clarityJustification": [text],
                    "professionalismScore": [number],
                    "professionalismJustification": [text],
                    "empathyAndRapportScore": [number],
                    "empathyAndRapportJustification": [text],
                    "needsIdentificationScore": [number],
                    "needsIdentificationJustification": [text],
                    "valuePropositionScore": [number],
                    "valuePropositionJustification": [text],
                    "overcomingObjectionsScore": [number],
                    "overcomingObjectionsJustification": [text],
                    "closingSkillsScore": [number],
                    "closingSkillsJustification": [text]
                  }
                `,
              },
              {
                role: "user",
                content: `Conversation: ${JSON.stringify(respt)}`,
              },
            ],
            max_tokens: 600,
            temperature: 0.7,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("response---------------", data);
      let evaluationContent = data.choices[0].message.content.trim();

      if (!evaluationContent.endsWith("}")) {
        console.warn("Response seems incomplete, truncation detected.");
        throw new Error("Response is incomplete or improperly formatted.");
      }

      let evaluation;
      try {
        evaluation = JSON.parse(evaluationContent);
      } catch (jsonError) {
        console.error("Error parsing JSON:", jsonError);
        throw new Error("Invalid JSON format in AI response.");
      }

      const clarityScore = evaluation.clarityScore || 0;
      const professionalismScore = evaluation.professionalismScore || 0;
      const empathyAndRapportScore = evaluation.empathyAndRapportScore || 0;
      const needsIdentificationScore = evaluation.needsIdentificationScore || 0;

      const averageScore =
        (clarityScore +
          professionalismScore +
          empathyAndRapportScore +
          needsIdentificationScore) /
        4;
      const feedback = `<p className="mx-3 my-3"><span class="font-bold">Clarity and Articulation: Score:</span> ${
        clarityScore || "[x/10]"
      }</p>
          <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
            evaluation.clarityJustification || "N/A"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Professionalism: Score:</span> ${
            professionalismScore || "[x/10]"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
            evaluation.professionalismJustification || "N/A"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Empathy and Rapport: Score:</span> ${
            empathyAndRapportScore || "[x/10]"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
            evaluation.empathyAndRapportJustification || "N/A"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Needs Identification: Score:</span> ${
            needsIdentificationScore || "[x/10]"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
            evaluation.needsIdentificationJustification || "N/A"
          }</p>
          <p className="mx-3 my-3"><span class="font-bold">Final Summary Score:</span> ${averageScore.toFixed(
            2
          )}</p>`;
      setSummary1(feedback);
      await saveFeedback(feedback);
    } catch (error) {
      console.error("Error fetching evaluation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You are an expert evaluator for sales conversations. Your task is to grade the performance of a sales representative based on the following criteria. Assign a score from 1 to 10 for each criterion, where 1 indicates 'poor performance' and 10 indicates 'exceptional performance.' Provide a brief explanation for each score.",
              },
              {
                role: "user",
                content: "Evaluate this sales conversation",
              },
            ],
            max_tokens: 600,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      let evaluationContent = data.choices[0].message.content.trim();

      if (
        evaluationContent.includes(
          "Sure, I'll need details about the sales conversation"
        )
      ) {
        evaluationContent = evaluationContent.replace(
          "Sure, I'll need details about the sales conversation to proceed with the evaluation. Please provide a transcript or a summary of the sales dialogue, including key interactions between the sales representative and the customer. This will allow me to assess the performance based on the criteria you mentioned.",
          ""
        );
      }

      setSummary2(evaluationContent);
    } catch (error) {
      console.error("Error fetching evaluation:", error);
    }
  };

  const content = (
    <div className="col-12 order-md-1 order-2">
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Generalized Report:</h3>
        <hr />

        <h4 className="mx-3 my-3">Evaluation Criteria and Scores:</h4>
        {isLoading ? (
          <div className="flex justify-center items-center min-h-[100px]">
            <Spin className="text-black" />
          </div>
        ) : (
          <div
            className="summary mx-3 my-3"
            dangerouslySetInnerHTML={{ __html: summary1 }}
          ></div>
        )}
        {/* <div
          className="summary mx-3 my-3"
          dangerouslySetInnerHTML={{ __html: summary1 }}
        ></div> */}
      </div>
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Conversation:</h3>
        <hr />
        <div className="row mx-3 my-3">
          {respt.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {message.role !== "system" && (
                <>
                  <strong>{message.role}:</strong> {message.content}{" "}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const sendInterviewConfunc = (data) => {
    console.log("response------------------setRespt", data);
    setRespt(data);
  };

  const ProfileSelection = ({ selectedProfile, setSelectedProfile }) => {
    const profiles = [
      { id: 1, name: "Lina", image: Lina, voice: "nova" },
      { id: 2, name: "Luka", image: Luka, voice: "onyx" },
      { id: 3, name: "Robert", image: Robert, voice: "echo" },
      { id: 4, name: "Stacy", image: Stacy, voice: "sage" },
    ];

    return (
      <div style={styles.profileSelectionContainer}>
        <h3 style={styles.profileSelectionTitle}>
          Who do you want to interact with?
        </h3>
        <div style={styles.profileImagesContainer}>
          {profiles.map((profile) => (
            <div
              key={profile.id}
              style={styles.profileImageWrapper}
              onClick={() => {
                setSelectedProfile(profile.id);
                setSelectedVoice(profile.voice);
              }}
            >
              <div
                style={{
                  ...styles.profileImageContainer,
                  border:
                    selectedProfile === profile.id
                      ? "2px solid #007bff"
                      : "2px solid transparent",
                }}
              >
                <img
                  src={profile.image}
                  alt={profile.name}
                  style={styles.profileImage}
                />
              </div>
              <p style={styles.profileName}>{profile.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      {showMeet ? (
        <SalesMeetScreen
          salesOptions={options}
          handleEndCall={handleEndCall}
          videoStream={videoStream}
          handleMute={handleMute}
          handleCamera={handleCamera}
          muted={muted}
          setMuted={setMuted}
          sessionId={sessionId}
          jobDescription={jobDescription}
          contactDetails={contactDetails}
          sendInterviewCon={sendInterviewConfunc}
          voice={selectedVoice}
        />
      ) : (
        <div style={styles.container}>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
            }}
          >
            {/* Exit Button */}
            <button style={styles.exitButton} onClick={handleExit}>
              <RiArrowLeftLine style={styles.exitIcon} />
            </button>

            {/* Progress Bar Attached to the Top of the Card */}
            <div style={styles.progressBarContainer}>
              <div
                style={{ ...styles.progressBar, width: `${progress}%` }}
              ></div>
            </div>

            {/* Title */}
            <h2 style={styles.title}>Create your Live Sales Call</h2>
            <ProfileSelection
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              setSelectedVoice={setSelectedVoice}
            />

            {/* Form */}
            <div style={styles.form}>
              {/* Call Type and Prospect's Role */}
              <div style={styles.row}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>Call Type</label>
                  <select
                    value={callType}
                    onChange={(e) => setCallType(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="Cold Call">Cold Call</option>
                    <option value="Warm Call">Warm Call</option>
                    <option value="Gatekeeper Call">Gatekeeper Call</option>
                    <option value="Closing Call">Closing Call</option>
                  </select>
                </div>

                <div style={styles.inputGroup}>
                  <label style={styles.label}>Prospect's Role</label>
                  <select
                    value={personna}
                    onChange={(e) => setPersonna(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="Front Desk">Front Desk</option>
                    <option value="Director">Director</option>
                    <option value="C-Level">C-Level</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              {/* Product/Service */}
              <div style={styles.inputGroup}>
                <label style={styles.label}>
                  What Product/Service Are You Selling?
                </label>
                <input
                  type="text"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  placeholder="Enter Your Product/Service"
                  style={styles.input}
                />
              </div>

              {/* Difficulty Level and Prospect's Industry */}
              <div style={styles.row}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>Difficulty Level</label>
                  <select
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                  </select>
                </div>

                <div style={styles.inputGroup}>
                  <label style={styles.label}>Prospect's Industry</label>
                  <select
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select...</option>
                    <option value="Technology">Technology</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Financial Services">
                      Financial Services
                    </option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Retail & Ecommerce">
                      Retail & Ecommerce
                    </option>
                    <option value="Automotive">Automotive</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Telecommunications">
                      Telecommunications
                    </option>
                    <option value="Insurance">Insurance</option>
                    <option value="Government">Government</option>
                  </select>
                </div>
              </div>

              {/* Situational Parameters */}
              <div style={styles.inputGroup}>
                <label style={styles.label}>
                  Any Specific Situational Parameters?
                </label>
                <input
                  type="text"
                  value={situation}
                  onChange={(e) => setSituation(e.target.value)}
                  placeholder="Add a standard"
                  style={styles.input}
                />
              </div>

              {/* Generate Button */}
              <button style={styles.button} onClick={handleGenerate}>
                Generate
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal show={isModal} header={header} content={content} footer="" />
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    padding: "20px",
    boxSizing: "border-box",
    paddingTop: "110px", // Adjusted to lower the card by 20px more
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "30px",
    maxWidth: "600px",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginTop: "0", // Reset marginTop since paddingTop is used
    position: "relative", // Add relative positioning for the exit button
  },
  exitButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "8px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
  },
  exitIcon: {
    fontSize: "24px",
    color: "#007bff",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e9ecef",
    borderRadius: "4px 4px 0 0", // Rounded corners only at the top
    width: "100%",
    position: "absolute", // Position the progress bar absolutely within the card
    top: 0, // Attach to the top of the card
    left: 0,
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#007bff",
    borderRadius: "4px 4px 0 0", // Rounded corners only at the top
    transition: "width 0.3s ease",
  },
  title: {
    textAlign: "center",
    color: "#343a40",
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "30px",
    marginTop: "20px", // Add margin to separate from the progress bar
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px", // Increased gap between form elements
  },
  row: {
    display: "flex",
    gap: "20px", // Gap between columns in a row
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flex: 1, // Ensure equal width for columns
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#495057",
  },
  select: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  input: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#ffffff",
    padding: "14px 20px",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginTop: "20px", // Added margin to separate from the last input
  },
  profileSelectionContainer: {
    marginBottom: "20px",
  },
  profileSelectionTitle: {
    textAlign: "center",
    color: "#343a40",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "15px",
  },
  profileImagesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  profileImageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  profileImageContainer: {
    borderRadius: "50%",
    overflow: "hidden",
    padding: "2px", // Add padding for the border
  },
  profileImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  profileName: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#343a40",
    textAlign: "center",
  },
};

export default SalesOptions;
