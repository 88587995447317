import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { error, success, warning } from "../../Utilities/toast";
import ReactModal from "react-modal";
import { SaveExitIcon } from "./svgimages";
import { addResumeJob, getResume } from "../../Redux/ResumeBuilderSlice";
import { useDispatch } from "react-redux";
import MiniAdditionalSection from "./MiniAdditionalSection";
import MiniResumeActions from "./MiniResumeActions";
import { createMarkup } from "../../Utilities/CreateMarkup";

const MiniResumePreview = ({
  item,
  handleResumeClick,
  handleGetFeedback,
  setSelectedDeleteResumeId,
  setIsOpenConfirmationModal,
}) => {
  console.log("itembydev", item);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedResume, setSelectedResume] = useState("");
  const [sectionsConfig, setSectionConfigs] = useState([]);
  console.log("sectionsConfig: ", sectionsConfig);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateString ? new Date(dateString) : new Date(); // Default to today if dateString is null or undefined
    return date.toLocaleDateString(undefined, options);
  };
  const reset = () => {
    setName("");
    setTitle("");
    setDescription("");
    setSelectedResume("");
  };

  const addClassToPTags = (htmlString, className) => {
    if (!htmlString) return "";
    return htmlString.replace(
      /<p\b([^>]*)>/gi,
      `<p$1 class="${className}" style="font-family:${item?.font_style}">`
    );
  };

  const sectionComponents = useMemo(
    () => [
      {
        key: "details",
        component: (
          <header
            className="flex justify-center item-center flex-col mb-1"
            style={{ fontFamily: `${item?.font_style}` }}
          >
            <p
              className="text-uppercase text-center text-black text-xs font-semibold leading-normal"
              style={{
                color: `${item?.heading_color && item?.heading_color}`,
                fontFamily: `${item?.font_style}`,
              }}
            >
              <span
                className={`text-uppercase text-center text-black text-xs font-semibold leading-normal ${item?.contact?.first_name_style} `}
              >
                {item?.contact?.first_name}
              </span>{" "}
              <span
                className={`text-uppercase text-center text-black text-xs font-semibold leading-normal ${item?.contact?.last_name_style} `}
              >
                {item?.contact?.last_name}
              </span>
            </p>
            <p
              className="text-[7px] text-[#777] text-center leading-normal mt-1"
              style={{ fontFamily: `${item?.font_style}` }}
            >
              {item?.contact?.introductory_title && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.introductory_title_style} `}
                >
                  {" "}
                  {item?.contact?.introductory_title} |{" "}
                </span>
              )}
              {item?.contact?.key_attribute && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.key_attribute_style} `}
                >
                  {" "}
                  {item?.contact?.key_attribute} |{" "}
                </span>
              )}
              {item?.contact?.email && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.email_style} `}
                >
                  {" "}
                  {item?.contact?.email} |{" "}
                </span>
              )}
              {item?.contact?.address && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.address_style} `}
                >
                  {" "}
                  {item?.contact?.address} |{" "}
                </span>
              )}
              {item?.contact?.phone && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.phone_style} `}
                >
                  {" "}
                  {item?.contact?.phone} |{" "}
                </span>
              )}
              {item?.contact?.linked_in && (
                <span
                  className={`text-[7px] text-[#777] text-center leading-normal mt-1 ${item?.contact?.linked_in_style} `}
                >
                  {" "}
                  {item?.contact?.linked_in}
                </span>
              )}
            </p>
          </header>
        ),
      },
      {
        key: "professionalsummary",
        component: item?.blurbs?.map((item, index) => (
          <div key={index} className="ml-[0.1rem] mb-[0.5] mt-1">
            <div key={index} className="mb-0">
              <p
                className="text-[6px] line-clamp-2 leading-normal"
                dangerouslySetInnerHTML={{
                  __html: addClassToPTags(
                    item?.text,
                    "text-[6px] leading-normal"
                  ),
                }}
              />
            </div>
          </div>
        )),
      },
      {
        key: "certification",
        component: (
          <ul className="p-0 gap-0 items-center flex justify-start flex-wrap mb-[0.5] mt-1">
            {item?.certifications?.slice(0, 4)?.map((certificate, index) => (
              <li
                className="border-none flex justify-center items-center list-none gap-1 mr-1 mt-0"
                key={index}
              >
                <span className="font-bold text-[#333] text-[7px]">•</span>{" "}
                <p
                  className="line-clamp-1 text-[#333] text-[7px] leading-normal"
                  style={{ fontFamily: `${item?.font_style}` }}
                >
                  {certificate?.name}
                </p>
              </li>
            ))}
          </ul>
        ),
      },
      {
        key: "skills",
        component:
          item?.skills &&
          item?.skills?.map((category, index) => (
            <ul
              className="p-0 gap-0 line-clamp-1 items-center flex justify-start flex-wrap leading-normal mb-[0.5] mt-1"
              key={index}
            >
              {category?.skills?.slice(0, 3)?.map((skill, index) => (
                <li
                  className="border-none flex justify-center items-center list-none gap-1 leading-normal mr-1"
                  key={index}
                >
                  <span className="font-bold text-[#333] text-[7px]">•</span>{" "}
                  <p
                    className="line-clamp-1 text-[#333] text-[7px] leading-normal"
                    style={{ fontFamily: `${item?.font_style}` }}
                  >
                    {skill?.name}
                  </p>
                </li>
              ))}
            </ul>
          )),
      },
      {
        key: "workexperience",
        component: item?.work_experience
          ?.slice(0, 1)
          ?.map((experience, index) => (
            <div key={index} className="mb-[0.5] mt-1 ml-[0.1rem]">
              <div className="flex justify-between items-center">
                <div className="text-capitalize">
                  <h3
                    className="text-[8px] leading-normal text-black"
                    style={{
                      color: `${
                        item?.subheading_color && item?.subheading_color
                      }`,
                      fontFamily: `${item?.font_style}`,
                    }}
                  >
                    {experience?.name}
                  </h3>
                  <p
                    className="text-black mb-0 text-[6px] leading-normal"
                    style={{ fontFamily: `${item?.font_style}` }}
                  >
                    {experience?.role_name}
                  </p>
                </div>
                <div className="text-capitalize">
                  <p
                    className="text-black text-[6px] leading-normal"
                    style={{ fontFamily: `${item?.font_style}` }}
                  >
                    {formatDate(experience?.started_at)}
                    {experience?.ended_at == null
                      ? " - present"
                      : `${""} - ${formatDate(experience?.ended_at)}`}
                  </p>
                </div>
              </div>
              <p
                className="text-black text-[6px] line-clamp-2 leading-normal"
                style={{ fontFamily: `${item?.font_style}` }}
                dangerouslySetInnerHTML={createMarkup(
                  experience.key_acheivement
                )}
              />
              <p
                className="text-black text-[6px] line-clamp-2 leading-normal"
                style={{ fontFamily: `${item?.font_style}` }}
                dangerouslySetInnerHTML={createMarkup(experience.description)}
              />
              {/* {experience?.description}...
                </p> */}
            </div>
          )),
      },
      {
        key: "education",
        component: item?.educations?.slice(0, 1)?.map((education, index) => (
          <div
            key={index}
            className="flex justify-between items-center mb-[0.5] mt-1 ml-[0.1rem]"
          >
            <div className="text-capitalize">
              <h3
                className="text-[8px] leading-normal text-black"
                style={{
                  color: `${item?.subheading_color && item?.subheading_color}`,
                  fontFamily: `${item?.font_style}`,
                }}
              >
                {education.school_name}
              </h3>
              <p
                className="text-black mb-0 text-[6px] leading-normal"
                style={{ fontFamily: `${item?.font_style}` }}
              >
                {education.degree && education.degree + " | "}
                {education?.field && education?.field}
              </p>
            </div>
            <div className="text-capitalize">
              <p
                className="text-black text-[6px] leading-normal"
                style={{ fontFamily: `${item?.font_style}` }}
              >
                {education?.ended_at == null
                  ? " present"
                  : `${formatDate(education?.ended_at)}`}
              </p>
            </div>
          </div>
        )),
      },
      {
        key: "project",
        component: item?.projects?.slice(0, 1)?.map((pro, index) => (
          <div key={index} className="mb-[0.5] mt-1 ml-[0.1rem]">
            <div className="flex justify-between items-center">
              <div className="text-capitalize">
                <h3
                  className="text-[8px] leading-normal text-black"
                  style={{
                    color: `${
                      item?.subheading_color && item?.subheading_color
                    }`,
                    fontFamily: `${item?.font_style}`,
                  }}
                >
                  {pro?.project_name}
                </h3>
                <p
                  className="text-black mb-0 text-[6px] leading-normal"
                  style={{ fontFamily: `${item?.font_style}` }}
                >
                  {pro?.organization}
                </p>
              </div>
              <div className="text-capitalize">
                <p
                  className="text-black text-[6px] leading-normal"
                  style={{ fontFamily: `${item?.font_style}` }}
                >
                  {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
                </p>
              </div>
            </div>
            <p
              className="text-black leading-normal line-clamp-2 text-[6px]"
              dangerouslySetInnerHTML={{
                __html: addClassToPTags(
                  pro?.additional_information,
                  "leading-normal line-clamp-2 text-[6px]"
                ),
              }}
            />
          </div>
        )),
      },
      {
        key: "publications",
        component: item?.publications
          ?.slice(0, 1)
          ?.map((publication, index) => {
            return (
              <div key={index} className="mb-[0.5] mt-1 ml-[0.1rem]">
                <div className="flex justify-between items-center text-capitalize">
                  <h3
                    className="text-[8px] leading-normal text-black"
                    style={{
                      color: `${
                        item?.subheading_color && item?.subheading_color
                      }`,
                      fontFamily: `${item?.font_style}`,
                    }}
                  >
                    {publication.title}
                  </h3>
                  <p
                    className="text-black mb-0 text-[6px] leading-normal"
                    style={{ fontFamily: `${item?.font_style}` }}
                  >
                    {publication.publisher}
                  </p>
                </div>
                <p
                  className="text-black text-[6px] leading-normal"
                  style={{ fontFamily: `${item?.font_style}` }}
                >
                  {formatDate(publication?.date)}
                </p>
              </div>
            );
          }),
      },
      {
        key: "interests",
        component: (
          <ul className="p-0 gap-0 items-center flex justify-start flex-wrap mb-[0.5] mt-1">
            {item?.interests[0] &&
              item?.interests[0]?.interests
                ?.slice(0, 3)
                ?.map((interest, index) => (
                  <li
                    className="border-none flex justify-center items-center list-none gap-1 mt-0 mr-1"
                    key={index}
                  >
                    <span className="font-bold text-[#333] text-[7px]">•</span>
                    <p
                      className="line-clamp-1 text-[#333] text-[7px] leading-normal"
                      style={{ fontFamily: `${item?.font_style}` }}
                    >
                      {interest}
                    </p>
                  </li>
                ))}
          </ul>
        ),
      },
      {
        key: "skill",
        component: (
          <ul className="p-0 gap-0 items-center flex justify-start flex-wrap mb-[0.5] mt-1">
            {item?.soft_skills[0] &&
              item?.soft_skills[0]?.skills?.slice(0, 3)?.map((skill, index) => (
                <li
                  className="border-none flex justify-center items-center list-none gap-1 mr-1 mt-0"
                  key={index}
                >
                  <span className="font-bold text-[#333] text-[7px]">•</span>
                  <p
                    className="line-clamp-1 text-[#333] text-[7px] leading-normal"
                    style={{ fontFamily: `${item?.font_style}` }}
                  >
                    {skill}
                  </p>
                </li>
              ))}
          </ul>
        ),
      },
      {
        key: "language",
        component: (
          <ul className="p-0 gap-0 items-center flex justify-start flex-wrap mb-[0.5] mt-1">
            {item?.languages[0] &&
              item?.languages[0]?.languages
                ?.slice(0, 3)
                ?.map((language, index) => (
                  <li
                    className="border-none flex justify-center items-center list-none gap-1 mt-0 mr-1"
                    key={index}
                  >
                    <span className="font-bold text-[#333] text-[7px]">•</span>
                    <p
                      className="line-clamp-1 text-[#333] text-[7px] leading-normal"
                      style={{ fontFamily: `${item?.font_style}` }}
                    >
                      {language}
                    </p>
                  </li>
                ))}
          </ul>
        ),
      },
    ],
    [item]
  );

  const updatedSections = useMemo(() => {
    if (!item?.positions) return [];

    return item.positions
      .filter((position) => !position.isDeleted)
      .filter((position) => !position.additional)
      .map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (comp) => comp.key === position.key
          );
          return matchingComponent
            ? {
                ...position,
                section_spacing: item?.section_spacing,
                inner_section_spacing: item?.inner_section_spacing,
                component: matchingComponent.component,
              }
            : position;
        }

        const matchingAdditional = item.additional_sections?.find(
          (item) => item.identity_key === position.key
        );

        return matchingAdditional
          ? {
              ...position,
              section_spacing: item?.section_spacing,
              inner_section_spacing: item?.inner_section_spacing,
              component: (
                <MiniAdditionalSection sections={matchingAdditional[0]} />
              ),
            }
          : position;
      })
      .sort((a, b) => a.position - b.position);
  }, [sectionComponents]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const payload = {
        name,
        title,
        description,
      };
      dispatch(
        addResumeJob({
          jobID: selectedResume,
          payload,
        })
      )
        .unwrap()
        .then((res) => {
          success(res.detail);
          setModal(false);
          reset();
          dispatch(getResume({ onSuccess: () => {} }));
        });
    } catch (errors) {
      error(errors.message);
    }
  };
  useEffect(() => {
    if (item?.font_style) {
      // Generate the Google Fonts link URL
      const fontName = item.font_style.replace(" ", "+");
      const fontLinkHref = `https://fonts.googleapis.com/css2?family=${fontName}&display=swap`;

      // Check if the font link already exists to avoid duplicates
      let fontLink = document.querySelector(`link[href="${fontLinkHref}"]`);
      if (!fontLink) {
        fontLink = document.createElement("link");
        fontLink.href = fontLinkHref;
        fontLink.rel = "stylesheet";
        document.head.appendChild(fontLink);
      }

      // Apply the font style as a CSS variable
      document.documentElement.style.setProperty(
        "--dynamic-font-family",
        item.font_style
      );
    }
  }, [item?.font_style]);
  useEffect(() => {
    setSectionConfigs(updatedSections);
  }, [updatedSections, setSectionConfigs]);
  return (
    <>
      <div
        className={`w-[300px] min-h-[300px] mt-1 `}
        style={{
          fontFamily: `var(--dynamic-font-family, ${item?.font_style})`,
        }}
      >
        <div
          className={`cv-child-container-preview ${
            item?.isNew ? "border border-1 !border-blue-600 !shadow-lg" : ""
          } pt-0 px-0 bg-white shadow-lg rounded-lg h-full overflow-hidden relative`}
        >
          {item?.isNew && (
            <div className="absolute top-0 right-0 bg-blue-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg z-1">
              New
            </div>
          )}
          <div
            onClick={() => navigate(`/resume-builder/view/${item?.id}`)}
            style={{
              fontFamily: `var(--dynamic-font-family, ${item?.font_style})`,
            }}
            className="w-full h-full p-2 cursor-pointer"
          >
            {sectionsConfig?.map((section, index) => {
              return (
                <div className={`py-0 px-2 w-full `} key={index}>
                  <div className="flex item-center">
                    {section?.title !== "Personal Information" && (
                      <span
                        className={`text-[7px] p-[0.1rem] leading-normal ${section?.style}`}
                        style={{
                          color: `${
                            item?.heading_color ? item?.heading_color : "#000"
                          }`,
                          fontFamily: `${item?.font_style}`,
                        }}
                      >
                        {section.title}
                      </span>
                    )}
                  </div>
                  {section?.title !== "Personal Information" && (
                    <hr
                      style={{
                        border: `0.1px solid ${item?.heading_color || "#000"}`,
                        marginTop: "-1px",
                      }}
                    />
                  )}
                  {section.component}
                </div>
              );
            })}
          </div>
          <MiniResumeActions
            item={item}
            handleResumeClick={handleResumeClick}
            setSelectedDeleteResumeId={setSelectedDeleteResumeId}
            setIsOpenConfirmationModal={setIsOpenConfirmationModal}
            setModal={setModal}
            handleGetFeedback={handleGetFeedback}
            setSelectedResume={setSelectedResume}
          />
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <p className="p-0 mb-0">
          Upload the Job Description You Would Like Us To Enhance Your Resume
          For
        </p>
        <hr />
        <br />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="d-flex flex-column mb-1">
            <div className="form-group d-flex justify-content-between mb-3">
              <div style={{ width: "45%" }}>
                <label htmlFor="companyname">Company Name</label>
                <input
                  type="text"
                  id="companyname"
                  className="form-control"
                  placeholder=""
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div style={{ width: "45%" }}>
                <label htmlFor="jobtitle">Job Title</label>
                <input
                  type="text"
                  id="jobtitle"
                  className="form-control"
                  placeholder=""
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group w-100">
              <label htmlFor="jobdescription">Job Description</label>
              <textarea
                id="jobdescription"
                className="workcontrol"
                placeholder=""
                style={{ height: "150px" }}
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center py-2 px-2">
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "150px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <SaveExitIcon></SaveExitIcon>
              Save
            </button>
            <button
              type="button"
              className="rounded-xl text-white bg-red-600 mx-2"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "150px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              }}
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </ReactModal>
    </>
  );
};

export default MiniResumePreview;
