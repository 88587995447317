import { Spin } from "antd";
import { useEffect, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import DialerAudio from "../../assets/sound/dialer.mp3";
import Header from "../../Components/header/index";
import Modal from "../../Components/modal";
import { error } from "../../Utilities/toast";
import Lina from "./images/lina.png";
import Luka from "./images/luka.png";
import Robert from "./images/robert.png";
import Stacy from "./images/stacy.png";
import "./spin.style.css";
import TradeMeetScreen from "./trades/tradesmeet"; // Updated import

function Trades() {
  const [job, setJob] = useState(""); // Renamed from department to job
  const [scenario, setScenario] = useState(""); // Renamed from challenge to scenario
  const [difficulty, setDifficulty] = useState(""); // Added difficulty level
  const [scenarioDetails, setScenarioDetails] = useState(""); // Renamed from situation to scenarioDetails
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [showMeet, setShowMeet] = useState(false);
  const [options, setOptions] = useState({});
  const [play, { stop }] = useSound(DialerAudio);
  const [isModal, setIsModal] = useState(false);
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [muted, setMuted] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [respt, setRespt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(1);
  const [selectedVoice, setSelectedVoice] = useState("nova");
  const navigate = useNavigate();

  // Map profiles to their corresponding OpenAI voices
  const profileVoiceMap = {
    1: "breeze", // Lina
    2: "cove", // Luka
    3: "juniper", // Robert
    4: "ember", // Stacy
  };

  // Handle navigation back to /octagon
  const handleExit = () => {
    navigate("/octagon");
  };

  const handleMute = () => {
    if (audioStream) {
      audioStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setMuted(!muted);
    }
  };

  const handleCamera = () => {
    if (videoStream) {
      videoStream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    }
  };

  const handleGenerate = () => {
    const generatedOptions = {
      trade: job, // Updated to trade instead of department
      scenario,
      difficulty,
      scenarioDetails,
    };
    setOptions(generatedOptions);
    setShowMeet(true);
    console.log("generatedOptions----------", generatedOptions);
  };

  useEffect(() => {
    let filledFields = 0;
    if (job) filledFields++;
    if (scenario) filledFields++;
    if (difficulty) filledFields++;
    if (scenarioDetails) filledFields++;
    setProgress((filledFields / 4) * 100); // Adjusted to 4 fields
  }, [job, scenario, difficulty, scenarioDetails]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300);
    }
  }, [progress]);

  const handleEndCall = () => {
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }

    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }

    setIsModal(true);
    fetchData();
    fetchData1();
    fetchData2();

    setOptions({});
    setShowMeet(false);
    setJob("");
    setScenario("");
    setDifficulty("");
    setScenarioDetails("");
    setProgress(0);
    setMuted(false);
  };

  useEffect(() => {
    if (showMeet) {
      const initiateStreams = async () => {
        try {
          const constraintsAudio = { audio: true, video: false };
          const constraintsVideo = { video: true, audio: false };

          const audio = await navigator.mediaDevices.getUserMedia(
            constraintsAudio
          );
          setAudioStream(audio);

          const video = await navigator.mediaDevices.getUserMedia(
            constraintsVideo
          );
          setVideoStream(video);
        } catch (err) {
          console.error("Error accessing media devices.", err);
          error("Unable to access camera or microphone.");
          setShowMeet(false);
          if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
            setAudioStream(null);
          }
          if (videoStream) {
            videoStream.getTracks().forEach((track) => track.stop());
            setVideoStream(null);
          }
        }
      };

      initiateStreams();
    }

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [showMeet]);

  const saveFeedback = async (feedback) => {
    try {
      const conversationsHTML = respt
        .map((message) =>
          message.role !== "system"
            ? `<h5>${message.role}:</h5> <p>${message.content}</p>`
            : ""
        )
        .join("");

      const feedbackData = {
        conversations: `<div class="row mx-3 my-3">${conversationsHTML}</div>`,
        feedback: feedback,
        role_id: 4, // Adjust role_id if needed for trades context
      };
      const token = localStorage.getItem("auth");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/octagon/simulation/feedback`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedbackData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save feedback");
      }

      const data = await response.json();
      console.log("Feedback saved successfully:", data);
      return data;
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const header = (
    <div className="mockInterview-modal-header">
      <button className="modal-close" onClick={() => handleCloseModal()}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );

  const [summary, setSummary] = useState("");
  const [summary1, setSummary1] = useState("");
  const [summary2, setSummary2] = useState("");

  const fetchData = async () => {
    console.log(respt);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: respt,
            max_tokens: 150,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setSummary(data.choices[0].message.content);
    } catch (error) {
      console.error("Error fetching summary:", error);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: [
              {
                role: "system",
                content: `
                You are an evaluator for a trades job simulation. Analyze the conversation and provide a structured evaluation in JSON format for a tradesperson's performance. Ensure all keys are included and the JSON is valid. Use the following structure:
                {
                  "technicalKnowledgeScore": [number],
                  "technicalKnowledgeJustification": [text],
                  "problemSolvingScore": [number],
                  "problemSolvingJustification": [text],
                  "communicationScore": [number],
                  "communicationJustification": [text],
                  "professionalismScore": [number],
                  "professionalismJustification": [text],
                  "safetyAwarenessScore": [number],
                  "safetyAwarenessJustification": [text]
                }
              `,
              },
              {
                role: "user",
                content: `Conversation: ${JSON.stringify(respt)}`,
              },
            ],
            max_tokens: 600,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      let evaluationContent = data.choices[0].message.content.trim();

      let evaluation;
      try {
        evaluation = JSON.parse(evaluationContent);
      } catch (jsonError) {
        console.error("Error parsing JSON:", jsonError);
        throw new Error("Invalid JSON format in AI response.");
      }

      const technicalKnowledgeScore = evaluation.technicalKnowledgeScore || 0;
      const problemSolvingScore = evaluation.problemSolvingScore || 0;
      const communicationScore = evaluation.communicationScore || 0;
      const professionalismScore = evaluation.professionalismScore || 0;
      const safetyAwarenessScore = evaluation.safetyAwarenessScore || 0;

      const averageScore =
        (technicalKnowledgeScore +
          problemSolvingScore +
          communicationScore +
          professionalismScore +
          safetyAwarenessScore) /
        5;
      const feedback = `
        <p className="mx-3 my-3"><span class="font-bold">Technical Knowledge: Score:</span> ${
          technicalKnowledgeScore || "[x/10]"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
          evaluation.technicalKnowledgeJustification || "N/A"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Problem Solving: Score:</span> ${
          problemSolvingScore || "[x/10]"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
          evaluation.problemSolvingJustification || "N/A"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Communication: Score:</span> ${
          communicationScore || "[x/10]"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
          evaluation.communicationJustification || "N/A"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Professionalism: Score:</span> ${
          professionalismScore || "[x/10]"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
          evaluation.professionalismJustification || "N/A"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Safety Awareness: Score:</span> ${
          safetyAwarenessScore || "[x/10]"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Justification:</span> ${
          evaluation.safetyAwarenessJustification || "N/A"
        }</p>
        <p className="mx-3 my-3"><span class="font-bold">Final Summary Score:</span> ${averageScore.toFixed(
          2
        )}</p>`;
      setSummary1(feedback);
      saveFeedback(feedback);
    } catch (error) {
      console.error("Error fetching evaluation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-4-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You are an expert evaluator for trades job simulations. Your task is to grade the performance of a tradesperson based on the following criteria: Technical Knowledge, Problem Solving, Communication, Professionalism, and Safety Awareness. Assign a score from 1 to 10 for each criterion, where 1 indicates 'poor performance' and 10 indicates 'exceptional performance.' Provide a brief explanation for each score.",
              },
              {
                role: "user",
                content: `Evaluate this trades job conversation: ${JSON.stringify(
                  respt
                )}`,
              },
            ],
            max_tokens: 600,
            temperature: 0.7,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setSummary2(data.choices[0].message.content.trim());
    } catch (error) {
      console.error("Error fetching evaluation:", error);
    }
  };

  const content = (
    <div className="col-12 order-md-1 order-2">
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Job Performance Report:</h3>
        <hr />
        <h4 className="mx-3 my-3">Evaluation Criteria and Scores:</h4>
        {isLoading ? (
          <div className="flex justify-center items-center min-h-[100px]">
            <Spin className="text-black" />
          </div>
        ) : (
          <div
            className="summary mx-3 my-3"
            dangerouslySetInnerHTML={{ __html: summary1 }}
          ></div>
        )}
      </div>
      <div className="cardBody">
        <h3 className="mx-3 my-3 font-bold">Conversation:</h3>
        <hr />
        <div className="row mx-3 my-3">
          {respt.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {message.role !== "system" && (
                <>
                  <strong>{message.role}:</strong> {message.content}{" "}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const handleInterviews = (data) => {
    console.log(data);
    setRespt(data);
  };

  // Profile Selection Component
  const ProfileSelection = ({ selectedProfile, setSelectedProfile }) => {
    const profiles = [
      { id: 1, name: "Lina", image: Lina, voice: "nova" },
      { id: 2, name: "Luka", image: Luka, voice: "onyx" },
      { id: 3, name: "Robert", image: Robert, voice: "echo" },
      { id: 4, name: "Stacy", image: Stacy, voice: "sage" },
    ];

    return (
      <div style={styles.profileSelectionContainer}>
        <h3 style={styles.profileSelectionTitle}>
          Who do you want to interact with?
        </h3>
        <div style={styles.profileImagesContainer}>
          {profiles.map((profile) => (
            <div
              key={profile.id}
              style={styles.profileImageWrapper}
              onClick={() => {
                setSelectedProfile(profile.id);
                setSelectedVoice(profile.voice);
              }}
            >
              <div
                style={{
                  ...styles.profileImageContainer,
                  border:
                    selectedProfile === profile.id
                      ? "2px solid #007bff"
                      : "2px solid transparent",
                }}
              >
                <img
                  src={profile.image}
                  alt={profile.name}
                  style={styles.profileImage}
                />
              </div>
              <p style={styles.profileName}>{profile.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      {showMeet ? (
        <TradeMeetScreen // Updated to TradeMeetScreen
          tradesOptions={options} // Updated to tradesOptions
          handleEndCall={handleEndCall}
          videoStream={videoStream}
          handleMute={handleMute}
          handleCamera={handleCamera}
          muted={muted}
          setMuted={setMuted}
          sessionId={sessionId}
          jobDescription=""
          contactDetails=""
          handleInterview={handleInterviews}
          selectedProfile={selectedProfile}
          voice={selectedVoice}
        />
      ) : (
        <div style={styles.container}>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
            }}
          >
            {/* Exit Button */}
            <button style={styles.exitButton} onClick={handleExit}>
              <RiArrowLeftLine style={styles.exitIcon} />
            </button>

            {/* Progress Bar */}
            <div style={styles.progressBarContainer}>
              <div
                style={{ ...styles.progressBar, width: `${progress}%` }}
              ></div>
            </div>

            <h2 style={styles.title}>Create Your Trades Scenario</h2>
            <ProfileSelection
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              setSelectedVoice={setSelectedVoice}
            />
            <div style={styles.form}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Choose Your Job</label>
                <select
                  value={job}
                  onChange={(e) => setJob(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Electrician">Electrician</option>
                  <option value="Plumber">Plumber</option>
                  <option value="Carpenter">Carpenter</option>
                  <option value="Mechanic">Mechanic</option>
                </select>
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label}>Scenario Selection</label>
                <select
                  value={scenario}
                  onChange={(e) => setScenario(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Emergency Repair Under Time Pressure">
                    Emergency Repair Under Time Pressure
                  </option>
                  <option value="Customer Complaint About a Recent Job">
                    Customer Complaint About a Recent Job
                  </option>
                  <option value="Incomplete Job Site Information">
                    Incomplete Job Site Information
                  </option>
                  <option value="Safety Hazard Discovery">
                    Safety Hazard Discovery
                  </option>
                  <option value="Tool or Material Shortage">
                    Tool or Material Shortage
                  </option>
                  <option value="Complex Diagnostic Challenge">
                    Complex Diagnostic Challenge
                  </option>
                  <option value="Team Coordination Issue">
                    Team Coordination Issue
                  </option>
                  <option value="Client Requests an Unsafe or Unpermitted Fix">
                    Client Requests an Unsafe or Unpermitted Fix
                  </option>
                  <option value="Weather-Related Job Complication">
                    Weather-Related Job Complication
                  </option>
                  <option value="Budget Dispute with a Client">
                    Budget Dispute with a Client
                  </option>
                </select>
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label}>Difficulty Level</label>
                <select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Easy">Easy</option>
                  <option value="Medium">Medium</option>
                  <option value="Hard">Hard</option>
                </select>
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label}>Scenario Details</label>
                <input
                  type="text"
                  value={scenarioDetails}
                  onChange={(e) => setScenarioDetails(e.target.value)}
                  placeholder="Add specific details to your scenario"
                  style={styles.input}
                />
              </div>

              <button style={styles.button} onClick={handleGenerate}>
                Start My Trades Simulation
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal show={isModal} header={header} content={content} footer="" />
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    padding: "20px",
    boxSizing: "border-box",
    paddingTop: "110px",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "30px",
    maxWidth: "600px",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginTop: "0",
    position: "relative",
  },
  exitButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "8px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
  },
  exitIcon: {
    fontSize: "24px",
    color: "#007bff",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e9ecef",
    borderRadius: "4px 4px 0 0",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#007bff",
    borderRadius: "4px 4px 0 0",
    transition: "width 0.3s ease",
  },
  title: {
    textAlign: "center",
    color: "#343a40",
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "30px",
    marginTop: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#495057",
  },
  select: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  input: {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#495057",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#ffffff",
    padding: "14px 20px",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  profileSelectionContainer: {
    marginBottom: "20px",
  },
  profileSelectionTitle: {
    textAlign: "center",
    color: "#343a40",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "15px",
  },
  profileImagesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  profileImageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  profileImageContainer: {
    borderRadius: "50%",
    overflow: "hidden",
    padding: "2px",
  },
  profileImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  profileName: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#343a40",
    textAlign: "center",
  },
};

export default Trades;
