import "./SectionStuff.css";

const Section5 = () => {
  return (
    <section className="results-section">
      <div className="intro-text">
        <h2 className="section-title">
          See the <span className="highlight">Results</span> for Yourself
        </h2>
      </div>
      <div
        className="content-block"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "60px",
          maxWidth: "1200px",
          width: "100%",
          flexWrap: "wrap",
          padding: "40px 20px",
          backgroundColor: "#ffffff",
          borderRadius: "16px",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="text-content"
          style={{
            flex: 1,
            minWidth: "300px",
            maxWidth: "500px",
            padding: "20px",
          }}
        >
          <h3
            className="content-title"
            style={{ marginBottom: "30px", color: "#1a1a1a" }}
          >
            A2C Staffing Agency
          </h3>
          <div className="result-item" style={{ marginBottom: "25px" }}>
            <h4
              className="result-subtitle"
              style={{ marginBottom: "10px", color: "#1a1a1a" }}
            >
              Challenge
            </h4>
            <p
              className="result-description"
              style={{ color: "#6b7280", lineHeight: "1.6" }}
            >
              High turnover and long time-to-hire.
            </p>
          </div>
          <div className="result-item" style={{ marginBottom: "25px" }}>
            <h4
              className="result-subtitle"
              style={{ marginBottom: "10px", color: "#1a1a1a" }}
            >
              Solution
            </h4>
            <p
              className="result-description"
              style={{ color: "#6b7280", lineHeight: "1.6" }}
            >
              Nexa RU streamlined hiring for software engineers, reducing
              time-to-hire by 60%.
            </p>
          </div>
          <div className="result-item" style={{ marginBottom: "0" }}>
            <h4
              className="result-subtitle"
              style={{ marginBottom: "10px", color: "#1a1a1a" }}
            >
              Result
            </h4>
            <p
              className="result-description"
              style={{ color: "#6b7280", lineHeight: "1.6" }}
            >
              $50,000 saved annually and improved employee retention by 30%.
            </p>
          </div>
        </div>
        <div
          className="image-content"
          style={{
            flex: 1,
            minWidth: "300px",
            maxWidth: "500px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <img
            src="/images/Group132131501.png"
            alt="Staffing Agency Results"
            className="content-image"
            style={{
              width: "100%",
              height: "auto",
              transition: "transform 0.3s ease",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Section5;
