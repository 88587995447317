import React from "react";
import ReactModal from "react-modal";

const PrefrenceSuggestionModal = ({ isOpen, onClose }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "99",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          maxWidth: "500px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div className="py-3 px-4 overflow-auto">
        <h2 className="pb-3 text-xl leading-9">
          please select your preference first to start job search
        </h2>
        <div className="flex flex-col sm:flex-row gap-3 w-full pt-4">
          <button
            type="button"
            className="modal-button save-button w-full"
            onClick={onClose}
          >
            Choose Preference
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default PrefrenceSuggestionModal;
