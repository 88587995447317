import React from "react";
import { Link, useNavigate } from "react-router-dom";
import l2 from "../../assets/Images/l2.png";
import l3 from "../../assets/Images/l3.png";
import logo from "../../assets/Images/reallogo5.png";
// import logo from "../../assets/Images/logo1.png";
import l4 from "../../assets/Images/l4.png";
import linkedinlogo from "../../assets/Images/linkedinlogo.png";
import tiktoklogo from "../../assets/Images/tiktoklogo.png";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-flex gap-y-10">
        <div>
          <img className="object-contain" src={logo} alt="logo" style={{ width: "93px", height: "71px", maxWidth: "100%", objectFit: "contain" }}  />
          <p className="text-white text-2xl mt-4">
            Career Services That Impact the World
          </p>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Resources</h1>
          <ul className="space-y-2">
            <li>
              <a
                href="https://tally.so/r/3yDKdg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light"
              >
                Career Center Efficiency Assessment
              </a>
            </li>

            <li>
            <a
                href="https://drive.google.com/file/d/1kegVSbQKUghYDiz-7xt0YyCRUiaRXjzf/view?pli=1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light cursor-pointer "
              >
                Case Studies
              </a>
            </li>
            <li
              className="text-white hover:text-gray-300 font-light"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </li>
            <li>
              <Link
                to="/faq"
                className="text-white hover:text-gray-300 font-light"
              >
                FAQ
              </Link>
            </li>
            <li>
              <a
                href="https://share.hsforms.com/1OqLkWvR5SHCqlZOUvRD9-Arfy9w"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light cursor-pointer "
              >
                Help center
              </a>
            </li>
            <li>
            <a
                href="https://trynexa.com/IQ/jobboard"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light cursor-pointer "
              >
                Job Board
              </a>
            </li>
            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light"
              >
                Post a Job for FREE
              </Link>
            </li>

            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light "
              >
                ROI Calculator
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Products</h1>
          <ul className="space-y-2">
            <li>
              <Link
                to="/landing"
                className="text-white hover:text-gray-300 font-light "
              >
                Elevate
              </Link>
            </li>
            <li>
              <Link
                to="/rucoming"
                className="text-white hover:text-gray-300 font-light "
              >
                Nexa RU
              </Link>
            </li>
            <li>
              <Link
                to="/nexa-iq"
                className="text-white hover:text-gray-300 font-light "
              >
                Nexa IQ
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Company</h1>
          <ul className="space-y-2">
            <li>
              <Link
                to="/about-us"
                className="text-white hover:text-gray-300 font-light"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="text-white hover:text-gray-300 font-light"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-condition"
                className="text-white hover:text-gray-300 font-light"
              >
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Social</h1>
          <div className="flex justify-around items-center space-x-8">
            <a
              href="https://www.tiktok.com/@learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={tiktoklogo}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="TikTok"
              />
            </a>
            <a
              href="https://www.instagram.com/learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l2}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Twitter"
              />
            </a>
            <a
              href="https://www.x.com/divyn7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l3}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinlogo}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Youtube"
              />
            </a>
            <a href="https://www.youtube.com/channel/UCUXvXYjTuhUBoMzmCyBErkQ" target="_blank" rel="noopener noreferrer">
<img
src={l4}
className="object-contain cursor-pointer filter brightness-0 invert transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
alt="Youtube"
/>
</a>
            
          </div>
        </div>
      </div>

      <hr style={{ border: "1px solid #FFFFFF" }} className="m-10" />
      <p className="mx-auto text-white text-center">
        © 2025 Nexa Inc. Copyright and rights reserved
      </p>
    </div>
  );
}
