import React from "react";
import BackButton from "../../../Components/BackButton";
import chevronDoubleRightIcon from "../../../assets/icons/chevronDoubleRight.svg";

const AllQuestions = ({ questions = [], role = "" , handleMode}) => {
  return (
    <div
      className="ic-all-question"
      style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
    >
      <div className="container">
      <button
      onClick={() => handleMode("question_info")}
        className={`absolute top-[8rem] z-50 left-5 bg-white rounded-full size-10 p-1 transition-all duration-500 border border-gray-200 hover:bg-gray-100 hover:border-gray-300 rotate-180`}
      >
        <img src={chevronDoubleRightIcon} alt="" />
      </button>
        <div className="ic-question-heading" style={{ marginTop: "150px" }}>
          <div className="row">
            <div className="col-lg-8 m-auto">
              <p className="support-text text-center">
                All of the questions for {role} are listed here. <br />
                To practice a question, click on it. To refine by kind, use the
                filter buttons.
              </p>
            </div>
          </div>
        </div>
        <div className="ic-all-questions-card">
          {questions.map((item, index) => (
            <div key="index" className="ic-question-card">
              <span className="question-tags">
                <i className="ri-error-warning-line" /> {item.type} Questions
              </span>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllQuestions;
