import React from "react";
import { motion } from "framer-motion";
import { ChevronRight } from "lucide-react";
import { StepBoardingStateAtom } from "../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import { produce } from "immer";

const WelcomeScreen = () => {
  const [_, setStepState] = useRecoilState(StepBoardingStateAtom);
  const welcomeData = [
    {
      heading:
        "Welcome to Nexa. You're about to embark on a Career journey like no other",
      para: "The following questions will personalize this journey for you.",
    },
  ];

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="max-w-4xl mx-auto text-center"
      >
        {welcomeData.map((term, index) => (
          <motion.div
            key={index + 100}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-6"
          >
            <h1 className="text-4xl font-bold text-gray-900 leading-tight">
              {term.heading}
            </h1>
            <p className="text-xl text-gray-600">{term.para}</p>
          </motion.div>
        ))}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mt-10"
        >
          <button
            onClick={() => {
              setStepState(
                produce((draft) => {
                  draft.isWelcomeBoard.activeStep = 1;
                })
              );
            }}
            className="group relative inline-flex items-center justify-center px-8 py-3 text-lg font-medium text-white bg-blue-600 rounded-full overflow-hidden transition-all duration-300 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <span className="relative flex items-center">
              I'm Ready
              <ChevronRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
            </span>
          </button>
        </motion.div>
      </motion.div>
    </>
  );
};

export default WelcomeScreen;
