import { atom } from "recoil";
import { IsNotSureInitialForm } from "../../Pages/Onboarding/components/steps/context";

const defaultState = {
  isActiveMode: "welcome",
  notSureKey: "not_sure",
  isWelcomeBoard: {
    activeStep: 0,
  },
  manualQuestionBoard: {
    activeStep: 0,
  },
  notSureQuestionBoard: {
    activeStep: 0,
    formData: IsNotSureInitialForm,
  },
  AIQuestionBoard: {
    activeStep: 0,
    isAiQuestions: [],
  },
  selectedJobRole: null,
  isProfilePayload: null,
  isCarierGoalModal: {
    mode: "onBoarding",
    value: false,
  },
  isCareerGoalBoarding: {
    isOpenBoarding: false,
    isOpenAiModal: false,
  },
  isBoardingLoading: false,
  applyRecommendationCareerSuggestion: null,
};

export const StepBoardingStateAtom = atom({
  key: "BoardingStepState",
  default: defaultState,
});

export { defaultState };
