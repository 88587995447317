import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import fourteen from "../../assets/Images/flip/image1.png";
import fifteen from "../../assets/Images/flip/image2.png";
import sixteen from "../../assets/Images/flip/image3.png";
import seventeen from "../../assets/Images/flip/image4.png";
import happywork from "../../assets/Images/happywork.png";
import hiringpipeline from "../../assets/Images/hiringpipeline.png";
import lifelike from "../../assets/Images/lifelikeinterview.png";
import newautopilot from "../../assets/Images/newautopilot.png";
import newiq from "../../assets/Images/newiq.png";
import newoctagon from "../../assets/Images/newoctagonimg.png";
import newvoyceimg from "../../assets/Images/newvoyce.png";
import orgdashimg from "../../assets/Images/orgdashimg.png";
import ResumeElevate from "../../assets/Images/resumeelevate.png";
import skillsgap from "../../assets/Images/skillsgap.png";
import dots from "../../assets/media/Landing/dots.svg";
import featurecardbg from "../../assets/media/Landing/featurescards.svg";
import lines from "../../assets/media/Landing/lines.svg";

const FeaturesSection = () => {
  const navigate = useNavigate();

  const images = [fourteen, fifteen, sixteen, seventeen];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const handlePrevious = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  // Navigate to the next image
  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <div className="section text-center">
        <div className="gradient-right"></div>
        <h1 className="section-heading color-dark">
          Focus ON Training, LET NEXA HELP WITH THE CAREER STUFF.{" "}
        </h1>
        <div className="container">
          <div
            className="feature-main-responsive feature-card row flex-md-row align-items-center
            justify-content-between my-5 py-5 feature-card-main"
          >
            <h1 className="feature-card-heading">
              An All-in-One Platform for the Entire Career Center Workflow
            </h1>
            <div
              className="col-12 col-md-4 feature-card-content"
              style={{ backgroundImage: "unset" }}
            >
              <p
                className="feature-card-subheading"
                style={{
                  fontSize: "15px",
                  lineHeight: "20px",
                  paddingTop: "15px",
                }}
              >
                At Nexa, we understand the challenges that come with managing
                multiple tools for career advisors, job seekers, and employers.
                The frustration of juggling different platforms can be
                overwhelming, not to mention costly and damaging to your brand’s
                integrity. Nexa brings everything you need into one cohesive,
                easy-to-use platform. Whether you’re handling student
                engagement, job placements, employer partnerships, or internal
                operations, Nexa simplifies the entire process, allowing you to
                focus on what truly matters—helping your students succeed. Say
                goodbye to the headaches of disjointed tools and experience the
                seamless efficiency of Nexa.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() =>
                  (window.location.href = "https://tally.so/r/3yDKdg")
                }
              >
                Learn more
              </button>
            </div>

            <div className="col-12 col-md-7 card-images position-relative custom-img-css">
              {/* Image with Navigation */}
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                STREAMLINE OPERATIONS & INCREASE YOUR ORGANIZATION'S EFFICIENCY
              </h1>
              <p className="feature-card-subheading">
                Enhance your organization's efficiency with Nexa. Automate
                administrative tasks, optimize career services, and provide
                personalized support, ensuring your job seekers’ success while
                saving time and resources. Put your job seekers at the center
                with streamlined operations designed for maximum impact.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={orgdashimg} className="card-main-img" />
            </div>
          </div>

          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={dots} className=" ipad-dots" />
              <img src={hiringpipeline} className="card-main-img" />
            </div>
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                A Direct Hiring Pipeline For Your Job Seekers
              </h1>
              <p className="feature-card-subheading">
                With Nexa’s Talent Hub, your job seekers gain immediate
                visibility to top employers actively seeking qualified talent.
                Showcase your job seekers’ verified skills and achievements to
                recruiters, creating a direct pathway from training to
                employment. Empower your program with Nexa, connecting your job
                seekers to high-demand job opportunities and improving your
                placement success.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
          </div>
          <h3
            className="section-heading color-dark"
            style={{ marginTop: "20px", marginBottom: "40px" }}
          >
            Every Tool You Need To Land A Job, In One Place{" "}
          </h3>
          <div className="features-main-card my-4">
            <div className="main-card-content">
              <h1 className="main-card-heading">
                LIGHTNING-FAST RESUME BUILDS
              </h1>
              <p className="main-card-subheading">
                Create polished, professional resumes in seconds with Nexa’s
                AI-powered Resume Builder. Tailored to specific job
                descriptions, our tool ensures your resume stands out to
                recruiters and passes ATS filters with ease.
              </p>
            </div>
            <div className="d-flex justify-content-center justify-content-lg-end position-relative">
              <img
                src={ResumeElevate}
                className="main-card-img"
                style={{ width: "55%" }}
              />
              {/* <img src={scanner} className="main-card-scanner" /> */}
            </div>
            <button
              className="feature-btn"
              onClick={() => navigate("/contact-us")}
            >
              Learn more
            </button>
          </div>

          {/* Features Cards */}
          {/* 1st Card */}
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 card-images position-relative">
              <img src={featurecardbg} className="card-img" />
              <img src={lifelike} className="feature-interview" />
            </div>
            <div className="col col-10 col-md-5 mb-4 mb-md-0 col-md-5 feature-card-content">
              <h1 className="feature-card-heading">
                LIFELIKE INTERVIEW SIMULATIONS
              </h1>
              <p className="feature-card-subheading">
                Prepare for any interview scenario with Nexa’s realistic
                AI-driven simulations. Receive real-time feedback on your
                answers, body language, and engagement to refine your approach
                and boost your confidence
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
          </div>
          {/* 2nd Card */}
          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 pb-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <img src={lines} className="lines_img" />
              <img src={dots} className="dots_img" />
              <h1 className="feature-card-heading">AI CAREER COUNSELING</h1>
              <p className="feature-card-subheading">
                Navigate your career path with guidance from Nexa Voyce, your
                personal AI career counselor. From exploring new career options
                to identifying skills gaps, Nexa Voyce provides personalized
                advice every step of the way.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>

            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={newvoyceimg} className="card-main-img" />
            </div>
          </div>
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={dots} className=" ipad-dots" />
              <img src={newautopilot} className="card-main-img" />
            </div>
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                Nexa Autopilot – Automatic Job Applications Made Simple
              </h1>
              <p className="feature-card-subheading">
                Nexa Autopilot takes the hassle out of job hunting by automating
                the application process. With Nexa Autopilot, you can set your
                preferences, and it will automatically apply to matching job
                openings on your behalf, ensuring you’re always first in line
                for opportunities that matter to you. You stay in control, and
                Autopilot handles the repetitive tasks, saving you time and
                increasing your chances of landing the right role without the
                stress of manual applications
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
          </div>

          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                Nexa IQ - Your Smart, Personalized Job Search
              </h1>
              <p className="feature-card-subheading">
                Nexa IQ is your ultimate job search interface that uses advanced
                algorithms to deliver personalized job recommendations just for
                you. It intelligently learns from your career goals, skills, and
                preferences, making every job listing relevant to your unique
                profile. Instead of wading through hundreds of irrelevant jobs,
                Nexa IQ pinpoints opportunities tailored to you, helping you
                stay ahead in the competitive job market. Its like have a
                personal recruiter in your pocket. Whether you’re in tech,
                healthcare, or any field, Nexa IQ provides a seamless, smart
                job-hunting experience
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={newiq} className="card-main-img" />
            </div>
          </div>
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={dots} className=" ipad-dots" />
              <img src={newoctagon} className="card-main-img" />
            </div>
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">Nexa Octagon</h1>
              <p className="feature-card-subheading">
                Nexa's Octagon allows you to gain experience in a specific role,
                making you stand out as a better candidate. Our engine uses AI
                to simulate hundreds of on-the-job scenarios based on specific
                companies.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                Discover Your Path With Nexa's Skills Gap Assessment
              </h1>
              <p className="feature-card-subheading">
                Struggling to figure out what’s holding you back from landing
                your dream job? Nexa’s Skills Gap Assessment identifies exactly
                what skills you’re missing and provides a clear roadmap to
                bridge the gap. We take the guesswork out of career growth by
                aligning your abilities with employer expectations and industry
                demands, so you can focus on what matters—building the skills
                that get you hired. Get personalized insights, actionable
                recommendations, and the confidence to take your next step.
                Whether you’re a job seeker or part of a training organization,
                Nexa empowers you to meet your goals faster and smarter.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </button>
            </div>
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={skillsgap} className="card-main-img" />
            </div>
          </div>
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
              <div className="col col-10 col-md-5 card-images position-relative">
                <img src={dots} className="ipad-dots" />
                <img
                  src={happywork}
                  className="card-main-img"
                  style={{
                    width: "80%", // Adjust width to make it smaller
                    height: "auto", // Maintain aspect ratio
                    maxHeight: "250px", // Decrease vertical height
                    borderRadius: "16px", // Apply rounded corners
                    display: "block",
                    margin: "auto", // Center the image
                    objectFit: "cover", // Ensure it maintains quality while resizing
                  }}
                />
              </div>
              <div className="col col-12 col-md-6 feature-card-content">
                <h1 className="feature-card-heading">
                  Your Partner Beyond the Platform
                </h1>
                <p className="feature-card-subheading">
                  At Nexa, we believe that exceptional software is only part of
                  the solution. That’s why we provide dedicated access to our
                  team for unparalleled support and consulting. From product
                  troubleshooting and personalized feature requests to expert
                  guidance for your career services staff, Nexa becomes an
                  extension of your in-house team. Whether you need help
                  navigating the platform or want to improve how your team
                  delivers results, we’re here every step of the way. With Nexa,
                  you’re never alone—we’re invested in your success
                </p>
                <button
                  className="feature-card-btn mt-4"
                  onClick={() => navigate("/contact-us")}
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesSection;
