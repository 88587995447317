import React, { useEffect, useState } from "react";
import SimulatorPro from "../SimulatorPro";
import { useParams } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { useSelector } from "react-redux";

export default function SmartScreenInterview() {
  const { token } = useParams();
  const { isSessionLoading } = useSelector(
    (state) => state.SimulatorProSession
  );
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (!isSessionLoading && count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1500);
      return () => clearTimeout(timer);
    }
  }, [isSessionLoading, count]);
  console.log("isSessionLoading: ", isSessionLoading);
  return (
    <>
      {isSessionLoading && <Loader />}
      {count != 0 && <div className="countdown-container flex flex-column justify-content-center align-items-center h-[100vh] bg-[#12121293] text-[#2b6be2] text-[6rem] font-bold">
        <h1 className="text-white mb-0 text-[2rem]">Your Interview will start in</h1>
         <div
          key={count}
          className={`countdown-text text-center ${
            count === 0 ? "go-text" : ""
          }`}
        >
          {count > 0 ? count : "Go!"}
        </div>
      </div>}
      {count === 0 && <SimulatorPro sessionsId={token} />}
    </>
  );
}
