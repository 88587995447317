import React from "react";

const TableShimmer = () => {
  return (
    <div className="animate-pulse w-full">
      {/* Table Header Shimmer */}
      <div className="w-full h-10 bg-gray-200 rounded mb-3" />

      {/* Table Rows Shimmer */}
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className="w-full h-12 bg-gray-100 rounded mb-2 flex items-center px-4"
        >
          <div className="w-1/4 h-6 bg-gray-300 rounded mr-4" />
          <div className="w-1/4 h-6 bg-gray-300 rounded mr-4" />
          <div className="w-1/4 h-6 bg-gray-300 rounded mr-4" />
          <div className="w-1/4 h-6 bg-gray-300 rounded" />
        </div>
      ))}
    </div>
  );
};

export default TableShimmer;
