import React from "react";
import { NotSureStepHeader } from "../../../../../../../Pages/Onboarding/components/steps/context";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";
import { INTERESTS } from "../../../../../../../Pages/Onboarding/components/boardingConstants";
import { produce } from "immer";
import CreatableSelect from "react-select/creatable";

const Step5 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;

  //FUNCTIONS
  const handleMultiSelectChange = (stepIndex, values) => {
    setStepState(
      produce((draft) => {
        draft.notSureQuestionBoard.formData[stepIndex].answer = values;
      })
    );
  };

  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Interests and Preferences"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <CreatableSelect
          className="basic-single w-full mt-3"
          classNamePrefix="select"
          defaultValue={[]}
          isMulti
          isClearable
          isSearchable
          name="color"
          options={INTERESTS}
          isLoading={false}
          menuPortalTarget={document.body}
          value={formData[currentStep - 1].answer}
          onChange={(selectedOptions) =>
            handleMultiSelectChange(currentStep - 1, selectedOptions)
          }
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
    </div>
  );
};

export default Step5;
