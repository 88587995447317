import { useState, useEffect, useRef, useCallback } from "react";
import { X } from "lucide-react";
import { useDispatch } from "react-redux";
import { produce } from "immer";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../../recoil/Loadings";
import ProgressBar from "../../../Pages/Onboarding/components/steps/components/common/ProgressBar";
import OnBoardingShimmer from "../../../Components/Shimmers/OnBoardingShimmer";
import NavigationButtons from "../../../Pages/Onboarding/components/steps/components/common/NavigationButtons";
import AIBoardingContent from "../../../Pages/Onboarding/components/steps/components/AiComponents/AIBoardingContent";
import { useSelector } from "react-redux";
import {
  defaultState,
  StepBoardingStateAtom,
} from "../../../recoil/onBoarding";
import {
  generateAiQuestions,
  generateLiveSkillsGapAnalysis,
  getMyAiBoardingQuestions,
  saveAiQuestions,
  updateAiQuestionOnboarding,
} from "../../../Redux/authenticate/authenticate.action";
import { error, success } from "../../../Utilities/toast";
import {
  getAiCurrentSlug,
  getCurrentAiAnswer,
  isAiAnswerModified,
  isCurrentAnswerValids,
} from "../../../Pages/Onboarding/components/steps/context";
import { updateIsUserStatus } from "../../../Redux/authenticate/authenticate.slicer";

const EditLiveSkillsReportModal = ({ updateLoadingState }) => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [originalAnswers, setOriginalAnswers] = useState([]);
  const { onboardingAiData, user } = useSelector((state) => state.authenticate);

  // Derived state
  const step = stepState.AIQuestionBoard.activeStep;
  const currentAnswer = getCurrentAiAnswer(stepState);
  const isAiQuestions = stepState.AIQuestionBoard.isAiQuestions;
  const isLastQuestion = step >= isAiQuestions.length;

  const prepareQuestionData = useCallback(() => {
    const currentQuestion = getAiCurrentSlug(isAiQuestions, step);
    const existingQuestion = onboardingAiData?.find(
      (item) => item.question === currentQuestion
    );
    const modified = isAiAnswerModified(step, originalAnswers, currentAnswer);

    const questionData = {
      question_slug: currentQuestion,
      answer: currentAnswer,
      modified,
    };

    if (!existingQuestion) {
      return { operation: "create", ...questionData };
    }

    return {
      operation: "update",
      id: existingQuestion.id,
      ...questionData,
    };
  }, [isAiQuestions, step, onboardingAiData, originalAnswers, currentAnswer]);

  const closeModal = () => {
    setStepState(
      produce((draft) => {
        draft.isCareerGoalBoarding.isOpenAiModal = false;
      })
    );
  };

  const saveCurrentQuestion = useCallback(
    (isNextBoard) => {
      if (step > 0) {
        const currentQuestion = isAiQuestions[step - 1];

        const isExisting = onboardingAiData.some(
          (item) => item.question === currentQuestion.question
        );
        if (isExisting) return isNextBoard && closeModal();
        dispatch(
          saveAiQuestions({
            payload: isAiQuestions[step - 1],
            onSuccess: () => {
              dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
              isNextBoard && closeModal();
            },
            onError: () => {},
          })
        );
      }
    },
    [dispatch, isAiQuestions, step]
  );

  const handleNext = useCallback(
    async (isNextBoard) => {
      if (!isCurrentAnswerValids(currentAnswer)) {
        return error("Please answer the question");
      }
      try {
        const questionData = prepareQuestionData();
        if (!questionData) return;
        updateLoadingState(true);
        if (questionData.operation === "update" && questionData.modified) {
          await dispatch(
            updateAiQuestionOnboarding({
              payload: {
                new_answer: questionData.answer,
              },
              aiQuestionId: questionData.id,
              onSuccess: () => {
                isNextBoard && closeModal();
                success("AI Question Updated Successfully");
                dispatch(getMyAiBoardingQuestions({ onComplete: () => {} }));
              },
              onError: () => {},
            })
          );
        }
        if (isNextBoard) {
          saveCurrentQuestion(isNextBoard);
          setStepState(defaultState);
          dispatch(updateIsUserStatus());
          generateAiFeedback();
          return;
        }
        if (isAiQuestions.length < 5 && isLastQuestion) {
          await dispatch(
            generateAiQuestions({
              AiBoardingPayload: {
                target_career: user?.target_career?.split("#")[0],
                previous_questions: isAiQuestions,
              },
              onSuccess: (res) => {
                success("AI Questions Generated Successfully");
                updateLoadingState(false);
                saveCurrentQuestion();
                setStepState(
                  produce((draft) => {
                    draft.AIQuestionBoard.isAiQuestions.push({
                      question: res,
                      answer: null,
                    });
                    draft.AIQuestionBoard.activeStep += 1;
                  })
                );
              },
              onError: () => {
                updateLoadingState(false);
              },
            })
          );
        } else {
          setStepState(
            produce((draft) => {
              draft.isActiveMode = "AI";
              draft.AIQuestionBoard.activeStep += 1;
              draft.isBoardingLoading = false;
            })
          );
          updateLoadingState(false);
        }
      } catch (err) {
        updateLoadingState(false);
        console.error("Error handling next step:", err);
      }
    },
    [
      currentAnswer,
      prepareQuestionData,
      updateLoadingState,
      dispatch,
      isAiQuestions,
      isLastQuestion,
      user,
      saveCurrentQuestion,
      setStepState,
      // navigate,
    ]
  );

  const handleBack = useCallback(() => {
    setStepState(
      produce((draft) => {
        if (draft.AIQuestionBoard.activeStep === 1) return;
        draft.AIQuestionBoard.activeStep -= 1;
      })
    );
  }, [setStepState]);

  const updateLiveSkillGapLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.careerGoals.liveSkillsGapAssessment = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const generateAiFeedback = () => {
    updateLiveSkillGapLoadingState(true);
    dispatch(
      generateLiveSkillsGapAnalysis({
        onSuccess: () => {
          updateLiveSkillGapLoadingState(false);
        },
        onError: () => {
          updateLiveSkillGapLoadingState(false);
          error("Something went wrong, please try again later");
        },
      })
    );
  };

  const handleSaveLater = () => {
    setStepState(defaultState);
    generateAiFeedback();
    closeModal();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (stepState.isCareerGoalBoarding.isOpenAiModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [stepState.isCareerGoalBoarding.isOpenAiModal, closeModal]);

  useEffect(() => {
    if (onboardingAiData?.length > 0) {
      const initialAnswers = onboardingAiData.map((item) => ({
        question: item.question,
        answer: item.answer,
      }));

      if (onboardingAiData.length > isAiQuestions.length) {
        setStepState(
          produce((draft) => {
            draft.AIQuestionBoard.isAiQuestions = initialAnswers;
          })
        );
      }
      setOriginalAnswers(initialAnswers);
    }
  }, [
    onboardingAiData,
    isAiQuestions.length,
    setStepState,
    stepState.isCareerGoalBoarding.isOpenAiModal,
  ]);

  if (!stepState.isCareerGoalBoarding.isOpenAiModal) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm transition-opacity">
      <div
        ref={modalRef}
        className="w-4/5 max-w-4xl max-h-[90vh] bg-white rounded-2xl shadow-2xl flex flex-col transform transition-all animate-fadeIn"
      >
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100">
          <h3 className="text-xl font-semibold text-gray-800">
            Edit Skills Gap Assessment Report
          </h3>
          <button
            onClick={closeModal}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
          >
            <X size={20} className="text-gray-500" />
          </button>
        </div>

        <div className="p-6 flex-grow overflow-auto">
          <div className="mb-4">
            <div className="px-2">
              <div className="flex flex-col justify-center">
                <div className="max-w-3xl w-full">
                  {loadingState.onBoarding.aiQuestionBoard ? (
                    <OnBoardingShimmer steps={5} activeStep={step} />
                  ) : (
                    <>
                      <ProgressBar steps={5} activeStep={step} />
                      <AIBoardingContent />
                      <NavigationButtons
                        activeStep={step}
                        totalSteps={5}
                        onBack={handleBack}
                        onNext={handleNext}
                        onSaveLater={handleSaveLater}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 py-4 border-t border-gray-100 flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 transition-colors duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditLiveSkillsReportModal;
