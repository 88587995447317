import React from "react";
import TextQuestionStep from "../../../../../../Pages/Onboarding/components/steps/components/common/TextQuestionStep";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../recoil/onBoarding";
import { produce } from "immer";

const AIBoardingContent = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  //FUNCTIONS
  const onAnswerChange = (value) => {
    const currentQuestion =
      stepState.AIQuestionBoard.isAiQuestions[
        stepState.AIQuestionBoard.activeStep - 1
      ]?.question;

    setStepState(
      produce((draft) => {
        const existingIndex = draft.AIQuestionBoard.isAiQuestions.findIndex(
          (item) => item?.question === currentQuestion
        );

        if (existingIndex !== -1) {
          draft.AIQuestionBoard.isAiQuestions[existingIndex].answer = value;
        } else {
          draft.AIQuestionBoard.isAiQuestions.push({
            question: currentQuestion,
            answer: value,
          });
        }
      })
    );
  };

  return (
    <TextQuestionStep
      question={
        stepState.AIQuestionBoard.isAiQuestions[
          stepState.AIQuestionBoard.activeStep - 1
        ]?.question
      }
      value={
        stepState.AIQuestionBoard.isAiQuestions[
          stepState.AIQuestionBoard.activeStep - 1
        ]?.answer || ""
      }
      onChange={onAnswerChange}
    />
  );
};

export default AIBoardingContent;
