import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import {
  getAiSkillGapAssessmentReport,
  getOnBoardingAction,
  getRecommendCareerSuggestion,
  getSkillsAnalysis,
} from "../../Redux/authenticate/authenticate.action";
import "./user.css";
import SkillGapInformation from "./components/SkillGapInformation";
import CarrierSuggestion from "./components/CarrierSuggestion";
import LiveSkillReport from "./components/LiveSkillReport";
import OrignalSkillGap from "./components/OrignalSkillGap";
import { useRecoilState } from "recoil";
import { StepLoadingAtom } from "../../recoil/Loadings";
import { produce } from "immer";
import { CircleHelp, Edit2 } from "lucide-react";
import { StepBoardingStateAtom } from "../../recoil/onBoarding";
import SelectCareerGoal from "../../Pages/Onboarding/components/modals/SelectCareerGoal";
import CareerOnBoarding from "./components/OnBoarding";

const UserDashboard2 = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  console.log("user: ", user);
  //RECOIL
  const [_, setLoadingState] = useRecoilState(StepLoadingAtom);
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  useEffect(() => {
    setLoadingState(
      produce((draft) => {
        draft.careerGoals.recommendCareersSuggestions = true;
        draft.careerGoals.skillsGapAssessment = true;
        draft.careerGoals.liveSkillsGapAssessment = true;
      })
    );
    dispatch(getOnBoardingAction());
    dispatch(
      getSkillsAnalysis({
        onComplete: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.skillsGapAssessment = false;
            })
          );
        },
      })
    );

    dispatch(
      getRecommendCareerSuggestion({
        isRetake: false,
        carier_goal: user?.target_career?.replace(/\//g, " ")?.split("#")[0],
        onComplete: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.recommendCareersSuggestions = false;
            })
          );
        },
      })
    );
    dispatch(
      getAiSkillGapAssessmentReport({
        onComplete: () => {
          setLoadingState(
            produce((draft) => {
              draft.careerGoals.liveSkillsGapAssessment = false;
            })
          );
        },
      })
    );
  }, []);
  return (
    <>
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          paddingTop: "6em",
        }}
      >
        <main role="main" className=" ml-sm-auto col-lg-12 px-4 bg-cover">
          {/* Skills Gap information */}
          <SkillGapInformation />

          {/* Target Career Goal box */}
          <div className="relative bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-12 mx-auto max-w-5xl mt-6 mb-8">
            <div className="space-y-4">
              <div className="text-left space-y-2">
                <div className="flex justify-between items-center border-b border-gray-100 pb-3">
                  <h1 className="text-2xl font-semibold">
                    Target Career Goal:
                  </h1>
                  <div className="relative group flex items-center">
                    <CircleHelp size={25} className="cursor-pointer" />
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-max px-3 py-1 text-sm text-white bg-gray-800 rounded-md opacity-0 group-hover:opacity-100 transition-opacity max-w-64">
                      You can update also your target career preference from
                      your profile settings under your skillprint
                    </div>
                  </div>
                </div>

                <p className="text-lg text-gray-600">
                  {user?.target_career === "not_sure"
                    ? "You Are Still Deciding!"
                    : user?.target_career?.split("#")[0]}
                </p>
              </div>
            </div>
          </div>

          {/* Recommend Careers suggestions report */}
          <CarrierSuggestion />

          {/* Skills Gap Assessment */}
          <OrignalSkillGap />

          {/* Live Skills gap assessment report */}
          <LiveSkillReport />
        </main>

        {/* Select Career Goal Modal */}
        <SelectCareerGoal />
        <CareerOnBoarding />
      </div>
    </>
  );
};

export default UserDashboard2;
