import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import logo from "../../../../assets/media/images/logo.jpeg";
import { produce } from "immer";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import {
  getCurrentManualAnswer,
  getManualCurrentSlug,
  isAnswerModified,
  isCurrentAnswerValids,
  MANUAL_QUESTIONS_SLUG_CONFIGRATION,
} from "./context";
import Header from "../../../../Pages/Onboarding/components/steps/components/common/Header";
import ProgressBar from "../../../../Pages/Onboarding/components/steps/components/common/ProgressBar";
import NavigationButtons from "../../../../Pages/Onboarding/components/steps/components/common/NavigationButtons";
import ManualBoardingContent from "../../../../Pages/Onboarding/components/steps/components/ManualComponents/ManualBoardingContent";
import {
  generateAiQuestions,
  getOnBoardingAction,
  onBoardingAction,
  updateOnboarding,
} from "../../../../Redux/authenticate/authenticate.action";
import { error, success } from "../../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import OnBoardingShimmer from "../../../../Components/Shimmers/OnBoardingShimmer";
import { StepLoadingAtom } from "../../../../recoil/Loadings";

const ManualQuestionsBoard = () => {
  //EXTRACT
  const fileInput = useRef(null);

  //REDUX
  const dispatch = useDispatch();
  const { onboardingData, onboardingAiData, user, loading } = useSelector(
    (state) => state.authenticate
  );

  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);
  const [loadingState, setLoadingState] = useRecoilState(StepLoadingAtom);

  //STATE
  const [answers, setAnswers] = useState({});
  const [originalAnswers, setOriginalAnswers] = useState({});

  //EXTRACT
  const step = stepState.manualQuestionBoard.activeStep;
  const currentAnswer = getCurrentManualAnswer(step, onboardingData, answers);
  const AiBoardingPayload = {
    target_career: user?.target_career?.split("#")[0],
    previous_questions: [],
  };

  //FUNCTIONS
  const updateLoadingState = useCallback(
    (isLoading) => {
      setLoadingState(
        produce((draft) => {
          draft.onBoarding.manualQuestionBoard = isLoading;
        })
      );
    },
    [setLoadingState]
  );

  const prepareQuestionData = () => {
    const currentSlug = getManualCurrentSlug(step);
    const existingQuestion = onboardingData?.find(
      (item) => item.question_slug === currentSlug
    );
    const modified = isAnswerModified(
      originalAnswers,
      currentSlug,
      currentAnswer,
      fileInput
    );
    const questionData = {
      question:
        step === 8
          ? "Resume Upload"
          : MANUAL_QUESTIONS_SLUG_CONFIGRATION[step]?.text,
      question_slug: currentSlug,
      answer: currentAnswer,
      modified,
    };
    if (step === 8 && fileInput.current?.files?.[0]) {
      questionData.file = fileInput.current.files[0];
    }

    const operationData = {
      operation: existingQuestion ? "update" : "create",
      ...questionData,
    };

    if (existingQuestion) {
      operationData.id = existingQuestion.id;
    }

    return operationData;
  };

  const generateAiQuestion = () => {
    if (
      onboardingAiData.length > 0 ||
      stepState.AIQuestionBoard.isAiQuestions.length > 0
    ) {
      setStepState(
        produce((draft) => {
          draft.isActiveMode = "AI";
          draft.AIQuestionBoard.activeStep = 1;
        })
      );
      updateLoadingState(false);
      return;
    }

    dispatch(
      generateAiQuestions({
        AiBoardingPayload,
        onSuccess: (res) => {
          success("AI Questions Generated Successfully");
          updateLoadingState(false);
          setStepState(
            produce((draft) => {
              draft.AIQuestionBoard.isAiQuestions.push({
                question: res,
                answer: null,
              });
              draft.isActiveMode = "AI";
              draft.AIQuestionBoard.activeStep = 1;
            })
          );
        },
        onError: () => {
          updateLoadingState(false);
        },
      })
    );
  };

  const getOnBoardingDataOnNext = async (operation, isNextBoard) => {
    if (isNextBoard) {
      generateAiQuestion();
    } else {
      setStepState(
        produce((draft) => {
          draft.manualQuestionBoard.activeStep += 1;
        })
      );
    }

    await dispatch(
      getOnBoardingAction({
        onComplete: () => {
          updateLoadingState(false);
        },
      })
    );
    success(`${operation === "create" ? "Created" : "Updated"} Successfully`);
  };

  const handleNext = async (isNextBoard) => {
    if (!isCurrentAnswerValids(currentAnswer))
      return error("Please answer the question");
    try {
      const questionData = prepareQuestionData();
      if (!questionData) return;
      const payload = {
        question_slug: questionData.question_slug,
        ...(questionData.answer instanceof File
          ? {
              file: questionData.answer,
              target_career: user?.target_career,
              customer_name: user?.name,
            }
          : { answer: questionData.answer }),
      };
      updateLoadingState(true);
      const { operation, modified } = questionData;
      let response;
      if (operation === "create") {
        response = await dispatch(
          onBoardingAction({
            payload,
            onSuccess: () => {},
            onError: () => {
              updateLoadingState(false);
            },
          })
        ).unwrap();
      } else if (operation === "update" && modified) {
        response = await dispatch(
          updateOnboarding({
            payload,
            onSuccess: () => {},
            onError: () => {
              updateLoadingState(false);
            },
          })
        ).unwrap();
      } else {
        if (isNextBoard) {
          generateAiQuestion();
        } else {
          setStepState(
            produce((draft) => {
              draft.manualQuestionBoard.activeStep += 1;
            })
          );
          updateLoadingState(false);
        }
      }
      if (response) {
        getOnBoardingDataOnNext(operation, isNextBoard);
      }
    } catch (error) {
      updateLoadingState(false);
      console.error("Error handling next step:", error);
    }
  };

  const handleBack = () => {
    setStepState(
      produce((draft) => {
        if (draft.manualQuestionBoard.activeStep === 1) {
          draft.isActiveMode = "welcome";
          draft.manualQuestionBoard.activeStep = 0;
          return;
        }
        draft.manualQuestionBoard.activeStep -= 1;
      })
    );
  };

  const handleSaveLater = () => {
    updateLoadingState(true);
    generateAiQuestion();
  };

  //USE EFFECT
  useEffect(() => {
    if (onboardingData?.length > 0) {
      const initialAnswers = onboardingData.reduce((acc, item) => {
        acc[item.question_slug] = item.answer;
        return acc;
      }, {});
      setAnswers(initialAnswers);
      setOriginalAnswers(initialAnswers);
    }
  }, [onboardingData]);

  return (
    <div className="layout-root">
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 px-20">
        <div className="col-span-2">
          <div className="min-h-screen pt-20 flex flex-col justify-center">
            <div className="max-w-3xl w-full">
              {loadingState.onBoarding.manualQuestionBoard ? (
                <OnBoardingShimmer steps={8} activeStep={step} />
              ) : (
                <>
                  <ProgressBar steps={8} activeStep={step} />

                  <ManualBoardingContent
                    stepState={stepState}
                    fileInput={fileInput}
                    currentAnswer={getCurrentManualAnswer(
                      step,
                      onboardingData,
                      answers
                    )}
                    setAnswers={setAnswers}
                  />

                  <NavigationButtons
                    activeStep={step}
                    totalSteps={8}
                    onBack={handleBack}
                    onNext={handleNext}
                    onSaveLater={handleSaveLater}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center">
          <img
            src={logo}
            className="img-fluid"
            style={{ width: "644px" }}
            alt="."
          />
        </div>
      </div>
    </div>
  );
};

export default ManualQuestionsBoard;
