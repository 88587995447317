import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ContinueIndividualModal from "../../Components/Confirmation/ContinueIndividualModal";
import Loader from "../../Components/main-loader";
import { stripeCheckoutAction } from "../../Redux/DashboardSlice";
import { getThemeColors } from "../../Redux/GlobalStates";
import {
  authenticateAction,
  continueIndividualAction,
  getUserSubscription,
  loginUser,
  ssoLoginAction,
} from "../../Redux/authenticate/authenticate.action";
import { error, success } from "../../Utilities/toast";
import "./style.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");

  const { loading } = useSelector((state) => state.authenticate);

  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [statusApi, setStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const [continueIndividual, setContinueIndividual] = useState(false);
  const [individualConfirm, setIndividualConfirm] = useState(false);
  const [confirmationLoader, setConfirmationLoader] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await dispatch(authenticateAction(response.access_token))
          .unwrap()
          .then((res) => {
            dispatch(ssoLoginAction(response.access_token))
              .unwrap()
              .then((ssoResponse) => {
                dispatch(getUserSubscription())
                  .unwrap()
                  .then((res) => {
                    if (jobId) {
                      if (
                        !ssoResponse?.organizationId ||
                        (res?.data?.is_nexa_iq && ssoResponse?.organizationId)
                      ) {
                        navigate(`/IQ/jobboard?jobId=${jobId}`);
                      } else {
                        navigate(`/nexa-iq?jobId=${jobId}`);
                      }
                    } else if (ssoResponse?.isNewUser) {
                      registerForTrialBundle();
                      navigate("/onboarding");
                    } else {
                      navigate("/home");
                    }
                  })
                  .catch((err) => {
                    console.log("Error getting user subscription: ", err);
                    navigate("/home");
                  });
              });
          });
      } catch (err) {
        console.log(err);
      }
    },
    onError: (err) => console.log("Login Failed:", err),
    redirectUri: "http://localhost:3001",
  });

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const registerForTrialBundle = async () => {
    let freePlanID = "2lNbMnPO8W";
    return dispatch(stripeCheckoutAction(freePlanID))
      .unwrap()
      .then((res) => {
        console.log("Registered for TRIAL plan: ", res);
        // navigate("/onboarding");
        return res;
      })
      .catch((err) => {
        console.error(err);
      });

    // return dispatch(getPricingBundles())
    //   .unwrap()
    //   .then((res) => {
    // console.log(res);
    // const trialBundlePlanId = res.plans.filter(
    //   (plan) => plan.planName === "TRIAL"
    // )[0].bundlePlanId;
    // console.log(trialBundlePlanId);
    // });
  };

  const login = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let payload = {
        username: email,
        password,
      };
      dispatch(loginUser(payload))
        .unwrap()
        .then((resolvedData) => {
          dispatch(getUserSubscription())
            .unwrap()
            .then((res) => {
              if (jobId) {
                if (res?.data?.is_nexa_iq && resolvedData?.organizationId) {
                  navigate(`/IQ/jobboard?jobId=${jobId}`);
                } else {
                  navigate(`/nexa-iq?jobId=${jobId}`);
                }
              } else if (resolvedData?.revoked) {
                setContinueIndividual(true);
              } else if (resolvedData?.isNewUser) {
                registerForTrialBundle();
                navigate("/onboarding");
              } else {
                navigate("/home");
              }
            })
            .catch((err) => {
              console.log("Error getting user subscription: ", err);
              navigate("/home");
            });
          if (resolvedData?.organizationId) {
            dispatch(getThemeColors());
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error("Login failed", rejectedValueOrSerializedError);
        });
    }
  };

  useEffect(() => {
    if (individualConfirm) {
      setConfirmationLoader(true);
      dispatch(continueIndividualAction())
        .unwrap()
        .then((res) => {
          let freePlanID = "2lNbMnPO8W";
          dispatch(stripeCheckoutAction(freePlanID))
            .unwrap()
            .then((resolvedData) => {
              if (resolvedData.redirectUrl !== null) {
                console.log(resolvedData);
                window.location = resolvedData.redirectUrl;
              } else {
                success(resolvedData.detail);
              }
              dispatch(getUserSubscription());
              navigate("/home");
              setConfirmationLoader(false);
            });
        })
        .catch((err) => {
          setConfirmationLoader(false);
          error(err.response.data.detail);
        });
    }
  }, [individualConfirm]);

  if (loading || confirmationLoader) {
    return <Loader />;
  }

  return (
    <div>
      <div className="ic-login">
        <div className="ic-login-box">
          <Link to="/" className="ic-auth-logo">
            <img src="/images/logo.png" alt="logo" style={{ width: "220px" }} />
          </Link>
          <p className="text-center auth-sub-title">
            Land Your Next Role with Nexa
          </p>
          <h4 className="text-center auth-title pb-1">Login</h4>

          <div className="auth-social">
            <button onClick={googleLogin} className="ic-google">
              <img src="/images/google.png" alt="" /> Sign in with Google
            </button>
          </div>
          <div className="or">
            <p>Or</p>
          </div>

          {statusApi && (
            <p
              className={
                statusApi.includes("successful")
                  ? "text-success"
                  : "text-danger"
              }
            >
              {statusApi}
            </p>
          )}

          <form onSubmit={login}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="example@nexa.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="position-relative">
                <input
                  type={passwordFieldType}
                  id="password"
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Type password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  className={`eye-password ${
                    passwordFieldType === "text"
                      ? "ri-eye-off-line"
                      : "ri-eye-line"
                  }`}
                  onClick={() =>
                    setPasswordFieldType(
                      passwordFieldType === "text" ? "password" : "text"
                    )
                  }
                />
              </div>
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
            </div>

            <div className="remember-me-wrap">
              <div className="">
                {/* <input
                  className="form-check-input"
                  type="checkbox"
                  id="remember"
                />
                <label className="form-check-label" htmlFor="remember">
                  Remember me
                </label> */}

                <label className="flex items-center justify-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <span className="form-check-label">Remember me</span>
                </label>
              </div>
              <Link to="/reset-password" className="have-text pt-0">
                Forgot <span className="text-primary">Password</span>
              </Link>
            </div>
            <button
              type="submit"
              className="ic-btn w-100"
              disabled={loading}
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              {/* Login */}
              Login
            </button>
          </form>
          <p className="have-text">
            New user?{" "}
            <Link to={`/register?${searchParams.toString()}`}>
              Create an account
            </Link>
          </p>
        </div>
      </div>
      <ContinueIndividualModal
        show={continueIndividual}
        setShow={setContinueIndividual}
        Confirmation={setIndividualConfirm}
      />
    </div>
  );
};

export default Login;
