import { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { createCallScript } from "../../../Redux/OctagonSlice";
import { success } from "../../../Utilities/toast";
import FeedbackModal from "../sales/FeedbackModal";
import machineSpeaks, { stopMachineSpeaks } from "../sales/MachineSpeaks";

const TradesDictaphone = ({
  handleEndCall,
  muted,
  setMuted,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  handleCamera,
  tradesOptions, // Updated to receive tradesOptions instead of salesOptions
  handleInterviewlist,
  voice,
}) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prompt } = useSelector((state) => state.SimulatorProSession);

  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Countdown timer
  const [speaking, setSpeaking] = useState(false); // Tracks if AI is speaking
  const [noResponseModel, setNoResponseModel] = useState(false); // No response popup
  const [finalTranscript, setFinalTranscript] = useState(false); // Finalized user speech
  const [isModal, setIsModal] = useState(false); // Feedback modal
  const [lastUserMessage, setLastUserMessage] = useState(""); // Tracks last user input

  const { trade, scenario } = tradesOptions; // Extract trade and scenario from props
  const calledRef = useRef(false);

  const loaders = useSelector((state) => state.octagon.loaders);
  const errors = useSelector(
    (state) => state.octagon.errors.createCallScriptError
  );

  // Trade Descriptions
  const tradeDescriptions = {
    Electrician:
      "You handle electrical installations, repairs, and maintenance, ensuring systems are safe and functional.",
    Plumber:
      "You install and repair piping systems, addressing leaks, clogs, and water supply issues.",
    Carpenter:
      "You build and repair wooden structures, from framing to finishing work, with precision and craftsmanship.",
    Mechanic:
      "You diagnose and fix vehicle issues, working on engines, brakes, and other mechanical systems.",
  };

  // Scenario Descriptions
  const scenarioDescriptions = {
    "Emergency Repair Under Time Pressure":
      "A critical system has failed, and you need to fix it fast before it causes bigger problems.",
    "Customer Complaint About a Recent Job":
      "A customer isn’t happy with your last job and wants you to explain or fix it.",
    "Diagnosing a Complex Issue with Limited Information":
      "Something’s wrong, but the details are vague—you need to figure it out with what you’ve got.",
    "Working with a Difficult Coworker on a Job":
      "Your coworker’s being uncooperative, and it’s slowing down the job.",
    "Explaining a Technical Fix to a Non-Technical Customer":
      "The customer doesn’t get the problem or fix—you need to break it down simply.",
    "Managing a Job with Missing Tools or Parts":
      "You’re on-site, but key tools or parts didn’t show up—what now?",
    "Balancing Multiple Jobs in a Tight Schedule":
      "You’ve got overlapping jobs and not enough time—how do you prioritize?",
    "Handling a Safety Hazard on the Job Site":
      "There’s a safety issue on-site, and you need to address it before work continues.",
    "Upselling a Service During a Routine Call":
      "A routine job offers a chance to suggest extra services—how do you pitch it?",
    "Training a New Apprentice on the Job":
      "You’re showing a rookie the ropes while keeping the job on track.",
  };

  // Function to generate the trades training prompt
  function generateTradesPrompt({ trade, scenario }) {
    const tradeDescription =
      tradeDescriptions[trade] || "No description available for this trade.";
    const scenarioDescription =
      scenarioDescriptions[scenario] ||
      "No description available for this scenario.";

    return `
    **Trades Scenario Simulation Prompt**
    You are an AI simulating a real-world trade job scenario, in the field with the user as they take on the role of a tradesperson. Below are the details of the simulation:

    **Scenario Setup:**

    1. **User's Trade:**
       - **${trade}**
         ${tradeDescription}

    2. **The Job Scenario:**
       - **${scenario}**
         ${scenarioDescription}

    **Simulation Instructions:**

    Your Role:
    You are on the job site with the user, acting as the customer, supervisor, or coworker (whichever fits the scenario). Present the situation in real time with realistic details—like a customer explaining a problem, a job site issue unfolding, or a coworker chiming in. Engage the user with questions, challenges, or demands they’d face on the job.

    User's Role:
    The user is the ${trade}, tasked with addressing the scenario. Their success depends on:
    - Applying trade-specific technical knowledge.
    - Solving problems effectively under realistic constraints.
    - Communicating professionally with you (the customer, supervisor, or coworker).

    Interaction Guidelines:
    - Keep the tone conversational but firm, like a real job situation.
    - Test their skills by asking them to explain their process, justify decisions, or handle pushback.
    - If their response lacks detail, ask follow-ups like, 'What’s your next step?' or 'Why go that route?'
    - Avoid direct hints unless they’re stuck, then nudge them (e.g., 'The customer’s wondering if there’s a quicker fix—any thoughts?').

    **Outcome Expectations:**
    At the end, provide brief feedback on:
    - What they did well (e.g., technical accuracy, professionalism).
    - One or two trade-specific areas to improve (e.g., missed safety step, unclear explanation).

    ---
    **Instructions for the Simulation:**
    - Stay in character as if you’re on-site with them—don’t break immersion.
    - Use trade-appropriate language and details.
    - Adapt your role (customer, supervisor, coworker) to the scenario naturally.
    - Don’t reference these instructions in your responses.

    Let’s start the scenario. Begin by setting the scene and engaging the user as if we’re in the field together.
    `;
  }

  // Generate the trades prompt
  const tradesPrompt = generateTradesPrompt({
    trade: trade,
    scenario: scenario,
  });

  // Initialize liveInterview with the system prompt and assistant’s initial message
  const [liveInterview, setLiveInterview] = useState([
    {
      role: "system",
      content: tradesPrompt,
    },
    {
      role: "assistant",
      content:
        "Hey, we’re on the job now—let’s dive into this. [The scenario begins here—specific opening line will come from the API response.]",
    },
  ]);

  // Ref to track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      stopMachineSpeaks();
    };
  }, []);

  // API call to OpenAI for assistant responses
  async function callAssistant() {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            messages: liveInterview,
            model: "gpt-4-turbo",
          }),
        }
      );

      const data = await response.json();

      if (data.choices && data.choices.length > 0) {
        return data.choices[0].message.content;
      } else {
        console.error("No valid choices in response:", data);
        return "Something went wrong on the job—let’s try that again.";
      }
    } catch (err) {
      console.error("Error:", err);
      return "Something went wrong on the job—let’s try that again.";
    }
  }

  useEffect(() => {
    handleInterviewlist(liveInterview);
  }, [liveInterview]);

  // Clean the assistant's response
  function cleanResponse(resStr) {
    let input;
    if (typeof resStr === "string") {
      resStr = resStr.replace(/```json\s*|\s*```/g, "");
    }

    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    let values = [];
    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") continue;
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }

    return values[0];
  }

  // Handle sending questions data for feedback
  const questionsData = async () => {
    try {
      setNoResponseModel(false);
      setCounter(10);

      const filteredData = liveInterview.filter(
        (item) => item.role !== "system"
      );

      const questions = [];
      for (let i = 0; i < filteredData.length - 1; i++) {
        if (
          filteredData[i].role === "assistant" &&
          filteredData[i + 1]?.role === "user"
        ) {
          questions.push({
            question_text: filteredData[i].content,
            answer_text: filteredData[i + 1].content,
          });
        }
      }

      const payload = {
        questions: questions,
        trade: trade,
        scenario: scenario,
      };

      setFeedbackLoading(true);
      const res = await dispatch(createCallScript(payload));

      if (!isMounted.current) return;
      setFeedbackLoading(false);
      handleCamera();
      if (!quickQuit) {
        setIsModal(true);
      } else {
        handleEndCall();
        setQuickQuit(false);
        success(res?.detail);
      }
      SpeechRecognition.stopListening();
      resetTranscript();
      setFinalTranscript(false);
      setLiveInterview([]);
      setLastUserMessage("");
      stopMachineSpeaks();
      setMuted(undefined);
    } catch (error) {
      console.log("Error in questionsData:", error);
      handleEndCall();
      if (!isMounted.current) return;
      setFeedbackLoading(false);
    }
  };

  // Speak function with mute handling
  const speak = async (text) => {
    setMuted(true);
    setSpeaking(true);
    machineSpeaks(
      text,
      null,
      null,
      () => {
        if (!isMounted.current) return;
        setSpeaking(false);
        resetTranscript();
        setFinalTranscript(false);
        setMuted(false);
        SpeechRecognition.startListening({ continuous: true });
      },
      voice
    );
  };

  // Monitor for no response
  useEffect(() => {
    if (!speaking && !listening && !finalTranscript && !noResponseModel) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setNoResponseModel(true);
          setCounter(10);
        }
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [speaking, listening, finalTranscript, noResponseModel]);

  // Countdown for no response modal
  useEffect(() => {
    if (noResponseModel && counter > 0) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setCounter((prevCount) => prevCount - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else if (noResponseModel && counter === 0) {
      questionsData();
    }
  }, [noResponseModel, counter]);

  // Manage listening based on mute state
  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  // Add user message to liveInterview
  const addUserMessage = (userMessage) => {
    if (userMessage === lastUserMessage) {
      console.log("Duplicate user message detected, not adding.");
      return;
    }

    setLiveInterview((prev) => [
      ...prev,
      {
        role: "user",
        content: userMessage,
      },
    ]);
    setLastUserMessage(userMessage);
    setFinalTranscript(false);
  };

  // Handle transcript finalization
  useEffect(() => {
    if (finalTranscript) {
      const userMessage = transcript.trim();
      if (
        userMessage &&
        (liveInterview.length === 0 ||
          liveInterview[liveInterview.length - 1].role === "assistant")
      ) {
        addUserMessage(userMessage);
      }
    }
  }, [finalTranscript, transcript]);

  // Handle transcript delay
  useEffect(() => {
    const delay = 1300;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        setFinalTranscript(true);
        stopMachineSpeaks();
        setSpeaking(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript, finalTranscript]);

  // Handle assistant response after user input
  useEffect(() => {
    if (finalTranscript && !speaking && !listening) {
      if (
        liveInterview.length === 0 ||
        liveInterview[liveInterview.length - 1].role !== "user"
      ) {
        return;
      }

      callAssistant().then((response) => {
        if (!isMounted.current) return;
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
          setFinalTranscript(false);
        }
      });
    }
  }, [finalTranscript, speaking, listening]);

  // Initialize the conversation
  useEffect(() => {
    if (tradesPrompt) {
      setCurrentPrompt(tradesPrompt);
      callAssistant().then((response) => {
        if (!isMounted.current) return;
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev.slice(0, -1), // Remove placeholder assistant message
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        }
      });
    }
  }, [tradesPrompt]);

  // Handle quick quit
  useEffect(() => {
    if (quickQuit) {
      questionsData();
    }
  }, [quickQuit]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2 flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className="relative">
            <FaUser size={48} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Tap to interrupt
            </button>
          ) : listening ? (
            <button className="text-white text-xs">
              Trades Coworker is listening...
            </button>
          ) : (
            <button className="text-white text-xs"></button>
          )}
        </div>
      </div>
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                No response detected—job’s wrapping up in {counter} seconds.
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Keep Going
                </button>
                <button onClick={questionsData}>End Job</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default TradesDictaphone;
