import OpenAI from "openai";

let currentAudio = null;

export const stopMachineSpeaks = () => {
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0;
    currentAudio.src = "";
    currentAudio = null;
  }
};

export default async function machineSpeaks(
  text,
  onPlayCallback,
  onProgressCallback,
  onEndCallback,
  voice = "nova"
) {
  console.log(
    "REACT-KEY---------------------",
    process.env.REACT_APP_OPENAI_API_KEY
  );
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  stopMachineSpeaks(); // Stop any currently playing audio before starting a new one

  try {
    const response = await openai.audio.speech.create({
      model: "tts-1",
      voice: voice ?? "nova",
      input: text && text,
    });

    // ash ---> men, nova ---> women, sage ---> women, echo --> Men

    const blob = new Blob([await response.arrayBuffer()], {
      type: "audio/mp3",
    });
    const audioSrc = window.URL.createObjectURL(blob);
    const audio = new Audio();
    audio.src = audioSrc;
    currentAudio = audio; // Set the current audio to the newly created audio element

    audio.play();

    audio.addEventListener("playing", async () => {
      onPlayCallback && (await onPlayCallback());
    });

    audio.addEventListener("timeupdate", async () => {
      const percentage = (audio.currentTime / audio.duration) * 100;
      onProgressCallback && (await onProgressCallback(Math.round(percentage)));
    });

    audio.addEventListener("ended", () => {
      URL.revokeObjectURL(audioSrc);
      currentAudio = null; // Clear the current audio reference when playback ends
      onEndCallback && onEndCallback();
    });
  } catch (err) {
    console.error("Error converting text to speech:", err);
    throw err;
  }
}
