import React, { useState, useEffect, useRef } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import {
  getResumeCertificate,
  udpateResumeCertificate,
  updateResumeSectionPosition,
} from "../../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { success } from "../../../Utilities/toast";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Certificates({ certificates, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [certificateState, setCertificateState] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const [selectedCertificateId, setSelectedCertificateId] = useState("");
  const [hoveredField, setHoveredField] = useState(null);
  const editableRefs = useRef({});

  const handleFieldClick = (index, id) => {
    if (activeField !== null && activeField !== index) submitChanges();
    setSelectedCertificateId(id);
    setActiveField(index);
    setTimeout(() => editableRefs.current[index]?.focus(), 0);
  };

  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setCertificateState((prev) =>
        prev?.map((certificate) =>
          certificate?.id === certificateState[index]?.id
            ? { ...certificate, name: value }
            : certificate
        )
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setCertificateState((prev) =>
        prev?.map((certificate) =>
          certificate?.id === certificateState[index]?.id
            ? { ...certificate, name: value }
            : certificate
        )
      );
    }
  };

  const getCertificationInfo = () => {
    const resumeId = id;
    dispatch(getResumeCertificate({ resumeId, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    if (!selectedCertificateId) return;

    const originalCertificate = certificates.find(
      (c) => c.id === selectedCertificateId
    );
    const updatedCertificate = certificateState.find(
      (c) => c.id === selectedCertificateId
    );

    if (
      originalCertificate &&
      updatedCertificate &&
      originalCertificate.name.trim() !== updatedCertificate.name.trim()
    ) {
      setActiveField(null);
      setHoveredField(null);
      const payload = {
        updateDocId: selectedCertificateId,
        data: { name: updatedCertificate.name.trim() },
      };
      setCertificateState((prev) =>
        prev.map((cert) =>
          cert.id === selectedCertificateId
            ? { ...cert, name: updatedCertificate.name.trim() }
            : cert
        )
      );
      dispatch(
        udpateResumeCertificate({
          payload,
          onSuccess: (res) => {
            success(res);
            getCertificationInfo();
          },
          onError: () => {
            setCertificateState(certificates);
          },
        })
      );
    } else {
      setSelectedCertificateId("");
      setActiveField(null);
      setHoveredField(null);
    }
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    const reorderedSections = Array.from(certificateState);
    const [removed] = reorderedSections.splice(source, 1);
    reorderedSections.splice(destination, 0, removed);
    const previousSections = [...certificateState];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setCertificateState(updatedSections);
    dispatch(
      updateResumeSectionPosition({
        resumeId: id,
        payload: {
          certification: updatedSections,
        },
        onSuccess: () => {
          setActiveField(null);
          setHoveredField(null);
          dispatch(getResumeCertificate({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setCertificateState(previousSections);
        },
      })
    );
  };
  const renderField = (certificate, index) => {
    const isActive = activeField === index;
    const isHovered = hoveredField === index;

    // Check for changes based on the `id` and `name`.
    const originalCertificate = certificates.find(
      (c) => c.id === certificate.id
    );
    const hasChanges =
      originalCertificate &&
      originalCertificate?.name !== certificate?.name?.trim();

    const iconStyle = {
      position: "absolute",
      top: "-10px",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "1.4rem",
      color: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      cursor: "pointer",
    };

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active px-3" : ""}`}
        onMouseEnter={() => setHoveredField(index)}
        onMouseLeave={() => setHoveredField(null)}
        onDoubleClick={() => handleFieldClick(index, certificate?.id)}
        style={{
          position: "relative",
          border:
            isActive || isHovered ? "1px solid #ffff" : "1px solid transparent",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          className={`editable-field`}
          contentEditable={isActive}
          onInput={(e) => handleFieldInput(index, e)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          onBlur={() => submitChanges()}
          ref={(el) => (editableRefs.current[index] = el)}
          style={{ fontFamily: `${getSingleResumeDoc?.font_style}` }}
        >
          {certificate.name}
        </span>
        {isActive && (
          <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-blue-500 text-white text-xs font-semibold  px-1 rounded-full shadow-md flex items-center gap-2">
            {/* Upper Arrow */}
            <FiChevronUp
              className={`text-white text-lg cursor-pointer ${
                activeField == 0 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeField - 1,
                  source: activeField,
                });
              }}
            />

            {/* Lower Arrow */}
            <FiChevronDown
              className={`text-white text-lg cursor-pointer  ${
                activeField == certificateState.length - 1 ? "hidden" : ""
              }`}
              onClick={() => {
                handleOnDragEnd({
                  destination: activeField + 1,
                  source: activeField,
                });
              }}
            />
          </div>
        )}
        {isActive && hasChanges && (
          <MdDone
            onClick={() => submitChanges()}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}

        {isActive && !hasChanges && (
          <MdClose
            onClick={() => setActiveField(null)}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}

        {/* {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, certificate?.id)}
            style={{ ...iconStyle, right: "-20px", background: "#000" }}
          />
        )} */}
      </span>
    );
  };

  useEffect(() => {
    if (certificates) {
      const sortedCertifications = certificates
        ?.slice()
        .sort((a, b) => Number(a?.position) - Number(b?.position));
      setCertificateState(sortedCertifications);
    }
  }, [certificates]);
  return (
    <section className="cv-certifications">
      <ul className="cv-skills-list d-flex justify-content-start">
        {certificateState.map((certificate, index) => (
          <li
            key={certificate.id}
            className="cv-skill_top apply-font flex gap-1 align-middle mt-1 mb-0"
          >
            <span className="font-bold text-lg">•</span>{" "}
            {renderField(certificate, index)}
          </li>
        ))}
      </ul>
    </section>
  );
}
