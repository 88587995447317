import React from "react";
import "./Section5.css";

const Section5 = () => {
  return (
    <div className="default-container-section5">
      <div className="text-wrapper-section5">
        <div className="narrow-text-section5">
          <p className="text-default-blue-bold-section5 mb-8 FiraFontFamily bold-family">
            What our{" "}
            <span className="text-default-blue-bold-section5 highlighted-word FiraFontFamily bold-family">
              Partners
            </span>{" "}
            Are Saying
          </p>
        </div>
        <div className="wide-text-section5"></div>
      </div>
      <div className="outer-quote">
        {/*TODO Decorative Box */}
        <img
          src="images/icons/left-arrow.svg"
          alt="Left Arrow"
          className="left-arrow-section-5"
        />

        <div className="styled-box-wrapper-section5">
          {/* Right Arrow */}

          <div className="styled-box-section5">
            <div>
              <p className="text-default-black-section5 quote-text FiraFontFamily">
                Nexa RU saved us thousands of dollars and delivered us top-tier
                candidates. It's like having a supercharged recruiter on our
                team!
              </p>
            </div>
            <div className="image-and-content-container-section5">
              <div className="image-container-section-5">
                <img
                  src="images/Bootcamp/BootcampSection5.png"
                  alt="Bootcamp Section"
                  className="section-image-section5"
                />
              </div>
              <div className="text-wrapper-section5">
                <p className="text-default-black-section5 bolder-text image-name-size FiraFontFamily">
                  John L
                </p>
                <p className="text-default-black-section5 image-job-title-size FiraFontFamily">
                  Recruiting Director at ACH Staffing
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/icons/right-arrow.svg"
          alt="Right Arrow"
          className="right-arrow-section-5"
        />
      </div>
    </div>
  );
};

export default Section5;
