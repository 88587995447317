import React, { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useSpeechRecognition } from "react-speech-recognition";
import "regenerator-runtime";
// import soundWave from "../../../assets/media/sound1.gif";
import machineSpeaks, { stopMachineSpeaks } from "./MachineSpeaks";
import { useDispatch, useSelector } from "react-redux";
import { simulatorProSave } from "../../../Redux/SimulatorProSlice";
import SpeechRecognition from "react-speech-recognition";
import { success } from "../../../Utilities/toast";
import FeedbackModal from "./FeedbackModal";
import avatar from "../../../assets/icons/operator.png";
import { useNavigate } from "react-router-dom";
import { ReactMic } from "react-mic";
const Dictaphone = ({
  handleEndCall,
  jobDescription,
  muted,
  setMuted,
  // loading,
  // feedbackLoading,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  contactDetails,
  handleCamera,
  sessionsId,
  interviewTone,
  setInterviewTone,
}) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  console.log(listening, "transcript");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socketRef = useRef(null);
  const { prompt } = useSelector((state) => state.SimulatorProSession);
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(true);
  const [noResponseModel, setNoResponseModel] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState(false);
  const [buffers, setBuffers] = useState([]);
  console.log("buffers: ", buffers.length);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioContext, setAudioContext] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [responseDone, setResponseDone] = useState(false);
  console.log("responseDone: ", responseDone);

  const replacePlaceholders = (prompt, values) => {
    return prompt.replace(/\$\{(\w+)\}/g, (_, key) => values[key] || "");
  };

  const dynamicValues = {
    jobDescription: jobDescription,
    contactDetails: contactDetails,
    type: interviewTone,
  };
  const [liveInterview, setLiveInterview] = useState([
    {
      role: "assistant",
      content: replacePlaceholders(prompt, dynamicValues),
    },
  ]);
  console.log(liveInterview, "liveInterview");
  // function cleanResponse(resStr) {
  //   let input;

  //   // Remove ```json``` if it exists
  //   if (typeof resStr === "string") {
  //     resStr = resStr.replace(/```json\s*|\s*```/g, ""); // Remove ```json``` and ```
  //   }

  //   try {
  //     input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
  //   } catch (e) {
  //     if (typeof resStr === "string") {
  //       input = resStr;
  //     } else {
  //       throw new Error("Input must be a string or JSON-formatted string.");
  //     }
  //   }

  //   let values = [];

  //   function extractFromObject(obj) {
  //     for (const [key, value] of Object.entries(obj)) {
  //       if (key === "") {
  //         continue;
  //       }
  //       if (typeof value === "object" && value !== null) {
  //         extractFromObject(value);
  //       } else {
  //         values.push(value);
  //       }
  //     }
  //   }

  //   function extractFromArray(arr) {
  //     for (const item of arr) {
  //       if (typeof item === "object" && item !== null) {
  //         extractFromObject(item);
  //       } else {
  //         values.push(item);
  //       }
  //     }
  //   }

  //   if (typeof input === "string") {
  //     values.push(input);
  //   } else if (Array.isArray(input)) {
  //     extractFromArray(input);
  //   } else if (typeof input === "object" && input !== null) {
  //     extractFromObject(input);
  //   } else {
  //     throw new Error("Input must be a string, object, or an array.");
  //   }

  //   const zeroIndexVal = values[0];
  //   return zeroIndexVal;
  // }

  const questionsData = async () => {
    setNoResponseModel(false);
    setCounter(10);
    // Step 1: Remove the first object where role is 'assistant' and content starts with 'Simulate a realistic'
    const filteredData = liveInterview.filter(
      (item, index) =>
        !(
          item.role === "assistant" &&
          item.content.startsWith("Simulate a realistic")
        )
    );
    console.log(filteredData, "filteredData");
    // Step 2: Create the questions array by pairing 'assistant' and 'user' objects
    const questions = [];

    for (let i = 0; i < filteredData.length; i++) {
      if (
        filteredData[i].role === "assistant" &&
        filteredData[i + 1] &&
        filteredData[i + 1].role === "user"
      ) {
        questions.push({
          question: filteredData[i].content,
          answer: filteredData[i + 1].content,
        });
      }
    }
    const payload = {
      simulation_session_id: !sessionsId ? sessionId : sessionsId,
      questions: questions,
    };
    setFeedbackLoading(true);
    dispatch(
      simulatorProSave({
        payload,
        sessionsId,
        onSuccess: (res) => {
          setFeedbackLoading(false);
          handleCamera();
          if (!quickQuit) {
            setIsModal(true);
          } else {
            handleEndCall();
            setQuickQuit(false);
            success(res?.detail);
          }
          SpeechRecognition.stopListening();
          resetTranscript();
          setFinalTranscript(false);
          setLiveInterview(null);
          stopMachineSpeaks();
          setMuted(undefined);
          setInterviewTone("");
          // if (sessionsId) {
          //   navigate("/");
          // }
        },
        onError: () => {
          setFeedbackLoading(false);
        },
      })
    );
  };

  // const speak = async (text) => {
  //   setSpeaking(true);
  //   machineSpeaks(text, null, null, () => {
  //     setSpeaking(false);
  //     resetTranscript();
  //     setFinalTranscript(false);
  //     SpeechRecognition.startListening({ continuous: true });
  //     setMuted(false);
  //   });
  // };

  useEffect(() => {
    if (!noResponseModel) {
      const timer = setTimeout(() => {
        if (!transcript && (listening || muted) && !speaking) {
          SpeechRecognition.stopListening();
          stopMachineSpeaks(); // Stop speaking if the user starts talking
          setSpeaking(false);
          resetTranscript();
          setFinalTranscript(false);
          setNoResponseModel(true);
          setMuted(false);
          setCounter(10);
        }
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [muted, transcript, listening]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount > 0 && noResponseModel) {
          return prevCount - 1;
        } else {
          clearInterval(timer);
          return prevCount;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [noResponseModel]);

  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  useEffect(() => {
    if (counter === 0) {
      setCounter(10);
      questionsData();
    }
  }, [counter]);

  useEffect(() => {
    if (finalTranscript) {
      setLiveInterview((prev) => [
        ...prev,
        {
          role: "user",
          content: transcript,
        },
      ]);
    }
  }, [finalTranscript]);

  useEffect(() => {
    const delay = 3500;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        // setTimeout(() => {
        setFinalTranscript(true); // Set final transcript
        stopMachineSpeaks();
        setSpeaking(false);
        // }, 500);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript]);

  // useEffect(() => {
  //   return () => {
  //     if (speaking) {
  //       stopMachineSpeaks();
  //       setSpeaking(false);
  //     }
  //   };
  // }, [speaking]);

  useEffect(() => {
    if (quickQuit) {
      questionsData();
    }
  }, [quickQuit]);

  const base64ToBlob = (base64, mimeType) => {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i += 512) {
        const slice = byteCharacters.slice(i, i + 512);
        const byteNumbers = new Uint8Array(
          [...slice].map((char) => char.charCodeAt(0))
        );
        byteArrays.push(byteNumbers);
      }
      return new Blob(byteArrays, { type: mimeType });
    } catch (error) {
      console.error("Error converting Base64 to Blob:", error);
      return null;
    }
  };
  const sendAudio = async (audioBlob) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const reader = new FileReader();

    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Convert AudioBuffer to WAV (You can use external libraries for MP3)
      const wavBlob = await encodeWav(audioBuffer);

      // Convert to Base64
      const wavReader = new FileReader();
      wavReader.onloadend = () => {
        const base64Audio = wavReader.result.split(",")[1];
        socketRef.current?.send(JSON.stringify({ content: base64Audio }));
      };
      wavReader.readAsDataURL(wavBlob);
    };

    reader.readAsArrayBuffer(audioBlob);
  };
  const encodeWav = async (audioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const numOfSamples = audioBuffer.length * numOfChannels;
    const wavBuffer = new ArrayBuffer(44 + numOfSamples * 2);
    const view = new DataView(wavBuffer);
    let offset = 0;

    const writeString = (str) => {
      for (let i = 0; i < str.length; i++) {
        view.setUint8(offset++, str.charCodeAt(i));
      }
    };

    // RIFF Header
    writeString("RIFF");
    view.setUint32(offset, 36 + numOfSamples * 2, true);
    offset += 4;
    writeString("WAVE");

    // Format Chunk
    writeString("fmt ");
    view.setUint32(offset, 16, true);
    offset += 4;
    view.setUint16(offset, 1, true);
    offset += 2;
    view.setUint16(offset, numOfChannels, true);
    offset += 2;
    view.setUint32(offset, sampleRate, true);
    offset += 4;
    view.setUint32(offset, sampleRate * numOfChannels * 2, true);
    offset += 4;
    view.setUint16(offset, numOfChannels * 2, true);
    offset += 2;
    view.setUint16(offset, 16, true);
    offset += 2;

    // Data Chunk
    writeString("data");
    view.setUint32(offset, numOfSamples * 2, true);
    offset += 4;

    // Interleave channels
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = audioBuffer.getChannelData(channel)[i];
        const intSample = Math.max(-1, Math.min(1, sample));
        view.setInt16(
          offset,
          intSample < 0 ? intSample * 0x8000 : intSample * 0x7fff,
          true
        );
        offset += 2;
      }
    }

    return new Blob([view], { type: "audio/wav" });
  };
  const onEndCallBack = () => {
    console.log("onEndCallBack");
    setResponseDone(false);
    setSpeaking(false);
    stopMachineSpeaks();
    resetTranscript();
    setFinalTranscript(false);
    SpeechRecognition.startListening({ continuous: true });
    setMuted(false);
    setIsPlaying(false);
  };
  useEffect(() => {
    if (!buffers.length || isPlaying) return;

    const playAudioBuffer = async () => {
      setIsPlaying(true);
      setSpeaking(true); 

      if (!audioContext) {
        setAudioContext(
          new (window.AudioContext || window.webkitAudioContext)()
        );
      }

      const context =
        audioContext ||
        new (window.AudioContext || window.webkitAudioContext)();

      const playChunk = async (buffer) => {
        const arrayBuffer = await buffer.arrayBuffer();
        const audioBuffer = await context.decodeAudioData(arrayBuffer);
        const source = context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(context.destination);

        return new Promise((resolve) => {
          source.onended = resolve;
          source.start();
        });
      };

      for (const buffer of buffers) {
        await playChunk(buffer);
        setBuffers((prevBuffers) => {
          const newBuffers = prevBuffers.slice(1);
          console.log("newBuffers.length: ", newBuffers.length);
          if (newBuffers.length === 0 && responseDone) {
            onEndCallBack?.();
          }
          return newBuffers;
        });
      }

      setIsPlaying(false);
    };

    playAudioBuffer();
  }, [buffers]);

  useEffect(() => {
    if (jobDescription) {
      const initialPrompt = replacePlaceholders(prompt, dynamicValues);
      const connectWebSocket = () => {
        socketRef.current = new WebSocket(
          "wss://api.stagingnexa.com/job_applicant/ws"
        );
        socketRef.current.onopen = () => {
          console.log("WebSocket connected");
          socketRef.current.send(
            JSON.stringify({
              initialPrompt: initialPrompt,
            })
          );
        };
        socketRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log("Received data:", data);

            if (data.type === "transcript") {
              // setTranscript((prev) => prev + data.delta);
            } else if (data.type === "audioConverted") {
              const blob = base64ToBlob(data.delta, "audio/mpeg");
              setBuffers((prevBuffers) => [...prevBuffers, blob]);
            } else if (data.type === "response.done") {
              console.log(data.transcript);
              console.log("Final Transcript:", data.transcript);
              setLiveInterview((prev) => [
                ...prev,
                {
                  role: "assistant",
                  content: data.transcript,
                },
              ]);
              setResponseDone(true);
              // speak(data.transcript);
            }
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        socketRef.current.onerror = (error) =>
          console.error("WebSocket error:", error);
        socketRef.current.onclose = () => {
          console.log("WebSocket disconnected. Reconnecting...");
          // setTimeout(connectWebSocket, 3000);
        };
      };
      connectWebSocket();
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [jobDescription]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        sessionsId={sessionsId && true}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2  flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className=" relative">
            {/* <FaUser size={48} className="object-cover text-white" /> */}
            <img src={avatar} width={70} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                setBuffers([]);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Hiring Manager is speaking...
            </button>
          ) : listening ? (
            <button className="text-white  text-xs">
              Hiring Manager is listening...
            </button>
          ) : (
            <button className="text-white  text-xs"></button>
          )}
        </div>
      </div>
      <ReactMic
        record={listening}
        className="w-full border border-gray-300 rounded hidden"
        onStop={(recordedBlob) => sendAudio(recordedBlob.blob)}
        mimeType="audio/wav"
        strokeColor="#000000"
        backgroundColor="#f5f5f5"
      />
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no respond from you, we will end this interview in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    questionsData();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default Dictaphone;

// You are a voice assistant for Mary's Dental, a dental office located at 123 North Face Place, Anaheim, California. The hours are 8 AM to 5PM daily, but they are closed on Sundays.

// Mary's dental provides dental services to the local Anaheim community. The practicing dentist is Dr. Mary Smith.

// You are tasked with answering questions about the business, and booking appointments. If they wish to book an appointment, your goal is to gather necessary information from callers in a friendly and efficient manner like follows:

// 1. Ask for their full name.
// 2. Ask for the purpose of their appointment.
// 3. Request their preferred date and time for the appointment.
// 4. Confirm all details with the caller, including the date and time of the appointment.

// - Be sure to be kind of funny and witty!
// - Keep all your responses short and simple. Use casual language, phrases like "Umm...", "Well...", and "I mean" are preferred.
// - This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.
