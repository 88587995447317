import React from "react";
import { useEffect, useState } from "react";
import { MdAccessTime, MdVerified, MdWork } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { FaArrowLeftLong, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { error, info } from "../../../Utilities/toast";
import {
  applyOnJob,
  getSingleJob,
  recommendationOnApply,
  resumeGrading,
} from "../../../Redux/NexaIqSlice";
import { Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import IQSidebarShimmer from "../../../Components/Shimmers/IQSidebarShimmer";
import { formatJobDescription } from "./IQContext";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { PiMoneyDuotone } from "react-icons/pi";

const IQSidebar = ({
  jobs,
  isOpen,
  onClose,
  isModal,
  setIsModal,
  setActivegoogleJobLink,
  setIsActiveApplyStep,
  isactiveApplyStep,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const jobId = searchParams.get("jobId");
  const { user } = useSelector((state) => state.authenticate);
  const { getSingleJobData, applyRecommendation } = useSelector(
    (state) => state.nexaIq
  );
  const [job, setJob] = useState(null);
  const [showAllButtons, setShowAllButtons] = useState(false);

  const [isOpenModal, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoadings] = useState(false);
  const fileTypes = ["PDF"];
  const [moreJobs, setMoreJobs] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [gradingLoading, setGradingLoading] = useState(false);
  const [gradingModal, setGradingModal] = useState(false);
  const [gradingData, setGradingData] = useState("");

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  const handleChange = (file) => {
    if (file) {
      const payload = new FormData();
      payload.append("job_id", getSingleJobData?.id);
      payload.append("file", file);

      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        error("File size must be less than 1 MB");
        file = null;
      } else {
        setFile(file);
        setGradingLoading(true);
        setGradingModal(true);
        dispatch(
          resumeGrading({
            payload,
            onSuccess: (res) => {
              setGradingLoading(false);
              if (res) {
                setGradingData(res);
              } else {
                setGradingData(null);
              }
            },
          })
        );
      }
    }
  };
  const handleFileOpen = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  };
  const handleAnswerChange = (value, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };
  const handleSubmit = () => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else {
      setLoadings(true);
      if (file == null) {
        error("Please upload your resume!");
        setLoadings(false);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("job_id", getSingleJobData?.id);
      if (getSingleJobData?.preset_questions != null) {
        const answersString = answers.join(",");
        formData.append("answers", answersString);
      }
      dispatch(
        applyOnJob({
          formData,
          onSuccess: (res) => {
            toggleModal();
            setLoadings(false);
            if (res) {
              //   setAppreciationModal(true);
            }
          },
        })
      );
    }
  };

  const toggleModal = () => {
    setIsActiveApplyStep(1);
    if (getSingleJobData?.preset_questions != null) {
      setAnswers(getSingleJobData?.preset_questions?.map(() => ""));
    }
    setIsOpen(!isOpenModal);
    setGradingModal(false);
    setFile(null);
  };

  const handleRecommendation = () => {
    if (!user) {
      navigate(`/login`);
      info("Please login to use AI Guide");
      return;
    }
    if (user?.organizationId && user?.activeBundle === "QolEraPnrw") {
      info("AI Guide is available on premium only");
      return;
    }
    if (!user?.organizationId && user?.activeBundle === "2lNbMnPO8W") {
      info("AI Guide is available on premium only");
      return;
    }
    setModalLoader(true);
    dispatch(
      recommendationOnApply({
        title: getSingleJobData?.title,
        company_name:
          getSingleJobData?.company || getSingleJobData?.organization?.name,
        experience: getSingleJobData?.experience,
        onComplete: (data) => {
          setModalLoader(false);
          if (data) {
            setIsModal((prev) => ({
              ...prev,
              isRecommendationModal: true,
            }));
          }
        },
      })
    );
  };

  const renderApplyButtons = () => {
    if (!getSingleJobData?.apply_links && getSingleJobData?.type === "google")
      return null;

    const uniqueLinks = getSingleJobData?.apply_links?.reduce((acc, curr) => {
      const text = curr?.text?.trim();
      if (!acc?.some((link) => link?.text === text)) {
        acc?.push(curr);
      }
      return acc;
    }, []);

    const visibleLinks = showAllButtons
      ? uniqueLinks
      : uniqueLinks?.slice(0, 3);
    const shouldShowMoreButton = uniqueLinks?.length > 3;

    return (
      <>
        {getSingleJobData?.type !== "google" &&
        getSingleJobData?.type !== "indeed" &&
        getSingleJobData?.type !== "linkedin" ? (
          <div
            className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[46px] font-semibold text-white opacity-50"
            onClick={handleApply}
          >
            <div>
              {getSingleJobData?.applied_status != null
                ? "Applied"
                : " Apply now"}
            </div>
          </div>
        ) : (
          <>
            {visibleLinks.map((data, index) => {
              const domain = new URL(data.url).hostname.replace("www.", "");
              const displayText = data.text
                ? data.text.split(" ").slice(0, 3).join(" ") +
                  (data.text.split(" ").length > 3 ? "..." : "")
                : `Apply on ${domain.split(".")[0]}`;

              return (
                <div
                  key={index}
                  className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[26px] font-semibold text-white opacity-50 text-sm"
                  onClick={() => {
                    if (!user) {
                      navigate("/login");
                      info("Please login to use AI Guide");
                      return;
                    }
                    setActivegoogleJobLink(data.url);
                    setIsModal((prev) => ({
                      ...prev,
                      GoogleJobAppreciationModal: true,
                    }));
                  }}
                >
                  <div title={data.text}>{displayText}</div>
                </div>
              );
            })}

            {shouldShowMoreButton && (
              <div
                className="w-full h-[40px] border-t-[#E6E6E6] border-t sm:flex flex-row items-center justify-center cursor-pointer"
                onClick={() => setShowAllButtons(!showAllButtons)}
              >
                <div className="flex flex-row items-center justify-center underline text-[#4D4D4D]">
                  {showAllButtons ? "Hide" : "Show More"}
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`ml-1 ${showAllButtons ? "rotate-180" : ""}`}
                  >
                    <path
                      d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                      fill="#4D4D4D"
                    />
                  </svg>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const handleApply = () => {
    if (!user) {
      navigate(`/login?jobId=${jobId}`);
      info("Please login to use AI Guide");
      return;
    }
    if (getSingleJobData?.applied_status === null) {
      if (getSingleJobData?.preset_questions == null) {
        setIsActiveApplyStep(2);
      }
      setIsModal((prev) => ({
        ...prev,
        applyJobModal: true,
      }));
    } else {
      error("You have already applied for this job");
    }
  };

  useEffect(() => {
    setMoreJobs(false);
    if (getSingleJobData?.preset_questions == null) return;
    setAnswers(getSingleJobData?.preset_questions?.map(() => ""));
  }, [getSingleJobData]);
  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ease-in-out z-[99] ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={onClose}
      />

      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 w-[100%] sm:w-[85%] lg:w-[60%]  z-[99] h-full bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {isLoading.isSidebarLoading ? (
          <IQSidebarShimmer />
        ) : (
          <div className="py-6 border flex h-[100vh] overflow-y-scroll justify-center">
            <div className="w-[100%] sm:w-[65%] lg:w-[65%] xl:w-[70%] h-[100%] flex flex-col">
              <div className="flex items-center justify-between w-full px-6 sm:border-r-2">
                <button onClick={onClose}>
                  <FaArrowLeftLong
                    size={20}
                    color="black"
                    className=" hidden sm:block"
                  />
                  <FaXmark size={20} color="black" className="sm:hidden" />
                </button>
                <button
                  className="bg-yellow-600 text-white text-[12px] px-3 py-1 rounded-full"
                  onClick={handleRecommendation}
                >
                  Nexa Job Fit Insights
                  {modalLoader && <Spin size="small" className="ml-1" />}
                </button>
              </div>
              <div className="relative h-auto w-full border-b-2 px-6 pb-8 sm:border-r-2">
                <h2 className="text-2xl mt-8 mb-2">
                  {getSingleJobData?.title}
                </h2>
                <div className="flex justify-left item-center gap-5 mt-4">
                  <span className="text-sm font-light">
                    {getSingleJobData?.employment_type ||
                      getSingleJobData?.tonality}
                  </span>
                  <h2 className="flex gap-1 items-center text-sm font-light">
                    <CiLocationOn size={20} color="black" />
                    {getSingleJobData?.location}
                  </h2>
                </div>
                <button
                  onClick={toggleCollapse}
                  className="bg-white border text-black font-bold py-1.5 pl-2 pr-3 rounded-es-2xl focus:outline-none absolute -bottom-8 z-50 -right-0.5 sm:hidden"
                >
                  {!collapse ? (
                    <div className="flex items-center text-xs">
                      <FiChevronDown
                        className={`text-black font-bold text-lg cursor-pointer mr-1  `}
                        // onClick={() => {
                        //   handleOnDragEnd({
                        //     destination: index + 1,
                        //     source: index,
                        //   });
                        // }}
                      />
                      Want to Apply
                    </div>
                  ) : (
                    <div className="flex items-center text-xs">
                      <FiChevronUp
                        className={`text-black font-bold text-lg cursor-pointer mr-1  `}
                        // onClick={() => {
                        //   handleOnDragEnd({
                        //     destination: index + 1,
                        //     source: index,
                        //   });
                        // }}
                      />
                      Not Interested!
                    </div>
                  )}
                </button>
              </div>
              <div className="w-full max-w-full mx-auto sm:border-r-2 sm:hidden">
                {/* Collapsible content */}
                <div
                  className={`flex-col px-4 mt-4 pt-2 pb-2 border-b-2 ${
                    collapse ? "flex max-h-screen" : "max-h-0 hidden"
                  }`}
                >
                  <div className="flex justify-center w-full flex-col gap-3">
                    {renderApplyButtons()}
                  </div>
                  <div className="w-full">
                    <h2 className="text-xl mt-4 mb-2">About the Company</h2>
                    <h2 className="flex gap-1 items-center mt-1 text-sm">
                      <MdVerified size={18} color="#7c96fd" />
                      {getSingleJobData?.company ||
                        getSingleJobData?.organization?.name}
                    </h2>
                    {getSingleJobData?.organization && (
                      <>
                        <h2 className="flex gap-1 items-center mt-1 text-sm">
                          <MdVerified size={18} color="#7c96fd" />
                          {getSingleJobData?.company ||
                            getSingleJobData?.organization?.address}
                        </h2>
                        <h2 className="flex gap-1 items-center mt-1 text-sm">
                          <MdVerified size={18} color="#7c96fd" />
                          {getSingleJobData?.company ||
                            getSingleJobData?.organization?.phone}
                        </h2>
                        <h2 className="flex gap-1 items-center mt-1 text-sm">
                          <MdVerified size={18} color="#7c96fd" />
                          {getSingleJobData?.company ||
                            getSingleJobData?.organization?.about_us}
                        </h2>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`h-auto w-full  sm:border-r-2 ${
                  getSingleJobData?.type !== "google" ? "border-b-2" : ""
                } px-6 py-6`}
              >
                <p
                  className="text-md"
                  dangerouslySetInnerHTML={{
                    __html: getSingleJobData?.description
                      ? formatJobDescription(getSingleJobData?.description)
                      : getSingleJobData?.job_description,
                  }}
                />
              </div>

              <div className="h-auto flex justify-center items-start w-full border-b-2  sm:border-r-2 px-6 py-6">
                <div className="w-[50%] flex justify-start item-center">
                  <div className="flex justify-center item-center gap-2">
                    <div className="">
                      <MdAccessTime size={24} />
                    </div>
                    <div className="flex justify-center item-center flex-col gap-1">
                      <span className="text-md font-bold">Employment Type</span>
                      <span className="text-md font-medium">
                        {getSingleJobData?.employment_type || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] flex justify-start item-center">
                  <div className="flex justify-center item-center gap-2">
                    <div className="">
                      <PiMoneyDuotone size={24} />
                    </div>
                    <div className="flex justify-center item-center flex-col gap-1">
                      <span className="text-md font-bold">Salary</span>
                      <span className="text-md font-medium">
                        {getSingleJobData?.salary
                          ? `${getSingleJobData?.salary}${
                              getSingleJobData?.salary_type === "Yearly"
                                ? "per Yr"
                                : getSingleJobData?.salary_type === "Hourly"
                                ? "per Hr"
                                : ""
                            }`
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {getSingleJobData?.type !== "google" && (
                <>
                  <div className="h-auto flex justify-center items-start w-full border-b-2  sm:border-r-2 px-6 py-6">
                    <div className="w-[50%] flex justify-start item-center">
                      <div className="flex justify-center item-center gap-2">
                        <div className="">
                          <SlCalender size={24} />
                        </div>
                        <div className="flex justify-center item-center flex-col gap-1">
                          <span className="text-md font-bold">
                            Prescreening Deadline
                          </span>
                          <span className="text-md font-medium">
                            {dayjs(
                              getSingleJobData?.prescreening_deadline
                            ).format("DD MMM YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-[50%] flex justify-start item-center">
                      <div className="flex justify-center item-center gap-2">
                        <div className="">
                          <SlCalender size={24} />
                        </div>
                        <div className="flex justify-center item-center flex-col gap-1">
                          <span className="text-md font-bold">
                            Interview Deadline
                          </span>
                          <span className="text-md font-medium">
                            {dayjs(getSingleJobData?.interview_deadline).format(
                              "DD MMM YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-auto w-full px-6 py-6 sm:border-r-2">
                    <h2 className="text-xl mb-3">Skills and Expertise</h2>
                    <div className="flex justify-start items-center">
                      {getSingleJobData?.skills &&
                        getSingleJobData?.skills
                          ?.split(",")
                          .map((skill, index) => (
                            <div
                              key={index}
                              className="chip"
                              style={{
                                margin: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {skill.trim()}
                            </div>
                          ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-[35%] lg:w-[35%] xl:w-[30%] h-[100%] hidden sm:flex flex-col px-3">
              <div className="flex justify-center w-full flex-col gap-3">
                {renderApplyButtons()}
              </div>
              <div className="w-full mb-8">
                <h2 className="text-xl mt-4 mb-2">About the Company</h2>
                <h2 className="flex gap-1 items-center mt-1 text-sm">
                  <MdVerified size={18} color="#7c96fd" />
                  {getSingleJobData?.company ||
                    getSingleJobData?.organization?.name}
                </h2>
                {getSingleJobData?.organization && (
                  <>
                    <h2 className="flex gap-1 items-center mt-1 text-sm">
                      <MdVerified size={18} color="#7c96fd" />
                      {getSingleJobData?.company ||
                        getSingleJobData?.organization?.address}
                    </h2>
                    <h2 className="flex gap-1 items-center mt-1 text-sm">
                      <MdVerified size={18} color="#7c96fd" />
                      {getSingleJobData?.company ||
                        getSingleJobData?.organization?.phone}
                    </h2>
                    <h2 className="flex gap-1 items-center mt-1 text-sm">
                      <MdVerified size={18} color="#7c96fd" />
                      {getSingleJobData?.company ||
                        getSingleJobData?.organization?.about_us}
                    </h2>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IQSidebar;
