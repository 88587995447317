import { Sparkles, Star } from "lucide-react";
import React from "react";
import leftSide from "../../assets/media/aboutUs/about_secound_secound.png";
import rightRide from "../../assets/media/aboutUs/about_secound_section.png";
import leftRidesecound from "../../assets/media/aboutUs/about_secound_third.png";
import JoinUSImg from "../../assets/media/aboutUs/joinUsImg.png";
import MissionImg from "../../assets/media/aboutUs/mission.png";

const StorySection = () => {
  return (
    <section className="relative bg-gradient-to-b from-blue-50 via-white to-indigo-50 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-96 h-96 bg-blue-200/30 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-200/30 rounded-full blur-3xl animate-pulse delay-700" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-purple-200/20 rounded-full blur-3xl animate-pulse delay-500" />
      </div>

      {/* Subtle Grid Pattern */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNjB2NjBIMHoiLz48cGF0aCBkPSJNMzAgMzBoMzB2MzBIMzB6IiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L2c+PC9zdmc+')] opacity-40" />

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Header Section */}
        <div className="text-center mb-20">
          <h1 className="text-5xl md:text-6xl font-bold mb-8">
            <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-transparent bg-clip-text">
              Our Story
            </span>
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            The inception of Nexa is rooted in a profound journey of resilience,
            passion, and an unwavering commitment to making a difference in the
            world of career preparation.
          </p>
        </div>

        {/* Story Sections */}
        {/* Spark of Inspiration */}
        <div className="mb-20">
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 md:p-12 shadow-xl border border-white">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <Star className="w-8 h-8 text-blue-500" />
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                    THE SPARK OF INSPIRATION
                  </h2>
                </div>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Nexa's story begins with{" "}
                  <span className="font-semibold">
                    our founder, Divy Nayyar,
                  </span>{" "}
                  a cybersecurity and data engineer who spent seven years
                  navigating the complexities of the tech industry. During this
                  time, Divy saw firsthand the struggles that job seekers
                  faced—talented individuals who, despite their potential, were
                  often unprepared for the rigorous demands of the job market.
                  This challenge hit even closer to home when he founded InTech
                  Institute, a vocational center for technology education.
                  There, he encountered students brimming with skills but
                  lacking the confidence and guidance needed to secure their
                  dream jobs.
                </p>
              </div>
              <div className="relative">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-transparent z-10" />
                  <img
                    src={rightRide}
                    alt="Inspiration"
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* The Hardship */}
        <div className="mb-20">
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 md:p-12 shadow-xl border border-white">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div className="order-2 lg:order-1">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-purple-500/10 to-transparent z-10" />
                  <img
                    src={leftSide}
                    alt="Hardship"
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
              <div className="order-1 lg:order-2">
                <div className="flex items-center gap-2 mb-6">
                  <Sparkles className="w-8 h-8 text-purple-500" />
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                    THE HARDSHIP
                  </h2>
                </div>
                <p className="text-gray-600 text-lg leading-relaxed">
                  It wasn't just the students' struggles that fueled Divy's
                  drive; it was his own personal journey. Growing up in a modest
                  family, Divy had to overcome numerous obstacles to achieve his
                  own career goals. The financial constraints, the countless
                  rejections, and the persistent feeling of being underprepared
                  were constant hurdles. He vividly remembers the sleepless
                  nights, the endless job applications, and the crushing
                  disappointments that almost made him give up. But through
                  sheer determination and the support of mentors, he persevered.
                  This journey instilled in him a deep empathy for job seekers
                  and a burning desire to ease their path.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* The Birth of Nexa */}
        <div className="mb-20">
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 md:p-12 shadow-xl border border-white">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <Star className="w-8 h-8 text-indigo-500" />
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                    THE BIRTH OF NEXA
                  </h2>
                </div>
                <div className="space-y-6 text-gray-600 text-lg leading-relaxed">
                  <p>
                    Motivated by these experiences, Divy envisioned a platform
                    that could bridge the gap between raw talent and job market
                    readiness.{" "}
                    <span className="font-semibold">
                      In 2023, Nexa was born—a revolutionary career services
                      platform designed to provide personalized interview
                      simulations, tailored resume building, and comprehensive
                      career coaching.
                    </span>
                  </p>
                  <p>
                    Joining Divy in this mission was
                    <span className="font-semibold">
                      {" "}
                      Prithvi, Co-Founder and CTO of Nexa.
                    </span>{" "}
                    With a background as an AI Developer at Scale AI and a dual
                    degree in Computer Science and Business from USC, Prithvi
                    brought a wealth of technical expertise and a deep
                    understanding of career and HR tech. Divy and Prithvi met at
                    a tech conference in LA and after sharing some ideas on
                    business and life their mindsets for creation synced faster
                    than google drive!
                  </p>
                  <p>
                    Their collaboration was the perfect blend of technical
                    prowess and a deep understanding of the career landscape,
                    ensuring that Nexa was not just another career services
                    tool, but a comprehensive, AI-driven solution that truly met
                    the needs of its users.
                  </p>
                </div>
              </div>
              <div className="relative">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-indigo-500/10 to-transparent z-10" />
                  <img
                    src={leftRidesecound}
                    alt="Birth of Nexa"
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Our Mission */}
        <div className="mb-20">
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 md:p-12 shadow-xl border border-white">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div className="order-2 lg:order-1">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-transparent z-10" />
                  <img
                    src={MissionImg}
                    alt="Our Mission"
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
              <div className="order-1 lg:order-2">
                <div className="flex items-center gap-2 mb-6">
                  <Sparkles className="w-8 h-8 text-blue-500" />
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                    OUR MISSION
                  </h2>
                </div>
                <p className="text-gray-600 text-lg leading-relaxed">
                  At Nexa, we believe that every individual deserves a fair
                  chance at success. We are driven by a mission to democratize
                  career preparation, making it accessible, effective, and
                  personalized. Our commitment is to stand by our users,
                  providing them not just with tools, but with hope and
                  confidence.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Join Us */}
        <div>
          <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 md:p-12 shadow-xl border border-white">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <Star className="w-8 h-8 text-purple-500" />
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                    JOIN US
                  </h2>
                </div>
                <p className="text-gray-600 text-lg leading-relaxed">
                  As we continue to grow and evolve, we invite you to be a part
                  of Nexa's story. Whether you're a job seeker, an educational
                  institution, or a corporate partner, together we can build a
                  future where everyone has the opportunity to succeed.
                </p>
              </div>
              <div className="relative">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-purple-500/10 to-transparent z-10" />
                  <img
                    src={JoinUSImg}
                    alt="Join Us"
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StorySection;
