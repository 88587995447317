import { Button } from "antd";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import GoogleJobAppreciationModal from "../../Components/Autoapply/GoogleJobAppreciationModal";
import PreferenceModal from "../../Components/Autoapply/PreferenceModal";
import HeaderNew from "../../Components/header_new/navbar";
import Header from "../../Components/header";
import Loader from "../../Components/main-loader";
import RecommendationModal from "../../Components/NexaIqSideBar/RecommendationModal";
import IQShimmer from "../../Components/Shimmers/IQShimmer";
import {
  appliedJobCount,
  getJobListings,
  getSingleJob,
  IqQuickApplyRole,
  scrapJobs,
} from "../../Redux/NexaIqSlice";
import { getPreferenceforJobs } from "../../Redux/UserProfileSlice";
import Pagination from "../../Utilities/Pagination";
import { error } from "../../Utilities/toast";
import Filters from "./components/Filters";
import { buildPayload, createSearchPayload } from "./components/IQContext";
import IQPreference from "./components/IQPreference";
import IQSidebar from "./components/IQSidebar";
import IqTab from "./components/IqTab";
import JobCard from "./components/JobCard";
import ApplyJobModal from "./components/Modals/ApplyJobModal";
import ThankYouModal from "./components/Modals/ThankYouModal";
import "./style.css";

const AutoApply = () => {
  //Parameter Initialization
  const dispatch = useDispatch();
  const hasInitialJobsPreferenceSet = useRef(false);
  const prevKeysRef = useRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const jobId = searchParams.get("jobId");

  //Global State Initialization
  const { isQuickApply, jobs, applyRecommendation, totalPages, currentPage } =
    useSelector((state) => state.nexaIq);
  const { jobsPreference } = useSelector((state) => state.UserProfile);
  const { user } = useSelector((state) => state.authenticate);

  //Local State Initialization
  const [activeTab, setActiveTab] = useState("myJobs");
  const [activegoogleJobLink, setActivegoogleJobLink] = useState(null);
  const [preferenceModal, setPreferenceModal] = useState(false);
  const [preferenceStep, setPreferenceStep] = useState(1);
  const [scrapText, setScrapText] = useState(false);
  const [scrapSearch, setScrapSearch] = useState(false);
  const [expandedJobId, setExpandedJobId] = useState(null);
  const [isactiveApplyStep, setIsActiveApplyStep] = useState(1);
  const [isPublicView, setIsPublicView] = useState(false);

  //Modal State Initialization
  const [isModal, setIsModal] = useState({
    isRecommendationModal: false,
    googleConfirmationModal: false,
    applyJobModal: false,
    ThankYouModal: false,
  });

  //Loading State Initialization
  const [isLoading, setIsLoading] = useState({
    isMainShimmer: false,
    isDefaultLoading: false,
    isSidebarLoading: false,
  });

  //Form Data Initialization
  const [formData, setFormData] = useState({
    title: "",
    industry_preference: "",
    location_type: "",
    location: "",
    workplace_type: "",
    work_schedule: "full-time",
  });

  //Functions Initialization
  const openSidebar = (id) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("jobId", id);
    setSearchParams(newParams);
  };

  const closeSidebar = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("jobId");
    setSearchParams(newParams);
  };

  const handlePaginationChange = (value) => {
    setIsLoading((prev) => ({
      ...prev,
      isMainShimmer: true,
    }));
    const prePayload = createSearchPayload(jobsPreference, paramsObject);
    const payload = buildPayload({
      activeTab: activeTab,
      filters: prePayload,
    });
    dispatch(
      getJobListings({
        payload: payload,
        page: value,
        jobApplied: activeTab !== "myJobs",
        nexa_jobs: isQuickApply,
        onSuccess: () => {
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
        onError: () => {
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
      })
    );
  };

  const handleScrapJobs = () => {
    const prePayload = createSearchPayload(jobsPreference, paramsObject);
    const payload = buildPayload({
      activeTab: activeTab,
      filters: prePayload,
    });

    if (!payload.title && !payload.location) {
      return error(
        "To start your Personal Career City and Search are required."
      );
    }
    setIsLoading((prev) => ({
      ...prev,
      isDefaultLoading: true,
    }));
    dispatch(
      scrapJobs({
        payload: payload,
        onSuccess: () => {
          setIsLoading((prev) => ({
            ...prev,
            isDefaultLoading: false,
          }));
          setScrapText(true);
        },
        onError: () => {
          setIsLoading((prev) => ({
            ...prev,
            isDefaultLoading: false,
          }));
          setScrapText(false);
        },
      })
    );
  };

  const nexaIqQuickApply = () => {
    setIsLoading((prev) => ({
      ...prev,
      isMainShimmer: true,
    }));
    const prePayload = createSearchPayload(jobsPreference, paramsObject);
    const payload = buildPayload({
      activeTab: activeTab,
      filters: prePayload,
    });
    dispatch(
      getJobListings({
        payload: payload,
        page: 1,
        jobApplied: activeTab !== "myJobs",
        nexa_jobs: !isQuickApply,
        onSuccess: () => {
          dispatch(IqQuickApplyRole({ role: !isQuickApply }));
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
        onError: () => {
          setIsLoading((prev) => ({
            ...prev,
            isMainShimmer: false,
          }));
        },
      })
    );
  };

  const debouncedApiCall = useCallback(
    debounce((filterData) => {
      setIsLoading((prev) => ({
        ...prev,
        isMainShimmer: true,
      }));
      dispatch(
        getJobListings({
          payload: filterData,
          page: 1,
          jobApplied: activeTab !== "myJobs",
          nexa_jobs: isQuickApply,
          onSuccess: () => {
            setIsLoading((prev) => ({
              ...prev,
              isMainShimmer: false,
            }));
          },
          onError: () => {
            setIsLoading((prev) => ({
              ...prev,
              isMainShimmer: false,
            }));
          },
        })
      );
    }, 500),
    []
  );

  const handleApplyOnJob = () => {
    setIsLoading((prev) => ({
      ...prev,
      isDefaultLoading: true,
    }));
    dispatch(
      appliedJobCount({
        job_id: jobId,
        onSuccess: () => {
          setIsLoading((prev) => ({
            ...prev,
            isDefaultLoading: false,
          }));
        },
        onError: () => {
          setIsLoading((prev) => ({
            ...prev,
            isDefaultLoading: false,
          }));
        },
      })
    );
    window.open(activegoogleJobLink, "_blank");
    setIsModal((prev) => ({
      ...prev,
      GoogleJobAppreciationModal: false,
    }));
    closeSidebar();
  };

  //Effects Initialization
  useEffect(() => {
    const currentKeys = Array.from(searchParams.keys());
    const isSidebarOperation = () => {
      if (currentKeys.length > prevKeysRef.current.length) {
        return currentKeys[currentKeys.length - 1] === "jobId";
      }
      if (currentKeys.length < prevKeysRef.current.length) {
        return (
          prevKeysRef.current.includes("jobId") &&
          !currentKeys.includes("jobId")
        );
      }
      return false;
    };
    if (!hasInitialJobsPreferenceSet.current) {
      hasInitialJobsPreferenceSet.current = true;
      if (jobId) {
        setIsLoading((prev) => ({ ...prev, isSidebarLoading: true }));
        dispatch(
          getSingleJob({
            id: jobId,
            onSuccess: () => {
              setIsLoading((prev) => ({ ...prev, isSidebarLoading: false }));
            },
            onError: () => {
              setIsLoading((prev) => ({ ...prev, isSidebarLoading: false }));
            },
          })
        );
      }
      prevKeysRef.current = currentKeys;
      return;
    }
    if (!isSidebarOperation()) {
      const payload = createSearchPayload(jobsPreference, paramsObject);
      debouncedApiCall(payload);
      setScrapText(false);
    }
    prevKeysRef.current = currentKeys;
  }, [searchParams, jobsPreference, isPublicView]);

  useEffect(() => {
    if (user) {
      setIsLoading((prev) => ({
        ...prev,
        isDefaultLoading: true,
      }));
      dispatch(
        getPreferenceforJobs({
          onSuccess: (data) => {
            setIsLoading((prev) => ({
              ...prev,
              isDefaultLoading: false,
            }));
            if (!data) {
              setPreferenceModal(true);
            }
            if (data.workplace_type) {
              const newParams = new URLSearchParams(searchParams);
              newParams.set("workplace_type", data.workplace_type);
              setSearchParams(newParams);
            }
          },
          onError: () => {
            setIsLoading((prev) => ({
              ...prev,
              isDefaultLoading: false,
            }));
          },
        })
      );
    } else {
      setIsPublicView(true);
    }
  }, []);

  return (
    <>
      {isLoading.isDefaultLoading && <Loader />}
      {user ? (
        <>
          <Header />
          <div className="h-[120px]" />
        </>
      ) : (
        <HeaderNew />
      )}
      {activeTab === "myJobs" && (
        <>
          <Filters />
          <IQPreference
            isOpen={() => setPreferenceModal(true)}
            setIsLoading={setIsLoading}
          />
        </>
      )}

      <IqTab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isQuickApply={isQuickApply}
        setIsLoading={setIsLoading}
      />
      <div className="parent-container-wrapper bg-transparent">
        {activeTab === "myJobs" && (
          <div className="w-full flex justify-center">
            <div className="flex justify-end max-w-[800px] w-full">
              <button
                onClick={() => {
                  nexaIqQuickApply();
                }}
                disabled={isLoading.isMainShimmer}
                className={`${
                  !isQuickApply
                    ? "bg-transparent text-black border"
                    : "bg-[#2B6BE2] border-none text-white"
                }  hover:text-[#2B6BE2] hover:border-[#2B6BE2] cursor-pointer shadow rounded-lg py-2 px-6  text-sm flex items-center gap-2 mt-1`}
              >
                <IoFilter />
                Nexa IQ Quick Apply
              </button>
            </div>
          </div>
        )}
        {isLoading.isMainShimmer ? (
          <IQShimmer />
        ) : (
          <div
            className="parent-container bg-transparent"
            style={{ position: "relative" }}
          >
            {jobs && jobs.length > 0 ? (
              jobs.map((job) => (
                <JobCard
                  key={job.id}
                  job={job}
                  activeTab={activeTab}
                  expandedJobId={expandedJobId}
                  setExpandedJobId={setExpandedJobId}
                  openSidebar={() => openSidebar(job.id)}
                  setIsLoading={setIsLoading}
                />
              ))
            ) : (
              <div className="text-center pt-6">
                <h2 className="text-red-600 text-2xl px-12 leading-10">
                  {activeTab === "myJobs"
                    ? scrapText
                      ? `Thank you for ${
                          scrapSearch
                            ? "creating a new custom job search"
                            : "updating your target career preference"
                        }. we will now populate your feed with jobs related to your ${
                          scrapSearch ? "Requested Search" : "preference"
                        }. If no results are found, please check your email in a few minutes.`
                      : "You Are Unique, Let Us Personally Find The Perfect Careers That Match What YOU Are Looking For"
                    : activeTab === "Applied Jobs"
                    ? "No Applied Jobs Yet"
                    : null}
                </h2>
                {activeTab !== "Applied Jobs" && !scrapText && (
                  <div className="w-full h-20 flex justify-center items-center">
                    <Button
                      className="beacon"
                      onClick={() => {
                        handleScrapJobs();
                        setScrapSearch(true);
                      }}
                      disabled={isLoading.isMainShimmer}
                    >
                      Start My Personal Career Search
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {jobs && jobs?.length > 0 && (
          <div className="d-flex justify-center items-center w-100">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePaginationChange}
            />
          </div>
        )}
      </div>

      {/* IQ Sidebar */}
      {
        <IQSidebar
          isOpen={Boolean(jobId)}
          onClose={closeSidebar}
          jobs={jobs}
          isModal={isModal}
          setIsModal={setIsModal}
          setActivegoogleJobLink={setActivegoogleJobLink}
          setIsActiveApplyStep={setIsActiveApplyStep}
          isactiveApplyStep={isactiveApplyStep}
          isLoading={isLoading}
        />
      }

      {/* Modals */}
      {isModal.isRecommendationModal && (
        <RecommendationModal
          isOpen={isModal.isRecommendationModal}
          onClose={() =>
            setIsModal((prev) => ({
              ...prev,
              isRecommendationModal: false,
            }))
          }
          data={applyRecommendation}
        />
      )}

      {isModal.GoogleJobAppreciationModal && (
        <GoogleJobAppreciationModal
          isOpen={isModal.GoogleJobAppreciationModal}
          onClose={() =>
            setIsModal((prev) => ({
              ...prev,
              GoogleJobAppreciationModal: false,
            }))
          }
          onConfirm={handleApplyOnJob}
        />
      )}

      {preferenceModal && (
        <PreferenceModal
          isOpen={preferenceModal}
          onClose={() => {
            setPreferenceModal(false);
            setPreferenceStep(1);
          }}
          formData={formData}
          setFormData={setFormData}
          step={preferenceStep}
          setStep={setPreferenceStep}
          setIsLoading={setIsLoading}
          // resetFilters={resetFilters}
        />
      )}

      {isModal.applyJobModal && (
        <ApplyJobModal
          isOpenModal={isModal.applyJobModal}
          toggleModal={() => {
            setIsModal((prev) => ({
              ...prev,
              applyJobModal: false,
            }));
            setIsActiveApplyStep(1);
          }}
          setIsActiveApplyStep={setIsActiveApplyStep}
          isactiveApplyStep={isactiveApplyStep}
          closeSidebar={closeSidebar}
          setIsModal={setIsModal}
          setIsLoading={setIsLoading}
        />
      )}

      {isModal.ThankYouModal && (
        <ThankYouModal
          isOpen={isModal.ThankYouModal}
          onClose={() => {
            setIsModal((prev) => ({
              ...prev,
              ThankYouModal: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default AutoApply;
