import { z } from "zod";

export const profileSchema = z.object({
  image: z
    .union([
      z.string().url({ message: "Invalid image URL" }),
      z.instanceof(File, { message: "Invalid image file" }),
    ])
    .optional(),
  address: z.string().optional(),
  education: z
    .string()
    .min(2, { message: "Education is required" })
    .optional()
    .or(z.literal("")),
  contact: z
    .string()
    .regex(/^\+?[\d\s-]{10,}$/, { message: "Invalid phone number format" })
    .optional()
    .or(z.literal("")),
  linkedIn_profile: z.string().optional().or(z.literal("")),
  employment_status: z
    .enum([
      "FullTime",
      "PartTime",
      "Contractor",
      "Intern",
      "Freelancer",
      "Temporary",
      "Unemployed",
      "Student",
      "Retired",
      "OnLeave",
      "Sabbatical",
      "Probation",
    ])
    .optional()
    .or(z.literal("")),
});
