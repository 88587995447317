import { ArrowRight, Sparkles, Users } from "lucide-react";
import React, { useState } from "react";
import founder from "../../assets/media/aboutUs/founder.png";
import cto from "../../assets/media/aboutUs/prithimage-modified.png";

const TeamSection = () => {
  const [activeBio, setActiveBio] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: "Divy Nayyar",
      role: "Founder and CEO",
      image: founder,
      bio: `Divy Nayyar is the visionary founder and CEO of Nexa, bringing over 7 
      years of experience as a cybersecurity and data engineer. With a deep 
      understanding of the technology landscape, Divy has dedicated his 
      career to empowering individuals through innovative career preparation 
      tools. Before founding Nexa, Divy established InTech Institute, a 
      leading vocational center for technology education. His hands-on 
      experience in the industry and direct interaction with job-seekers 
      have given him unique insights into the challenges and pain points 
      faced by job seekers and educational institutions alike.

      Driven by a passion for making career preparation accessible 
      and effective, Divy leverages his technical expertise and 
      entrepreneurial spirit to lead Nexa towards transforming the way 
      people prepare for and secure their dream jobs.`,
    },
    {
      id: 2,
      name: "Prithvi Yendapally",
      role: "Co-Founder & CTO",
      image: cto,
      bio: `Prithvi Yendapally is the Co-Founder and Chief Technology Officer of 
      Nexa, bringing a wealth of experience and a passion for innovation in 
      career and HR tech. Before co-founding Nexa, Prithvi honed his skills 
      as an AI Developer at Scale AI, where he worked on cutting-edge 
      projects that pushed the boundaries of artificial intelligence.

      Prithvi holds dual degrees in Computer Science and Business 
      from the University of Southern California (USC), a combination that 
      has equipped him with both technical prowess and a deep understanding 
      of business dynamics. His entrepreneurial spirit led him to build a 
      successful freelance staffing and recruiting business, where he gained 
      invaluable insights into the intricacies of the hiring process and the 
      challenges faced by both job seekers and employers.

      Driven by a passion for creating meaningful career opportunities, 
      Prithvi leverages his technical expertise and industry experience to 
      lead Nexa's technological vision and development. His commitment to 
      innovation and excellence ensures that Nexa's platform continually 
      evolves to meet the needs of its users, providing them with the tools 
      and confidence to achieve their career aspirations.`,
    },
  ];

  return (
    <section className="relative bg-gradient-to-b from-blue-50 via-white to-indigo-50 overflow-hidden py-24">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-96 h-96 bg-blue-200/30 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-200/30 rounded-full blur-3xl animate-pulse delay-700" />
      </div>

      {/* Grid Pattern */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNjB2NjBIMHoiLz48cGF0aCBkPSJNMzAgMzBoMzB2MzBIMzB6IiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L2c+PC9zdmc+')] opacity-40" />

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-20">
          <div className="flex items-center justify-center gap-2 mb-6">
            <Users className="w-8 h-8 text-blue-500" />
            <h2 className="text-4xl md:text-5xl font-bold">
              <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-transparent bg-clip-text">
                Team and Culture
              </span>
            </h2>
          </div>
        </div>

        {/* Team Grid */}
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="group relative"
              onMouseEnter={() => setActiveBio(member.id)}
              onMouseLeave={() => setActiveBio(null)}
            >
              {/* Profile Card */}
              <div className="backdrop-blur-lg bg-white/80 rounded-2xl p-8 shadow-xl border border-white hover:shadow-2xl transition-all duration-300">
                <div className="flex flex-col items-center">
                  {/* Image */}
                  <div className="relative w-48 h-48 mb-6">
                    <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-purple-500/10 rounded-full" />
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>

                  {/* Info */}
                  <h3 className="text-2xl font-bold text-gray-800 mb-2">
                    {member.name}
                  </h3>
                  <p className="text-gray-600 mb-6">{member.role}</p>

                  {/* Bio Button */}
                  <button className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-full font-medium hover:from-blue-600 hover:to-indigo-600 transition-all duration-300">
                    View Bio
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>

              {/* Bio Card - Appears on Hover */}
              <div
                className={`absolute top-0 left-0 w-full h-full backdrop-blur-lg bg-white/95 rounded-2xl p-8 shadow-xl border border-white transition-all duration-300 transform 
                  ${
                    activeBio === member.id
                      ? "opacity-100 scale-100"
                      : "opacity-0 scale-95 pointer-events-none"
                  }
                `}
              >
                <div className="h-full overflow-auto">
                  <h4 className="text-xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
                    <Sparkles className="w-5 h-5 text-blue-500" />
                    Biography
                  </h4>
                  <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                    {member.bio}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
