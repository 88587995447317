import React, { useEffect, useState } from "react";
import "./style.css";
import "./TextEditor.css";
import Header from "../../Components/header";
import MyDetails from "../../features/profile/profileForm";
import { useDispatch, useSelector } from "react-redux";
import ProfessionalSummary from "../../features/professionalsummary/professionalSummaryForm";
import CVTemplate from "../../Components/Resume/CVTemplate";
import ResumeHeader from "../../Components/Resume/ResumeHeader";
import WorkExperienceForm from "../../features/workexperience/workExperienceForm";
import EducationForm from "../../features/education/EducationForm";
import Skills from "../../features/Skills/Skills";
import CompanyInfoForm from "../../features/companyinformation/CompanyInfoForm";
import {
  addStep,
  selectStep,
  setStep1,
  setActivePreview,
} from "../../features/companyinformation/StepsSlice";
import Guidance from "../../Components/Resume/Guidance";
import ProjectForm from "../../features/projects/ProjectForm";
import CertificationForm from "../../features/Certifications/CertificationForm";
import PublicationForm from "../../features/publications/PublicationForm";
import AiGeneration from "../../Components/Resume/AiGeneration";
import {
  deleteAdditionalSections,
  deleteResumePosition,
  getAllTemplates,
  getDeletedSections,
  getSingleResume,
  updateResumePosition,
} from "../../Redux/ResumeBuilderSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/main-loader";
import InterestForm from "../../features/Interests/InterestForm";
import LanguagesForm from "../../features/Languages/LanguagesForm";
import SoftSkills from "../../features/companyinformation/SoftSkills/SoftSkills";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import {
  ProfessionalSummaryIcon,
  EducationIcon,
  WorkexperienceIcon,
  TopSkillsIcon,
  CertificateIcon,
  PersonalSumIcon,
  SectionsIcon,
} from "../../Components/Resume/svgimages";
import ReactModal from "react-modal";
import AdditionalSections from "../../features/additionalSections/AdditionalSections";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { error } from "../../Utilities/toast";
import AddAdditionalUser from "../../Components/ResumeAdditionalSection";
import {
  defaultState,
  StepStateAtom,
} from "../../recoil/ResumeAdditionalSection/StepsState";
import { useRecoilState } from "recoil";
import PreviewResume from "../../Components/Resume/PreviewResume";
import useResumeData from "../../hook/useResumeData";

const backgroundStyle = {
  backgroundImage: 'url("/images/background.svg")',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  height: "100%",
};
const ResumeBuilder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [_, setStepState] = useRecoilState(StepStateAtom);
  const { existingResume } = useResumeData();
  const {
    isLoading,
    profile,
    professionalSummaries,
    educations,
    workExperience,
    getSingleResumeDoc,
    templates,
  } = useSelector((state) => state.ResumeBuilder);
  const step = useSelector(selectStep);
  const activeField = useSelector((state) => state?.educationForm?.activeField);
  const { activePreview } = useSelector((state) => state?.Steps);
  const [collapse, setCollapse] = useState("");
  const [tempRef, settempRef] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeSection, setRemoveSection] = useState(false);
  const [removeInnerSection, setRemoveInnerSection] = useState(false);
  const [sectionsConfig, setSectionConfigs] = useState([]);
  const [additionalSectionModal, setAdditionalSectionModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [show, setShow] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);

  const toggleCollapse = (value) => {
    if (value !== collapse) {
      setCollapse(value);
    } else {
      setCollapse("");
    }
  };

  const handlePreview2 = () => {
    const payload = {
      preview: false,
      step: step,
      step1: true,
    };
    dispatch(setActivePreview(payload));
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const reorderedSections = Array.from(sectionsConfig);
    const [removed] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, removed);
    const previousSections = [...sectionsConfig];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setSectionConfigs(updatedSections);
    dispatch(
      updateResumePosition({
        resumeId: id,
        payload: updatedSections,
        onSuccess: () => {
          dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setSectionConfigs(previousSections);
        },
      })
    );
  };
  const checkDisable = () => {
    if (
      !(
        profile &&
        professionalSummaries?.length > 0 &&
        workExperience?.length > 0 &&
        educations?.length > 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    let sectionComponents = [
      {
        key: "details",
        icon: <PersonalSumIcon />,
        component: <MyDetails />,
      },
      {
        key: "professionalsummary",
        icon: <ProfessionalSummaryIcon />,
        component: <ProfessionalSummary />,
      },
      {
        key: "certification",
        icon: <CertificateIcon />,
        component: <CertificationForm />,
      },
      {
        key: "skills",
        icon: <TopSkillsIcon />,
        component: <Skills />,
      },
      {
        key: "workexperience",
        icon: <WorkexperienceIcon />,
        component: <WorkExperienceForm />,
      },
      {
        key: "education",
        icon: <EducationIcon />,
        component: <EducationForm />,
      },
      {
        key: "project",
        icon: "",
        component: <ProjectForm />,
      },
      {
        key: "publications",
        icon: "",
        component: <PublicationForm />,
      },
      {
        key: "interests",
        icon: "",
        component: <InterestForm />,
      },
      {
        key: "skill",
        icon: "",
        component: <SoftSkills />,
      },
      {
        key: "language",
        icon: "",
        component: <LanguagesForm />,
      },
    ];
    const updatedSections = getSingleResumeDoc?.positions
      .filter((position) => position.isDeleted === false)
      .map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (component) => component.key === position.key
          );
          if (matchingComponent) {
            return {
              ...position,
              icon: matchingComponent.icon,
              component: matchingComponent.component,
              style: position.style || "",
            };
          }
        } else {
          const matchingAdditional =
            getSingleResumeDoc?.additional_sections?.find(
              (additionalItem) => additionalItem.identity_key === position.key
            );
          if (matchingAdditional) {
            return {
              ...position,
              icon: <SectionsIcon />,
              style: position.style || "",
              component: (
                <AdditionalSections
                  setRemoveInnerSection={setRemoveInnerSection}
                  setSelectedSection={setSelectedSection}
                  section={matchingAdditional}
                  resumeId={id}
                />
              ),
            };
          }
        }
        return position;
      });
    const sortedSections = updatedSections?.sort(
      (a, b) => a.position - b.position
    );
    setSectionConfigs(sortedSections);
  }, [getSingleResumeDoc]);
  useEffect(() => {
    if (id) {
      const resumeId = id;
      dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
    }
  }, [id]);
  useEffect(() => {
    toggleCollapse("contactInfoShow");
  }, []);

  return (
    <div style={backgroundStyle}>
      <Header />
      {isLoading && <Loader />}
      <div className="resume-back">
        <button
          onClick={() => {
            if (step > 2) {
              navigation("/resumes");
            } else {
              setModalIsOpen(true);
            }
          }}
          className={` z-50 mt-28 ml-2 bg-white rounded-full size-10 p-1 transition-all duration-500  rotate-180`}
        >
          <img src={chevronDoubleRightIcon} alt="" />
        </button>
      </div>
      <ResumeHeader resumeId={id} handlePreview2={handlePreview2} />

      <div className="resume">
        {step === 1 && !activePreview && (
          <>
            <div className="resume-work">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="sections" direction="vertical">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="w-full"
                    >
                      {sectionsConfig?.map((section, index) => (
                        <Draggable
                          key={section.key}
                          draggableId={section.key}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={section.className}
                              style={{
                                ...provided.draggableProps.style,
                                border:
                                  collapse === section.key
                                    ? "1px solid #2A68DC"
                                    : "none",
                                borderRadius: "8px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                className="relative d-flex justify-content-between py-1 px-2 rounded-xl shadow"
                                style={{ backgroundColor: "white" }}
                              >
                                {!section.isRequired &&
                                  section.isRequired != null && (
                                    <div
                                      className="absolute -top-2 -right-2 bg-white text-red-600 rounded-full w-6 h-6 flex items-center justify-center shadow cursor-pointer"
                                      onClick={() => {
                                        setRemoveSection(true);
                                        setSelectedPosition(section?.key);
                                      }}
                                    >
                                      <i className="ri-close-line text-sm"></i>
                                    </div>
                                  )}
                                <div className="d-flex">
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {section.icon}
                                  </div>
                                  <h3>{section.title}</h3>
                                  {section.isRequired &&
                                    section.isRequired != null && (
                                      <span className="text-red-600 mt-1">
                                        *
                                      </span>
                                    )}
                                </div>
                                <button
                                  className="toggle_button text-center d-flex align-items-center justify-content-center"
                                  onClick={() => toggleCollapse(section.key)}
                                >
                                  <i
                                    className={`ri-arrow-${
                                      collapse === section.key ? "up" : "down"
                                    }-s-line`}
                                    style={{
                                      fontSize: "26px",
                                      color:
                                        collapse === section.key
                                          ? "#2A68DC"
                                          : "inherit",
                                    }}
                                  />
                                </button>
                              </div>
                              {collapse === section.key && section.component}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="d-flex justify-content-between  py-1 px-4 mt-1 w-full">
                <button
                  className="professionalitem-button"
                  onClick={() => {
                    if (templates?.length >= 1) {
                      setStepState(defaultState);
                      toggleCollapse("contactInfoShow");
                      setAdditionalSectionModal(true);
                    } else {
                      dispatch(
                        getAllTemplates({
                          onSuccess: () => {
                            setStepState(defaultState);
                            toggleCollapse("contactInfoShow");
                            setAdditionalSectionModal(true);
                          },
                          onError: () => {
                            error("Something went wrong on load Templates");
                          },
                        })
                      );
                    }
                  }}
                >
                  <i className="ri-add-circle-line"></i>
                  <span className="ml-2">Additional Sections</span>
                </button>
                <button
                  className={` ${
                    checkDisable()
                      ? "cursor-not-allowed save-btn-disabled"
                      : "save-btn"
                  }`}
                  style={{
                    fontWeight: "500",
                    width: "130px",
                    height: "50px",
                  }}
                  disabled={checkDisable()}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    dispatch(addStep(2));
                    dispatch(setStep1(true));
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Next
                  </span>
                  <i
                    className="ri-arrow-right-line ml-2"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  />
                </button>
              </div>
            </div>

            {!activeField && (
              <div>
                <div className="cv-main-container w-[700px]">
                  <CVTemplate tempRef={tempRef} settempRef={settempRef} />
                </div>
              </div>
            )}

            {activeField && step === 1 && <Guidance />}
          </>
        )}

        {step === 2 && !activePreview && (
          <>
            <div className="companyinformation max-w-[757px] max-h-[765px]">
              <CompanyInfoForm
                setShow={setShow}
                setSelectedResume={setSelectedResume}
              />
            </div>
            <div>
              <div className="cv-main-container w-[760px]">
                <PreviewResume resumeData={existingResume} />
              </div>
            </div>
          </>
        )}

        {step >= 3 && !activePreview && (
          <AiGeneration
            setShow={setShow}
            show={show}
            selectedResume={selectedResume}
            setSelectedResume={setSelectedResume}
          />
        )}
        {activePreview && (
          <div className="py-3">
            <div className="cv-main-container w-full min-[760px]:w-[760px]">
              <PreviewResume resumeData={existingResume} />
            </div>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              You are about to exit the resume module, please make sure your
              work is saved before exiting back to home
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-green-600 px-4 py-2 rounded text-white"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => navigation("/resumes")}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={removeSection}
        onRequestClose={() => setRemoveSection(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: "5",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              Are you sure you want to remove this section from your resume?
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-red-600 px-4 py-2 rounded text-white"
                onClick={() => setRemoveSection(false)}
              >
                No
              </button>
              <button
                className=" bg-green-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => {
                  dispatch(
                    deleteResumePosition({
                      resumeId: id,
                      heading: selectedPosition,
                      onSuccess: () => {
                        setRemoveSection(false);
                        dispatch(
                          getSingleResume({ resumeId: id, onSuccess: () => {} })
                        );
                        dispatch(getDeletedSections(id));
                        // dispatch(deletedSectionsData({ selectedPosition }));
                      },
                      onError: () => {},
                    })
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      {/* // Remove Inner Section */}
      <ReactModal
        isOpen={removeInnerSection}
        onRequestClose={() => setRemoveInnerSection(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: "5",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              Are you sure you want to remove this inner section from your
              resume?
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-red-600 px-4 py-2 rounded text-white"
                onClick={() => setRemoveInnerSection(false)}
              >
                No
              </button>
              <button
                className=" bg-green-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => {
                  dispatch(
                    deleteAdditionalSections({
                      sectionId: selectedSection,
                      onSuccess: () => {
                        setRemoveInnerSection(false);
                        dispatch(
                          getSingleResume({ resumeId: id, onSuccess: () => {} })
                        );
                      },
                      onError: () => {},
                    })
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <AddAdditionalUser
        additionalSectionModal={additionalSectionModal}
        setAdditionalSectionModal={setAdditionalSectionModal}
      />
    </div>
  );
};

export default ResumeBuilder;
