import React from "react";

const ProgressBar = ({ steps, activeStep }) => {
  return (
    <div className="flex gap-2 mb-6 mt-5">
      {Array(steps)
        .fill(1)
        .map((_, index) => (
          <div
            key={index}
            className={`h-1 flex-1 rounded ${
              activeStep >= index + 1 ? "bg-blue-500" : "bg-gray-200"
            }`}
          />
        ))}
    </div>
  );
};

export default ProgressBar;
