import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { PiBriefcaseBold } from "react-icons/pi";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import CircularLoader from "../../Components/loader";
import Loader from "../../Components/main-loader";
import Modal from "../../Components/modal";
import TypingEffect from "../../Utilities/TypingEffect";
//import TypingEffect from "../../Components/TypingEffect";

import { Spin } from "antd";
import { getSkillsAnalysis } from "../../Redux/authenticate/authenticate.action";
import {
  FeedbackData,
  FeedbackSimulationData,
  GetInterviewSimulationsData,
  interviewSimulationAnalytics,
  interviewTrainingAnalytics,
  jobsAppliedStats,
  skillsPracticeData,
  TrainingSessionData,
} from "../../Redux/ProfileAnalysticsSlice";
import Pagination from "../../Utilities/Pagination";
import { error } from "../../Utilities/toast";
import TruncateText from "./TruncateText";
import { getSkillPracticeInterviewFeedback } from "../../Redux/SkillsPracticeSlice";
import TableShimmer from "../../Components/Shimmers/TableShimmer";
import TableBodyShimmer from "../../Components/Shimmers/TableBodyShimmer";
import PaginationShimmer from "../../Components/Shimmers/PaginationShimmer";

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    jobsStats,
    interviewStats,
    interviewSimulationStats,
    simulationList,
    feedbacks,
    trainingSessionData,
    trainingPagination,
    skilllPagination,
    skillPracticeData,
    simulationPagination,
    isLoading,
  } = useSelector((state) => state.profile);
  const { userId } = useSelector((state) => state.chat);
  const [practicePage, setPracticePage] = useState(1);
  const [skillPracticePage, setSkillPracticePage] = useState(1);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [simulationFeedback, setSimulationFeedback] = useState(false);
  const [simulationPage, setSimulationPage] = useState(1);
  const [isModal, setIsModal] = useState(false);
  const [isSkillModal, setIsSkillModal] = useState(false);
  const [handleToogle, sethandleToogle] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);
  const [ocatgonModal, setOctagonModal] = useState(false);
  const [respt, setRespt] = useState([]);
  const [summary1, setSummary1] = useState("");
  const [storeResult, setStoreResult] = useState(null);
  const [storeResultScore, setStoreResultScore] = useState(null);
  const [loading, setLoading] = useState({
    practice: false,
    skill: false,
    interview: false,
    octagon: false,
  });

  const handleOpenModal = async (id, type) => {
    window.scrollTo(0, 0);
    setFeedbackLoading(true);
    let payload = { id, type };

    if (type === "simulation") {
      dispatch(FeedbackSimulationData(payload))
        .unwrap()
        .then((res) => {
          setSimulationFeedback(true);
          setFeedbackLoading(false);
          setIsModal(true);
        })
        .catch((err) => {
          error(err?.message || "Error fetching feedback");
          setFeedbackLoading(false);
          setIsModal(false);
        });
    } else if (type === "skillPractice") {
      dispatch(getSkillPracticeInterviewFeedback(id))
        .unwrap()
        .then((res) => {
          setStoreResult(res?.feedback_list);
          setStoreResultScore(res?.average_score);
          setSimulationFeedback(false);
          setFeedbackLoading(false);
          setIsSkillModal(true);
        })
        .catch((err) => {
          error(err?.message || "Error fetching feedback");
          setFeedbackLoading(false);
          setIsModal(false);
        });
    } else {
      dispatch(FeedbackData(payload))
        .unwrap()
        .then((res) => {
          setSimulationFeedback(false);
          setFeedbackLoading(false);
          setIsModal(true);
        })
        .catch((err) => {
          error(err?.message || "Error fetching feedback");
          setFeedbackLoading(false);
          setIsModal(false);
        });
    }
  };

  const handleCloseModal = () => setIsModal(false);
  const handleCloseSkillModal = () => setIsSkillModal(false);
  const handleToogleFunction = () => sethandleToogle((prev) => !prev);
  const { user } = useSelector((state) => state.authenticate);

  useEffect(() => {
    dispatch(interviewTrainingAnalytics());
    dispatch(interviewSimulationAnalytics());
    dispatch(jobsAppliedStats());
    dispatch(getSkillsAnalysis({ onComplete: () => {} }));
  }, []);

  const handleOcatgonModal = (feedback) => {
    window.scrollTo(0, 0);
    setFeedbackLoading(true);
    setRespt(feedback?.feedback);
    setSummary1(feedback?.conversations);
    setTimeout(() => {
      setFeedbackLoading(false);
      setOctagonModal(true);
    }, 2000);
  };

  const getOcatagonFeedback = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        octagon: true,
      }));
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("auth");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/octagon/simulation/feedback`,
        // `${process.env.REACT_APP_API_URL}/octagon/simulation/feedback/${user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save feedback");
      }

      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.created_on) - new Date(a.created_on)
      );
      setFeedbackData(sortedData);
      setLoading((prev) => ({
        ...prev,
        octagon: false,
      }));
    } catch (error) {
      setLoading((prev) => ({
        ...prev,
        octagon: false,
      }));
      console.error("Error saving feedback:", error);
    }
  };

  const analyticsViewed = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("auth");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/customer/analytics/viewed`,
        // `${process.env.REACT_APP_API_URL}/octagon/simulation/feedback/${user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to viewed analytics");
      }

      const data = await response.json();
      // setFeedbackData(data);
      console.log("data------------", data);
    } catch (error) {
      console.error("Error viewed analytics", error);
    }
  };

  useEffect(() => {
    setLoading((prev) => ({
      ...prev,
      practice: true,
    }));
    dispatch(TrainingSessionData(practicePage)).finally(() =>
      setLoading((prev) => ({
        ...prev,
        practice: false,
      }))
    );
  }, [practicePage]);

  useEffect(() => {
    if (userId && userId?.user_id) {
      setLoading((prev) => ({
        ...prev,
        skill: true,
      }));
      dispatch(skillsPracticeData(skillPracticePage)).finally(() =>
        setLoading((prev) => ({
          ...prev,
          skill: false,
        }))
      );
    }
  }, [skillPracticePage, userId?.user_id]);

  useEffect(() => {
    setLoading((prev) => ({
      ...prev,
      interview: true,
    }));
    dispatch(GetInterviewSimulationsData(simulationPage)).finally(() =>
      setLoading((prev) => ({
        ...prev,
        interview: false,
      }))
    );
  }, [simulationPage]);

  useEffect(() => {
    getOcatagonFeedback();
    analyticsViewed();
  }, []);

  const getModal = () => {
    const header = (
      <div className="flex justify-between items-center p-4 border-b">
        <h3 className="text-lg font-semibold">Interview Feedback</h3>
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <i className="ri-close-line text-xl"></i>
        </button>
      </div>
    );

    const content = (
      <div className="p-6">
        {!simulationFeedback ? (
          feedbacks.data ? (
            (feedbacks?.data?.session_type !== "simulation-pro"
              ? feedbacks?.feedbackList?.slice().reverse()
              : feedbacks?.feedbackList
            )?.map((item, index) => (
              <div key={index} className="mb-6 last:mb-0">
                <div className="mb-4">
                  <h5 className="font-semibold mb-2">Question:</h5>
                  <p className="text-gray-700">{item?.question}</p>
                </div>
                <div className="mb-4">
                  <h5 className="font-semibold text-blue-600 mb-2">Answer:</h5>
                  <p className="text-gray-700">{item?.answer}</p>
                </div>
                <div>
                  <h5 className="font-semibold text-blue-600 mb-2">
                    Feedback:
                  </h5>
                  <ReactMarkdown className="prose text-gray-700">
                    {item?.feedback}
                  </ReactMarkdown>
                </div>
                {index < feedbacks?.feedbackList?.length - 1 && (
                  <hr className="my-6" />
                )}
              </div>
            ))
          ) : (
            feedbacks?.feedbackList?.map((item, index) => (
              <div key={index} className="mb-6 last:mb-0">
                <div className="mb-4">
                  <h5 className="font-semibold mb-2">Question:</h5>
                  <p className="text-gray-700">{item?.question}</p>
                </div>
                <div className="mb-4">
                  <h5 className="font-semibold text-blue-600 mb-2">Answer:</h5>
                  <p className="text-gray-700">{item?.answer}</p>
                </div>
                <div>
                  <h5 className="font-semibold text-blue-600 mb-2">
                    Feedback:
                  </h5>
                  <ReactMarkdown className="prose text-gray-700">
                    {item?.feedback}
                  </ReactMarkdown>
                </div>
                {index < feedbacks?.feedbackList?.length - 1 && (
                  <hr className="my-6" />
                )}
              </div>
            ))
          )
        ) : (
          <>
            <div className="mb-6">
              <h3 className="font-semibold text-lg mb-2">
                Generalized Report:
              </h3>
              <p className="text-gray-700">{feedbacks?.generalized_report}</p>
            </div>
            <div className="mb-6">
              <h3 className="font-semibold text-lg mb-2">
                Suggested Skill Improvements:
              </h3>
              <p className="text-gray-700">
                {feedbacks?.suggested_skill_improvements}
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-2">
                Strength and positive points:
              </h3>
              <p className="text-gray-700">
                {feedbacks?.key_strengths_and_positive_points}
              </p>
            </div>
          </>
        )}
      </div>
    );

    const footer = (
      <div className="flex items-center justify-between p-4 border-t bg-gray-50">
        <h5 className="font-medium">Nexa Grading Score:</h5>
        <span className="text-lg font-semibold text-blue-600">
          {feedbacks?.score || 0}
        </span>
      </div>
    );

    return (
      <Modal show={isModal} header={header} content={content} footer={footer} />
    );
  };

  const getSkillModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseSkillModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {storeResult?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Question:</h5>
              <hr />
              <p className="mx-3 my-3">{item?.question}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <ReactMarkdown className="mx-3 my-3">
                {item?.feedback}
              </ReactMarkdown>{" "}
            </div>
          ))}
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Nexa Grading Score:</h5>
        <span className="score">{storeResultScore?.toFixed(2)}</span>
      </div>
    );

    return (
      <Modal
        show={isSkillModal}
        header={header}
        content={content}
        footer={footer}
      />
    );
  };

  const getOcatgonModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => setOctagonModal(false)}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="col-12 order-md-1 order-2">
        <div className="cardBody">
          <h3 className="mx-3 my-3 font-bold">Generalized Report:</h3>
          <hr />

          <h4 className="mx-3 my-3">Evaluation Criteria and Scores:</h4>
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[100px]">
              <Spin className="text-black" />
            </div>
          ) : (
            <div
              className="summary mx-3 my-3"
              dangerouslySetInnerHTML={{ __html: respt }}
            ></div>
          )}
          {/* <div
            className="summary mx-3 my-3"
            dangerouslySetInnerHTML={{ __html: summary1 }}
          ></div> */}
        </div>
        <div className="cardBody">
          <h3 className="mx-3 my-3 font-bold">Conversation:</h3>
          <hr />
          <div
            className="summary mx-3 my-3"
            dangerouslySetInnerHTML={{ __html: summary1 }}
          ></div>
        </div>
      </div>
    );

    return (
      <Modal show={ocatgonModal} header={header} content={content} footer="" />
    );
  };

  return (
    <>
      <Header />
      {feedbackLoading ? (
        <div className="fixed inset-0 bg-gray-50 z-[9999]">
          <div className="pt-24">
            <CircularLoader
              title="Nexa is hard at work to analyze your results, give us a minute"
              icon={true}
            />
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-gray-50">
          <main className="max-w-5xl mx-auto px-4 pt-24 pb-8">
            {/* Welcome Section */}
            <div className="mb-10">
              <h1 className="text-2xl font-semibold text-gray-900">
                Hello, {user?.name}
              </h1>
              <p className="text-gray-600">We are Glad to see you again!</p>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
              {/* Total Interviews Card */}
              <div className="bg-white rounded-lg border border-gray-200 p-6 hover:border-gray-300 transition-colors shadow-sm hover:shadow-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Total Interviews
                    </p>
                    <p className="text-2xl font-semibold mt-1">
                      {(interviewStats?.total_sessions || 0) +
                        (interviewSimulationStats?.total_sessions || 0)}
                    </p>
                  </div>
                  <div className="bg-blue-50 p-3 rounded-full">
                    <i className="ri-user-6-line text-blue-500"></i>
                  </div>
                </div>
              </div>

              {/* Practice Rounds Card */}
              <div className="bg-white rounded-lg border border-gray-200 p-6 hover:border-gray-300 transition-colors shadow-sm hover:shadow-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Practice Rounds
                    </p>
                    <p className="text-2xl font-semibold mt-1">
                      {interviewStats?.total_sessions || 0}
                    </p>
                  </div>
                  <div className="bg-green-50 p-3 rounded-full">
                    <i className="ri-chat-3-line text-green-500"></i>
                  </div>
                </div>
              </div>

              {/* Interview Simulations Card */}
              <div className="bg-white rounded-lg border border-gray-200 p-6 hover:border-gray-300 transition-colors shadow-sm hover:shadow-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-500 whitespace-nowrap">
                      Interview Simulations
                    </p>
                    <p className="text-2xl font-semibold mt-1">
                      {interviewSimulationStats?.total_sessions || 0}
                    </p>
                  </div>
                  <div className="bg-purple-50 p-3 rounded-full">
                    <i className="ri-profile-line text-purple-500"></i>
                  </div>
                </div>
              </div>

              {/* Jobs Applied Card */}
              <div className="bg-white rounded-lg border border-gray-200 p-6 hover:border-gray-300 transition-colors shadow-sm hover:shadow-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Jobs Applied
                    </p>
                    <div className="flex items-center gap-2 mt-1">
                      <p className="text-2xl font-semibold">
                        {jobsStats?.total_count || 0}
                      </p>
                      <FaRegEye
                        size={20}
                        className="cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={() =>
                          navigate("/IQ/jobboard", {
                            state: { tab: "Applied Jobs" },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="bg-yellow-50 p-3 rounded-full">
                    <PiBriefcaseBold className="text-yellow-500" size={24} />
                  </div>
                </div>
              </div>
            </div>
            {/* Beautiful Skills Card */}
            <div className="bg-gradient-to-br from-white via-blue-50/20 to-white rounded-lg border border-blue-100/50 mb-4 shadow-sm hover:shadow-md transition-all duration-300">
              <div
                className={`relative transition-all duration-300 ease-in-out ${
                  handleToogle ? "max-h-[400px]" : "max-h-[60px]"
                } overflow-hidden`}
              >
                {/* Content wrapper */}
                <div className="p-2.5">
                  {/* Beautiful compact header */}
                  <div className="flex items-center justify-between">
                    <div className="inline-flex items-center gap-1.5">
                      <div className="w-5 h-5 flex items-center justify-center bg-gradient-to-br from-blue-500 to-blue-600 rounded-md shadow-sm">
                        <i className="ri-lightbulb-flash-line text-[10px] text-white"></i>
                      </div>
                      <span className="text-[18px] font-medium bg-gradient-to-r from-gray-700 to-gray-600 bg-clip-text text-transparent">
                        Skills Gap Analysis & Recommendations
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => navigate("/career-goals")}
                        className="inline-flex items-center text-[11px] px-2 py-0.5 text-blue-600 bg-blue-50/50 hover:bg-blue-50 rounded-md transition-colors gap-1 font-medium"
                      >
                        Retake
                        <i className="ri-refresh-line text-[10px]"></i>
                      </button>
                      <button
                        onClick={handleToogleFunction}
                        className="w-5 h-5 flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-50 rounded-md transition-all"
                      >
                        <i
                          className={`ri-${
                            handleToogle ? "arrow-up-s" : "arrow-down-s"
                          }-line text-xs`}
                        ></i>
                      </button>
                    </div>
                  </div>

                  {/* Enhanced text content container */}
                  <div
                    className={`mt-1.5 pl-1 ${
                      handleToogle
                        ? "overflow-y-auto max-h-[320px] pr-1 custom-scrollbar"
                        : ""
                    }`}
                  >
                    <div className="prose prose-sm max-w-none">
                      <TypingEffect
                        storeFetchValue={""}
                        className="text-[11px] leading-[1.6] tracking-wide text-gray-600 whitespace-pre-wrap [&>p]:mb-2 [&>p:last-child]:mb-0 [&>ul]:mt-1 [&>ul]:mb-2 [&>ul>li]:mb-1 [&>ul>li:last-child]:mb-0 [&>ul>li]:pl-3 [&>ul>li]:relative [&>ul>li]:before:content-[''] [&>ul>li]:before:w-1 [&>ul>li]:before:h-1 [&>ul>li]:before:bg-blue-400 [&>ul>li]:before:rounded-full [&>ul>li]:before:absolute [&>ul>li]:before:left-0 [&>ul>li]:before:top-[0.5em]"
                      />
                    </div>
                  </div>
                </div>

                {/* Enhanced fade overlay when collapsed */}
                {!handleToogle && (
                  <div className="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-white via-white/70 to-transparent"></div>
                )}
              </div>

              <style jsx>{`
                .custom-scrollbar::-webkit-scrollbar {
                  width: 4px;
                }
                .custom-scrollbar::-webkit-scrollbar-track {
                  background: #f1f1f1;
                  border-radius: 4px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb {
                  background: #ddd;
                  border-radius: 4px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                  background: #ccc;
                }
              `}</style>
            </div>
            {/* Practice Rounds Table */}
            <div className="bg-white rounded-lg border border-gray-200 mb-8 shadow-sm overflow-hidden">
              <div className="p-5">
                <h3 className="text-lg font-semibold mb-4">Practice Rounds</h3>
                <div className="overflow-x-auto px-4">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Date of Interview
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Category
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Feedback
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {loading.practice ? (
                        <TableBodyShimmer />
                      ) : trainingSessionData &&
                        trainingSessionData?.length > 0 ? (
                        trainingSessionData.map((feedback, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {moment(feedback?.created_on).format(
                                "MM/DD/YYYY HH:mm:ss A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              <TruncateText
                                text={
                                  feedback?.job_description ||
                                  feedback?.role_category
                                }
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                disabled={isLoading}
                                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={() =>
                                  handleOpenModal(feedback?.id, "practice")
                                }
                              >
                                {isLoading ? <Loader /> : "Feedback"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No Interviews found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {loading.practice ? (
                  <PaginationShimmer />
                ) : (
                  <div className="mt-4 overflow-x-auto pb-2">
                    <div className="min-w-full flex justify-center">
                      <Pagination
                        currentPage={practicePage}
                        totalPages={trainingPagination?.totalPages}
                        onPageChange={setPracticePage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Skill Practice Table */}
            <div className="bg-white rounded-lg border border-gray-200 mb-8 shadow-sm overflow-hidden">
              <div className="p-5">
                <h3 className="text-lg font-semibold mb-4">
                  Skill Practice Feedback
                </h3>
                <div className="overflow-x-auto px-4">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Date of Interview
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Category
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Feedback
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {loading.skill ? (
                        <TableBodyShimmer />
                      ) : skillPracticeData && skillPracticeData?.length > 0 ? (
                        skillPracticeData.map((feedback, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {moment(feedback?.created_on).format(
                                "MM/DD/YYYY HH:mm:ss A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {feedback?.sector_name ?? "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                disabled={isLoading}
                                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={() =>
                                  handleOpenModal(feedback?.id, "skillPractice")
                                }
                              >
                                {isLoading ? <Loader /> : "Feedback"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No skill practice feedback found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {loading.skill ? (
                  <PaginationShimmer />
                ) : (
                  <div className="mt-4 overflow-x-auto pb-2">
                    <div className="min-w-full flex justify-center">
                      <Pagination
                        currentPage={skillPracticePage}
                        totalPages={skilllPagination?.totalPages}
                        onPageChange={setSkillPracticePage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Interview Simulations Table */}
            <div className="bg-white rounded-lg border border-gray-200">
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Interview Simulations
                </h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Date of Interview
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Category
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Feedback
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {loading.interview ? (
                        <TableBodyShimmer />
                      ) : simulationList && simulationList?.length > 0 ? (
                        simulationList.map((feedback, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {moment(feedback?.created_on).format(
                                "MM/DD/YYYY HH:mm:ss A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              <TruncateText
                                text={
                                  feedback?.job_description ||
                                  feedback?.role_category
                                }
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                disabled={
                                  isLoading ||
                                  (feedback?.type === "simulation-pro" &&
                                    !feedback?.feedback_status)
                                }
                                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={() =>
                                  handleOpenModal(feedback?.id, "simulation")
                                }
                              >
                                {isLoading ? (
                                  <Loader />
                                ) : feedback?.type === "simulation-pro" &&
                                  !feedback?.feedback_status ? (
                                  "Processing"
                                ) : (
                                  "Feedback"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No Interview Simulations found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {loading.interview ? (
                  <PaginationShimmer />
                ) : (
                  <div className="mt-4 flex justify-center">
                    <Pagination
                      currentPage={simulationPage}
                      totalPages={simulationPagination?.totalPages}
                      onPageChange={setSimulationPage}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Octagon Table */}
            <div className="bg-white rounded-lg border border-gray-200 mt-4 ">
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Octagon Simulations (Beta)
                </h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Date of Simulation
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Category
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Feedback
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {loading.octagon ? (
                        <TableBodyShimmer />
                      ) : feedbackData && feedbackData?.length > 0 ? (
                        feedbackData.map((feedback, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {moment(feedback?.created_on).format(
                                "MM/DD/YYYY HH:mm:ss A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              <TruncateText
                                text={feedback?.role_category_name ?? "Ocatgon"}
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={() => handleOcatgonModal(feedback)}
                              >
                                {isLoading ? <Loader /> : "Feedback"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No Octagon Simulations found
                          </td>
                        </tr>
                      )}
                      {/* {simulationList && simulationList?.length > 0 ? (
                        simulationList.map((feedback, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {moment(feedback?.created_on).format(
                                "DD/MM/YYYY HH:mm:ss A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              <TruncateText
                                text={
                                  feedback?.job_description ||
                                  feedback?.role_category
                                }
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                disabled={
                                  isLoading ||
                                  (feedback?.type === "simulation-pro" &&
                                    !feedback?.feedback_status)
                                }
                                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={() =>
                                  handleOpenModal(feedback?.id, "simulation")
                                }
                              >
                                {isLoading ? (
                                  <Loader />
                                ) : feedback?.type === "simulation-pro" &&
                                  !feedback?.feedback_status ? (
                                  "Processing"
                                ) : (
                                  "Feedback"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No Interview Simulations found
                          </td>
                        </tr>
                      )} */}
                    </tbody>
                  </table>
                </div>
                {loading.octagon ? (
                  <PaginationShimmer />
                ) : (
                  <div className="mt-4 flex justify-center">
                    <Pagination
                      currentPage={simulationPage}
                      totalPages={simulationPagination?.totalPages}
                      onPageChange={setSimulationPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </main>

          {/* Render Modal */}
          {isModal && getModal()}
          {ocatgonModal && getOcatgonModal()}
          {isSkillModal && getSkillModal()}
        </div>
      )}
    </>
  );
};

export default UserDashboard;
