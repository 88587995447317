import React, { useState } from "react";

const SkillGapInformation = () => {
  const [features] = useState([
    {
      id: 1,
      title: "Precision Analysis",
      description: "Get detailed insights about your skill gaps",
      isActive: false,
    },
    {
      id: 2,
      title: "Personalized Roadmap",
      description: "Receive a custom learning path",
      isActive: false,
    },
    {
      id: 3,
      title: "Smart Job Matching",
      description: "Find opportunities matching your skills",
      isActive: false,
    },
  ]);
  return (
    <div className="relative bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-12 mx-auto max-w-5xl mt-6 mb-8">
      <div className="absolute inset-x-0 -top-4 flex justify-center">
        <div className="relative w-32">
          <div className="bg-gray-200 h-2 w-full rounded-full" />
          <div className="absolute w-full top-0 left-0 bg-gradient-to-r from-blue-600 to-purple-600 h-2 rounded-full transition-all duration-300" />
        </div>
      </div>

      <div className="space-y-8">
        <div className="text-center space-y-4">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Welcome to Your Skills Gap Analysis!
          </h1>

          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Your pathway to professional excellence starts here. We identify the
            exact skills you need to bridge the gap between your current
            position and your dream role.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="group bg-white hover:bg-blue-50 p-6 rounded-xl shadow-md transition-all duration-300 cursor-pointer transform hover:scale-105"
            >
              <h3 className="text-lg font-semibold text-gray-800 mb-2 group-hover:text-blue-600">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="space-y-4">
          <div className="text-center">
            <p className="text-gray-600 italic">
              The more information you provide, the more precise our
              recommendations become
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillGapInformation;
