import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const notificationRepo = RepositoryFactory.get("notifications");

// Async actions
export const getAllNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await notificationRepo.getAllNotifications();
      if (data?.status_code === 200) {
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getUnReadNotifications = createAsyncThunk(
  "notifications/getUnReadNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await notificationRepo.getUnReadNotifications();
      if (data?.status_code === 200) {
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/UpdateNotifications",
  async ({ id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await notificationRepo.updateNotifications(id);
      console.log(data, "data");
      if (data?.status_code === 200) {
        success(data?.detail);
        onSuccess();
      } else {
        error(data?.detail);
        onError(data?.detail);
        throw new Error("Failed to update notification");
      }
    } catch (err) {
      onError(err.response.data.detail);
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getSingleNotification = createAsyncThunk(
  "notifications/getSingleNotifications",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await notificationRepo.getSingleNotification(id);
      if (data?.status_code === 200) {
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotifications",
  async ({ notification_ids, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await notificationRepo.deleteNotifications({
        notification_ids,
      });
      console.log(data, "data");
      if (data?.status_code === 200) {
        success(data?.detail);
        onSuccess();
      } else {
        error(data?.detail);
        onError(data?.detail);
        throw new Error("Failed to delete notification");
      }
    } catch (err) {
      onError(err.response.data.detail);
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
const NotificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    loading: false,
    notifications: [],
    notificationDetail: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(getAllNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUnReadNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnReadNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(getUnReadNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationDetail = action.payload;
      })
      .addCase(getSingleNotification.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default NotificationsSlice.reducer;
