import Repository from "./Repository";

const GET_PROMPT_BY_ID = "/admin/prompt";
const SIMULATOR_PRO_BEGIN = "/dashboard/interview-simulator-pro/begin";
const SAVE_QUESTIONS = "/dashboard/interview-simulator-pro/save-questions";
const SAVE_SMARTSCREEN_QUESTIONS = "/job_applicant/save-questions";
// const VALIDATE_SESSION_ID = "/job_applicant/validate_session";
const SimulatorProRepository = {
  getPromptById(id) {
    return Repository.get(`${GET_PROMPT_BY_ID}/${id}`);
  },
  simulatorProBegin(payload) {
    return Repository.post(`${SIMULATOR_PRO_BEGIN}`, payload);
  },
  simulatorProSave(payload, sessionsId) {
    return Repository.post(
      `${!sessionsId ? SAVE_QUESTIONS : SAVE_SMARTSCREEN_QUESTIONS}`,
      payload
    );
  },
  // validateSessionId(id) {
  //   return Repository.post(`${VALIDATE_SESSION_ID}/${id}`);
  // },
  getAiDescription(title) {
    return Repository.get(
      `/smart_screen_jobs/generate_description?title=${title}`
    );
  },
};

export default SimulatorProRepository;
