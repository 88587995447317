import { BarChart, Calendar, MessageCircle, Star, Users } from "lucide-react";
import React, { useEffect } from "react";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";
import David from "./creatorimages/davidkim.png";
import Emma from "./creatorimages/emma.png";
import DigitalEmpress from "./creatorimages/empress.png";
import James from "./creatorimages/jameswilson.png";
import Marco from "./creatorimages/marco.png";
import Priya from "./creatorimages/priya.png";

const testimonials = [
  {
    name: "The Digital Empress",
    role: "Tech Career Coach",
    image: DigitalEmpress,
    text: "Nexa is transforming my career coaching influence!",
    followers: "100K+",
  },
  {
    name: "Marcus",
    role: "Software Engineering Mentor",
    image: Marco,
    text: "The analytics dashboard helps me understand my audience better.",
    followers: "75K+",
  },
  {
    name: "Priya S",
    role: "Career Transition Expert",
    image: Priya,
    text: "My students love the AI-powered career tools.",
    followers: "100K+",
  },
  {
    name: "David K",
    role: "Tech Leadership Coach",
    image: David,
    text: "The whitelabel platform maintains my brand consistency- just an awesome platform.",
    followers: "45K+",
  },
  {
    name: "Emma T",
    role: "Product Management Mentor",
    image: Emma,
    text: "Direct support through WhatsApp groups is a game-changer.",
    followers: "10K+",
  },
  {
    name: "James Wilson",
    role: "Data Science Educator",
    image: James,
    text: "Nexa helped me scale my impact in the Data Science career community.",
    followers: "5k+",
  },
];

const CreatorPage = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
    return () => {
      document.documentElement.style.scrollBehavior = "auto";
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-100">
      <Header />
      <main className="container mx-auto px-6 pt-16 overflow-hidden">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_forwards]">
            <h1 className="text-5xl font-bold leading-tight mb-6 text-gray-900">
              Turn Your Career Expertise{" "}
              <span className="text-blue-600">Into a Thriving Business</span>
            </h1>
            <p className="text-xl mb-8 text-gray-700 max-w-lg">
              Nexa is the #1 Career Coaching Platform built specifically for
              career creators—giving you everything you need to scale your
              impact effortlessly.
            </p>

            <a
              href="https://tally.so/r/wkJO9d"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-all duration-300 hover:scale-105 hover:shadow-lg group"
            >
              Apply Now
              <span className="inline-block transition-transform duration-300 group-hover:translate-x-1">
                →
              </span>
            </a>
          </div>

          <div className="lg:w-1/2 mt-12 lg:mt-0 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.2s_forwards]">
            <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
              <div className="grid grid-cols-2 gap-4">
                <FeatureCard
                  icon={<Calendar className="w-6 h-6 text-blue-600" />}
                  title="AI Career Tools"
                  description="Access our suite of AI-powered career development tools"
                  delay="0"
                />
                <FeatureCard
                  icon={<Users className="w-6 h-6 text-blue-600" />}
                  title="Creator Dashboard"
                  description="Track student progress and engagement metrics"
                  delay="100"
                />
                <FeatureCard
                  icon={<MessageCircle className="w-6 h-6 text-blue-600" />}
                  title="Direct Support"
                  description="WhatsApp groups and 48-hour response time"
                  delay="200"
                />
                <FeatureCard
                  icon={<BarChart className="w-6 h-6 text-blue-600" />}
                  title="Full Analytics"
                  description="Comprehensive insights into student performance"
                  delay="300"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Revenue Section */}
        <section className="py-20">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-gray-900 mb-6 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.4s_forwards]">
                💰 Unlock New Revenue Streams
              </h2>
              <p className="text-xl text-gray-700 leading-relaxed max-w-2xl mx-auto transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.5s_forwards]">
                With Nexa, you're not just selling PDFs or courses. You're
                providing real career services that people will gladly pay for.
              </p>
            </div>

            <div className="grid gap-6 mb-12">
              {[
                {
                  title: "AI-powered resume reviews",
                  description: "Deliver expert-level feedback in seconds",
                  icon: "🤖",
                },
                {
                  title: "Automated mock interviews",
                  description: "Help clients practice & improve instantly",
                  icon: "🎯",
                },
                {
                  title: "Job search tools",
                  description: "Guide them with the best opportunities",
                  icon: "🔍",
                },
                {
                  title: "Meeting scheduling & payments",
                  description: "Offer coaching calls without hassle",
                  icon: "📅",
                },
                {
                  title: "Your own branded career coaching hub",
                  description:
                    "Not just another link page—this is your platform",
                  icon: "🎨",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="flex items-start p-6 bg-white/90 backdrop-blur-lg rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 opacity-0 animate-[slideUp_0.6s_ease-out_forwards]"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <div className="flex items-center">
                    <span className="text-2xl mr-4">{feature.icon}</span>
                    <span className="text-green-500 text-xl mr-4">✅</span>
                    <div>
                      <h3 className="font-semibold text-lg text-gray-900 mb-1">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-8 rounded-2xl text-white text-center transform hover:scale-[1.02] transition-all duration-300">
              <p className="font-medium text-xl mb-4">
                You set the prices, keep the profits, and Nexa does the heavy
                lifting.
              </p>
              <div className="inline-block bg-white/20 backdrop-blur-lg rounded-xl p-6">
                <p className="text-2xl font-bold">
                  💡 The average career creator earns
                  <span className="text-3xl ml-2 bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-yellow-500">
                    $2,000–$10,000+
                  </span>
                  <span className="block mt-2">per month on Nexa!</span>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Comparison Section */}
        <section className="py-20 bg-white/50">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl text-center font-bold text-gray-900 mb-12 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.4s_forwards]">
              🔥 Why Nexa TRUMPS Generic Platforms
            </h2>
            <div className="grid gap-4">
              <div className="grid grid-cols-3 p-4 bg-white/80 backdrop-blur-lg rounded-xl font-semibold">
                <div>Feature</div>
                <div className="text-center">Nexa</div>
                <div className="text-center">Stan & Others</div>
              </div>
              {[
                ["Built for career coaching", true],
                ["AI-powered resume & interview tools", true],
                ["Job search integration", true],
                ["Custom-branded coaching hub", true],
                ["Scalable, automated services", true],
              ].map(([feature, hasFeature], index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 p-4 bg-white/80 backdrop-blur-lg rounded-xl shadow-sm hover:shadow-md transition-all duration-300 transform opacity-0 animate-[slideUp_0.6s_ease-out_forwards]"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <div className="text-gray-800">{feature}</div>
                  <div className="text-center text-green-500">✅</div>
                  <div className="text-center text-red-500">❌</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonial Wall Section */}
        <section className="py-24">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-16 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.4s_forwards]">
            Trusted by Top Career Creators
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={testimonial.name}
                {...testimonial}
                delay={index * 100}
              />
            ))}
          </div>
        </section>

        <section className="py-20">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.4s_forwards]">
            Creator Benefits
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <BenefitCard
              title="Whitelabel Platform"
              description="Customize the platform with your personal branding"
              icon={<Star className="w-6 h-6 text-blue-600" />}
              delay="0"
            />
            <BenefitCard
              title="Premium Support"
              description="Monthly check-ins and feature request priority"
              icon={<MessageCircle className="w-6 h-6 text-blue-600" />}
              delay="100"
            />
            <BenefitCard
              title="Analytics Dashboard"
              description="Track engagement and student progress"
              icon={<BarChart className="w-6 h-6 text-blue-600" />}
              delay="200"
            />
          </div>
        </section>

        {/* Automation Message */}
        <section className="py-16 bg-gradient-to-r from-blue-50 to-indigo-50">
          <div className="max-w-4xl mx-auto px-6 text-center">
            <p className="text-xl text-gray-700 mb-8 leading-relaxed transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_forwards]">
              Most platforms make you sell PDFs, book 1:1 calls manually, or
              struggle with limited tools.
            </p>
            <p className="text-2xl font-bold text-blue-600 mb-12 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_0.1s_forwards]">
              🚀 Nexa automates & scales your entire coaching business—so you
              make money without working 24/7.
            </p>
          </div>
        </section>

        {/* How It Works */}
        <section className="py-20">
          <div className="max-w-4xl mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_forwards]">
              📲 How It Works{" "}
              <span className="text-blue-600">(3-Minute Setup!)</span>
            </h2>

            <div className="grid gap-8 mb-16">
              {[
                {
                  step: "1️⃣",
                  title: "Create Your Account",
                  description: "Sign up & customize your coaching hub.",
                },
                {
                  step: "2️⃣",
                  title: "Set Your Services",
                  description:
                    "Choose what you offer (resume reviews, mock interviews, coaching calls, etc.).",
                },
                {
                  step: "3️⃣",
                  title: "Share Your Link & Earn",
                  description:
                    "Clients book, pay, and get results—without you lifting a finger.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center p-6 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 opacity-0 animate-[slideUp_0.6s_ease-out_forwards]"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <span className="text-2xl mr-6">{item.step}</span>
                  <div>
                    <h3 className="font-semibold text-xl mb-2">{item.title}</h3>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center mb-16">
              <p className="text-2xl font-bold text-green-600 mb-4 transform translate-y-8 opacity-0 animate-[slideUp_0.8s_ease-out_forwards]">
                🎉 That's it. More impact. More income. Less stress.
              </p>
            </div>

            {/* Final CTA */}
            <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-8 text-white text-center transform hover:scale-[1.02] transition-all duration-300">
              <h2 className="text-3xl font-bold mb-6">
                🚀 Start Monetizing Your Expertise Today
              </h2>
              <p className="text-xl mb-8">
                Join top career creators who are scaling their income without
                the extra workload.
              </p>
              <a
                href="https://tally.so/r/wkJO9d"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-8 py-4 bg-white text-blue-600 rounded-full hover:bg-blue-50 transition-all duration-300 hover:scale-105 hover:shadow-lg group font-semibold"
              >
                👉 Sign Up Now & Get Early Access
              </a>
              <p className="text-sm mt-4 text-blue-100">
                (Spots are limited—secure yours before the waitlist fills up!)
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <style jsx global>{`
        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(2rem);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

const FeatureCard = ({ icon, title, description, delay = "0" }) => (
  <div
    className="p-4 rounded-xl bg-white/50 hover:bg-white/80 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1 group"
    style={{
      opacity: 0,
      animation: `slideUp 0.6s ease-out forwards`,
      animationDelay: `${delay}ms`,
    }}
  >
    <div className="mb-3 transform transition-transform duration-300 group-hover:scale-110">
      {icon}
    </div>
    <h3 className="font-semibold mb-2 text-gray-900">{title}</h3>
    <p className="text-sm text-gray-600">{description}</p>
  </div>
);

const BenefitCard = ({ icon, title, description, delay = "0" }) => (
  <div
    className="bg-white/80 backdrop-blur-lg rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-white/90 group"
    style={{
      opacity: 0,
      animation: `slideUp 0.6s ease-out forwards`,
      animationDelay: `${delay}ms`,
    }}
  >
    <div className="mb-4 transform transition-transform duration-300 group-hover:scale-110">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-3 text-gray-900">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const TestimonialCard = ({
  name,
  role,
  image,
  text,
  followers,
  delay = "0",
}) => (
  <div
    className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 group"
    style={{
      opacity: 0,
      animation: `slideUp 0.6s ease-out forwards`,
      animationDelay: `${delay}ms`,
    }}
  >
    <div className="flex items-center mb-4">
      <img
        src={image}
        alt={name}
        className="w-12 h-12 rounded-full object-cover mr-4"
      />
      <div>
        <h3 className="font-semibold text-gray-900">{name}</h3>
        <p className="text-sm text-gray-600">{role}</p>
      </div>
    </div>
    <p className="text-gray-700 mb-4">{text}</p>
    <div className="flex items-center text-sm text-blue-600 font-medium">
      <Users className="w-4 h-4 mr-2" />
      {followers} Followers
    </div>
  </div>
);

export default CreatorPage;
