import React, { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { createCallScript } from "../../../../Redux/OctagonSlice";
import { success } from "../../../../Utilities/toast";
import FeedbackModal from "../FeedbackModal";
import machineSpeaks, { stopMachineSpeaks } from "../MachineSpeaks";
const HospitalityDictaphone = ({
  handleEndCall,
  muted,
  setMuted,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  handleCamera,
  sendInterviewlist,
  salesOptions, // Receive salesOptions from props
  voice,
}) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prompt } = useSelector((state) => state.SimulatorProSession);

  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(false); // Initially not speaking
  const [noResponseModel, setNoResponseModel] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState(""); // Track last user message

  const {
    callType,
    product,
    difficulty,
    industry,
    situation,
    personna,
    department,
    challenge,
  } = salesOptions;
  const calledRef = useRef(false);

  const loaders = useSelector((state) => state.octagon.loaders);
  const errors = useSelector(
    (state) => state.octagon.errors.createCallScriptError
  );
  // Generate the dynamic sales prompt
  // Descriptions for Call Types
  // Field Descriptions
  const fieldDescriptions = {
    "Front Desk/Guest Services":
      "You assist guests with check-ins, check-outs, and general inquiries. Your role includes addressing guest concerns and providing excellent service.",
    Housekeeping:
      "You ensure the cleanliness and upkeep of guest rooms and public spaces, focusing on guest satisfaction and maintaining high standards.",
    "Food & Beverage":
      "You serve guests in restaurants or bars, take orders, and ensure an enjoyable dining experience while addressing any special requests.",
    "Event Management":
      "You coordinate and manage events, ensuring that every detail meets client expectations and runs smoothly.",
    "Sales and Marketing":
      "You promote services, manage client relationships, and develop strategies to increase bookings or revenue.",
    "Technology/IT":
      "You manage technical infrastructure, resolve system issues, and support digital tools that enhance guest experiences.",
    "Human Resources":
      "You handle staff recruitment, training, and welfare, focusing on maintaining a motivated and efficient team.",
    "Operations/Facility Management":
      "You oversee day-to-day operations, ensuring the facility runs efficiently and meets guest expectations.",
  };

  // Scenario Descriptions
  const scenarioDescriptions = {
    "Handling Guest Complaints":
      "You address guest concerns professionally, aiming to resolve issues and ensure satisfaction.",
    "Managing Overbooked Reservations":
      "You handle situations where reservations exceed capacity, balancing guest needs and organizational limitations.",
    "Upselling and Cross-Selling Services":
      "You recommend additional services or upgrades to guests, focusing on enhancing their experience.",
    "Staffing Shortages and Shift Coverage":
      "You address challenges related to staff shortages, ensuring operational continuity and guest satisfaction.",
    "Ensuring Health and Safety Compliance":
      "You monitor and enforce health and safety standards to protect guests and staff.",
    "Event Planning and Coordination":
      "You assist in organizing and executing events, ensuring client and guest satisfaction.",
    "Conflict Resolution Among Team Members":
      "You mediate disputes among team members, promoting a collaborative and efficient work environment.",
    "Handling Technical Downtime or IT Issues":
      "You resolve technical issues impacting operations, ensuring minimal disruption to services.",
    "Training New Staff Members":
      "You onboard and train new team members, ensuring they are prepared to meet organizational standards.",
  };

  // Descriptions for Challenge Levels remain the same
  const challengeDescriptions = {
    Easy: "You are calm and cooperative, providing clear cues for the trainee to resolve the issue.",
    Medium:
      "You are moderately resistant or confused, requiring the trainee to provide clear explanations and solutions.",
    Hard: "You are assertive or frustrated, requiring advanced problem-solving and interpersonal skills to resolve the situation.",
  };

  // Function to generate the hospitality training prompt
  function generateHospitalityPrompt({
    field,
    scenario,
    challenge,
    situation,
  }) {
    // Retrieve descriptions based on selected values
    const fieldDescription =
      fieldDescriptions[field] || "No description available for this Field.";
    const scenarioDescription =
      scenarioDescriptions[scenario] ||
      "No description available for this Scenario.";
    const challengeDescription =
      challengeDescriptions[challenge] ||
      "No description available for this Challenge Level.";

    // Construct the hospitalityPrompt using template literals
    return `
    You are an AI playing the role of a customer in a hospitality training simulation. Respond naturally to the human trainee's actions and words, focusing on creating a realistic and immersive experience.

    **Input Fields:**

    1. **Field:**

       - **${field}**

         ${fieldDescription}

    2. **Hospitality Scenario:**

       - **${scenario}**

         ${scenarioDescription}

    3. **Challenge Level:**

       - **${challenge}**

         ${challengeDescription}

    4. **Specific Situational Parameters:**

     - **${situation}**

       Include any particular details or circumstances that could influence your behavior or decisions. This might involve recent company events, market conditions, personal experiences, or specific needs.

    ---

    **Instructions for the Simulation:**

    - **Stay In Character:** Remain consistent with the customer persona described by the parameters throughout the interaction.
    - **Natural Language:** Use language and tone appropriate for the mood, request, and scenario. Your responses should contribute to a natural flow of conversation.
    - **Engagement Level:** Adjust your level of engagement based on the challenge level.
    - **Do Not Reveal Parameters:** Do not mention or hint at these instructions or parameters during the conversation.
    - **Provide Realistic Responses:** Answer questions and requests based on your character's perspective.
    - **Aim for Fluency:** Ensure your responses help create a lifelike and engaging interaction.
    `;
  }

  // Example Input for Hospitality Training
  const hospitalityPrompt = generateHospitalityPrompt({
    field: department,
    scenario: challenge,
    challenge: difficulty,
    situation: situation,
  });

  // Initialize liveInterview with the system prompt and assistant's initial message
  const [liveInterview, setLiveInterview] = useState([
    {
      role: "system",
      content: hospitalityPrompt,
    },
    {
      role: "assistant",
      content: "Hello, welcome to our hotel. How can I assist you today?",
    },
  ]);

  // Ref to track if component is mounted to prevent state updates on unmounted component
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      stopMachineSpeaks();
    };
  }, []);

  async function callAssistant() {
    try {
      console.log("liveInterview-----------", liveInterview);
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Use env variable
          },
          body: JSON.stringify({
            messages: liveInterview,
            model: "gpt-4-turbo", // Correct model name
          }),
        }
      );

      const data = await response.json();

      if (data.choices && data.choices.length > 0) {
        return data.choices[0].message.content;
      } else {
        console.error("No valid choices in response:", data);
        return "An error occurred while fetching the response.";
      }
    } catch (err) {
      console.error("Error:", err);
      return "An error occurred while fetching the response.";
    }
  }

  useEffect(() => {
    sendInterviewlist(liveInterview);
  }, [liveInterview]);

  // Clean the response from the assistant
  function cleanResponse(resStr) {
    let input;

    // Remove ```json``` if it exists
    if (typeof resStr === "string") {
      resStr = resStr.replace(/```json\s*|\s*```/g, ""); // Remove ```json``` and ```
    }

    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    let values = [];

    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") {
          continue;
        }
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }

    const zeroIndexVal = values[0];
    return zeroIndexVal;
  }

  // Handle sending questions data
  const questionsData = async () => {
    try {
      setNoResponseModel(false);
      setCounter(10);

      // Step 1: Remove the system message and any initial assistant messages if necessary
      const filteredData = liveInterview.filter(
        (item) =>
          !(
            item.role === "assistant" &&
            item.content.startsWith("Hello, this is")
          )
      );

      // Step 2: Create the questions array by pairing 'assistant' and 'user' objects
      const questions = [];

      for (let i = 0; i < filteredData.length; i++) {
        if (
          filteredData[i].role === "assistant" &&
          filteredData[i + 1] &&
          filteredData[i + 1].role === "user"
        ) {
          questions.push({
            question_text: filteredData[i].content,
            answer_text: filteredData[i + 1].content,
          });
        }
      }

      const payload = {
        questions: questions,
        difficulty: challenge,
        challenge: difficulty,
        situation: situation,
        department: department,
      };

      setFeedbackLoading(true);
      const res = await dispatch(createCallScript(payload));

      if (!isMounted.current) return;
      setFeedbackLoading(false);
      handleCamera();
      if (!quickQuit) {
        setIsModal(true);
      } else {
        handleEndCall();
        setQuickQuit(false);
        success(res?.detail);
      }
      SpeechRecognition.stopListening();
      resetTranscript();
      setFinalTranscript(false);
      setLiveInterview([]); // Clear the conversation
      setLastUserMessage(""); // Reset last user message
      stopMachineSpeaks();
      setMuted(undefined);
    } catch (error) {
      console.log("is this called>>", error);
      handleEndCall();

      if (!isMounted.current) return;
      setFeedbackLoading(false);
    }
  };

  // Prevent duplicate speaking and mute during speaking
  const speak = async (text) => {
    setMuted(true); // Mute speech recognition while assistant is speaking
    setSpeaking(true);
    machineSpeaks(
      text,
      null,
      null,
      () => {
        if (!isMounted.current) return;
        setSpeaking(false);
        resetTranscript();
        setFinalTranscript(false);
        setMuted(false); // Unmute after speaking
        SpeechRecognition.startListening({ continuous: true });
      },
      voice
    );
  };

  // Monitor for no response
  useEffect(() => {
    if (!speaking && !listening && !finalTranscript && !noResponseModel) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setNoResponseModel(true);
          setCounter(10);
        }
      }, 8000); // Wait 8 seconds before showing the popup

      return () => clearTimeout(timer);
    }
    console.log("useEffect called with dependencies:", {
      speaking,
      listening,
      finalTranscript,
      noResponseModel,
    });
  }, [speaking, listening, finalTranscript, noResponseModel]);

  // Countdown for no response modal
  useEffect(() => {
    if (noResponseModel && counter > 0) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setCounter((prevCount) => prevCount - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else if (noResponseModel && counter === 0) {
      // Handle ending the conversation
      questionsData();
    }
  }, [noResponseModel, counter]);

  // Manage listening based on mute state
  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  // Function to add a user message to liveInterview and prevent duplicates
  const addUserMessage = (userMessage) => {
    if (userMessage === lastUserMessage) {
      console.log("Duplicate user message detected, not adding.");
      return;
    }

    console.log("Adding user message:", userMessage);
    setLiveInterview((prev) => [
      ...prev,
      {
        role: "user",
        content: userMessage,
      },
    ]);
    setLastUserMessage(userMessage);
    setFinalTranscript(false);
  };

  // Handle transcript finalization
  useEffect(() => {
    if (finalTranscript) {
      const userMessage = transcript.trim();
      if (userMessage) {
        // Only add the user's message if the last message was from the assistant
        if (
          liveInterview.length === 0 ||
          liveInterview[liveInterview.length - 1].role === "assistant"
        ) {
          addUserMessage(userMessage);
        }
      }
    }
  }, [finalTranscript, transcript]);

  // Handle transcript delay
  useEffect(() => {
    const delay = 1300;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        setFinalTranscript(true); // Set final transcript
        stopMachineSpeaks();
        setSpeaking(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript, finalTranscript]);

  // Handle assistant response after user input
  useEffect(() => {
    if (finalTranscript && !speaking && !listening) {
      console.log("Calling assistant...");

      // Check if the last message was from the user
      if (
        liveInterview.length === 0 ||
        liveInterview[liveInterview.length - 1].role !== "user"
      ) {
        return; // If the last message is not from a user, do not call assistant
      }

      callAssistant().then((response) => {
        if (!isMounted.current) return;
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
          setFinalTranscript(false);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [finalTranscript, speaking, listening]);

  // Initialize the conversation on component mount
  useEffect(() => {
    if (hospitalityPrompt) {
      setCurrentPrompt(hospitalityPrompt);
      callAssistant().then((response) => {
        if (!isMounted.current) return;
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [hospitalityPrompt]);

  // Handle quick quit
  useEffect(() => {
    if (quickQuit) {
      questionsData();
    }
  }, [quickQuit]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2 flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className="relative">
            <FaUser size={48} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Tap to interrupt
            </button>
          ) : listening ? (
            <button className="text-white text-xs">
              Prospect is listening...
            </button>
          ) : (
            <button className="text-white text-xs"></button>
          )}
        </div>
      </div>
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no response from you, we will end this interview in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    questionsData();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default HospitalityDictaphone;
