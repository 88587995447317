import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../Components/main-loader-mini";
import NewQuestionComponent from "../../../Components/QuestionList/newQuestionComponent"; // Make sure this path is correct.
import techStacks from "../../../DummyData/techStacks.json";

const TechStacks = ({
  developerRole,
  setTechStack,
  techStackIcon,
  selectedRole,
  setRoleSubCategoryId,
}) => {
  console.log("developerRole: ", developerRole);
  const { isLoading } = useSelector((state) => state.dashboard);
  const [showNewQuestion, setShowNewQuestion] = useState(false);

  const subCategories = selectedRole?.categories?.find(
    (category) =>
      category?.name?.replace(" ", "-").toLowerCase() == developerRole
  )?.subcategories;
  console.log("subCategories: ", subCategories);

  useEffect(() => {
    if (developerRole === "faang") {
      setShowNewQuestion(true);
    } else {
      setShowNewQuestion(false);
    }
  }, [developerRole]);

  const getTechStackCard = (item, index) => {
    return (
      <Link
        onClick={() => {
          setTechStack(item.name);
          setRoleSubCategoryId(item?.id);
        }}
        key={item?.id}
        to={`/interview/role/developer/${developerRole}`}
        className="ic-interview-items"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration={index * 200 + 500}
      >
        <div className="ic-icons">
          <img src={techStackIcon} className="img-fluid" alt={item.name} />
        </div>
        <p>{item.name}</p>
        <i
          className="ri-arrow-right-line ic-right-arrow"
          style={{ fontSize: "22px" }}
        />
      </Link>
    );
  };

  return (
    <div>
      {showNewQuestion ? (
        <NewQuestionComponent techStack="faang" interviewTimer={false} />
      ) : (
        <div className="ic-interviews">
          <div className="container">
            <div className="ic-interview-container">
              <h3 className="text-center text-capitalize">
                {developerRole === "qa-engineer"
                  ? "Ready to practice for your QA Automation Engineer Role?"
                  : "Which Tech Stack are you interested in practicing?"}
              </h3>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="ic-interview-card-container">
                  {subCategories.map((item, i) => getTechStackCard(item, i))}
                  {/* {techStacks[developerRole]?.tech_stacks
                    .sort((a, b) =>
                      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                    )
                    .map((item, i) => getTechStackCard(item, i))} */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechStacks;
