import React from "react";
import { useInView } from "react-intersection-observer";
import Section1 from "../../Components/Rucamps/Section1";
import Section2 from "../../Components/Rucamps/Section2";
import Section3 from "../../Components/Rucamps/Section3";
import Section4 from "../../Components/Rucamps/Section4";
import Section5 from "../../Components/Rucamps/Section5";
import Section6 from "../../Components/Rucamps/Section6";
import Section7 from "../../Components/Rucamps/Section7";
import Section8 from "../../Components/Rucamps/Section8";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";
import "./styles.css";

const Rucoming = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref6, inView: inView6 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref7, inView: inView7 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref8, inView: inView8 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className="app-container">
      <Header />
      <div ref={ref1} className={`section ${inView1 ? "slide-up" : ""}`}>
        <Section1 />
      </div>
      <div ref={ref2} className={`section ${inView2 ? "slide-up" : ""}`}>
        <Section2 />
      </div>
      <div ref={ref4} className={`section ${inView4 ? "slide-up" : ""}`}>
        <Section4 />
      </div>
      <div ref={ref3} className={`section ${inView3 ? "slide-up" : ""}`}>
        <Section3 />
      </div>
      <div ref={ref5} className={`section ${inView5 ? "slide-up" : ""}`}>
        <Section5 />
      </div>
      <div ref={ref6} className={`section ${inView6 ? "slide-up" : ""}`}>
        <Section6 />
      </div>
      <div ref={ref8} className={`section ${inView8 ? "slide-up" : ""}`}>
        <Section8 />
      </div>
      <div ref={ref7} className={`section ${inView7 ? "slide-up" : ""}`}>
        <Section7 />
      </div>
      <Footer />
    </div>
  );
};

export default Rucoming;
