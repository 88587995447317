import { CircleDot, Sparkles } from "lucide-react";
import React from "react";

const HeroSection = () => {
  return (
    <section className="relative min-h-screen bg-gradient-to-b from-blue-50 via-white to-indigo-50 overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-blue-200/50 rounded-full blur-3xl animate-pulse" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-indigo-200/50 rounded-full blur-3xl animate-pulse delay-700" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-purple-100/50 rounded-full blur-3xl animate-pulse delay-500" />
      </div>

      {/* Subtle grid pattern */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoNjB2NjBIMHoiLz48cGF0aCBkPSJNMzAgMzBoMzB2MzBIMzB6IiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L2c+PC9zdmc+')] opacity-50" />

      {/* Main content */}
      <div className="relative max-w-6xl mx-auto px-4 py-32 sm:px-6 lg:px-8 flex flex-col items-center justify-center min-h-screen">
        {/* Decorative elements */}
        <div className="absolute top-12 left-1/2 -translate-x-1/2 flex items-center gap-2 text-blue-400">
          <Sparkles className="w-6 h-6 animate-pulse" />
          <CircleDot className="w-4 h-4" />
          <Sparkles className="w-6 h-6 animate-pulse delay-150" />
        </div>

        <div className="text-center max-w-4xl mx-auto">
          {/* Title with gradient */}
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight">
            <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-transparent bg-clip-text">
              Our Mission
            </span>
          </h1>

          {/* Subtitle */}
          <p className="mt-8 text-2xl font-light text-gray-600">
            Empowering careers through transparency and innovation
          </p>

          {/* Main content with glass effect */}
          <div className="mt-12 backdrop-blur-lg bg-white/80 rounded-2xl p-8 shadow-xl border border-white">
            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              At Nexa, we believe every individual deserves a fair and
              transparent path to success. Our mission is to democratize career
              preparation, making it accessible, effective, and deeply
              personalized.
            </p>

            <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-8" />

            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              We are unwavering in our commitment to creating a 100% transparent
              job search ecosystem—where every candidate, whether excelling or
              needing growth, receives clear and honest feedback to learn,
              adapt, and thrive.
            </p>

            <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-8" />

            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              By fostering clarity and accountability, we aim to make the world
              more productive, honest, and empowering for job seekers and
              employers alike.
            </p>
          </div>
        </div>

        {/* Bottom decorative element */}
        <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
          <div className="w-12 h-1 bg-gradient-to-r from-blue-400 to-purple-400 rounded-full" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
