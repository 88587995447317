import React from "react";
import ReactModal from "react-modal";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import { updateOnboardingUser } from "../../../../Redux/authenticate/authenticate.action";
import { success } from "../../../../Utilities/toast";
import { Spin } from "antd";

const NotSureConfirmationModal = ({
  isOpenModal,
  setIsOpenModal,
  isLoading,
  setIsloading,
}) => {
  //REDUX
  const dispatch = useDispatch();

  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  //FUNCTIONS
  const handleSubmit = () => {
    setIsloading(true);
    const data = {
      ...stepState.isProfilePayload,
      target_career: stepState.selectedJobRole,
    };
    let payload = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      if (key === "image") {
        if (typeof val === "object") {
          payload.append(key, val);
        }
        return;
      } else {
        if (val) {
          payload.append(key, val);
        }
      }
    });
    dispatch(
      updateOnboardingUser({
        payload,
        onSuccess: (res) => {
          success(res);
          setIsOpenModal(false);
          setIsloading(false);
          setStepState(
            produce((draft) => {
              draft.manualQuestionBoard.activeStep = 0;
              draft.AIQuestionBoard.activeStep = 0;
              draft.notSureQuestionBoard.activeStep = 1;
              draft.isActiveMode = stepState.notSureKey;
              draft.isCarierGoalModal.value = false;
            })
          );
        },
        onError: () => {
          setIsloading(false);
        },
      })
    );
  };

  return (
    <ReactModal
      isOpen={isOpenModal}
      onRequestClose={() => {
        setIsOpenModal(false);
      }}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "40px 20px",
          maxWidth: "700px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <h2 className="text-xl font-semibold mb-4 capitalize">
        Hey that’s okay to not be sure! We have all been there but you just took
        the first step to success. We will now try our best to match you with a
        few career that suit you personally. Let’s get you hired.
      </h2>
      <div className="flex justify-end gap-2">
        <button
          className="ic-btn !bg-blue-600 !text-white chip-btn"
          onClick={() => setIsOpenModal(false)}
          disabled={isLoading}
        >
          Close
        </button>
        <button
          className="ic-btn chip-btn"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Sure {isLoading && <Spin size="medium" className="pl-3" />}
        </button>
      </div>
    </ReactModal>
  );
};

export default NotSureConfirmationModal;
