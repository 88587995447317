import React from "react";
import { useNavigate } from "react-router-dom";

const FeedbackModal = ({ open, handleClose, handleNavigation, sessionsId }) => {
  const navigate = useNavigate();
  if (!open) return null;
  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-3xl p-6 w-80 shadow-lg max-w-lg">
        <h2
          className="mb-4 text-[#2b6be2] text-center text-3xl font-bold"
        >
          {!sessionsId
            ? "Interview Submitted"
            : "Congratulations on completing your interview! 🎉"}
        </h2>
        {!sessionsId ? (
          <p className="text-gray-700 mb-4 text-lg text-center">
            Your interview has been submitted. Feedback will be ready within few
            minutes. You can access it from the
            <button
              onClick={handleNavigation}
              className="text-blue-500 underline"
            >
              feedback page
            </button>
            .
          </p>
        ) : (
          <p className="text-gray-700 mb-4 text-lg text-center">
            Thank you for your time and effort. We truly appreciate your
            participation. We will review your interview and send you the proper
            result in the{" "}
            <button
              onClick={() => {
                navigate("/IQ/jobboard", {
                  state: { tab: "Applied Jobs" },
                });
              }}
              className="hover:text-blue-500 underline"
            >
              Job page
            </button>{" "}
            soon. Have a great day! 😊
          </p>
        )}

        <button
          onClick={handleClose}
          className="bg-blue-500 text-white px-5 text-lg py-2 rounded-full mt-3 hover:bg-blue-600 flex ml-auto"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;
