import { useNavigate } from "react-router-dom";
import "./Section1.css";

const Section1 = () => {
  const navigate = useNavigate();

  const handleDemoClick = () => {
    window.open("https://trynexa.com/nexa-iq", "_blank");
  };

  const handleBrowseTalent = () => {
    window.location.href = "https://tally.so/r/3xlZWd";
  };

  return (
    <section className="hero-section">
      <div className="content-wrapper">
        <div className="text-block">
          <h1 className="hero-title">
            Transform Hiring with AI-Powered Recruitment
          </h1>
          <p className="hero-subtitle">
            Source, screen, and connect with top talent effortlessly. Nexa RU
            saves time, reduces costs, and delivers pre-vetted candidates with
            transparent insights.
          </p>
          <div className="cta-buttons">
            <button className="primary-btn" onClick={handleDemoClick}>
              Get Hired
            </button>
            <button className="secondary-btn" onClick={handleBrowseTalent}>
              Hire Elite Talent <span className="arrow">→</span>
            </button>
          </div>
        </div>
        <div className="image-block">
          <div className="image-wrapper">
            <img
              src="/images/rulogo.png"
              alt="AI Recruitment Solution"
              className="hero-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
