import React, { useEffect } from "react";
import Header from "../../Components/header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationTab from "./components/NotificationTab";
import DOMPurify from "dompurify";
import moment from "moment";
import Pagination from "../../Utilities/Pagination";
import {
  deleteNotifications,
  getAllNotifications,
  getUnReadNotifications,
  updateNotifications,
} from "../../Redux/NotificationsSlice";
import { useDispatch, useSelector } from "react-redux";
import IQShimmer from "../../Components/Shimmers/IQShimmer";
import TableShimmer from "../../Components/Shimmers/TableShimmer";
import { JobStatusBadge } from "../AutoApply/components/JobCard/JobStatusBadge";
const Notifications = () => {
  const { notifications, loading } = useSelector(
    (state) => state.notifications
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("All");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const handleMarkAsRead = (id) => {
    console.log(id, "id>>>>>");
    dispatch(
      updateNotifications({
        id,
        onSuccess: () => {
          if (activeTab === "All") dispatch(getAllNotifications());
          if (activeTab === "Unread") dispatch(getUnReadNotifications());
        },
        onError: () => {},
      })
    );
  };
  const handleDelete = (id) => {
    dispatch(
      deleteNotifications({
        notification_ids: [id],
        onSuccess: () => {
          if (activeTab === "All") dispatch(getAllNotifications());
          if (activeTab === "Unread") dispatch(getUnReadNotifications());
        },
        onError: () => {},
      })
    );
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    if (activeTab === "All") dispatch(getAllNotifications());
    if (activeTab === "Unread") dispatch(getUnReadNotifications());
  }, [dispatch, activeTab]);
  return (
    <>
      <Header />
      <div
        className="ic-interview-full-body m"
        style={{
          marginTop: "7em",
        }}
      >
        <main role="main" className="ml-sm-auto col-lg-12 px-4 bg-cover">
          <div className="pt-3 pb-2">
            <h1 className="h2">Notifications</h1>
          </div>
          <NotificationTab activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="bg-white rounded-lg border border-gray-200 mb-8 shadow-sm overflow-hidden">
            <div className="p-1">
              <div className="overflow-x-auto px-4">
                {loading ? (
                  <TableShimmer />
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Subject
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Message
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Type
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {notifications?.length > 0 ? (
                        notifications.map((notification) => {
                          const sanitizedDescription = DOMPurify.sanitize(
                            notification?.description
                          );
                          const tempElement = document.createElement("div");
                          tempElement.innerHTML = sanitizedDescription;

                          let plainTextDescription =
                            tempElement.querySelector("body")?.textContent ||
                            tempElement.textContent ||
                            "";
                          plainTextDescription = plainTextDescription
                            .replace(/\s+/g, " ")
                            .trim();

                          const truncatedText =
                            plainTextDescription
                              .split(" ")
                              .slice(0, 3)
                              .join(" ") +
                            (plainTextDescription.split(" ").length > 3
                              ? "..."
                              : "");

                          console.log(truncatedText, "truncatedText");
                          return (
                            <tr
                              key={notification.id}
                              className="hover:bg-gray-50"
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {notification.subject
                                  .split(" ")
                                  .slice(0, 3)
                                  .join(" ") +
                                  (notification.subject.split(" ").length > 3
                                    ? "..."
                                    : "N/A")}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {truncatedText}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm">
                                {notification?.notification_type ===
                                "not_verified"
                                  ? "Incomplete Profile"
                                  : notification?.notification_type ===
                                    "not_in_skill_gap_analysis"
                                  ? "Missing Skill"
                                  : notification?.notification_type ===
                                    "no_interview_training"
                                  ? "Interview Training"
                                  : notification?.notification_type ===
                                    "not_in_onboarding"
                                  ? "Onboarding"
                                  : notification?.notification_type ===
                                    "no_job_search"
                                  ? "Job Search"
                                  : notification?.notification_type ===
                                    "no_target_career"
                                  ? "Target Career"
                                  : notification?.notification_type || "N/A"}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm">
                                {" "}
                                {moment(notification?.created_on).format(
                                  "D MMM h:mm A"
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm">
                                {" "}
                                <JobStatusBadge
                                  status={
                                    notification.is_read ? "Read" : "Unread"
                                  }
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm">
                                <div className="flex gap-3">
                                  <button
                                    disabled={notification.is_read}
                                    className={`py-1 px-3 text-md rounded-lg font-medium text-white ${
                                      notification.is_read
                                        ? "bg-secondary"
                                        : "bg-primary"
                                    } `}
                                    onClick={() =>
                                      handleMarkAsRead(notification?.id)
                                    }
                                  >
                                    Mark as Read
                                  </button>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/notifications/${notification.id}`
                                      )
                                    }
                                    className="py-1 bg-primary px-3 text-md rounded-lg font-medium text-white"
                                  >
                                    <i className="ri-eye-line text-lg"></i>
                                  </button>
                                  <button
                                    className={`py-1 px-3 text-md rounded-lg font-medium text-white bg-red-500
                                   `}
                                    onClick={() =>
                                      handleDelete(notification.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-line text-lg"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="px-6 py-4 text-center text-sm text-gray-500"
                          >
                            No Notifications Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              {/* <div className="mt-4 overflow-x-auto pb-2">
                <div className="min-w-full flex justify-end">
                  <Pagination
                    currentPage={page}
                    totalPages={Math.ceil(
                      (notifications?.length || 0) / itemsPerPage
                    )}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default Notifications;
