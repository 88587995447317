import { useNavigate } from "react-router-dom";
import "./Section8.css";

const Section8 = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open("https://cal.com/prithvi-nexa/30min", "_blank");
  };

  return (
    <section className="cta-section">
      <div className="cta-container">
        <div className="text-content">
          <h2 className="section-title">Ready to Transform Hiring?</h2>
          <p className="section-subtitle">
            Join thousands of recruiters using Nexa to streamline their hiring
            process and onboard superstars.
          </p>
          <button className="demo-button" onClick={handleClick}>
            Book a Free Demo Today
          </button>
        </div>
        <div className="image-content">
          <img
            src="/images/Bootcamp/BootCampSection8.png"
            alt="Transform Hiring"
            className="cta-image"
            style={{
              position: "relative",
              top: "60px", // Adjust this value to move the image down; increase if needed
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Section8;
