// icons svg images
import alert from "../../../assets/icons/alert.svg";
import { ReactComponent as SkillsPractice } from "../../../assets/icons/skills.svg";
import { ReactComponent as adows } from "../../../assets/icons/adow.svg";
import { ReactComponent as octagon } from "../../../assets/icons/octagon.svg";
import { ReactComponent as Human } from "../../../assets/icons/human.svg";
import { ReactComponent as TaskImg } from "../../../assets/icons/tasksImg.svg";
import { ReactComponent as blogs } from "../../../assets/icons/blogs.svg";
import bell from "../../../assets/icons/bell.svg";
import bills from "../../../assets/icons/bills.svg";
import { ReactComponent as Budget } from "../../../assets/icons/budget.svg";
import card from "../../../assets/icons/card.svg";
import check from "../../../assets/icons/check.svg";
import empty_check from "../../../assets/icons/empty_check.svg";
import { ReactComponent as Gears } from "../../../assets/icons/gears.svg";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import menu from "../../../assets/icons/menu.svg";
import { ReactComponent as Plane } from "../../../assets/icons/plane.svg";
import plus from "../../../assets/icons/plus.svg";
import report from "../../../assets/icons/report.svg";
import search from "../../../assets/icons/search.svg";
import user from "../../../assets/icons/user.svg";
import announcements from "../../../assets/icons/announcements.png";
import { ReactComponent as Wallet } from "../../../assets/icons/wallet.svg";
// import Human from "../../../assets/icons/image_2024_06_06T06_05_35_355Z.svg";

//
import wealth from "../../../assets/icons/wealth.svg";
//import Voyceicon from "../../../assets/icons/voyce.jsx";
// person imasfages
import person_one from "../../../assets/media/images/person_one.jpg";
import person_two from "../../../assets/media/images/person_two.jpg";
import person_three from "../../../assets/media/images/person_three.jpg";
import person_four from "../../../assets/media/images/person_four.jpg";
// import Ai gif
import aiLoading from "../../../assets/Images/AiLoading.gif";
// import human from '../../../../src/assets/media/images/human.png'
import adow from "../../../../src/assets/media/images/image_2024_06_06T06_05_35_355Z.jpg";
import { ReactComponent as resumeImg } from "../../../assets/icons/resumeSvg.svg";
import { ReactComponent as interviewPractice } from "../../../assets/icons/interviewPractice.svg";
import { ReactComponent as inerviewSimulator } from "../../../assets/icons/inerviewSimulator.svg";
import { ReactComponent as simulatorPro } from "../../../assets/icons/simulatorPro.svg";
import { ReactComponent as liveSimulatorPro } from "../../../assets/icons/livesimulatorpro.svg";
import { ReactComponent as nexaIq } from "../../../assets/icons/nexaIq.svg";
import { ReactComponent as autoPilot } from "../../../assets/icons/autoPilot.svg";
export const iconsImgs = {
  alert,
  adows,
  Home,
  Human,
  octagon,
  blogs,
  TaskImg,
  bell,
  bills,
  Budget,
  card,
  check,
  empty_check,
  Gears,
  menu,
  Plane,
  plus,
  report,
  search,
  user,
  Wallet,
  wealth,
  announcements,
  resumeImg,
  interviewPractice,
  inerviewSimulator,
  simulatorPro,
  liveSimulatorPro,
  nexaIq,
  autoPilot,
  aiLoading,
  SkillsPractice,
};

export const personsImgs = {
  person_one,
  person_two,
  person_three,
  person_four,
  adow,
};
