import { useState, useRef, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";

const UserAddress = ({
  initialValue,
  setAddress,
  addressError,
  setAddressError,
}) => {
  const autocompleteRef = useRef(null);

  const [tempAddress, setTempAddress] = useState("");

  const handlePlaceSelected = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const address = place.formatted_address || "";
      setAddress(address);
      setTempAddress(address);
      setAddressError(false);
    }
  };

  const handleChangeAddress = (e) => {
    setAddress("");
    setTempAddress(e.target.value);
    setAddressError(false);
  };

  useEffect(() => {
    setAddress("");
    setTempAddress(initialValue);
    setAddressError(false);
  }, []);

  return (
    <>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        options={{
          componentRestrictions: { country: ["us", "gb", "ca"] },
        }}
        onPlaceChanged={handlePlaceSelected}
        className="w-full"
      >
        <input
          // required
          type="text"
          id="address"
          className="form-control mt-3"
          style={{ border: addressError && "1px solid #ff5c5c" }}
          placeholder="Address"
          name="address"
          value={tempAddress}
          onChange={handleChangeAddress}
        />
      </Autocomplete>
      {addressError && (
        <p className="text-red-500 text-sm self-start mt-1">
          Please select a location from dropdown
        </p>
      )}
    </>
  );
};

export default UserAddress;
