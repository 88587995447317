import React from "react";
import usecase1 from "../../assets/media/Landing/usecase1.svg";
import usecase2 from "../../assets/media/Landing/usecase2.svg";
import usecase3 from "../../assets/media/Landing/usecase3.svg";
import usecase4 from "../../assets/media/Landing/usecase4.svg";

const UseCases = () => {
  const useCaseData = [
    {
      icon: usecase1,
      title: <u>Marianna Academy</u>,
      descr:
        "We helped Marianna Academy Students with career preparation, upskilling, and job placement ",
      link: "https://drive.google.com/file/d/1kegVSbQKUghYDiz-7xt0YyCRUiaRXjzf/view?usp=sharing",
    },
    {
      icon: usecase2,
      title: "Department of Labor",
      descr:
        "Nexa partners with  training institutions to enhance their career services, offering at scale professional guidance, tools, and resources",
    },
    {
      icon: usecase3,
      title: "Upskill Academy",
      descr:
        "Nexa offers robust career support services designed to empower all walks of individuals at every stage of their professional journey.",
    },
    {
      icon: usecase4,
      title: "Techvoc Academy",
      descr:
        "We helped increase Techvoc academy's Data Science grad get placed in their careers at a higher rate.",
    },
  ];
  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">Nexa Case Studies</h1>
      <div className="container my-5">
        <div className="row usecase-row">
          {useCaseData.map((useCase, index) => (
            <div
              key={index}
              className="col col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0"
            >
              <div className="use_case-card">
                <div className="profileImage-div ">
                  <img src={useCase.icon} alt="" className="profileImage" />
                </div>
                <div className="textContainer">
                  {useCase?.link ? (
                    <a
                      href={useCase.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="title"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {useCase?.title}
                    </a>
                  ) : (
                    <p className="title">{useCase?.title}</p>
                  )}
                  <p className="description">{useCase?.descr}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases;
