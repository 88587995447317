import React from "react";
import {
  CreatableSelectWithPortal,
  handleCreatableSelectChange,
  NotSureStepHeader,
} from "../../../../../../../Pages/Onboarding/components/steps/context";
import { useRecoilState } from "recoil";
import { StepBoardingStateAtom } from "../../../../../../../recoil/onBoarding";
import { WORK_EXPERIENCE_OPTIONS } from "../../../../../../../Pages/Onboarding/components/boardingConstants";

const Step3 = () => {
  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  // DERIVED STATES
  const currentStep = stepState.notSureQuestionBoard.activeStep;
  const formData = stepState.notSureQuestionBoard.formData;

  return (
    <div className="ic-heading py-3">
      <NotSureStepHeader
        title="Past Experience and Education"
        subtitle={formData[currentStep - 1].question}
      />
      <div className="text-area">
        <CreatableSelectWithPortal
          options={WORK_EXPERIENCE_OPTIONS}
          value={formData[currentStep - 1].answer || null}
          onChange={(selectedOption) =>
            handleCreatableSelectChange(
              currentStep - 1,
              selectedOption,
              setStepState
            )
          }
          defaultLabel="Choose or Enter your Work Experience"
          name="workExperience"
        />
      </div>
    </div>
  );
};

export default Step3;
