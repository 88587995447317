import React, { useState } from "react";
import { StepBoardingStateAtom } from "../../../../recoil/onBoarding";
import { useRecoilState } from "recoil";
import TargetCareerGoalDropdown from "../../../../Pages/Onboarding/components/TargetCareerGoalDropdown";
import { useSelector } from "react-redux";
import { getProfessions } from "../../../../Redux/UserProfileSlice";
import { useDispatch } from "react-redux";
import { produce } from "immer";
import { error, success } from "../../../../Utilities/toast";
import ReactModal from "react-modal";
import NotSureConfirmationModal from "./NotSureConfirmationModal";
import { updateOnboardingUser } from "../../../../Redux/authenticate/authenticate.action";
import { Spin } from "antd";

const SelectCareerGoal = () => {
  //REDUX
  const dispatch = useDispatch();
  const { jobProfessions } = useSelector((state) => state.UserProfile);
  const { user } = useSelector((state) => state.authenticate);

  //RECOIL
  const [stepState, setStepState] = useRecoilState(StepBoardingStateAtom);

  //STATES
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  //FUNCTIONS
  const jobRolesData = jobProfessions?.professions?.map((item) => ({
    label: item?.display_name,
    value:
      item?.name +
      "#" +
      (item?.preferred_industry ? item?.preferred_industry : ""),
  }));

  const handleSubmit = () => {
    if (!stepState.selectedJobRole) {
      return error("Please select a job role");
    }
    if (stepState.selectedJobRole === stepState.notSureKey) {
      return setIsOpenConfirmation(true);
    }
    const data = {
      ...stepState.isProfilePayload,
      target_career: stepState.selectedJobRole,
    };
    setIsloading(true);
    let payload = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      if (key === "image") {
        if (typeof val === "object") {
          payload.append(key, val);
        }
        return;
      } else {
        if (val) {
          payload.append(key, val);
        }
      }
    });
    dispatch(
      updateOnboardingUser({
        payload,
        onSuccess: (res) => {
          success(res);
          setIsloading(false);
          setStepState(
            produce((draft) => {
              draft.manualQuestionBoard.activeStep = 1;
              draft.AIQuestionBoard.activeStep = 0;
              draft.notSureQuestionBoard.activeStep = 0;
              draft.isActiveMode = "manual";
              draft.isCarierGoalModal.value = false;
            })
          );
          if (stepState.isCarierGoalModal.mode === "careerGoals") {
            setStepState(
              produce((draft) => {
                draft.isCareerGoalBoarding.isOpenBoarding = true;
              })
            );
          }
        },
        onError: () => {
          setIsloading(false);
        },
      })
    );
  };

  const fetchJobTitles = async (value, page) => {
    await dispatch(
      getProfessions({ page, search: value, onSuccess: () => {} })
    );
  };
  return (
    <ReactModal
      isOpen={stepState.isCarierGoalModal.value}
      onRequestClose={() => {
        setStepState(
          produce((draft) => {
            draft.isCarierGoalModal.value = false;
          })
        );
      }}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          maxWidth: "700px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <h2 className="text-xl font-semibold mb-4 ">
        What is your target career goal?
      </h2>
      <div className="text-area">
        <TargetCareerGoalDropdown
          className="w-full h-12 capitalize"
          value={stepState?.selectedJobRole || user?.target_career}
          onChange={(value) => {
            setStepState(
              produce((draft) => {
                draft.selectedJobRole = value;
              })
            );
          }}
          data={jobRolesData ? jobRolesData : []}
          placeholder="Select Job Role"
          placeholderAdd="Add Your Own Job Title"
          fetchOptions={fetchJobTitles}
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-wrap items-center justify-end gap-3 mt-1">
        <button
          className="ic-btn chip-btn"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Submit {isLoading && <Spin size="medium" className="pl-3" />}
        </button>
      </div>
      <NotSureConfirmationModal
        isOpenModal={isOpenConfirmation}
        setIsOpenModal={() => {
          setIsOpenConfirmation(false);
        }}
        isLoading={isLoading}
        setIsloading={setIsloading}
      />
    </ReactModal>
  );
};

export default SelectCareerGoal;
